import React, { useEffect, useState } from "react";
import {
   Accordion,
   AccordionDetails,
   AccordionSummary,
   Alert,
   Box,
   Button,
   Table,
   TableBody,
   TableCell,
   TableContainer,
   TableHead,
   TableRow,
   Typography,
   useTheme,
} from "@mui/material";
import { RemoteTournament } from "../../useRemoteTournament";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TagChip from "./TagChip";
import CreateOrEditTagDialog from "./CreateOrEditTagDialog";
import useReload from "../../../header/useReload";
import { TagSummary } from "../../../rest.client/useTagsApi";
import { Maybe } from "../../../TYPE";
import { empty } from "../../../utils";

export const tagComparator = (a: TagSummary, b: TagSummary) => a.name.localeCompare(b.name);

export default function TagsExpander({ remoteTournament }: { remoteTournament: RemoteTournament }) {
   const theme = useTheme();
   const [expanded, setExpanded] = useState(false);
   const [openCreate, setOpenCreate] = useState(false);
   const [tagToEdit, setTagToEdit] = useState<Maybe<TagSummary>>();
   const { reload } = useReload("tags");
   const tags = remoteTournament.tags || [];

   useEffect(() => {
      if (expanded && (remoteTournament.tags == null || reload)) {
         remoteTournament.loadTags();
      }
   }, [expanded, remoteTournament, reload]);

   return (
      <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
         <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ExpandMoreIcon />}>
            <Box sx={{ display: "flex", alignItems: "center", gap: theme.spacing(1) }}>
               <Typography>Player tags</Typography>
            </Box>
         </AccordionSummary>
         <AccordionDetails>
            {remoteTournament.canEditNotEnded && (
               <Button
                  variant={"contained"}
                  sx={{ marginBottom: theme.spacing(2) }}
                  onClick={() => setOpenCreate(true)}
               >
                  Create tag
               </Button>
            )}
            <TableContainer>
               <Table>
                  <TableHead>
                     <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>Options</TableCell>
                     </TableRow>
                  </TableHead>
                  <TableBody>
                     {tags.sort(tagComparator).map((tag) => (
                        <TableRow
                           sx={{ cursor: "pointer" }}
                           key={tag.id}
                           onClick={remoteTournament.canEditNotEnded ? () => setTagToEdit(tag) : empty}
                        >
                           <TableCell>
                              <TagChip tag={tag} />
                           </TableCell>
                           <TableCell>
                              <Typography>{tag.description}</Typography>
                           </TableCell>
                           <TableCell></TableCell>
                        </TableRow>
                     ))}
                  </TableBody>
               </Table>
            </TableContainer>
            {tags.length === 0 && <Alert severity={"info"}>No tags found!</Alert>}
         </AccordionDetails>
         {remoteTournament.id && Boolean(openCreate || tagToEdit) && (
            <CreateOrEditTagDialog
               open={true}
               tagId={tagToEdit?.id}
               close={() => {
                  setOpenCreate(false);
                  setTagToEdit(null);
               }}
               tournamentId={remoteTournament.id}
            />
         )}
      </Accordion>
   );
}
