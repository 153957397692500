import React from "react";
import { TableCell } from "@mui/material";
import { StandingsRegResponse } from "../../../../rest.client/useStagesApi";

export default function Mtra31Cells({ standing }: { standing: StandingsRegResponse }) {
   return (
      <>
         <TableCell>{(standing.standing.opponentMatchWinPercentageInclByes * 100).toFixed(4)}</TableCell>
      </>
   );
}
