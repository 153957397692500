import React, { CSSProperties, useCallback, useMemo, useState } from "react";
import Panel from "./Panel";
import { Box, Button, Theme, Typography } from "@mui/material";
import { EMPTY_STRING, GLOBAL_VAR_NAME, TournamentCreationPagePanelIndex } from "../../UI_CONST";
import { SxProps } from "@mui/system";
import { DEFAULT_PADDING_IN_PX } from "../tournaments2dComponents/TOURNAMENTS_2D_CONST";
import useGlobal from "../../global/useGlobal";
import { TTournamentsPageState } from "../../TYPE";
import { useTournamentApi } from "../../rest.client/useTournamentApi";
import CircularProgress from "@mui/material/CircularProgress";
import useAddMessageItem from "../../messagesComponent/useAddMessageItem";
import { useNavigate, useParams } from "react-router-dom";
import { tournamentsPageDefaultState } from "./TournamentsCreationEditDialog";
import { RemoteTournament } from "../useRemoteTournament";
import { Procedure } from "../../utils";
import { toTournamentDetails } from "../../navPaths";
import MarkdownDescriptionDisplay from "../MarkdownDescriptionDisplay";

const textContainerSx: CSSProperties = {
   display: "flex",
   flexDirection: "column",
   boxSizing: "border-box",
   width: "100%",
   rowGap: `${DEFAULT_PADDING_IN_PX}px`,
};

const errorTextCss: SxProps<Theme> = {
   display: "inline-flex",
   color: (theme) => theme.palette.error.main,
};

const multilineContainerSx: SxProps<Theme> = {
   font: (theme) => theme.typography.body2,
};

const ConfirmationPanel: React.FC<{
   remoteTournament: RemoteTournament;
   onClose: Procedure;
}> = ({ remoteTournament, onClose }) => {
   const addMessageItem = useAddMessageItem();
   const { tournamentId } = useParams();
   const nav = useNavigate();

   const {
      createTournament: { call: createTournament },
      updateTournament: { call: updateTournament },
   } = useTournamentApi();

   const [tournamentsPageState, setTournamentsPageState] = useGlobal<TTournamentsPageState>(
      GLOBAL_VAR_NAME.TOURNAMENTS_DIALOG_STATE,
      tournamentsPageDefaultState
   );

   const isCreatingNewTournament = remoteTournament.id == null;
   const isEditingExistingTournament = useMemo(
      () => Boolean(!isCreatingNewTournament && tournamentId),
      [isCreatingNewTournament, tournamentId]
   );

   const [createTournamentOnGoing, setCreateTournamentOnGoing] = useState(false);

   const noNameProvided = useMemo(() => {
      const name = isEditingExistingTournament
         ? tournamentsPageState.editTournamentRequestData.name
         : tournamentsPageState.createTournamentRequestData.name;
      return name === EMPTY_STRING;
   }, [
      isEditingExistingTournament,
      tournamentsPageState.createTournamentRequestData.name,
      tournamentsPageState.editTournamentRequestData.name,
   ]);

   const numOfPlayersValid = useMemo(() => {
      const maxPlayerCount = isEditingExistingTournament
         ? tournamentsPageState.editTournamentRequestData.maxPlayerCount
         : tournamentsPageState.createTournamentRequestData.maxPlayerCount;
      return maxPlayerCount >= 3 && maxPlayerCount <= 256;
   }, [
      isEditingExistingTournament,
      tournamentsPageState.createTournamentRequestData.maxPlayerCount,
      tournamentsPageState.editTournamentRequestData.maxPlayerCount,
   ]);

   const datesValid = useMemo(() => {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const startDate = isEditingExistingTournament
         ? tournamentsPageState.editTournamentRequestData.startDate
         : tournamentsPageState.createTournamentRequestData.startDate;
      const endDate = isEditingExistingTournament
         ? tournamentsPageState.editTournamentRequestData.endDate
         : tournamentsPageState.createTournamentRequestData.endDate;
      return startDate <= endDate && endDate < new Date("2100-01-01");
   }, [
      isEditingExistingTournament,
      tournamentsPageState.editTournamentRequestData.startDate,
      tournamentsPageState.editTournamentRequestData.endDate,
      tournamentsPageState.createTournamentRequestData.startDate,
      tournamentsPageState.createTournamentRequestData.endDate,
   ]);

   const allDataValid = useMemo(
      () => !noNameProvided && numOfPlayersValid && datesValid,
      [noNameProvided, numOfPlayersValid, datesValid]
   );

   const numOfPlayersToDisplay = useMemo(() => {
      const maxPlayerCount = isEditingExistingTournament
         ? tournamentsPageState.editTournamentRequestData.maxPlayerCount
         : tournamentsPageState.createTournamentRequestData.maxPlayerCount;
      return maxPlayerCount < 3 ? (
         <Box sx={errorTextCss}>{`${maxPlayerCount} - Minimum 3 players`}</Box>
      ) : maxPlayerCount > 256 ? (
         <Box sx={errorTextCss}>{`${maxPlayerCount} - Maximum 256 players`}</Box>
      ) : (
         <b>{maxPlayerCount}</b>
      );
   }, [
      isEditingExistingTournament,
      tournamentsPageState.createTournamentRequestData.maxPlayerCount,
      tournamentsPageState.editTournamentRequestData.maxPlayerCount,
   ]);

   const nameToDisplay = useMemo(() => {
      if (noNameProvided) {
         return <Box sx={errorTextCss}>{"No name provided"}</Box>;
      } else {
         const name = isEditingExistingTournament
            ? tournamentsPageState.editTournamentRequestData.name
            : tournamentsPageState.createTournamentRequestData.name;
         return <b>{name}</b>;
      }
   }, [
      isEditingExistingTournament,
      noNameProvided,
      tournamentsPageState.createTournamentRequestData.name,
      tournamentsPageState.editTournamentRequestData.name,
   ]);

   const locationToDisplay = useMemo(() => {
      const location = isEditingExistingTournament
         ? tournamentsPageState.editTournamentRequestData.location
         : tournamentsPageState.createTournamentRequestData.location;
      return <b>{location}</b>;
   }, [
      isEditingExistingTournament,
      tournamentsPageState.createTournamentRequestData.location,
      tournamentsPageState.editTournamentRequestData.location,
   ]);

   const datesToDisplay = useMemo(() => {
      const startDate = isEditingExistingTournament
         ? tournamentsPageState.editTournamentRequestData.startDate
         : tournamentsPageState.createTournamentRequestData.startDate;
      const endDate = isEditingExistingTournament
         ? tournamentsPageState.editTournamentRequestData.endDate
         : tournamentsPageState.createTournamentRequestData.endDate;

      return datesValid ? (
         <b>{`${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`}</b>
      ) : (
         <Box sx={errorTextCss}>
            {`${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()} - Invalid dates`}
         </Box>
      );
   }, [
      isEditingExistingTournament,
      tournamentsPageState.editTournamentRequestData.startDate,
      tournamentsPageState.editTournamentRequestData.endDate,
      tournamentsPageState.createTournamentRequestData.startDate,
      tournamentsPageState.createTournamentRequestData.endDate,
      datesValid,
   ]);

   const resetCreateUpdateTournamentRequestData = useCallback(() => {
      setTournamentsPageState(tournamentsPageDefaultState);
   }, [setTournamentsPageState]);

   const handleCreateTournament = useCallback(() => {
      setCreateTournamentOnGoing(true);
      createTournament({ body: tournamentsPageState.createTournamentRequestData })
         .then((response) => {
            if (response) {
               addMessageItem(`Great job! Your tournament "${response.name}" has been created!`, "success", 10000);
               resetCreateUpdateTournamentRequestData();
               onClose();
               nav(toTournamentDetails(response.id));
            }
         })
         .finally(() => {
            setCreateTournamentOnGoing(false);
         });
   }, [
      addMessageItem,
      createTournament,
      nav,
      onClose,
      resetCreateUpdateTournamentRequestData,
      tournamentsPageState.createTournamentRequestData,
   ]);

   const handleUpdateTournament = useCallback(() => {
      if (tournamentsPageState.editTournamentId) {
         setCreateTournamentOnGoing(true);
         updateTournament({
            body: tournamentsPageState.editTournamentRequestData,
            pathParams: { id: tournamentsPageState.editTournamentId },
         }).then((response) => {
            if (response) {
               addMessageItem(`Your tournament "${response.name}" has been updated!`, "success", 10000);
               resetCreateUpdateTournamentRequestData();
               setCreateTournamentOnGoing(false);
               onClose();
               remoteTournament.loadData();
            }
         });
      }
   }, [
      addMessageItem,
      onClose,
      remoteTournament,
      resetCreateUpdateTournamentRequestData,
      tournamentsPageState.editTournamentId,
      tournamentsPageState.editTournamentRequestData,
      updateTournament,
   ]);

   const buttonText = useMemo(
      () => (isEditingExistingTournament ? "Confirm and Update" : "Confirm and Create"),
      [isEditingExistingTournament]
   );

   return (
      <Panel panelIndex={TournamentCreationPagePanelIndex.TOURNAMENT_CONFIRMATION} panelTitle={"Confirmation"}>
         <Button
            sx={{ position: "sticky" }}
            disabled={createTournamentOnGoing || !allDataValid}
            onClick={isEditingExistingTournament ? handleUpdateTournament : handleCreateTournament}
            variant={"contained"}
         >
            {createTournamentOnGoing ? <CircularProgress size={24} color={"secondary"} /> : buttonText}
         </Button>
         <Typography variant={"body1"}>{`Tournament - ${
            (
               isEditingExistingTournament
                  ? tournamentsPageState.editTournamentRequestData.inviteOnly
                  : tournamentsPageState.createTournamentRequestData.inviteOnly
            )
               ? "Invites only"
               : "Open registration"
         }`}</Typography>
         <div style={textContainerSx}>
            <Typography variant={"body2"}>Name: {nameToDisplay}</Typography>
            <Typography variant={"body2"}>Dates: {datesToDisplay}</Typography>
            <Typography variant={"body2"}>Location: {locationToDisplay}</Typography>
            <Typography variant={"body2"}>Size: {numOfPlayersToDisplay}</Typography>
         </div>
         <MarkdownDescriptionDisplay
            description={
               isEditingExistingTournament
                  ? tournamentsPageState.editTournamentRequestData.description
                  : tournamentsPageState.createTournamentRequestData.description
            }
         />
         <Button
            sx={{ position: "sticky" }}
            disabled={createTournamentOnGoing || !allDataValid}
            onClick={isEditingExistingTournament ? handleUpdateTournament : handleCreateTournament}
            variant={"contained"}
         >
            {createTournamentOnGoing ? <CircularProgress size={24} color={"secondary"} /> : buttonText}
         </Button>
      </Panel>
   );
};

export default ConfirmationPanel;
