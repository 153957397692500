import React, { useMemo } from "react";
import { Box, Grow, Theme, Typography, useMediaQuery } from "@mui/material";
import { SxProps } from "@mui/system";
import { Maybe } from "../../TYPE";
import { CommanderPair } from "../../cardFeatures/CardTypes";
import { MutableGame } from "../MutableGame";

const logGamePagePlayersAndCommandersSummaryPanelRootSx: SxProps = {
   position: "relative",
   width: "75vw",
   height: "100%",
   boxSizing: "border-box",
   display: "flex",
   flexWrap: "wrap",
   justifyContent: "space-around",
   alignItems: "center",
   padding: "1vmin",
   rowGap: "1vmin",
   pointerEvents: "none",
};

const logGamePagePlayerSummaryCommanderPairContainerBasicSx: SxProps = {
   pointerEvents: "none",
   position: "relative",
   boxSizing: "border-box",
};

const logGamePagePlayerSummaryCommanderPairContainerBaseImageSx: SxProps = {
   position: "relative",
   left: 0,
   top: 0,
   width: "100%",
   pointerEvents: "none",
};

const logGamePagePlayerSummaryCommanderPlaceholderSx: SxProps<Theme> = {
   position: "absolute",
   border: (theme) => "0.5vmin dashed " + theme.palette.borderColor,
   borderRadius: "4%",
   width: "80%",
   height: "80%",
   top: "50%",
   left: "50%",
   transform: "translate(-50%, -50%)",
};

const summaryTitleContainerSx: SxProps = {
   position: "relative",
   boxSizing: "border-box",
   width: "100%",
   textAlign: "center",
   paddingTop: "1vh",
};

const LogGamePagePlayersAndCommandersSummaryPanel: React.FC<{ game: MutableGame }> = ({ game }) => {
   const isMobile = !useMediaQuery("(min-width: 600px)");

   const cards = useMemo(() => {
      const allCommanderPairs: Array<Maybe<CommanderPair>> = [
         game.player1CommanderPair,
         game.player2CommanderPair,
         game.player3CommanderPair,
         game.player4CommanderPair,
      ];
      return allCommanderPairs.map((commanderPair, index) => {
         const commander1Exists = Boolean(commanderPair?.commander1);
         const commander2Exists = Boolean(commanderPair?.commander2);

         const logGamePagePlayerSummaryCommanderPairContainerSx: SxProps = {
            ...logGamePagePlayerSummaryCommanderPairContainerBasicSx,
            width: isMobile ? "40%" : "20%",
         };

         const logGamePagePlayer1CommanderImgSx = commander2Exists
            ? {
                 ...logGamePagePlayerSummaryCommanderPlaceholderSx,
                 boxShadow: "0 0 0.5vmin 0.1vmin rgba(0,0,0,1)",
                 border: "none",
                 transform: "none",
                 top: "auto",
                 right: "auto",
                 left: 0,
                 bottom: 0,
              }
            : {
                 ...logGamePagePlayerSummaryCommanderPlaceholderSx,
                 border: "none",
                 boxShadow: "0 0 0.5vmin 0.1vmin rgba(0,0,0,1)",
              };

         const logGamePagePlayer2CommanderImgSx = {
            ...logGamePagePlayerSummaryCommanderPlaceholderSx,
            boxShadow: "0 0 0.5vmin 0.1vmin rgba(0,0,0,1)",
            border: "none",
            transform: "none",
            left: "auto",
            bottom: "auto",
            top: 0,
            right: 0,
         };

         return (
            <Grow key={index} in={true} timeout={1000}>
               <Box
                  id={`logGamePagePlayer${index}SummaryCommanderPairContainer`}
                  sx={logGamePagePlayerSummaryCommanderPairContainerSx}
               >
                  <Box
                     component={"img"}
                     src={"/magic_card_transparent.png"}
                     sx={logGamePagePlayerSummaryCommanderPairContainerBaseImageSx}
                  />
                  {commander2Exists && (
                     <Box
                        component={"img"}
                        sx={logGamePagePlayer2CommanderImgSx}
                        src={commanderPair?.commander2?.largeImageUri}
                     />
                  )}
                  {commander1Exists ? (
                     <Box
                        component={"img"}
                        sx={logGamePagePlayer1CommanderImgSx}
                        src={commanderPair?.commander1.largeImageUri}
                     />
                  ) : (
                     <Box sx={logGamePagePlayerSummaryCommanderPlaceholderSx} />
                  )}
               </Box>
            </Grow>
         );
      });
   }, [
      game.player1CommanderPair,
      game.player2CommanderPair,
      game.player3CommanderPair,
      game.player4CommanderPair,
      isMobile,
   ]);

   return (
      <Box
         id={"logGamePagePlayersAndCommandersSummaryPanelRoot"}
         sx={logGamePagePlayersAndCommandersSummaryPanelRootSx}
      >
         <Box sx={summaryTitleContainerSx}>
            <Typography fontSize={"14px"} color={(theme) => theme.palette.text.primary}>
               Players and commanders
            </Typography>
         </Box>
         {cards}
      </Box>
   );
};

export default LogGamePagePlayersAndCommandersSummaryPanel;
