import React, { useCallback, useEffect } from "react";
import { Box, Button, useMediaQuery } from "@mui/material";
import { SxProps } from "@mui/system";
import {
   HEADER_LOGO_MARGIN_RIGHT_IN_PX,
   HEADER_SIDE_PADDING_IN_PX,
   HOME_PAGE_SWITCH_VIEW_BUTTON_MARGIN_BOTTOM_IN_VH,
   HOME_PAGE_SWITCH_VIEW_BUTTON_MARGIN_TOP_IN_VH,
} from "../../UI_CONST";
import useGlobal from "../../global/useGlobal";
import PopularCommandersPage from "./PopularCommandersPage";
import LeaderBoardPage from "./LeaderBoardPage";
import { Maybe, TPoint2 } from "../../TYPE";
import { randomPos } from "../../utils";

const homeRootSx: SxProps = {
   position: "absolute",
   width: "100%",
   height: "100%",
   overflow: "auto",
   display: "flex",
   flexDirection: "column",
   alignItems: "center",
};

const switchViewButtonMobileSx: SxProps = {
   position: "relative",
   flexGrow: 0,
   margin: "auto",
   marginTop: `${HOME_PAGE_SWITCH_VIEW_BUTTON_MARGIN_TOP_IN_VH}vh`,
   marginBottom: `${HOME_PAGE_SWITCH_VIEW_BUTTON_MARGIN_BOTTOM_IN_VH}vh`,
};

const switchViewButtonSx: SxProps = {
   ...switchViewButtonMobileSx,
   marginLeft: `${HEADER_SIDE_PADDING_IN_PX + 48 + HEADER_LOGO_MARGIN_RIGHT_IN_PX}px`,
};

const TopPlayersAndCardsPage: React.FC = () => {
   const [showTopCards, setShowTopCards] = useGlobal<boolean>("showTopCards", true);
   const isMobileDevice = !useMediaQuery("(min-width:600px)");
   const [, setTilesPos] = useGlobal<Maybe<TPoint2>>("tilesPoints", null);

   useEffect(() => {
      setTilesPos(randomPos());
   }, [setTilesPos]);

   const handleSwitchView = useCallback(() => {
      setShowTopCards(!showTopCards);
   }, [setShowTopCards, showTopCards]);

   return (
      <Box id={"homeRoot"} sx={homeRootSx}>
         <Button
            id={"switchViewButton"}
            sx={isMobileDevice ? switchViewButtonMobileSx : switchViewButtonSx}
            size={"medium"}
            variant={"outlined"}
            onClick={handleSwitchView}
         >
            {showTopCards ? "View top players" : "View top cards"}
         </Button>
         {showTopCards ? <PopularCommandersPage /> : <LeaderBoardPage />}
      </Box>
   );
};

export default TopPlayersAndCardsPage;
