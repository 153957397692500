import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useApplicationApi } from "../../rest.client/useApplicationApi";
import {
   FormControl,
   FormControlLabel,
   Paper,
   Radio,
   RadioGroup,
   TableFooter,
   TablePagination,
   TableRow,
   useTheme,
} from "@mui/material";
import useCurrentUser from "../../auth/useCurrentUser";
import GamesTable from "./GamesTable";
import TablePaginationActions from "./TablePagingActions";
import useReload from "../../header/useReload";
import ScrollableBorderedWrapper from "../../common/ScrollableBorderedWrapper";

function MyGamesPage() {
   const userData = useCurrentUser();
   let nav = useNavigate();
   const {
      findGamesByUserId: { call: findGamesByUserId, responseData: gamesResponse },
   } = useApplicationApi();
   const theme = useTheme();

   const [page, setPage] = React.useState(0);
   const [rowsPerPage, setRowsPerPage] = React.useState(25);
   const [hasPendingGames, setHasPendingGames] = useState(true);
   const { reload } = useReload();
   const [filter, setFilter] = useState<"PENDING" | "ALL" | "OWNED">("PENDING");

   const games = gamesResponse != null ? gamesResponse._embedded.games : [];

   const pageData =
      gamesResponse != null
         ? gamesResponse.page
         : {
              size: 0,
              totalElements: 0,
              totalPages: 0,
              number: 0,
           };

   useEffect(() => {
      if (hasPendingGames && filter === "PENDING" && games.length === 0 && gamesResponse) {
         setHasPendingGames(false);
         setFilter("ALL");
      }
   }, [filter, games.length, gamesResponse, hasPendingGames]);

   useEffect(() => {
      if (userData?.user?.uid != null) {
         findGamesByUserId({
            queryParams: {
               userId: userData.user.uid,
               pendingOnly: filter === "PENDING",
               creatorOnly: filter === "OWNED",
               page: page,
               size: rowsPerPage,
               sort: "createDate,desc",
            },
         });
      }
   }, [filter, findGamesByUserId, page, rowsPerPage, userData?.user?.uid, reload]);

   const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
   };
   return (
      <ScrollableBorderedWrapper>
         <Paper sx={{ display: "flex", flexDirection: "column", gap: theme.spacing(1) }}>
            <FormControl>
               <RadioGroup
                  row
                  aria-labelledby="demo-radio-buttons-group-label"
                  value={filter}
                  name="radio-buttons-group"
                  sx={{ display: "flex" }}
               >
                  <FormControlLabel
                     value="PENDING"
                     disabled={!hasPendingGames}
                     control={<Radio />}
                     label="Pending approval"
                     onClick={() => setFilter("PENDING")}
                  />
                  <FormControlLabel
                     value="ALL"
                     onClick={() => setFilter("ALL")}
                     control={<Radio />}
                     label="All games"
                  />
                  <FormControlLabel
                     value="OWNED"
                     control={<Radio />}
                     label="Created"
                     onClick={() => setFilter("OWNED")}
                  />
               </RadioGroup>
            </FormControl>
            <GamesTable games={games}>
               <TableFooter>
                  <TableRow>
                     <TablePagination
                        rowsPerPageOptions={[25, 50, 100]}
                        count={pageData.totalElements}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                           inputProps: {
                              "aria-label": "rows per page",
                           },
                           native: true,
                        }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                     />
                  </TableRow>
               </TableFooter>
            </GamesTable>
         </Paper>
      </ScrollableBorderedWrapper>
   );
}

export default MyGamesPage;
