import { Paper, Typography, useTheme } from "@mui/material";

export default function TermsOfServicePage() {
   const theme = useTheme();
   return (
      <Paper sx={{ padding: theme.spacing(1), overflow: "auto", height: "93%" }}>
         <Typography variant={"h3"}>Terms of Service</Typography>
         <Typography variant={"h4"}>Effective date: 23 October 2022</Typography>
         <Typography>
            Brainstorm TMS("Brainstorm TMS", "we", "us") is a website for tracking game data for EDH (also referred to
            herein as the “Application", "Website", "Site"). You are welcome to use the Application to engage with the
            community and store your game data. To that end, we provide you with these terms of service to advise you of
            the legal obligations you assume when you engage with the Brainstorm TMS community or otherwise access or
            use the Application or any services provided. These terms govern the use of the Application. By proceeding
            further you will be deemed to have accepted them.
         </Typography>

         <Typography variant={"h4"}>Intellectual Property</Typography>
         <Typography>
            Portions of Brainstorm TMS are unofficial Fan Content permitted under the Wizards of the Coast Fan Content
            Policy. The literal and graphical information presented on this site about Magic: The Gathering, including
            card images, mana symbols, and Oracle text, is copyright Wizards of the Coast, LLC, a subsidiary of Hasbro,
            Inc. Brainstorm TMS is not produced by or endorsed by Wizards of the Coast.
         </Typography>

         <Typography variant={"h4"}>Acceptable use</Typography>

         <Typography variant={"h5"}>Restrictions</Typography>
         <Typography>
            You are prohibited from violating or attempting to violate the security of the Site, including, without
            limitation, by (a) accessing data not intended for such user or logging onto a server or an account which
            the user is not authorized to access; (b) attempting to probe, scan or test the vulnerability of a system or
            network or to breach security or authentication measures without proper authorization; (c) accessing or
            using the Site or any portion thereof without authorization; or (d) introducing any viruses, Trojan horses,
            worms, logic bombs or other material which is malicious or technologically harmful.
         </Typography>
         <Typography>You agree to not carry out any of the following:</Typography>
         <ul>
            <li>
               Use the Site in any manner that could disable, overburden, damage, or impair the Site or interfere with
               any other party's use of the Site;
            </li>
            <li>
               Use any robot, spider or other automatic device, process or means to access the Site for any purpose,
               including monitoring or copying any of the material on the Site;
            </li>
            <li>
               Use any manual process to monitor or copy any of the material on the Site or for any other unauthorized
               purpose without our prior written consent;
            </li>
            <li>
               Use any device, software or routine that interferes with the proper working of the Site; or interfere
               with the working of the site
            </li>
            <li>
               Attempt to abuse the Elo calculation in any way, including, without limitation, (a) attempting to create
               fabricated game results to bolster Elo rating, (b) creating fake application accounts for the purpose of
               Elo farming.
            </li>
         </ul>

         <Typography variant={"h4"}>Disclaimers</Typography>
         <Typography>
            This Site is provided on an ‘as-is’ basis. Although we have made all reasonable efforts to ensure that all
            of the information on the Site is accurate at the time of inclusion, we do not represent that this is the
            case and it should not be relied upon as such. Please note that we may update or alter the information on
            this Site at any time without giving notice of the alterations. Accordingly you agree not to hold us
            responsible for any inaccuracies or omissions. We disclaim all warranties (to the fullest extent permitted
            by applicable law) express or implied, including, without limitation, implied warranties of merchantability,
            fitness for a particular purpose, compatibility, security, accuracy or infringement.
         </Typography>
         <Typography>
            We do not make any warranties or representations as to the completeness, accuracy or reliability of the
            contents of this Site. We neither accept nor assume any responsibility to you in relation to the contents of
            this Site. Access to and use of this Site is at the user’s own risk and we do not represent or warrant that
            the use of this Site or any material downloaded from it will not cause damage to property, including but not
            limited to loss of data or computer virus infection.
         </Typography>
         <Typography>
            In no event do we accept liability of any description including liability for negligence for any damages
            whatsoever resulting from loss of use, data or profits arising out of or in connection with the access, use
            or performance of this Site or any its contents.
         </Typography>

         <Typography variant={"h4"}>Governing Law</Typography>
         <Typography>
            This Site is established in Ireland in accordance with the laws of Ireland and will be governed by the laws
            of that country. When you use the Site, you accept that your use of the Site and any information on the
            Site, will also be governed by the laws of Ireland and if any claim or dispute arises from your use of the
            Site or any of the information on it, you agree that the Irish courts will have exclusive jurisdiction over
            all such claims or disputes.
         </Typography>
         <Typography>For any issues or complaints please contact us through our discord server.</Typography>
      </Paper>
   );
}
