import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Tab, Tabs, Typography, useTheme } from "@mui/material";
import { toStageViewPage, toTournamentDetails } from "../../navPaths";
import { RemoteTournament } from "../useRemoteTournament";
import CreateOrEditStageDialog from "./createDialog/CreateOrEditStageDialog";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

export default function StageTabs({
   remoteTournament,
   stageId,
}: {
   remoteTournament: RemoteTournament;
   stageId?: string;
}) {
   const nav = useNavigate();
   const theme = useTheme();
   const tournament = remoteTournament.tournament;

   const [openCreateDialog, setOpenCreateDialog] = useState(false);

   const value = stageId == null ? "DETAILS" : stageId;

   return tournament != null ? (
      <Tabs value={value} variant="scrollable" scrollButtons="auto" allowScrollButtonsMobile>
         <Tab value={"DETAILS"} onClick={() => nav(toTournamentDetails(tournament.id))} label={"General info"} />

         {tournament.stages.map((s, i) => (
            <Tab
               value={s.id}
               key={i}
               onClick={() => nav(toStageViewPage(tournament.id, s.id))}
               label={s.name + (s.status === "ACTIVE" ? " (Active)" : "")}
            ></Tab>
         ))}
         {remoteTournament.canEditNotEnded &&
            remoteTournament?.tournament &&
            remoteTournament?.tournament.stages.length < 5 && (
               <Tab
                  onClick={() => setOpenCreateDialog(true)}
                  label={
                     <Box sx={{ display: "flex", gap: theme.spacing(0.5), color: theme.palette.secondary.main }}>
                        <AddCircleOutlineIcon />
                        <Typography>Add stage</Typography>
                     </Box>
                  }
               />
            )}
         <CreateOrEditStageDialog
            open={openCreateDialog}
            close={() => setOpenCreateDialog(false)}
            remoteTournament={remoteTournament}
         />
      </Tabs>
   ) : (
      <></>
   );
}
