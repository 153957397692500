import React from "react";
import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { Procedure } from "../../utils";
import RoundTimerCountdown from "./RoundTimerCountdown";
import { RoundFullResponse } from "../../rest.client/useStagesApi";

export default function RoundTimerDialog({
   open,
   onClose,
   round,
}: {
   open: boolean;
   onClose: Procedure;
   round: RoundFullResponse;
}) {
   return (
      <Dialog
         fullScreen={true}
         open={open}
         aria-labelledby="alert-dialog-title"
         aria-describedby="alert-dialog-description"
         onKeyUp={(event) => {
            if (event.key === "Escape") {
               onClose();
            }
         }}
      >
         <DialogTitle id="alert-dialog-title">Round timer</DialogTitle>
         <DialogContent>
            <RoundTimerCountdown round={round} size={"80vmin"} fontSize={"10vmin"} />
            <Button sx={{ position: "absolute", right: 10, bottom: 10 }} onClick={onClose} autoFocus>
               Close
            </Button>
         </DialogContent>
      </Dialog>
   );
}
