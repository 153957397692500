import React, { useCallback, useEffect, useMemo, useState } from "react";
import Panel from "./Panel";
import { Checkbox, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField } from "@mui/material";
import { GLOBAL_VAR_NAME, TournamentCreationPagePanelIndex } from "../../UI_CONST";
import useGlobal from "../../global/useGlobal";
import { TTournamentsPageState } from "../../TYPE";
import { SaveTournamentRequest } from "../../rest.client/useTournamentApi";
import { useParams } from "react-router-dom";
import { tournamentsPageDefaultState } from "./TournamentsCreationEditDialog";
import { RemoteTournament } from "../useRemoteTournament";
import OrganiserEditBox from "./OrganiserEditBox";
import { Consumer } from "../../utils";
import { UserSummary } from "../../rest.client/useApplicationApi";

const OPEN_REGISTRATION = 0;
const INVITE_ONLY = 1;

const SettingsPanel: React.FC<{
   remoteTournament: RemoteTournament;
   addOrganiser: Consumer<UserSummary>;
   removeOrganiser: Consumer<UserSummary>;
   organisers: UserSummary[];
}> = ({ remoteTournament, addOrganiser, removeOrganiser, organisers }) => {
   const [tournamentsPageState, setTournamentsPageState] = useGlobal<TTournamentsPageState>(
      GLOBAL_VAR_NAME.TOURNAMENTS_DIALOG_STATE,
      tournamentsPageDefaultState
   );
   const { tournamentId } = useParams();

   const [maxNumOfPlayersFieldError, setMaxNumOfPlayersFieldError] = useState<string | null>(null);

   const isCreatingNewTournament = remoteTournament.id == null;
   const isEditingExistingTournament = useMemo(
      () => Boolean(!isCreatingNewTournament && tournamentId),
      [isCreatingNewTournament, tournamentId]
   );
   const isPublic = isEditingExistingTournament
      ? tournamentsPageState.editTournamentRequestData.isPublic
      : tournamentsPageState.createTournamentRequestData.isPublic;
   const makeDecklistsVisible = isEditingExistingTournament
      ? tournamentsPageState.editTournamentRequestData.makeDecklistsVisible
      : tournamentsPageState.createTournamentRequestData.makeDecklistsVisible;

   const setJoinMethod = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
         const num = Number(event.target.value);
         const newData: SaveTournamentRequest = isEditingExistingTournament
            ? {
                 ...tournamentsPageState.editTournamentRequestData,
                 inviteOnly: num === INVITE_ONLY,
              }
            : {
                 ...tournamentsPageState.createTournamentRequestData,
                 inviteOnly: num === INVITE_ONLY,
              };
         isEditingExistingTournament
            ? setTournamentsPageState({ ...tournamentsPageState, editTournamentRequestData: newData })
            : setTournamentsPageState({ ...tournamentsPageState, createTournamentRequestData: newData });
      },
      [isEditingExistingTournament, setTournamentsPageState, tournamentsPageState]
   );

   const setMaxNumOfPlayers = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
         const num = Number(event.target.value);
         const newData: SaveTournamentRequest = isEditingExistingTournament
            ? {
                 ...tournamentsPageState.editTournamentRequestData,
                 maxPlayerCount: num,
              }
            : {
                 ...tournamentsPageState.createTournamentRequestData,
                 maxPlayerCount: num,
              };
         isEditingExistingTournament
            ? setTournamentsPageState({ ...tournamentsPageState, editTournamentRequestData: newData })
            : setTournamentsPageState({ ...tournamentsPageState, createTournamentRequestData: newData });
      },
      [isEditingExistingTournament, setTournamentsPageState, tournamentsPageState]
   );

   useEffect(() => {
      const num = isEditingExistingTournament
         ? tournamentsPageState.editTournamentRequestData.maxPlayerCount
         : tournamentsPageState.createTournamentRequestData.maxPlayerCount;
      setMaxNumOfPlayersFieldError(num < 3 ? "Minimum 3 players" : num > 256 ? "Maximum 256 players" : null);
   }, [
      isEditingExistingTournament,
      tournamentsPageState.createTournamentRequestData.maxPlayerCount,
      tournamentsPageState.editTournamentRequestData.maxPlayerCount,
   ]);

   return (
      <Panel panelIndex={TournamentCreationPagePanelIndex.TOURNAMENT_SETTINGS} panelTitle={"Tournament Settings"}>
         <TextField
            fullWidth
            required
            error={maxNumOfPlayersFieldError !== null}
            value={
               isEditingExistingTournament
                  ? tournamentsPageState.editTournamentRequestData.maxPlayerCount
                  : tournamentsPageState.createTournamentRequestData.maxPlayerCount
            }
            type={"number"}
            size={"small"}
            variant={"outlined"}
            label={maxNumOfPlayersFieldError ? maxNumOfPlayersFieldError : "Max number of players"}
            onChange={setMaxNumOfPlayers}
            InputLabelProps={{ shrink: true }}
            InputProps={{ inputProps: { min: 3, max: 256 } }}
         />
         <FormControl fullWidth>
            <FormLabel>{"Join method"}</FormLabel>
            <RadioGroup
               row={false}
               value={
                  (
                     isEditingExistingTournament
                        ? tournamentsPageState.editTournamentRequestData.inviteOnly
                        : tournamentsPageState.createTournamentRequestData.inviteOnly
                  )
                     ? INVITE_ONLY
                     : OPEN_REGISTRATION
               }
               onChange={setJoinMethod}
            >
               <FormControlLabel
                  value={OPEN_REGISTRATION}
                  control={<Radio size={"small"} />}
                  label="Open registration"
               />
               <FormControlLabel value={INVITE_ONLY} control={<Radio size={"small"} />} label="Invites only" />
            </RadioGroup>
         </FormControl>
         <FormControl fullWidth>
            <FormLabel>{"Data visibility"}</FormLabel>
            <FormControlLabel
               control={
                  <Checkbox
                     checked={isPublic}
                     onClick={() => {
                        setTournamentsPageState({
                           ...tournamentsPageState,
                           createTournamentRequestData: {
                              ...tournamentsPageState.createTournamentRequestData,
                              isPublic: !isPublic,
                           },
                           editTournamentRequestData: {
                              ...tournamentsPageState.editTournamentRequestData,
                              isPublic: !isPublic,
                           },
                        });
                     }}
                  />
               }
               label="Publish tournament data"
            />
            <FormControlLabel
               control={
                  <Checkbox
                     checked={makeDecklistsVisible}
                     onClick={() => {
                        setTournamentsPageState({
                           ...tournamentsPageState,
                           createTournamentRequestData: {
                              ...tournamentsPageState.createTournamentRequestData,
                              makeDecklistsVisible: !makeDecklistsVisible,
                           },
                           editTournamentRequestData: {
                              ...tournamentsPageState.editTournamentRequestData,
                              makeDecklistsVisible: !makeDecklistsVisible,
                           },
                        });
                     }}
                  />
               }
               label="Decklists visible for all players"
            />
         </FormControl>
         <OrganiserEditBox addOrganiser={addOrganiser} removeOrganiser={removeOrganiser} organisers={organisers} />
      </Panel>
   );
};

export default SettingsPanel;
