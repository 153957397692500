import { useCallback, useEffect, useMemo, useState } from "react";
import { TagSummary, UpdateTagRequest, useTagsApi } from "../../../rest.client/useTagsApi";
import { Maybe } from "../../../TYPE";
import { Consumer, Procedure } from "../../../utils";

export interface RemoteTag {
   loading: boolean;
   id?: string;
   tag: Maybe<TagSummary>;
   loadData: Procedure;
   save: () => Promise<TagSummary>;
   tagSaveData: UpdateTagRequest;
   setSaveTagData: Consumer<UpdateTagRequest>;
}

export const defaultTagData = () => ({
   name: "",
   color: "#" + ((Math.random() * 0xffffff) << 0).toString(16).padStart(6, "0"),
   description: "",
});

export default function useRemoteTag(tournamentId: string, id?: string): RemoteTag {
   const {
      loading,
      getTag: { call: getTag, responseData: tag, clear },
      createTag: { call: createTagCall },
      updateTag: { call: updateTagCall },
   } = useTagsApi();
   const [saveTagData, setSaveTagData] = useState<UpdateTagRequest>(defaultTagData());

   useEffect(() => {
      if (tag && id && tag.id !== id) {
         clear();
      }
   }, [clear, id, tag]);

   const loadData = useCallback(async () => {
      if (id) {
         const loadedTag = await getTag({ pathParams: { id: id } });
         setSaveTagData({ description: loadedTag.description, color: loadedTag.color, name: loadedTag.name });
      }
   }, [getTag, id]);

   const save = useCallback(async () => {
      const res = tag
         ? await updateTagCall({
              body: saveTagData,
              pathParams: { id: tag.id },
           })
         : await createTagCall({ body: { ...saveTagData, tournamentId } });
      await getTag({ pathParams: { id: res.id } });
      return res;
   }, [createTagCall, getTag, tag, tournamentId, saveTagData, updateTagCall]);

   return useMemo(
      () => ({
         loading,
         loadData,
         tagSaveData: saveTagData,
         setSaveTagData: setSaveTagData,
         save,
         tag,
         id: tag?.id,
      }),
      [loading, loadData, saveTagData, save, tag]
   );
}
