import React, {ReactElement} from "react";
import {Box, Theme, Typography} from "@mui/material";
import {SxProps} from "@mui/system";
import {XLARGE_PADDING_IN_PX} from "../tournaments2dComponents/TOURNAMENTS_2D_CONST";
import {DECK_VIEWER_CARD_COLUMN_WIDTH_IN_VW} from "../../UI_CONST";

const columnGapInVw = (100 - 5 * DECK_VIEWER_CARD_COLUMN_WIDTH_IN_VW) / 6;

const rowSx: SxProps = {
    boxSizing: "border-box",
    padding: `${columnGapInVw * 0.5}vw`,
    position: "relative",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    width: "100%",
    rowGap: `${XLARGE_PADDING_IN_PX}px`,
    columnGap: `${columnGapInVw}vw`,
    overflow: "hidden",
    "&:hover": {
        "& .deckviewerRowTitle": {
            opacity: 1,
        },
    },
    borderBottom: "1px solid rgba(0, 0, 0, 0.5)",

}

const rowTitleSx: SxProps<Theme> = {
    position: "relative",
    pointerEvents: "none",
    width: "100%",
    color: (theme) => theme.palette.deckViewerTextColor,
    textShadow: (theme) => `0 0 0.5vmin ${theme.palette.deckViewerTextShadowColor}`,
    opacity: 0.4,
    transition: "opacity 0.3s",
}

const bottomInsetShadowSx: SxProps<Theme> = {
    boxSizing: "border-box",
    position: "absolute",
    height: 0,
    bottom: 0,
    left: 0,
    width: "100%",
    boxShadow: (theme) => `0 0 2vw 0.2vw ${theme.palette.deckViewerCardRowBottomShadowColor}`,
}

interface IDeckViewerCardRow {
    rowTitle: string,
    cardColumns: Array<ReactElement>,
    commanderColumns?: Array<ReactElement>,
}

const DeckViewerCardRow: React.FC<IDeckViewerCardRow> = ({rowTitle, cardColumns, commanderColumns}) => {
    return (
        <Box sx={rowSx}>
            <Typography className={"deckviewerRowTitle"} sx={rowTitleSx} variant={"h6"}>{`${rowTitle}`}</Typography>
            {commanderColumns ? commanderColumns : cardColumns}
            <Box sx={bottomInsetShadowSx}/>
        </Box>
    )
};

export default DeckViewerCardRow;
