import React, { useEffect } from "react";
import { useApplicationApi, UserStatsExtended } from "../../rest.client/useApplicationApi";
import { Box, Typography, useTheme } from "@mui/material";
import useGlobal from "../../global/useGlobal";
import { Maybe, TPoint2 } from "../../TYPE";
import GamesTable from "../../gameFeatures/myGamesPage/GamesTable";
import useReload from "../../header/useReload";
import { randomPos } from "../../utils";

export default function PlayerGamesTable({ id }: { id: string }) {
   const [, setTilesPos] = useGlobal<Maybe<TPoint2>>("tilesPoints", null);
   const theme = useTheme();
   const { reload } = useReload();

   const {
      findUserById: { call: findUser, responseData: userSummaryData },
      findGamesByUserId: { call: findGamesByUserId, responseData: gamesResponse },
   } = useApplicationApi();

   const userData = userSummaryData as UserStatsExtended | null;

   useEffect(() => {
      id && findUser({ pathParams: { id: id }, queryParams: { projection: "extendedStats" } });
      id &&
         findGamesByUserId({
            queryParams: {
               pendingOnly: false,
               creatorOnly: false,
               userId: id,
               page: 0,
               size: 10,
               sort: "createDate,desc",
            },
         });
   }, [findGamesByUserId, findUser, id, reload]);

   useEffect(() => {
      setTilesPos(randomPos());
   }, [setTilesPos]);

   return (
      <>
         {gamesResponse?._embedded.games && userData && (
            <Box sx={{ margin: theme.spacing(1) }}>
               <Typography variant={"h6"}>Recent games for {userData.name}</Typography>
               <GamesTable games={gamesResponse?._embedded.games} />
            </Box>
         )}
      </>
   );
}
