import React, { memo, ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { Box, Button, TextField } from "@mui/material";
import BackdropContainerComponent from "../../backdropContainer/BackdropContainerComponent";
import { SxProps } from "@mui/system";
import { useApplicationApi } from "../../rest.client/useApplicationApi";
import { EMPTY_STRING } from "../../UI_CONST";
import CommentComponent from "./CommentComponent";
import useReload from "../../header/useReload";
import useCurrentUser from "../../auth/useCurrentUser";

const flexColumnContainerSx: SxProps = {
   width: "100vw",
   height: "100vh",
   display: "flex",
   flexDirection: "column",
};
const commentsSectionSx: SxProps = {
   width: "100%",
   margin: "auto",
   flexGrow: 1,
   overflow: "auto",
   display: "flex",
   flexDirection: "column",
};

const newCommentSectionSx: SxProps = {
   width: "100%",
   margin: "auto",
   flexGrow: 0,
   display: "flex",
   flexDirection: "column",
};

const newCommentTextFieldSx: SxProps = {
   margin: "1vmin",
};
const newCommentCreateButtonSx: SxProps = {
   margin: "auto",
   marginBottom: "1vmin",
};

interface IGameCommentComponentProps {
   showGameComment: boolean;
   gameId: string;
   closeCommentHandler: () => void;
}

const GameCommentsComponent: React.FC<IGameCommentComponentProps> = ({
   showGameComment,
   gameId,
   closeCommentHandler,
}) => {
   const { requestReload } = useReload();
   const {
      createComment: { call: createComment },
      findCommentsByGame: { call: getComments, responseData: comments },
   } = useApplicationApi();
   const user = useCurrentUser();

   const [newCommentText, setNewCommentText] = useState(EMPTY_STRING);

   const enableAddCommentButton = useMemo(
      () => user.loggedIn && gameId && newCommentText && newCommentText.trim() !== EMPTY_STRING,
      [gameId, newCommentText, user.loggedIn]
   );

   const loadComments = useCallback(() => {
      gameId && getComments({ queryParams: { id: gameId, sort: "createDate,desc" } });
   }, [gameId, getComments]);

   const addCommentButtonHandler = useCallback(() => {
      enableAddCommentButton &&
         createComment({ pathParams: { id: gameId }, body: { text: newCommentText } }).finally(() => {
            loadComments();
            setNewCommentText("");
            requestReload();
         });
   }, [createComment, enableAddCommentButton, gameId, loadComments, newCommentText, requestReload]);

   useEffect(() => {
      loadComments();
   }, [loadComments]);

   const commentElements = useMemo(() => {
      let result: Array<ReactNode> = [];
      if (comments) {
         const totalNumOfComments = comments._embedded.comments.length;
         result = comments._embedded.comments.map((comment, i) => (
            <CommentComponent key={i} commentIndex={totalNumOfComments - i} commentData={comment} />
         ));
      }
      return result;
   }, [comments]);

   return (
      <BackdropContainerComponent closeHandler={closeCommentHandler} open={showGameComment}>
         <Box sx={flexColumnContainerSx}>
            <Box sx={commentsSectionSx}>{commentElements}</Box>
            <Box sx={newCommentSectionSx}>
               {/*Had to move this one up for mobile...the browser on mobile moves its search bar over it sometimes it is really annoying*/}
               <Button
                  disabled={!enableAddCommentButton}
                  onClick={addCommentButtonHandler}
                  variant={"contained"}
                  color={"secondary"}
                  sx={newCommentCreateButtonSx}
               >
                  Add Comment
               </Button>
               <TextField
                  color={"secondary"}
                  id={"newCommentField"}
                  sx={newCommentTextFieldSx}
                  disabled={!user.loggedIn}
                  multiline
                  rows={5}
                  value={newCommentText}
                  onChange={(v) => v.target.value.length <= 500 && setNewCommentText(v.target.value)}
               />
            </Box>
         </Box>
      </BackdropContainerComponent>
   );
};

export default memo(GameCommentsComponent);
