import React, { useMemo } from "react";
import { Box, TextField, Theme, Typography, useMediaQuery } from "@mui/material";
import { SxProps } from "@mui/system";
import { MutableGame } from "../MutableGame";
import { CARD_HEIGHT_WIDTH_RATIO } from "../../UI_CONST";

const rotationGap = 15;

const logGamePageOptionalGameDataSummaryPanelRootBasicSx: SxProps = {
   position: "relative",
   height: "100%",
   boxSizing: "border-box",
   display: "flex",
   flexWrap: "wrap",
   justifyContent: "space-around",
   alignItems: "center",
   pointerEvents: "none",
};

const optionalDataContainerBasicSx: SxProps = {
   position: "relative",
   // border: "1px solid black",
   display: "flex",
   justifyContent: "space-evenly",
};

const endingTurnSx: SxProps = {
   width: "90px",
};

const winConditionSx: SxProps = {
   width: "180px",
};

const optionalDataCardsContainerSx: SxProps = {
   position: "relative",
   boxSizing: "border-box",
   width: "40%",
};

const optionalDataTitleContainerSx: SxProps = {
   position: "relative",
   boxSizing: "border-box",
   width: "100%",
   textAlign: "center",
   padding: "1vh",
   paddingBottom: "2vh",
   color: "rgba(0 ,0, 0, 0.6)",
};

const cardsContainerBaseImageSx: SxProps = {
   position: "relative",
   left: 0,
   top: 0,
   width: "100%",
};

const optionalCardsBaseBasicSx: SxProps<Theme> = {
   position: "absolute",
   height: "15.5vh",
   width: `${15.5 / CARD_HEIGHT_WIDTH_RATIO}vh`,
   boxSizing: "border-box",
   borderRadius: "4%",
   left: "50%",
   top: "50%",
   transform: "translate(-50%, -50%)",
   boxShadow: (theme) => `0 0 0.5vmin 0.1vmin ${theme.palette.boxShadowColor}`,
};

const optionalCardsPlaceholderSx: SxProps<Theme> = {
   ...optionalCardsBaseBasicSx,
   width: "70%",
   border: (theme) => `0.5vmin dashed ${theme.palette.borderColor}`,
   borderRadius: "4%",
   boxShadow: "none",
};

const optionalCardBaseImageSx: SxProps = {
   position: "relative",
   height: "100%",
   borderRadius: "4%",
};

const LogGamePageOptionalGameDataSummaryPanel: React.FC<{ game: MutableGame }> = ({ game }) => {
   const isMobile = !useMediaQuery("(min-width: 600px)");

   const logGamePageOptionalGameDataSummaryPanelRootSx = useMemo(
      () => ({
         ...logGamePageOptionalGameDataSummaryPanelRootBasicSx,
         width: isMobile ? "98vw" : "70vw",
      }),
      [isMobile]
   );

   const optionalDataContainerSx = useMemo(
      () => ({
         ...optionalDataContainerBasicSx,
         width: isMobile ? "100%" : "50%",
      }),
      [isMobile]
   );

   const winTypeToShow = useMemo(() => {
      switch (game.winType) {
         case "NONE":
            return "--";
         case "COMBO":
            return "Combo";
         case "COMBAT_DAMAGE":
            return "Combat damage";
         case "COMMANDER_DAMAGE":
            return "Commander damage";
         case "CONCEDE":
            return "All players conceded";
         case "TIME_LIMIT":
            return "Game time limit";
         case "INFECT":
            return "Infect";
         case "HARD_STAX_LOCK":
            return "Hard stax lock";
         default:
            return "--";
      }
   }, [game.winType]);

   const highImpactCardsToShow = useMemo(() => {
      const indexOffset = game.highImpactCards.length * 0.5 - 0.5;

      return game.highImpactCards.map((card, index) => {
         const baseSx: SxProps<Theme> = {
            ...optionalCardsBaseBasicSx,
            transformOrigin: "50% 100%",
            transform: `translate(-50%, -50%) rotateZ(${(index - indexOffset) * rotationGap}deg)`,
         };
         return (
            <Box key={index} sx={baseSx}>
               <Box component={"img"} src={card.largeImageUri} sx={optionalCardBaseImageSx} />
            </Box>
         );
      });
   }, [game.highImpactCards]);

   const gameWonByCardsToShow = useMemo(() => {
      const indexOffset = game.gameWonBy.length * 0.5 - 0.5;

      return game.gameWonBy.map((card, index) => {
         const baseSx: SxProps<Theme> = {
            ...optionalCardsBaseBasicSx,
            transformOrigin: "50% 100%",
            transform: `translate(-50%, -50%) rotateZ(${(index - indexOffset) * rotationGap}deg)`,
         };
         return (
            <Box key={index} sx={baseSx}>
               <Box component={"img"} src={card.largeImageUri} sx={optionalCardBaseImageSx} />
            </Box>
         );
      });
   }, [game.gameWonBy]);

   return (
      <Box id={"logGamePageOptionalGameDataSummaryPanelRoot"} sx={logGamePageOptionalGameDataSummaryPanelRootSx}>
         <Box sx={optionalDataTitleContainerSx}>
            <Typography fontSize={"14px"} color={(theme) => theme.palette.text.primary}>
               Optional data
            </Typography>
         </Box>
         <Box id={"optionalDataContainer1"} sx={optionalDataContainerSx}>
            <TextField
               sx={endingTurnSx}
               size={"small"}
               id={"endingTurnField"}
               label="Ending turn"
               value={game.endingTurn != null ? game.endingTurn : "--"}
            />
            <TextField
               sx={winConditionSx}
               size={"small"}
               id={"winConUsedField"}
               label="Win condition used"
               value={winTypeToShow}
            />
         </Box>
         <Box id={"optionalDataContainer2"} sx={optionalDataContainerSx}>
            <Box id={"gameDefiningCardsContainer"} sx={optionalDataCardsContainerSx}>
               <Box component={"img"} src={"/magic_card_transparent.png"} sx={cardsContainerBaseImageSx} />
               {highImpactCardsToShow.length > 0 ? highImpactCardsToShow : <Box sx={optionalCardsPlaceholderSx} />}
            </Box>
            <Box id={"winConCardsContainer"} sx={optionalDataCardsContainerSx}>
               <Box component={"img"} src={"/magic_card_transparent.png"} sx={cardsContainerBaseImageSx} />
               {gameWonByCardsToShow.length > 0 ? gameWonByCardsToShow : <Box sx={optionalCardsPlaceholderSx} />}
            </Box>
         </Box>
      </Box>
   );
};

export default LogGamePageOptionalGameDataSummaryPanel;
