import {
   Alert,
   Paper,
   Table,
   TableBody,
   TableCell,
   TableContainer,
   TableHead,
   TableRow,
   Typography,
   useMediaQuery,
   useTheme,
} from "@mui/material";
import React from "react";
import { GameSummary, PlayerSummary } from "../../rest.client/useApplicationApi";
import { useNavigate } from "react-router-dom";
import GameTableOptionsCell from "./GameTableOptionsCell";
import StatusIcon from "../StatusIcon";
import CompactMultiPlayerCell from "./CompactMultiPlayerCell";
import { blurBackgroundStyle } from "../pageLogGame/FlexibleContainer";
import { VIEW_GAME_PATH } from "../../index";
import { Consumer } from "../../utils";
import PlayerGameEntryCell from "./PlayerGameEntryCell";

export default function GamesTable({
   MenuCell,
   games,
   children,
   onClickGame,
}: React.PropsWithChildren<{
   games: Array<GameSummary>;
   MenuCell?: ({ game }: { game: GameSummary }) => JSX.Element;
   onClickGame?: Consumer<string>;
}>) {
   const nav = useNavigate();
   const theme = useTheme();
   const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));
   const medUp = useMediaQuery(theme.breakpoints.up("md"));
   return (
      <div>
         <TableContainer component={Paper} sx={{ ...blurBackgroundStyle }}>
            <Table aria-label="simple table">
               <TableHead>
                  <TableRow>
                     {medUp && <TableCell>Winner</TableCell>}
                     <TableCell>Players</TableCell>
                     <TableCell>Status</TableCell>
                     <TableCell></TableCell>
                  </TableRow>
               </TableHead>
               <TableBody>
                  {games.map((game) => {
                     const allPlayers: Array<PlayerSummary> = [
                        game.player1,
                        game.player2,
                        game.player3,
                        game.player4,
                     ].filter((p) => p != null) as Array<PlayerSummary>;
                     const winner = allPlayers.find((p) => p.winner);
                     return (
                        <TableRow
                           key={game.id}
                           sx={{ cursor: "pointer" }}
                           onClick={onClickGame ? () => onClickGame(game.id) : () => nav(VIEW_GAME_PATH + game.id)}
                        >
                           {medUp && (
                              <TableCell>
                                 {winner && <PlayerGameEntryCell player={winner} />}
                                 {winner == null && (
                                    <Typography
                                       width={smallScreen ? "100px" : "auto"}
                                       overflow={"hidden"}
                                       textOverflow={"ellipsis"}
                                       whiteSpace={"nowrap"}
                                    >
                                       <Alert severity={"warning"}>Game marked as draw</Alert>
                                    </Typography>
                                 )}
                              </TableCell>
                           )}
                           <TableCell>
                              <CompactMultiPlayerCell game={game} />
                           </TableCell>
                           <TableCell>
                              <StatusIcon status={game.status} iconSize={"1em"} />
                           </TableCell>
                           {MenuCell != null ? <MenuCell game={game} /> : <GameTableOptionsCell game={game} />}
                        </TableRow>
                     );
                  })}
               </TableBody>
               {children}
            </Table>
            {games.length === 0 && <Alert severity={"info"}>No games created for this match.</Alert>}
         </TableContainer>
      </div>
   );
}
