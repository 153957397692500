import { AppBar, Box, Toolbar, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import HeaderLogo from "./HeaderLogo";
import HeaderUserButton from "./HeaderUserButton";
import HeaderNavigator from "./HeaderNavigator";

const Header: React.FC = () => {
   const theme = useTheme();
   const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));
   return (
      <AppBar id={"headerRoot"} color={"secondary"} position={"static"}>
         <Box sx={{ marginLeft: theme.spacing(1), marginRight: theme.spacing(1) }}>
            <Toolbar disableGutters sx={{ gap: theme.spacing(1) }}>
               <HeaderLogo />
               <Box sx={{ flexGrow: 1 }}>{!smallScreen && <HeaderNavigator />}</Box>
               <Box sx={{ alignSelf: "flex-end" }}>
                  <HeaderUserButton />
               </Box>
            </Toolbar>
         </Box>
      </AppBar>
   );
};

export default Header;
