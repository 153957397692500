import { useMemo } from "react";
import { useAxiosInstance } from "./useAxiosInstance";
import useRequest from "./useRequest";

export function useTagsApi() {
   const instance = useAxiosInstance();

   const api = {
      getTag: useRequest<TagSummary, { id: string }, never, never>(
         useMemo(
            () => ({
               url: "/tags/{id}?projection=summary",
               method: "get",
            }),
            []
         ),
         instance
      ),
      createTag: useRequest<TagSummary, never, CreateTagRequest, never>(
         useMemo(
            () => ({
               url: "/tags",
               method: "post",
            }),
            []
         ),
         instance
      ),
      updateTag: useRequest<TagSummary, { id: string }, UpdateTagRequest, never>(
         useMemo(
            () => ({
               url: "/tags/{id}/update",
               method: "post",
            }),
            []
         ),
         instance
      ),
      deleteTag: useRequest<never, { id: string }, never, never>(
         useMemo(
            () => ({
               url: "/tags/{id}",
               method: "delete",
            }),
            []
         ),
         instance
      ),
   };

   const loading = Object.values(api).some((r) => Boolean(r.loading));
   return { ...api, loading };
}

export interface TagSummary {
   id: string;
   name: string;
   color: string;
   description: string;
}

export interface CreateTagRequest extends UpdateTagRequest {
   tournamentId: string;
}

export interface UpdateTagRequest {
   name: string;
   color: string;
   description: string;
}
