import React, { useMemo } from "react";
import { Box, Grow, useMediaQuery } from "@mui/material";
import { SxProps } from "@mui/system";
import { TPlayerIndex } from "../../TYPE";
import LogGamePagePlayerPicker from "./LogGamePagePlayerPicker";
import LogGamePageCommanderPlaceholder from "./LogGamePageCommanderPlaceholder";
import { MutableGame } from "../MutableGame";

const logGamePagePlayersAndCommandersPickerRootBasicSx: SxProps = {
   position: "relative",
   boxSizing: "border-box",
};

const logGamePagePlayersAndCommandersPickerBaseBasicSx: SxProps = {
   position: "relative",
   pointerEvents: "none",
};

interface ILogGamePagePlayersAndCommandersPicker {
   playerIndex: TPlayerIndex;
   game: MutableGame;
}

const LogGamePagePlayersAndCommandersPicker: React.FC<ILogGamePagePlayersAndCommandersPicker> = ({
   playerIndex,
   game,
}) => {
   const isMobileDevice = !useMediaQuery("(min-width:600px)");

   const logGamePagePlayersAndCommandersPickerRootSx: SxProps = useMemo(() => {
      return {
         ...logGamePagePlayersAndCommandersPickerRootBasicSx,
         height: isMobileDevice ? "29vh" : "auto",
         width: isMobileDevice ? "auto" : "20vw",
      };
   }, [isMobileDevice]);

   const logGamePagePlayersAndCommandersPickerBaseSx: SxProps = useMemo(() => {
      return {
         ...logGamePagePlayersAndCommandersPickerBaseBasicSx,
         height: isMobileDevice ? "100%" : "auto",
         width: isMobileDevice ? "auto" : "100%",
      };
   }, [isMobileDevice]);

   return (
      <Grow in={true} timeout={1000}>
         <Box
            id={`logGamePagePlayersAndCommandersPickerRoot_${playerIndex}`}
            sx={logGamePagePlayersAndCommandersPickerRootSx}
         >
            <Box
               component={"img"}
               src={"/magic_card_transparent.png"}
               sx={logGamePagePlayersAndCommandersPickerBaseSx}
            />
            <LogGamePageCommanderPlaceholder playerIndex={playerIndex} game={game} />
            <LogGamePagePlayerPicker playerIndex={playerIndex} game={game} />
         </Box>
      </Grow>
   );
};

export default LogGamePagePlayersAndCommandersPicker;
