import React from "react";
import { Box, Chip, Typography, useTheme } from "@mui/material";
import { PrizeTierFullResp } from "../../../rest.client/useTournamentApi";

export default function TierChip({ tier }: { tier: PrizeTierFullResp }) {
   const theme = useTheme();
   return (
      <>
         <Chip
            size={"medium"}
            color={tier.tier === 1 ? "primary" : "secondary"}
            label={
               <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box sx={{ display: "flex" }}></Box>
                  <Typography maxWidth={"160px"} overflow={"hidden"} textOverflow={"ellipsis"} whiteSpace={"nowrap"}>
                     {tier.name}
                  </Typography>
               </Box>
            }
            variant="outlined"
         />
      </>
   );
}
