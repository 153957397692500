import React, { useCallback, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Alert, Typography, useTheme } from "@mui/material";
import { RemoteStage } from "../stageView/useRemoteStage";
import { RemoteMatch } from "../stageView/useRemoteMatch";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TournamentGameEditDialog from "./editDialog/TournamentGameEditDialog";
import DeleteGameConfirmDialog from "../../../gameFeatures/DeleteGameConfirmDialog";
import { useApplicationApi } from "../../../rest.client/useApplicationApi";
import useReload from "../../../header/useReload";
import GamesList from "./GamesList";

export default function GamesListAccordion({
   remoteStage,
   remoteMatch,
   startExpanded,
}: {
   remoteMatch: RemoteMatch;
   remoteStage: RemoteStage;
   startExpanded: boolean;
}) {
   const theme = useTheme();
   const reload = useReload();
   const match = remoteMatch.match;
   const {
      deleteGame: { call: deleteGameRequest },
   } = useApplicationApi();
   const [expanded, setExpanded] = useState(startExpanded);
   const [openAddDialog, setOpenAddDialog] = useState(false);
   const [gameDeleteTarget, setGameToDelete] = useState<string | undefined>();
   const [gameToEditId, setGameToEditId] = useState<string | undefined>(undefined);
   const deleteGame = useCallback(() => {
      gameDeleteTarget != null &&
         deleteGameRequest({ pathParams: { id: gameDeleteTarget } })
            .then(() => reload.requestReload())
            .finally(() => {
               setGameToDelete(undefined);
            });
   }, [deleteGameRequest, gameDeleteTarget, reload]);

   const closeDialog = useCallback(() => {
      setOpenAddDialog(false);
      setGameToEditId(undefined);
   }, []);

   return (
      <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
         <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ExpandMoreIcon />}>
            <Typography>Games</Typography>
         </AccordionSummary>
         {match && (
            <AccordionDetails>
               {remoteMatch.anyGamesPending && (
                  <Alert severity="warning" sx={{ cursor: "pointer", marginBottom: theme.spacing(1) }}>
                     Some games are still pending review. All players in the game must approve the game or the
                     organisers need to force approval from the game controls.
                  </Alert>
               )}
               <GamesList remoteMatch={remoteMatch} games={match.games} onClickGame={(g) => setGameToEditId(g.id)} />
               {(openAddDialog || gameToEditId != null) && (
                  <TournamentGameEditDialog
                     open={openAddDialog || gameToEditId != null}
                     matchId={match.id}
                     gameId={gameToEditId}
                     onCancel={closeDialog}
                     onOk={closeDialog}
                     remoteStage={remoteStage}
                  />
               )}
               <DeleteGameConfirmDialog
                  open={gameDeleteTarget != null}
                  onOk={deleteGame}
                  onCancel={() => setGameToDelete(undefined)}
               />
            </AccordionDetails>
         )}
      </Accordion>
   );
}
