import React from "react";
import { TableCell } from "@mui/material";

export default function MstrHeaders({}: {}) {
   return (
      <>
         <TableCell>PMW%</TableCell>
         <TableCell>OppAvgPts</TableCell>
         <TableCell>OppMW%</TableCell>
      </>
   );
}
