import React, { CSSProperties, useCallback, useMemo, useState } from "react";
import { Box, Fab, useMediaQuery, useTheme } from "@mui/material";
import { SxProps } from "@mui/system";
import { CardExtendedInfo, CardNameAndImage } from "../../cardFeatures/CardTypes";
import CardPickerComponent from "../../cardFeatures/CardPickerComponent";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { CARD_HEIGHT_WIDTH_RATIO } from "../../UI_CONST";

const horizontalGapRange = 75;
const horizontalGapOffset = (100 - horizontalGapRange) * 0.5;
const rotationGap = 15;

const logGamePageOtherCardClickablePlaceholderBaseBasicSx: SxProps = {
   position: "absolute",
   height: "15.5vh",
   width: `${15.5 / CARD_HEIGHT_WIDTH_RATIO}vh`,
   boxSizing: "border-box",
   cursor: "pointer",
   borderRadius: "4%",
   left: "50%",
   top: "50%",
   transform: "translate(-50%, -60%)",
   boxShadow: "0 0 0.5vmin 0.1vmin rgba(0,0,0,1)",
   display: "inline-block",
};

const logGamePageOtherCardBaseImageSx: SxProps = {
   position: "relative",
   height: "100%",
   width: "100%",
   pointerEvents: "none",
   borderRadius: "4%",
   display: "inline-block",
};

const addCardButtonSx: SxProps = {
   position: "absolute",
   left: "33%",
   bottom: 0,
   transform: "translate(-50%, 50%)",
   width: "36px",
   height: "36px",
   boxShadow: "0 0 3px 0.3px rgba(0,0,0,1)",
   backgroundColor: "rgba(200,200,200,0.8)",
   color: "rgba(0,0,0,0.5)",
};

const removeCardButtonSx: SxProps = {
   ...addCardButtonSx,
   left: "66%",
};

const logGamePageOtherCardPlaceholderRootSx: SxProps = {
   position: "relative",
   boxSizing: "border-box",
   width: "48%",
   height: "30%",
};

interface ILogGamePageOtherCardPlaceholder {
   title: string;
   cardsToDisplay: Array<CardNameAndImage>;
   addCard: (card: CardNameAndImage) => void;
   removeCard: () => void;
   readOnly: boolean;
}

const LogGamePageOtherCardPlaceholder: React.FC<ILogGamePageOtherCardPlaceholder> = ({
   title,
   cardsToDisplay,
   addCard,
   removeCard,
   readOnly,
}) => {
   const isMobileDevice = !useMediaQuery("(min-width:600px)");
   const [showCardPicker, setShowCardPicker] = useState<boolean>(false);
   const theme = useTheme();

   const fieldsetCSS: CSSProperties = useMemo(
      () => ({
         position: "absolute",
         width: "100%",
         height: "100%",
         left: 0,
         top: 0,
         margin: 0,
         border: "1px solid " + theme.palette.borderColor,
         borderRadius: "4px",
         paddingLeft: "10px",
         boxSizing: "border-box",
      }),
      [theme.palette.borderColor]
   );

   const legendBasicCSS: CSSProperties = useMemo(
      () => ({
         position: "relative",
         fontSize: "10px",
         fontWeight: "400",
         fontFamily: "Roboto, Helvetica, Arial, sans-serif",
         padding: "0 5px",
         color: theme.palette.text.primary,
      }),
      [theme.palette.text.primary]
   );

   const legendCSS: CSSProperties = useMemo(() => {
      return {
         ...legendBasicCSS,
         fontSize: isMobileDevice ? "10px" : "12px",
      };
   }, [isMobileDevice, legendBasicCSS]);

   const selectCardHandler = useCallback(
      (card: CardExtendedInfo) => {
         addCard(card);
      },
      [addCard]
   );

   const openCardPickerHandler = useCallback(() => {
      setShowCardPicker(true);
   }, []);

   const closeCardPickerHandler = useCallback(() => {
      setShowCardPicker(false);
   }, []);

   const cards = useMemo(() => {
      const numOfCards = cardsToDisplay.length;
      const cardHorizontalGapInPercentage = horizontalGapRange / (numOfCards + 1);

      return cardsToDisplay.map((card, index) => {
         const baseSx: SxProps = {
            ...logGamePageOtherCardClickablePlaceholderBaseBasicSx,
            left: `${cardHorizontalGapInPercentage * (index + 1) + horizontalGapOffset}%`,
         };
         return (
            <Box key={index} sx={baseSx} onClick={openCardPickerHandler}>
               <Box id={card.id} component={"img"} src={card.largeImageUri} sx={logGamePageOtherCardBaseImageSx} />
            </Box>
         );
      });
   }, [cardsToDisplay, openCardPickerHandler]);

   const cardsForMobile = useMemo(() => {
      const indexOffset = cardsToDisplay.length * 0.5 - 0.5;

      return cardsToDisplay.map((card, index) => {
         const baseSx: SxProps = {
            ...logGamePageOtherCardClickablePlaceholderBaseBasicSx,
            transformOrigin: "50% 100%",
            transform: `translate(-50%, -60%) rotateZ(${(index - indexOffset) * rotationGap}deg)`,
         };
         return (
            <Box key={index} sx={baseSx} onClick={openCardPickerHandler}>
               <Box component={"img"} src={card.largeImageUri} sx={logGamePageOtherCardBaseImageSx} />
            </Box>
         );
      });
   }, [cardsToDisplay, openCardPickerHandler]);

   return (
      <Box id={"logGamePageOtherCardPlaceholderRoot"} sx={logGamePageOtherCardPlaceholderRootSx}>
         <fieldset style={fieldsetCSS}>
            <legend style={legendCSS}>{title}</legend>
            {isMobileDevice ? cardsForMobile : cards}
            {!readOnly && (
               <Fab size={"small"} sx={addCardButtonSx} onClick={openCardPickerHandler}>
                  <AddIcon />
               </Fab>
            )}
            {!readOnly && (
               <Fab size={"small"} sx={removeCardButtonSx} onClick={removeCard}>
                  <RemoveIcon />
               </Fab>
            )}
         </fieldset>

         <CardPickerComponent
            showCardPicker={showCardPicker}
            filterPartners={false}
            filterBackgrounds={false}
            filterCommanders={false}
            filterFriendsForever={false}
            closeCardPickerHandler={closeCardPickerHandler}
            selectCardHandler={selectCardHandler}
            disallowedCards={[]}
         />
      </Box>
   );
};

export default LogGamePageOtherCardPlaceholder;
