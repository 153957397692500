import React, { useState } from "react";
import {
   Alert,
   Box,
   Button,
   Dialog,
   DialogActions,
   DialogContent,
   DialogTitle,
   Table,
   TableBody,
   TableCell,
   TableHead,
   TableRow,
} from "@mui/material";
import { Procedure } from "../../../utils";
import { RemoteTournament } from "../../useRemoteTournament";
import { PrizeTierFullResp, RegistrationSummary, useTournamentApi } from "../../../rest.client/useTournamentApi";
import RegistrationSelector from "../matches/editDialog/RegistrationSelector";
import { Maybe } from "../../../TYPE";
import RegistrationChip from "../../registration/RegistrationChip";

export default function TierSingleDialog({
   open,
   onClose,
   remoteTournament,
   prizeTier,
}: {
   open: boolean;
   onClose: Procedure;
   remoteTournament: RemoteTournament;
   prizeTier: PrizeTierFullResp;
}) {
   const [selReg, setSelReg] = useState<Maybe<RegistrationSummary>>(null);
   const {
      addWinner: { call: addWinner },
      removeWinner: { call: removeWinner },
   } = useTournamentApi();

   const allWinners = prizeTier.winners;

   return (
      <Dialog open={open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
         <DialogTitle id="alert-dialog-title">{prizeTier.name}</DialogTitle>
         <DialogContent sx={{ height: "400px", minWidth: "400px" }}>
            <Box>
               {remoteTournament.isOrganiser && (
                  <Box sx={{ display: "flex" }}>
                     <RegistrationSelector
                        readOnly={!remoteTournament.isOrganiser}
                        reg={selReg}
                        setRegistration={setSelReg}
                        inUseRegs={allWinners.map((w) => w.registration)}
                        registrations={remoteTournament.tournament?.registrations || []}
                     />
                     <Button
                        disabled={selReg == null}
                        onClick={async () => {
                           if (selReg && remoteTournament.tournament) {
                              await addWinner({
                                 pathParams: {
                                    id: remoteTournament.tournament?.id,
                                    tierNumber: prizeTier.tier,
                                    registrationId: selReg?.id,
                                 },
                              });
                           }
                           setSelReg(null);
                           remoteTournament.loadData();
                        }}
                     >
                        Add winner
                     </Button>
                  </Box>
               )}
               {prizeTier.winners.length === 0 && <Alert severity={"info"}>No players assigned to this tier!</Alert>}
               {prizeTier.winners.length > 0 && (
                  <Table sx={{}} aria-label="simple table">
                     <TableHead>
                        <TableRow>
                           <TableCell>Name</TableCell>
                           <TableCell></TableCell>
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {prizeTier.winners.map((w, i) => (
                           <TableRow key={w.tier}>
                              <TableCell>
                                 <RegistrationChip
                                    registration={w.registration}
                                    onClickOpenRegistrationCard={true}
                                    remoteTournament={remoteTournament}
                                 />
                              </TableCell>
                              <TableCell>
                                 {remoteTournament.isOrganiser && (
                                    <Button
                                       onClick={async () => {
                                          remoteTournament.tournament &&
                                             (await removeWinner({
                                                pathParams: {
                                                   id: remoteTournament.tournament?.id,
                                                   tierNumber: prizeTier.tier,
                                                   registrationId: w.registration.id,
                                                },
                                             }));
                                          remoteTournament.loadData();
                                       }}
                                    >
                                       Delete
                                    </Button>
                                 )}
                              </TableCell>
                           </TableRow>
                        ))}
                     </TableBody>
                  </Table>
               )}
            </Box>
         </DialogContent>
         <DialogActions>
            <Button onClick={onClose} color={"secondary"}>
               Close
            </Button>
         </DialogActions>
      </Dialog>
   );
}
