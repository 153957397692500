import React from "react";
import { PlayerSummary } from "../../rest.client/useApplicationApi";
import { Avatar, Box, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";
import StatusIcon from "../StatusIcon";
import WinnerIcon from "../WinnerIcon";
import { empty } from "../../utils";

export default function PlayerGameEntryCell({ player, num }: { player: PlayerSummary; num?: number }) {
   const theme = useTheme();
   const medUp = useMediaQuery(theme.breakpoints.up("md"));
   return (
      <Box
         key={player.id}
         sx={{
            marginLeft: theme.spacing(1),
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: theme.spacing(1),
         }}
      >
         {num != null && (
            <Typography variant={"caption"} color={player.winner ? "primary" : "main"}>
               Player {num + 1}
            </Typography>
         )}
         <Tooltip title={player.commander1.name + (player.commander2 != null ? " // " + player.commander2.name : "")}>
            <Box sx={{ display: "flex" }}>
               <Avatar src={player.commander1.artCropImageUri} />
               {player.commander2 && <Avatar src={player.commander2.artCropImageUri} />}
            </Box>
         </Tooltip>
         <Box>
            <Typography
               sx={{ fontSize: ".8em", maxWidth: 200 }}
               width={"140px"}
               overflow={"hidden"}
               textOverflow={"ellipsis"}
               whiteSpace={"nowrap"}
            >
               <StatusIcon status={player.status} />
               <WinnerIcon won={player.winner} onClick={empty} />
               <span
                  style={{
                     marginLeft: theme.spacing(1),
                     color: player.winner ? theme.palette.primary.main : theme.palette.text.primary,
                     fontWeight: "normal",
                  }}
               >
                  {player?.name}
                  <span
                     style={{
                        color: player.winner ? theme.palette.primary.main : theme.palette.text.secondary,
                        fontStyle: "italic",
                     }}
                  >
                     /{player.user?.shortTag}
                  </span>
               </span>
            </Typography>
         </Box>
      </Box>
   );
}
