import { useCallback, useEffect, useState } from "react";
import { getAuth, getIdToken, User } from "firebase/auth";
import { initializeApp } from "firebase/app";
import useGlobal from "../global/useGlobal";

export interface UserDataType {
   user: User | null;
   idToken: string | null;
   loggedIn: boolean;
   setupComplete: boolean;
}

const firebaseConfig = {
   apiKey: "AIzaSyBsxKNMF8-uyJ27HGpA6L_FiInG7zoigjw",
   authDomain: "edh-elo.firebaseapp.com",
};
export const fireBaseApp = initializeApp(firebaseConfig);
export const fireBaseAuth = getAuth(fireBaseApp);

const refreshTime = 1000 * 60 * 45; // 45 mins

export function useCurrentUserListener() {
   const [userData, setUserData] = useGlobal<UserDataType>("userData", {
      user: null,
      idToken: null,
      loggedIn: false,
      setupComplete: false,
   });
   const [nextRefreshDate, setNextRefreshDate] = useState(new Date(Date.now() + refreshTime));
   const refreshToken = useCallback(
      (user: User) => {
         getIdToken(user, /* forceRefresh */ true).then(function (idToken) {
            setUserData({ user, idToken, loggedIn: true, setupComplete: true });
         });
      },
      [setUserData]
   );

   useEffect(() => {
      const interval = setInterval(() => {
         if (userData.user && Date.now() > nextRefreshDate.getTime()) {
            setNextRefreshDate(new Date(Date.now() + refreshTime));
            refreshToken(userData.user);
         }
      }, 1000);
      return () => {
         clearInterval(interval);
      };
   }, [nextRefreshDate, refreshToken, userData.user]);

   useEffect(() => {
      const currentUnSub = fireBaseAuth.onAuthStateChanged((user) => {
         if (user) {
            refreshToken(user);
         } else {
            setUserData({ user: null, idToken: null, loggedIn: false, setupComplete: true });
         }
      });

      return () => {
         currentUnSub();
      };
   }, [refreshToken, setUserData]);
}

export default function useCurrentUser() {
   const [userData] = useGlobal<UserDataType>("userData", {
      user: null,
      idToken: null,
      loggedIn: false,
      setupComplete: false,
   });

   return userData;
}
