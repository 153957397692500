import React from "react";
import { Alert } from "@mui/material";
import { RemoteStage } from "../stageView/useRemoteStage";
import DynamicNumberField from "./DynamicNumberField";

export default function SingleEliminationOptions({
   remoteStage,
   setError,
}: {
   remoteStage: RemoteStage;
   setError: (key: string, isInError: boolean) => void;
}) {
   const { saveRequest, setSaveRequest } = remoteStage;

   const playerCount = Number(saveRequest.singleEliminationSettings?.startingPlayerCount);

   return (
      <>
         {playerCount !== 256 && playerCount !== 64 && playerCount !== 16 && playerCount !== 4 && (
            <Alert severity="warning" sx={{ cursor: "pointer" }}>
               Elimination stages with a starting player count not a power of 4, for example 4/16/64/256, will result in
               uneven pod generation and final round player count. Please be sure this value is correct as you may be
               required to manually adjust pods.
            </Alert>
         )}
         <DynamicNumberField
            isError={(num) => num < 4 || num > 256}
            label={"Max player count"}
            errorMapKey={"seedCountLimit"}
            setError={setError}
            onChange={(seedCount) => {
               if (seedCount <= 256) {
                  setSaveRequest({
                     ...saveRequest,
                     singleEliminationSettings:
                        saveRequest.singleEliminationSettings != null
                           ? {
                                ...saveRequest.singleEliminationSettings,
                                startingPlayerCount: seedCount,
                             }
                           : null,
                  });
               }
            }}
            value={playerCount}
         ></DynamicNumberField>
      </>
   );
}
