import React, { CSSProperties, memo, ReactElement, useEffect } from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import InteractiveTileComponent from "./InteractiveTileComponent";
import { NUM_OF_TILES_PER_COLUMN, NUM_OF_TILES_PER_ROW } from "../UI_CONST";
import useGlobal from "../global/useGlobal";
import { Maybe, TPoint2 } from "../TYPE";

const blurStyle: CSSProperties = {
   position: "absolute",
   left: 0,
   top: 0,
   width: "100vw",
   height: "100vh",
   // backdropFilter: "blur(3px)",
   pointerEvents: "none",
};

const InteractiveTilesComponent: React.FC = () => {
   const [, setTilesPos] = useGlobal<Maybe<TPoint2>>("tilesPoints", null);
   const theme = useTheme();
   const smallScreen = useMediaQuery(theme.breakpoints.down("md"));

   const rootBasicStyle: CSSProperties = {
      position: "absolute",
      left: 0,
      top: 0,
      width: "100vw",
      height: "100vh",
      perspective: "800px",
      boxShadow: "inset 0 0 0.5vw 0.1vw rgba(0,0,0,0.5)",
      background: `linear-gradient(45deg, ${theme.palette.tilesGradiant1} 0%, ${theme.palette.tilesGradiant2} 60%, ${theme.palette.tilesGradiant3} 100%)`,
      // background: "linear-gradient(-45deg, rgb(210,210,210) 0%, rgb(180,180,180) 50%, rgb(220,220,220) 100%)",
      overflow: "hidden",
      zIndex: -1,
      pointerEvents: "none",
   };

   useEffect(() => {
      setTilesPos({ x: 0, y: window.innerHeight });
   }, [setTilesPos, smallScreen]);

   return (
      <Box id={"interactiveTilesContainer"} style={rootBasicStyle}>
         {!smallScreen && generateTiles()}
         <Box style={blurStyle} />
      </Box>
   );
};

const generateTiles = (): Array<ReactElement> => {
   let tiles: Array<ReactElement> = [];
   for (let row = 0; row < NUM_OF_TILES_PER_ROW; row++) {
      for (let column = 0; column < NUM_OF_TILES_PER_COLUMN; column++) {
         tiles.push(
            <InteractiveTileComponent
               key={`${row}*${column}`}
               idPostfix={`${row}*${column}`}
               row={row}
               column={column}
            />
         );
      }
   }
   return tiles;
};

export default memo(InteractiveTilesComponent);
