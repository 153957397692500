import React from "react";
import {
   Button,
   Dialog,
   DialogActions,
   DialogContent,
   DialogContentText,
   DialogTitle,
   Typography,
} from "@mui/material";
import { Procedure } from "../../utils";

export default function EndDialog({ open, onOk, onCancel }: { open: boolean; onCancel: Procedure; onOk: Procedure }) {
   return (
      <Dialog
         open={open}
         aria-labelledby="alert-dialog-title"
         aria-describedby="alert-dialog-description"
         onKeyUp={(event) => {
            if (event.key === "Escape") {
               onCancel();
            }
         }}
      >
         <DialogTitle id="alert-dialog-title">End tournament?</DialogTitle>
         <DialogContent>
            <DialogContentText id="alert-dialog-description">
               <Typography>
                  Are you sure you want to end this tournament? Once closed it will not be possible to add games or
                  stages.
               </Typography>
            </DialogContentText>
         </DialogContent>
         <DialogActions>
            <Button onClick={onOk} color={"secondary"}>
               Ok
            </Button>
            <Button onClick={onCancel} autoFocus>
               Cancel
            </Button>
         </DialogActions>
      </Dialog>
   );
}
