import React, { useCallback, useMemo } from "react";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Bar, CartesianGrid, ComposedChart, Legend, Line, LineChart, Tooltip, XAxis, YAxis } from "recharts";
import { CommanderPairNameImageOnly, VsOtherCommandersData } from "../cardFeatures/CardTypes";
import { useNavigate } from "react-router-dom";
import { toCommanderInsights } from "../navPaths";
import { INSIGHTS_CHARTS_MOBILE_WIDTH } from "./InsightsWinsByTurn";

const IMAGE_SIZE = 30;

export default function InsightsVersusView({ data }: { data: Array<VsOtherCommandersData> }) {
   const theme = useTheme();
   const nav = useNavigate();
   const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));
   const processed = useMemo(() => {
      return data
         .map((e) => {
            return {
               name:
                  e.pairNameImage.commander1.name +
                  (e.pairNameImage.commander2 != null ? "/" + e.pairNameImage.commander2?.name : ""),
               ...e,
            };
         })
         .slice(0, 5);
   }, [data]);

   const openInsightsCommander = useCallback(
      (pair: CommanderPairNameImageOnly) => {
         nav(toCommanderInsights(pair.commander1.id, pair.commander2 != null ? pair.commander2.id : ""));
      },
      [nav]
   );

   const tickRender = (props: { x: number; y: number; payload: { index: number } }) => {
      const target = processed[props.payload.index];
      if (target) {
         let c1 = target.pairNameImage.commander1;
         let c2 = target.pairNameImage.commander2;
         return (
            <g
               transform={`translate(${props.x},${props.y})`}
               onClick={() => openInsightsCommander(target.pairNameImage)}
               style={{ cursor: "pointer" }}
            >
               <image href={c1.artCropImageUri} width={IMAGE_SIZE} x={c2 == null ? -(IMAGE_SIZE / 2) : -IMAGE_SIZE} />
               (c2 != null &&
               <image href={c2?.artCropImageUri} width={IMAGE_SIZE} x={0} />)
            </g>
         );
      } else {
         return <></>;
      }
   };

   return (
      <Box>
         <Typography variant={"h6"} textAlign={"center"}>
            Opponents
         </Typography>
         <>
            <ComposedChart
               width={smallScreen ? INSIGHTS_CHARTS_MOBILE_WIDTH : 500}
               height={300}
               data={processed}
               margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
               }}
            >
               <CartesianGrid strokeDasharray="3 3" />
               <XAxis tick={tickRender} />
               <YAxis />
               <Tooltip />
               <Legend />
               <Bar dataKey="totalGames" barSize={5} fill="#8884d8" />
               <Bar dataKey="lossesVs" barSize={5} fill="#BB3524" />
               <Bar dataKey="winsVs" barSize={5} fill={theme.palette.primary.main} />
            </ComposedChart>
            <LineChart
               width={smallScreen ? INSIGHTS_CHARTS_MOBILE_WIDTH : 500}
               height={300}
               data={processed}
               margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
               }}
            >
               <CartesianGrid strokeDasharray="3 3" />
               <XAxis tick={tickRender} />
               <YAxis />
               <Tooltip />
               <Legend />
               <Line type="monotone" dataKey="winRateVs" fill="#8884d8" stroke={theme.palette.primary.main} />
            </LineChart>
         </>
      </Box>
   );
}
