import { MdCheckCircleOutline, MdError, MdWarning } from "react-icons/md";
import React, { CSSProperties } from "react";
import { GameStatus } from "../rest.client/useApplicationApi";
import { Tooltip, useTheme } from "@mui/material";

export default function StatusIcon({
   status,
   iconStyle,
   iconSize,
   iconColor,
}: {
   status: GameStatus;
   iconStyle?: CSSProperties;
   iconSize?: string | number;
   iconColor?: string;
}) {
   const theme = useTheme();
   const defaultIconStyle: CSSProperties = {
      marginLeft: theme.spacing(1),
   };
   return (
      <Tooltip title={status === "APPROVED" ? "Approved" : status === "PENDING" ? "Pending" : "Disputed"}>
         <>
            {status === "APPROVED" && (
               <MdCheckCircleOutline
                  size={iconSize}
                  color={iconColor ? iconColor : theme.palette.success.main}
                  style={iconStyle ? iconStyle : defaultIconStyle}
               />
            )}
            {status === "PENDING" && (
               <MdWarning
                  size={iconSize}
                  color={iconColor ? iconColor : theme.palette.warning.main}
                  style={iconStyle ? iconStyle : defaultIconStyle}
               />
            )}
            {status === "DISPUTED" && (
               <MdError
                  size={iconSize}
                  color={iconColor ? iconColor : theme.palette.error.main}
                  style={iconStyle ? iconStyle : defaultIconStyle}
               />
            )}
         </>
      </Tooltip>
   );
}
