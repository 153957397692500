import React, { memo, ReactNode, useMemo } from "react";
import { Box, Grow, IconButton, Theme, Typography } from "@mui/material";
import { SxProps } from "@mui/system";
import { Property } from "csstype";
import CloseIcon from "@mui/icons-material/Close";
import { Maybe } from "../TYPE";

const containerBasicSx: SxProps<Theme> = {
   padding: "2vmin",
   paddingTop: "3.5vmin",
   left: "5vmin",
   top: "10vmin",
   right: "auto",
   bottom: "auto",
   maxWidth: "40vw",
   maxHeight: "60vh",
   position: "absolute",
   overflow: "auto",
   borderRadius: "0.5vmin",
   backdropFilter: "blur(4px)",
   boxShadow: (theme) => `0 0 1vmin 0.1vmin ${theme.palette.boxShadowColor}`,
   zIndex: 1,
};

const closeButtonSx: SxProps = {
   position: "absolute",
   top: "0.5vmin",
   right: "0.5vmin",
   fontSize: "2vmin",
};

const textSx: SxProps = {};

interface IInstructionComponentProps {
   open: boolean;
   closeHandler?: () => void;
   left?: Property.Left;
   top?: Property.Top;
   bottom?: Property.Bottom;
   right?: Property.Right;
   texts: Array<ReactNode>;
   indexByNum: boolean;
}

const InstructionComponent: React.FC<IInstructionComponentProps> = ({
   open,
   closeHandler,
   left,
   right,
   top,
   bottom,
   texts,
   indexByNum,
}) => {
   const containerSx: SxProps<Theme> = useMemo(() => {
      const copy = { ...containerBasicSx };
      left && (copy.left = left);
      right && (copy.right = right);
      top && (copy.top = top);
      bottom && (copy.bottom = bottom);
      return copy;
   }, [bottom, left, right, top]);

   const textElements: Maybe<Array<ReactNode>> = useMemo(
      () =>
         texts
            ? texts.map((t, i) => {
                 return (
                    <Typography key={i} color="text.secondary" sx={textSx}>
                       {`${indexByNum ? `${i + 1}.` : "-"} `}
                       {t}
                    </Typography>
                 );
              })
            : null,
      [indexByNum, texts]
   );

   return (
      <Grow in={open}>
         <Box sx={containerSx}>
            {closeHandler != null && (
               <IconButton color={"secondary"} onClick={closeHandler} sx={closeButtonSx}>
                  <CloseIcon fontSize="inherit" />
               </IconButton>
            )}
            {textElements}
         </Box>
      </Grow>
   );
};

export default memo(InstructionComponent);
