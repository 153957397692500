// GiMedal
import React from "react";
import { Box, Tooltip, useTheme } from "@mui/material";
import { Rank } from "../rest.client/useApplicationApi";
import { GiBlackKnightHelm, GiBorderedShield, GiChessQueen, GiCutDiamond, GiRank1, GiRank3 } from "react-icons/gi";

export default function RankRender({ rank }: { rank: Rank }) {
   let icon = <GiRank1 />;
   let color = "brown";
   let name = "";
   switch (rank) {
      case "BRONZE_1":
      case "SILVER_1":
      case "GOLD_1":
         icon = <GiRank3 />;
         break;
      case "BRONZE_2":
      case "SILVER_2":
      case "GOLD_2":
         icon = <GiRank1 />;
         break;
      case "PLATINUM":
         icon = <GiBorderedShield />;
         break;
      case "DIAMOND":
         icon = <GiCutDiamond />;
         break;
      case "GRANDMASTER":
         icon = <GiChessQueen />;
         break;
      default:
         icon = <GiRank1 />;
   }

   switch (rank) {
      case "BRONZE_1":
      case "BRONZE_2":
         color = "brown";
         break;
      case "SILVER_1":
      case "SILVER_2":
         color = "silver";
         break;
      case "GOLD_1":
      case "GOLD_2":
         color = "#d6a802";
         break;
      case "PLATINUM":
         color = "#79999c";
         break;
      case "DIAMOND":
         color = "lightblue";
         break;
      case "GRANDMASTER":
         color = "purple";
         break;
   }

   switch (rank) {
      case "BRONZE_1":
         name = "Bronze tier 1";
         break;
      case "BRONZE_2":
         name = "Bronze tier 2";
         break;
      case "SILVER_1":
         name = "Silver tier 1";
         break;
      case "SILVER_2":
         name = "Silver tier 2";
         break;
      case "GOLD_1":
         name = "Gold tier 1";
         break;
      case "GOLD_2":
         name = "Gold tier 2";
         break;
      case "PLATINUM":
         name = "Platinum";
         break;
      case "DIAMOND":
         name = "Diamond";
         break;
      case "GRANDMASTER":
         name = "Grandmaster";
         break;
   }

   const theme = useTheme();
   return (
      <Tooltip title={name} placement={"top-start"}>
         <Box style={{ fontSize: 20, color: color, display: "flex", flexDirection: "column" }}>
            {icon}
            {rank !== "GRANDMASTER" && <GiBlackKnightHelm />}
         </Box>
      </Tooltip>
   );
}
