import React from "react";
import { TableCell } from "@mui/material";

export default function BrainstormWbeHeaders({}: {}) {
   return (
      <>
         <TableCell>Wins</TableCell>
         <TableCell>Buchholz</TableCell>
         <TableCell>Elo</TableCell>
      </>
   );
}
