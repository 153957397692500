import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { Procedure } from "../../../../utils";
import LogGamePageOptionalGameDataFullPanel from "../../../../gameFeatures/pageLogGame/LogGamePageOptionalGameDataFullPanel";
import { MutableGame } from "../../../../gameFeatures/MutableGame";

export default function TournamentOptionalDataDialog({
   open,
   close,
   mutableGame,
   readOnly,
}: {
   open: boolean;
   close: Procedure;
   mutableGame: MutableGame;
   readOnly: boolean;
}) {
   return (
      <Dialog
         open={open}
         fullScreen={true}
         aria-labelledby="alert-dialog-title"
         aria-describedby="alert-dialog-description"
         onKeyUp={(event) => {
            if (event.key === "Escape") {
               close();
            }
         }}
      >
         <DialogTitle id="alert-dialog-title">Enter optional data</DialogTitle>
         <DialogContent sx={{ height: "100%" }}>
            <LogGamePageOptionalGameDataFullPanel readOnly={readOnly} game={mutableGame} showButtons={false} />
         </DialogContent>
         <DialogActions>
            <Button onClick={close} autoFocus variant={"contained"}>
               Close
            </Button>
         </DialogActions>
      </Dialog>
   );
}
