import React, { useCallback, useEffect, useState } from "react";
import {
   Box,
   Button,
   Dialog,
   DialogActions,
   DialogContent,
   DialogTitle,
   IconButton,
   Paper,
   Table,
   TableBody,
   TableCell,
   TableContainer,
   TableHead,
   TableRow,
   useMediaQuery,
   useTheme,
} from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Procedure } from "../../../../utils";
import { RemoteStage } from "../../stageView/useRemoteStage";
import useMutableGame from "../../../../gameFeatures/useMutableGame";
import CommandersCell from "./CommandersCell";
import useTournamentGameAdapter from "./useTournamentGameAdapter";
import RegistrationSelector, { PlayerSelectionStatus } from "./RegistrationSelector";
import useReload from "../../../../header/useReload";
import WinnerSelect from "../../../../gameFeatures/pageLogGame/WinnerSelect";
import TournamentOptionalDataDialog from "./TournamentOptionalDataDialog";
import DeckViewer from "../../../deckViewer/DeckViewer";
import { Maybe } from "../../../../TYPE";
import useAddMessageItem from "../../../../messagesComponent/useAddMessageItem";
import { RegistrationSummary } from "../../../../rest.client/useTournamentApi";
import { useRoundApi } from "../../../../rest.client/useRoundApi";

export default function TournamentGameEditDialog({
   open,
   onOk,
   onCancel,
   gameId,
   matchId,
   remoteStage,
}: {
   open: boolean;
   onCancel: Procedure;
   onOk: Procedure;
   matchId: string;
   gameId?: string;
   remoteStage: RemoteStage;
}) {
   const {
      getByes: { call: getByesCall, responseData: byes },
   } = useRoundApi();
   const mutableGame = useMutableGame(gameId, matchId, false);
   const game = mutableGame.existingFullGame;
   const reload = useReload();
   const theme = useTheme();
   const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));
   const tournamentGameAdapter = useTournamentGameAdapter(mutableGame, remoteStage.remoteTournament);
   const [openDialog, setOpenDialog] = useState(false);
   const [snapId, setSnapId] = useState<Maybe<string>>(null);
   const showMessage = useAddMessageItem();
   const round = remoteStage.stage?.rounds.find((r) => r.matches.some((m) => m.id === matchId));
   const roundId = round?.id;

   const gameStateOkToEdit = game == null || game?.status !== "APPROVED";
   const canEdit = remoteStage.canSubmitGame && gameStateOkToEdit;
   const canShowDeckList =
      remoteStage.remoteTournament.isOrganiser ||
      remoteStage.remoteTournament.tournament?.areDecklistsVisible ||
      remoteStage.remoteTournament.tournament?.isPublic;

   const selectSx = { width: 320 };

   useEffect(() => {
      if (open && remoteStage.seeds == null) {
         remoteStage.loadSeeds();
      }
   }, [open, remoteStage]);

   useEffect(() => {
      if (open && roundId) {
         getByesCall({ pathParams: { id: roundId } });
      }
   }, [getByesCall, open, roundId]);

   const validator: (r: RegistrationSummary) => PlayerSelectionStatus = useCallback(
      (r) => {
         const otherMatchForPlayer = round?.matches.find(
            (m) => m.id !== matchId && m.matchResults.some((mR) => mR.registration.id === r.id)
         );
         if (remoteStage.requiresMatchAndQualifiesValidation && otherMatchForPlayer != null) {
            return { severity: "warning", message: "Match " + otherMatchForPlayer.matchNumber };
         } else if (
            remoteStage.requiresMatchAndQualifiesValidation &&
            remoteStage.seeds &&
            !remoteStage.seeds.some((s) => s.id === r.id)
         ) {
            return { severity: "warning", message: "Not qualified" };
         } else if (byes?._embedded.registrations.some((bye) => bye.id === r.id)) {
            return { severity: "warning", message: "Bye" };
         } else {
            return { severity: "success", message: "Available" };
         }
      },
      [
         byes?._embedded.registrations,
         matchId,
         remoteStage.requiresMatchAndQualifiesValidation,
         remoteStage.seeds,
         round?.matches,
      ]
   );

   return (
      <Dialog
         open={open}
         fullScreen={smallScreen}
         maxWidth={"md"}
         aria-labelledby="alert-dialog-title"
         aria-describedby="alert-dialog-description"
         onKeyUp={(event) => {
            if (event.key === "Escape") {
               onCancel();
            }
         }}
      >
         <DialogTitle id="alert-dialog-title">Game data</DialogTitle>
         <DialogContent>
            <WinnerSelect readOnly={!canEdit} game={mutableGame} />
            <TableContainer component={Paper} sx={{ marginBottom: theme.spacing(2) }}>
               <Table sx={{}} aria-label="simple table">
                  <TableHead>
                     <TableRow>
                        <TableCell>Seat</TableCell>
                        <TableCell>Commander</TableCell>
                        <TableCell>Player</TableCell>
                        {canEdit && <TableCell>Clear</TableCell>}
                     </TableRow>
                  </TableHead>
                  <TableBody>
                     <TableRow>
                        <TableCell>1</TableCell>
                        {mutableGame.player1CommanderPair ? (
                           <CommandersCell
                              commander1={mutableGame.player1CommanderPair.commander1}
                              commander2={mutableGame.player1CommanderPair.commander2}
                              onClick={() => setSnapId(mutableGame.player1Snapshot?.id)}
                           />
                        ) : (
                           <TableCell>--</TableCell>
                        )}
                        <RegistrationSelector
                           readOnly={!canEdit}
                           selectSx={selectSx}
                           inUseRegs={tournamentGameAdapter.usedRegistrations}
                           reg={tournamentGameAdapter.regPlayer1}
                           setRegistration={tournamentGameAdapter.setPlayer1Reg}
                           registrations={remoteStage.remoteTournament.tournament?.registrations}
                           selectionValidator={validator}
                        />
                        <TableCell>
                           {canEdit && (
                              <IconButton
                                 color={"primary"}
                                 onClick={() => {
                                    mutableGame.setPlayer1Summary(null);
                                    mutableGame.setPlayer1CommanderPair(null);
                                 }}
                              >
                                 <DeleteForeverIcon />
                              </IconButton>
                           )}
                        </TableCell>
                     </TableRow>
                     <TableRow>
                        <TableCell>2</TableCell>
                        {mutableGame.player2CommanderPair ? (
                           <CommandersCell
                              commander1={mutableGame.player2CommanderPair.commander1}
                              commander2={mutableGame.player2CommanderPair.commander2}
                              onClick={() => setSnapId(mutableGame.player2Snapshot?.id)}
                           />
                        ) : (
                           <TableCell>--</TableCell>
                        )}
                        <RegistrationSelector
                           readOnly={!canEdit}
                           inUseRegs={tournamentGameAdapter.usedRegistrations}
                           reg={tournamentGameAdapter.regPlayer2}
                           selectSx={selectSx}
                           setRegistration={tournamentGameAdapter.setPlayer2Reg}
                           registrations={remoteStage.remoteTournament.tournament?.registrations}
                           selectionValidator={validator}
                        />
                        <TableCell>
                           {canEdit && (
                              <IconButton
                                 color={"primary"}
                                 onClick={() => {
                                    mutableGame.setPlayer2Summary(null);
                                    mutableGame.setPlayer2CommanderPair(null);
                                 }}
                              >
                                 <DeleteForeverIcon />
                              </IconButton>
                           )}
                        </TableCell>
                     </TableRow>
                     <TableRow>
                        <TableCell>3</TableCell>
                        {mutableGame.player3CommanderPair ? (
                           <CommandersCell
                              commander1={mutableGame.player3CommanderPair.commander1}
                              commander2={mutableGame.player3CommanderPair.commander2}
                              onClick={() => setSnapId(mutableGame.player3Snapshot?.id)}
                           />
                        ) : (
                           <TableCell>--</TableCell>
                        )}
                        <RegistrationSelector
                           readOnly={!canEdit}
                           selectSx={selectSx}
                           inUseRegs={tournamentGameAdapter.usedRegistrations}
                           reg={tournamentGameAdapter.regPlayer3}
                           setRegistration={tournamentGameAdapter.setPlayer3Reg}
                           registrations={remoteStage.remoteTournament.tournament?.registrations}
                           selectionValidator={validator}
                        />
                        {canEdit && (
                           <TableCell>
                              <IconButton
                                 color={"primary"}
                                 onClick={() => {
                                    mutableGame.setPlayer3Summary(null);
                                    mutableGame.setPlayer3CommanderPair(null);
                                 }}
                              >
                                 <DeleteForeverIcon />
                              </IconButton>
                           </TableCell>
                        )}
                     </TableRow>
                     <TableRow>
                        <TableCell>4</TableCell>
                        {mutableGame.player4CommanderPair ? (
                           <CommandersCell
                              commander1={mutableGame.player4CommanderPair.commander1}
                              commander2={mutableGame.player4CommanderPair.commander2}
                              onClick={() => setSnapId(mutableGame.player4Snapshot?.id)}
                           />
                        ) : (
                           <TableCell>--</TableCell>
                        )}
                        <RegistrationSelector
                           readOnly={!canEdit}
                           selectSx={selectSx}
                           inUseRegs={tournamentGameAdapter.usedRegistrations}
                           reg={tournamentGameAdapter.regPlayer4}
                           setRegistration={tournamentGameAdapter.setPlayer4Reg}
                           registrations={remoteStage.remoteTournament.tournament?.registrations}
                           selectionValidator={validator}
                        />
                        {canEdit && (
                           <TableCell>
                              <IconButton
                                 color={"primary"}
                                 onClick={() => {
                                    mutableGame.setPlayer4Summary(null);
                                    mutableGame.setPlayer4CommanderPair(null);
                                 }}
                              >
                                 <DeleteForeverIcon />
                              </IconButton>
                           </TableCell>
                        )}
                     </TableRow>
                  </TableBody>
               </Table>
            </TableContainer>
            <Box>
               <Button onClick={() => setOpenDialog(true)}>Additional data</Button>
               {canEdit && (
                  <Button
                     onClick={() => {
                        mutableGame.randomizePlayers();
                        showMessage("Players randomized", "info", 3000);
                     }}
                  >
                     Randomize players
                  </Button>
               )}
            </Box>
            <TournamentOptionalDataDialog
               readOnly={!canEdit}
               open={openDialog}
               close={() => setOpenDialog(false)}
               mutableGame={mutableGame}
            />
            {canShowDeckList && snapId && (
               <DeckViewer open={true} closeHandler={() => setSnapId(null)} readonly={true} initialSnapId={snapId} />
            )}
         </DialogContent>
         <DialogActions>
            <Button
               onClick={async () => {
                  await mutableGame.saveGame();
                  showMessage("Game saved", "success");
                  reload.requestReload();
                  onOk();
               }}
               color={"secondary"}
               disabled={!mutableGame.valid || !canEdit}
            >
               Save
            </Button>
            <Button onClick={onCancel} autoFocus>
               Cancel
            </Button>
         </DialogActions>
      </Dialog>
   );
}
