import useRequest from "./useRequest";
import { useMemo } from "react";
import {
   CardExtendedInfo,
   CardNameAndImage,
   CardUsageData,
   CommanderInsights,
   CommanderPair,
   CommanderPairNameImageOnly,
   GamesBySeat,
   GamesByTurn,
   VsOtherCommandersData,
} from "../cardFeatures/CardTypes";
import { Maybe, WinType } from "../TYPE";
import { useAxiosInstance } from "./useAxiosInstance";
import { RegistrationSummary } from "./useTournamentApi";
import { SnapshotSummary } from "./useDeckListApi";

export const API_BASE_URL = process.env.NODE_ENV === "production" ? "/api/" : "http://localhost:8080/api/";

export function useApplicationApi() {
   const instance = useAxiosInstance();

   return {
      findAllUsersStats: useRequest<AllUsersResponse<UserStats>, never, never, AllUsersQueryParams>(
         useMemo(
            () => ({
               url: "/users/",
               method: "get",
               queryParamsDefault: {
                  projection: "stats",
                  size: 100,
                  page: 0,
                  sort: "",
               },
            }),
            []
         ),
         instance
      ),
      findAllRankedUsersStats: useRequest<AllUsersResponse<UserStats>, never, never, AllUsersQueryParams>(
         useMemo(
            () => ({
               url: "/users/search/rankedOnly",
               method: "get",
               queryParamsDefault: {
                  projection: "stats",
                  size: 100,
                  page: 0,
                  sort: "",
               },
            }),
            []
         ),
         instance
      ),
      findAllUsersEloLessThan: useRequest<AllUsersResponse<UserStats>, never, never, EloSearchQueryParams>(
         useMemo(
            () => ({
               url: "/users/search/byEloLessThan",
               method: "get",
               queryParamsDefault: {
                  projection: "stats",
                  size: 10,
                  page: 0,
                  sort: "",
                  elo: 0,
               },
            }),
            []
         ),
         instance
      ),
      findAllUsersEloGreaterThan: useRequest<AllUsersResponse<UserStats>, never, never, EloSearchQueryParams>(
         useMemo(
            () => ({
               url: "/users/search/byEloGreaterThan",
               method: "get",
               queryParamsDefault: {
                  projection: "stats",
                  size: 10,
                  page: 0,
                  sort: "",
                  elo: 0,
               },
            }),
            []
         ),
         instance
      ),
      findUserByNameStart: useRequest<
         EmbeddedResponse<{ users: UserSummary[] }>,
         never,
         never,
         { name: string; tag: String; projection?: string }
      >(
         useMemo(
            () => ({
               url: "/users/search/byName",
               method: "get",
               queryParamsDefault: {
                  projection: "userSummary",
                  name: "",
                  tag: "",
               },
            }),
            []
         ),
         instance
      ),
      findUserById: useRequest<UserSummary, { id: string }, never, { projection?: string }>(
         useMemo(
            () => ({
               url: "/users/:id",
               method: "get",
               queryParamsDefault: {
                  projection: "",
               },
            }),
            []
         ),
         instance
      ),
      findUserProfileById: useRequest<UserProfile, { id: string }, never, { projection?: string }>(
         useMemo(
            () => ({
               url: "/users/:id",
               method: "get",
               queryParamsDefault: {
                  projection: "userProfile",
               },
            }),
            []
         ),
         instance
      ),
      findCardExtendedInfoById: useRequest<CardExtendedInfo, { id: string }, never, { projection?: string }>(
         useMemo(
            () => ({
               url: "/cards/:id",
               method: "get",
               queryParamsDefault: {
                  projection: "extendedInfo",
               },
            }),
            []
         ),
         instance
      ),
      findCommanderPairInsights: useRequest<CommanderInsights, { id1: string; id2: Maybe<string> }, never, {}>(
         useMemo(
            () => ({
               url: "/insights/commander/:id1/:id2",
               method: "get",
            }),
            []
         ),
         instance
      ),
      findPlayerInsights: useRequest<PlayerInsightsResponse, { uid: string }, never, {}>(
         useMemo(
            () => ({
               url: "/insights/user/:uid",
               method: "get",
            }),
            []
         ),
         instance
      ),
      findMetaInsights: useRequest<MetaInsightsResponse, {}, never, {}>(
         useMemo(
            () => ({
               url: "/insights/meta/",
               method: "get",
            }),
            []
         ),
         instance
      ),
      sendVerifyEmail: useRequest<never, never, never, never>(
         useMemo(
            () => ({
               url: "/users/myAccount/sendVerifyEmail",
               method: "post",
            }),
            []
         ),
         instance
      ),
      findUserStatsById: useRequest<UserStats, { id: string }, never, { projection?: string }>(
         useMemo(
            () => ({
               url: "/users/:id",
               method: "get",
               queryParamsDefault: {
                  projection: "stats",
               },
            }),
            []
         ),
         instance
      ),
      findUserDefaultCommanderById: useRequest<DefaultCommander, { id: string }, never, { projection?: string }>(
         useMemo(
            () => ({
               url: "/users/:id",
               method: "get",
               queryParamsDefault: {
                  projection: "defaultCommander",
               },
            }),
            []
         ),
         instance
      ),
      findGamesByUserId: useRequest<GamesByUserResponse, { pendingOnly: boolean }, never, GamesByUserQParams>(
         useMemo(
            () => ({
               url: "/games/search/byUserId",
               method: "get",
               queryParamsDefault: {
                  projection: "gameSummary",
                  pendingOnly: false,
                  creatorOnly: false,
                  size: 3,
                  page: 0,
                  userId: "",
                  sort: "",
               },
            }),
            []
         ),
         instance
      ),
      findGamesInPastDays: useRequest<GameSummary[], { numOfDays: number }, never, never>(
         useMemo(
            () => ({
               url: "/games/inPastDays/:numOfDays",
               method: "get",
            }),
            []
         ),
         instance
      ),
      findTop10CardsByPopularityInPastDays: useRequest<
         Array<Array<CardNameAndImage>>,
         { numOfDays: number },
         never,
         never
      >(
         useMemo(
            () => ({
               url: "/cards/popularity/inPastDays/:numOfDays",
               method: "get",
            }),
            []
         ),
         instance
      ),
      findGameById: useRequest<GameFull, { id: string }, never, ProjectableQueryParams>(
         useMemo(
            () => ({
               url: "/games/:id",
               method: "get",
               queryParamsDefault: {
                  projection: "gameFull",
               },
            }),
            []
         ),
         instance
      ),
      deleteGame: useRequest<void, { id: string }, never, never>(
         useMemo(
            () => ({
               url: "/games/:id/delete/",
               method: "delete",
            }),
            []
         ),
         instance
      ),
      submitResult: useRequest<GameSummary, { id: string }, SubmitGameResultRequest, never>(
         useMemo(
            () => ({
               url: "/games/:id/resultSubmissions",
               method: "post",
            }),
            []
         ),
         instance
      ),
      approveGame: useRequest<GameSummary, { id: string }, never, never>(
         useMemo(
            () => ({
               url: "/games/:id/approve",
               method: "post",
            }),
            []
         ),
         instance
      ),
      forceApproveGame: useRequest<GameSummary, { id: string }, never, never>(
         useMemo(
            () => ({
               url: "/games/:id/forceApprove",
               method: "post",
            }),
            []
         ),
         instance
      ),
      disputeGame: useRequest<GameSummary, { id: string }, never, never>(
         useMemo(
            () => ({
               url: "/games/:id/dispute",
               method: "post",
            }),
            []
         ),
         instance
      ),
      findCardByName: useRequest<PageableResponse<{ cards: CardExtendedInfo[] }>, never, never, CardFind>(
         useMemo(
            () => ({
               url: "/cards/search/byFilter",
               method: "get",
               queryParamsDefault: {
                  name: "",
                  nameStripped: "",
                  page: 0,
                  size: 25,
                  sort: "",
                  filterCommanders: false,
                  filterPartners: false,
                  filterBackgrounds: false,
                  filterFriendsForever: false,
                  projection: "extendedInfo",
               },
            }),
            []
         ),
         instance
      ),
      createGame: useRequest<GameSummary, never, CreateNewGameRequest, never>(
         useMemo(
            () => ({
               url: "/games/",
               method: "post",
            }),
            []
         ),
         instance
      ),
      updateGame: useRequest<GameSummary, { id: string }, CreateNewGameRequest, never>(
         useMemo(
            () => ({
               url: "/games/update/:id",
               method: "patch",
            }),
            []
         ),
         instance
      ),
      createComment: useRequest<Comment, { id: string }, CreateComment, never>(
         useMemo(
            () => ({
               url: "/games/:id/comments",
               method: "post",
            }),
            []
         ),
         instance
      ),
      findCommentsByGame: useRequest<
         EmbeddedResponse<{ comments: Comment[] }>,
         never,
         never,
         CommentsByGameIdQueryParams
      >(
         useMemo(
            () => ({
               url: "/comments/search/byGameId",
               method: "get",
               queryParamsDefault: { projection: "basic", id: "", sort: "" },
            }),
            []
         ),
         instance
      ),
      changeUsername: useRequest<never, never, { name: string }, never>(
         useMemo(
            () => ({
               url: "/users/myAccount/username",
               method: "post",
            }),
            []
         ),
         instance
      ),
      changeSettings: useRequest<
         never,
         never,
         {
            sendAutoApproveEmails: boolean;
            themeSelection: ThemeSelection;
         },
         never
      >(
         useMemo(
            () => ({
               url: "/users/myAccount/settings",
               method: "post",
            }),
            []
         ),
         instance
      ),
      uploadPhoto: useRequest<never, never, FormData, never>(
         useMemo(
            () => ({
               url: "/users/myAccount/photo",
               method: "post",
               configMutator: (baseConfig) => {
                  if (!baseConfig.headers) {
                     baseConfig.headers = {};
                  }
                  baseConfig.headers["Content-Type"] = "multipart/form-data";
                  return baseConfig;
               },
            }),
            []
         ),
         instance
      ),
   };
}

interface CardFind extends PageableQueryParams {
   name: string;
   nameStripped: string;
   filterCommanders: boolean;
   filterPartners: boolean;
   filterBackgrounds: boolean;
   filterFriendsForever: boolean;
   projection?: string;
}

export interface GamesByUserQParams extends PageableQueryParams, ProjectableQueryParams {
   userId: string;
   pendingOnly: boolean;
   creatorOnly: boolean;
}

export interface GamesByUserResponse extends PageableResponse<{ games: GameSummary[] }> {}

export interface AllUsersResponse<Projection> extends PageableResponse<{ users: Projection[] }> {}

export interface AllUsersQueryParams extends PageableQueryParams, ProjectableQueryParams, SortableQueryParams {}

export interface EloSearchQueryParams extends AllUsersQueryParams {
   elo: number;
}

export interface PageableResponse<T> extends EmbeddedResponse<T> {
   page: {
      size: number;
      totalElements: number;
      totalPages: number;
      number: number;
   };
}

export interface EmbeddedResponse<T> {
   _embedded: T;
}

export interface SortableQueryParams {
   sort: string;
}

export interface PageableQueryParams extends SortableQueryParams {
   page: number;
   size: number;
}

export interface ProjectableQueryParams {
   projection?: string;
}

export interface CommentsByGameIdQueryParams extends ProjectableQueryParams, SortableQueryParams {
   id: string;
}

export interface Comment {
   id: string;
   text: string;
   createDate: string;
   userName: string;
   photoUrl: string;
}

export interface User {
   id: string;
   name: string;
   elo: number;
   winRate: number;
   numberGamesPlayed: number;
   numberGamesWon: number;
   createDate: Date;
}

export interface UserSummary {
   id: string;
   name: string;
   shortTag: string;
}

export interface UserProfile {
   id: string;
   name: string;
   shortTag: string;
   lastVerifyEmailDate?: string;
   nameChangeCount?: number;
   sendAutoApproveEmails: boolean;
   themeSelection: ThemeSelection;
   registrations: RegistrationSummary[];
}

export type ThemeSelection = "DARK" | "LIGHT";

export interface UserStats {
   elo: number;
   winPercent: number;
   gamesPlayedCount: number;
   gamesWonCount: number;
   rank: Rank;
   name: string;
   shortTag: string;
   photoUrl: string;
   rankingsPosition?: number;
   id: string;
}

export interface UserStatsExtended extends UserStats {
   commandersPlayed: Array<PlayedCountPair>;
}

export interface PlayedCountPair extends CommanderPair {
   playedCount: number;
}

export interface DefaultCommander {
   defaultCommanderPair?: CommanderPair;
}

export type Rank =
   | "BRONZE_2"
   | "BRONZE_1"
   | "SILVER_2"
   | "SILVER_1"
   | "GOLD_2"
   | "GOLD_1"
   | "PLATINUM"
   | "DIAMOND"
   | "GRANDMASTER";

export interface GameSummary {
   id: string;
   createDate: string;
   description: string;
   creator: UserSummary;
   status: GameStatus;
   player1: PlayerSummary;
   player2: PlayerSummary;
   player3: PlayerSummary;
   player4?: PlayerSummary;
}

export interface PlayerSummary {
   id: string;
   name: string;
   user: UserSummary;
   winner: boolean;
   status: GameStatus;
   commander1: CardExtendedInfo;
   commander2?: CardExtendedInfo;
}

export interface GameFull extends GameSummary {
   player1: PlayerFull;
   player2: PlayerFull;
   player3: PlayerFull;
   player4?: PlayerFull;
   turns: number;
   winType: WinType;
   matchId?: string;
   highImpactCards: Array<CardNameAndImage>;
   gameWonBy: Array<CardNameAndImage>;
   commentCount: number;
   daysUntilAutoApprove: number;
}

export interface SubmitGameResultRequest {
   playerId: Maybe<string>;
}

export type GameStatus = "PENDING" | "APPROVED" | "DISPUTED";

export interface PlayerFull extends PlayerSummary {
   winner: boolean;
   status: GameStatus;
   snapshot: SnapshotSummary;
}

export interface CreateNewGameRequest {
   player1: CreateNewPlayerRequest;
   player2: CreateNewPlayerRequest;
   player3: CreateNewPlayerRequest;
   player4?: CreateNewPlayerRequest;
   description?: string;
   turns?: number;
   highImpactCards: Array<string>;
   gameWonByCards: Array<string>;
   winType?: WinType;
   matchId?: string;
}

export interface CreateComment {
   text: string;
}

export interface CreateNewPlayerRequest {
   userId: string;
   wonGame: boolean;
   commander1Id: string;
   commander2Id?: string;
   deckSnapshotId?: string;
}

export interface PlayerInsightsResponse {
   user: UserStatsExtended;
   gamesByTurn: Array<GamesByTurn>;
   gamesBySeat: Array<GamesBySeat>;
   vsOtherCommandersData: Array<VsOtherCommandersData>;
   winConsUsed: Array<CardUsageData>;
   highImpactCardsInWins: Array<CardUsageData>;
   commanderPerformance: Array<CommanderPerformance>;
   winCount: number;
   lossCount: number;
}

export interface MetaGamesByTurn {
   // Integer turn, Integer total, Float gameEndRate
   turn: number;
   total: number;
   gameEndRate: number;
}

export interface MetaInsightsResponse {
   gamesByTurn: Array<MetaGamesByTurn>;
   gamesBySeat: Array<GamesBySeat>;
   winConsUsed: Array<CardUsageData>;
   highImpactCards: Array<CardUsageData>;
}

export interface CommanderPerformance {
   pairNameImage: CommanderPairNameImageOnly;
   winRate: number;
   wins: number;
   losses: number;
   totalGames: number;
}
