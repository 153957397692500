import React, { ReactNode, useState } from "react";
import { Avatar, Box, Chip, Typography, useTheme } from "@mui/material";
import { RegistrationSummary } from "../../rest.client/useTournamentApi";
import PlayerTournamentCardDialog from "./PlayerTournamentCardDialog";
import { RemoteTournament } from "../useRemoteTournament";
import { Result } from "../../rest.client/useStagesApi";
import { empty } from "../../utils";
import WinnerIcon from "../../gameFeatures/WinnerIcon";

export default function RegistrationChip({
   registration,
   children,
   onClickOpenRegistrationCard = false,
   remoteTournament,
   matchResult,
}: {
   registration: RegistrationSummary;
   children?: ReactNode;
   onClickOpenRegistrationCard?: boolean;
   remoteTournament?: RemoteTournament;
   matchResult?: Result;
}) {
   const theme = useTheme();
   const [openCard, setOpenCard] = useState(false);

   return (
      <>
         <Chip
            color={matchResult === "FIRST" ? "primary" : "default"}
            onClick={onClickOpenRegistrationCard ? () => setOpenCard(true) : undefined}
            size={"medium"}
            label={
               <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box sx={{ display: "flex" }}>
                     <Avatar
                        alt={registration.commander1.name}
                        src={registration.commander1.artCropImageUri}
                        sx={{ width: 20, height: 20 }}
                     />
                     {registration.commander2 && (
                        <Avatar
                           alt={registration.commander2.name}
                           src={registration.commander2.artCropImageUri}
                           sx={{ width: 20, height: 20 }}
                        />
                     )}
                  </Box>
                  <Typography maxWidth={"160px"} overflow={"hidden"} textOverflow={"ellipsis"} whiteSpace={"nowrap"}>
                     <span
                        style={{
                           marginLeft: theme.spacing(1),
                           fontWeight: "normal",
                        }}
                     >
                        {registration.user.name}
                        <span
                           style={{
                              color: theme.palette.text.secondary,
                              fontStyle: "italic",
                           }}
                        >
                           /{registration.user.shortTag}
                        </span>
                     </span>
                  </Typography>
                  {matchResult === "FIRST" && <WinnerIcon won={true} onClick={empty} />}
                  {children}
               </Box>
            }
            variant="outlined"
         />
         {remoteTournament && openCard && (
            <PlayerTournamentCardDialog
               open={openCard}
               close={() => setOpenCard(false)}
               remoteTournament={remoteTournament}
               registration={registration}
            />
         )}
      </>
   );
}
