import React from "react";
import { Consumer } from "../../utils";
import { Button, Typography, useTheme } from "@mui/material";
import { CardNameAndImage } from "../../cardFeatures/CardTypes";

export default function CardImageList({
   onRemoveClick,
   cards,
   label,
   editable = true,
}: {
   cards: CardNameAndImage[];
   onRemoveClick: Consumer<CardNameAndImage>;
   label?: string;
   editable?: boolean;
}) {
   const theme = useTheme();
   return (
      <>
         {label && <Typography variant={"h6"}>{label}</Typography>}
         <div style={{ display: "flex", flexWrap: "wrap", marginTop: theme.spacing(1) }}>
            {cards.map((item) => (
               <div key={item.id} style={{ marginRight: theme.spacing(1) }}>
                  <img src={item.artCropImageUri} srcSet={item.smallImageUri} alt={item.name} loading="lazy" />
                  {editable && (
                     <div>
                        <Button onClick={() => onRemoveClick(item)}>Remove</Button>
                     </div>
                  )}
               </div>
            ))}
            {cards.length === 0 && (
               <div>
                  <Typography>No cards set</Typography>
               </div>
            )}
         </div>
      </>
   );
}
