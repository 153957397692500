import React from "react";
import { Box, ListItem, ListItemText } from "@mui/material";
import { getUserFriendlyNameForTieBreakers, PointsProvider } from "../../../rest.client/useStagesApi";
import SettingsIcon from "@mui/icons-material/Settings";

export default function PointsListItems({
   pointsProvider,
   byePoints,
}: {
   pointsProvider: PointsProvider;
   byePoints?: number;
}) {
   const itemStyle = { width: "120px" };
   return (
      <>
         <ListItem sx={itemStyle}>
            <ListItemText primary={pointsProvider.winPoints} secondary={"Points for win"} />
         </ListItem>
         <ListItem sx={itemStyle}>
            <ListItemText primary={pointsProvider.lossPoints} secondary={"Points for loss"} />
         </ListItem>
         <ListItem sx={itemStyle}>
            <ListItemText primary={pointsProvider.drawPoints} secondary={"Points for draw"} />
         </ListItem>
         {byePoints && (
            <ListItem sx={itemStyle}>
               <ListItemText primary={byePoints} secondary={"Points for bye"} />
            </ListItem>
         )}
         <ListItem sx={itemStyle}>
            <ListItemText primary={pointsProvider.secondPlacePoints} secondary={"Points for second"} />
         </ListItem>
         <ListItem sx={itemStyle}>
            <ListItemText primary={pointsProvider.thirdPlacePoints} secondary={"Points for third"} />
         </ListItem>
         <ListItem sx={itemStyle}>
            <ListItemText primary={pointsProvider.fourthPlacePoints} secondary={"Points for fourth"} />
         </ListItem>
         <ListItem sx={itemStyle}>
            <ListItemText
               primary={(pointsProvider.matchWinPercentageFloor * 100).toFixed(0) + "%"}
               secondary={"Min win percent"}
            />
         </ListItem>
         <ListItem sx={itemStyle}>
            <ListItemText
               primary={getUserFriendlyNameForTieBreakers(pointsProvider.tiebreakerSystem)}
               secondary={
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                     <SettingsIcon fontSize={"small"} />
                     Tiebreaker system
                  </Box>
               }
            />
         </ListItem>
      </>
   );
}
