import React from "react";
import { Alert, Box, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { RemoteTournament } from "../../useRemoteTournament";
import TierRow from "./TierRow";

export default function ResultsTierList({ remoteTournament }: { remoteTournament: RemoteTournament }) {
   return (
      <Box>
         <Table sx={{}} aria-label="simple table">
            <TableHead>
               <TableRow>
                  <TableCell>Tier rank</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell></TableCell>
               </TableRow>
            </TableHead>
            {remoteTournament.tournament && (
               <TableBody>
                  {remoteTournament.tournament.prizeTiers.map((t, i) => (
                     <TierRow
                        remoteTournament={remoteTournament}
                        tier={t}
                        showDelete={
                           remoteTournament.isOrganiser && i + 1 === remoteTournament.tournament?.prizeTiers.length
                        }
                     />
                  ))}
               </TableBody>
            )}
         </Table>
         {remoteTournament.tournament?.prizeTiers.length === 0 && (
            <Alert severity={"warning"}>
               No tournament results specified by organisers. At least 1 result tier must be added in order to end a
               tournament. Auto generating tiers first is the recommended approach.
            </Alert>
         )}
      </Box>
   );
}
