import React from "react";
import { Box, Button, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { RemoteStage } from "./useRemoteStage";
import { RoundFullResponse } from "../../../rest.client/useStagesApi";
import useRemoteRound from "./useRemoteRound";
import useAddMessageItem from "../../../messagesComponent/useAddMessageItem";

export default function MatchControls({
   remoteStage,
   selectedRound,
}: {
   remoteStage: RemoteStage;
   selectedRound: RoundFullResponse;
}) {
   const remoteRound = useRemoteRound(remoteStage, selectedRound);
   const theme = useTheme();
   const addMessage = useAddMessageItem();
   const nav = useNavigate();

   return (
      <Box>
         <Box sx={{ display: "flex", gap: theme.spacing(1), flexWrap: "wrap" }}>
            <Button
               onClick={async () => {
                  await remoteRound.autoPair();
                  addMessage(
                     "Generation complete. Please verify all generated matches and byes to ensure suitability.",
                     "success"
                  );
               }}
               disabled={!remoteRound.canPair || remoteStage.isClosed}
            >
               Auto generate matches
            </Button>
            <Button
               disabled={!remoteRound.isActive}
               onClick={async () => {
                  await remoteRound.createMatch();
               }}
            >
               Add match
            </Button>
         </Box>
      </Box>
   );
}
