import React, { memo, useMemo } from "react";
import { Avatar, Box, Theme } from "@mui/material";
import { Comment } from "../../rest.client/useApplicationApi";
import { SxProps } from "@mui/system";

const commentPanelSx: SxProps<Theme> = {
   width: "70%",
   margin: "1vmin auto 0 auto",
   boxShadow: "inset 0 0 ",
   borderRadius: "3px",
   padding: "1vmin",
   display: "flex",
   flexDirection: "column",
   color: (theme) => theme.palette.secondary.main,
};

const commentIndexAndUserSx: SxProps = {
   margin: "0",
   display: "flex",
   alignItems: "center",
};

const commentUserSx: SxProps = {
   margin: "0 5vmin 0 0",
   display: "inline-block",
};

const commentIndexSx: SxProps = {
   margin: "0 0 0 auto",
   display: "inline-block",
};

const commentTextSx: SxProps<Theme> = {
   margin: "1vmin auto 1vmin 0",
   color: (theme) => theme.palette.text.primary,
   width: "100%",
   whiteSpace: "pre-line",
   maxHeight: 250,
   overflow: "auto",
};

const commentDateSx: SxProps<Theme> = {
   margin: "0 0 0 auto",
};

interface ICommentComponentProps {
   commentData: Comment;
   commentIndex: number;
}

const CommentComponent: React.FC<ICommentComponentProps> = ({ commentData, commentIndex }) => {
   const commentDate = useMemo(
      () => new Date(Date.parse(commentData.createDate)).toLocaleString(undefined, { dateStyle: "medium" }),
      [commentData.createDate]
   );

   return (
      <Box sx={commentPanelSx}>
         <Box sx={commentIndexAndUserSx}>
            <Avatar
               alt={commentData.userName + " avatar image"}
               src={commentData.photoUrl != null ? commentData.photoUrl : ""}
            />
            <Box sx={commentUserSx}>{commentData.userName}</Box>
            <Box sx={commentIndexSx}>{`#${commentIndex}`}</Box>
         </Box>
         <Box sx={commentTextSx}>{commentData.text}</Box>
         <Box sx={commentDateSx}>{commentDate}</Box>
      </Box>
   );
};

export default memo(CommentComponent);
