import React from "react";
import { Alert, Box, useMediaQuery, useTheme } from "@mui/material";
import { RegistrationSummary } from "../../rest.client/useTournamentApi";
import { AlertColor } from "@mui/material/Alert/Alert";

export default function MiniStatusAlert({ registration }: { registration: RegistrationSummary }) {
   const theme = useTheme();
   const isMobileDevice = !useMediaQuery("(min-width:600px)");

   let severity: AlertColor = "info";
   switch (registration.status) {
      case "ACCEPTED":
         severity = "success";
         break;
      case "DROPPED":
      case "PENDING":
         severity = "warning";
         break;
      case "REJECTED":
         severity = "error";
         break;
   }

   return (
      <Box>
         <Alert severity={severity}>{registration.status}</Alert>
      </Box>
   );
}
