import React, { useEffect } from "react";
import { Box } from "@mui/material";
import useGlobal from "../../global/useGlobal";
import { Maybe, TPoint2 } from "../../TYPE";
import { randomPos } from "../../utils";
import TournamentDetails from "./TournamentDetails";
import { useParams } from "react-router-dom";
import StageTabs from "./StageTabs";
import StageView from "./stageView/StageView";
import useRemoteTournament from "../useRemoteTournament";
import useReload from "../../header/useReload";
import BackDropSpinner from "../../common/BackDropSpinner";
import ScrollableBorderedWrapper from "../../common/ScrollableBorderedWrapper";

const ViewTournamentPage: React.FC = () => {
   const { tournamentId, stageId } = useParams();
   const [, setTilesPos] = useGlobal<Maybe<TPoint2>>("tilesPoints", null);
   const { reload } = useReload();
   const showStage = stageId != null;
   const remoteTournament = useRemoteTournament(tournamentId);

   useEffect(() => {
      if (reload) {
         remoteTournament.loadData();
      }
   }, [reload, remoteTournament]);

   useEffect(() => {
      setTilesPos(randomPos());
   }, [setTilesPos]);

   return (
      <ScrollableBorderedWrapper>
         <BackDropSpinner show={remoteTournament.loading} />
         <Box
            id={"leagueViewRoot"}
            sx={{
               width: "100%",
               display: "flex",
               flexDirection: "column",
               justifyContent: "flex-start",
               alignItems: "center",
            }}
         >
            <Box sx={{ width: "100%" }}>
               <StageTabs remoteTournament={remoteTournament} stageId={stageId} />
            </Box>
            {!showStage && <TournamentDetails remoteTournament={remoteTournament} />}
            {showStage && <StageView remoteTournament={remoteTournament} />}
         </Box>
      </ScrollableBorderedWrapper>
   );
};

export default ViewTournamentPage;
