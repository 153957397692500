import React from "react";
import Header from "./header/Header";
import { Box } from "@mui/material";
import { SxProps } from "@mui/system";
import useCurrentUser, { useCurrentUserListener } from "./auth/useCurrentUser";
import VerifiedEmailChecker from "./userFeatures/VerifiedEmailChecker";
import { BODY_HEIGHT_IN_CALC } from "./UI_CONST";
import InteractiveTilesComponent from "./interactiveTiles/InteractiveTilesComponent";
import Messages from "./messagesComponent/Messages";

const frameRootSx: SxProps = {
   width: "100vw",
   height: "100vh",
   overflow: "hidden",
};

export const bodyRootSx: SxProps = {
   width: "100vw",
   height: BODY_HEIGHT_IN_CALC,
};

export default function Frame(props: React.PropsWithChildren<{}>) {
   useCurrentUserListener();
   const user = useCurrentUser();
   return (
      <Box id={"frameRoot"} sx={frameRootSx}>
         <InteractiveTilesComponent />
         <Header />
         <Box id={"bodyRoot"} sx={bodyRootSx}>
            {user.setupComplete && props.children}
         </Box>
         <VerifiedEmailChecker />
         <Messages />
      </Box>
   );
}
