import React, { FormEvent, useCallback, useRef } from "react";
import { Avatar, Box, Button, CircularProgress } from "@mui/material";
import { SxProps } from "@mui/system";
import useCurrentUser from "../../auth/useCurrentUser";
import { useApplicationApi } from "../../rest.client/useApplicationApi";
import useReload from "../../header/useReload";
import useAddMessageItem from "../../messagesComponent/useAddMessageItem";

const profilePageUserAvatarRootSx: SxProps = {
   position: "relative",
};
const profilePageUserAvatarSx: SxProps = {
   position: "relative",
   width: "60px",
   height: "60px",
};

export default function ProfilePageUserAvatar() {
   const user = useCurrentUser();
   const addMessageItem = useAddMessageItem();
   const { requestReload } = useReload();
   const {
      uploadPhoto: { call: uploadPhoto, loading: uploadingImage },
   } = useApplicationApi();
   const fileInput = useRef<HTMLInputElement>(null);

   const handleSubmit = useCallback(
      (event: FormEvent) => {
         const files = fileInput.current?.files;
         if (files != null) {
            event.preventDefault();
            let formData = new FormData();
            formData.append("file", files[0]);
            uploadPhoto({ body: formData })
               .then(() =>
                  addMessageItem("Photo upload success. Please allow up to 1 hour for you avatar to change!", "success")
               )
               .catch(() => addMessageItem("Photo upload failed", "error"))
               .finally(() => requestReload());
         }
      },
      [addMessageItem, requestReload, uploadPhoto]
   );

   const openFilePicker = useCallback(() => {
      fileInput.current && fileInput.current.click();
   }, [fileInput]);

   return (
      <Box id={"profilePageUserAvatarRoot"} sx={profilePageUserAvatarRootSx}>
         <Avatar src={user?.user?.photoURL != null ? user.user?.photoURL : ""} sx={profilePageUserAvatarSx} />
         <Button color={"secondary"} size={"small"} variant="text" onClick={openFilePicker}>
            {uploadingImage ? <CircularProgress size={"23px"} /> : "Edit"}
         </Button>
         <input
            type="file"
            hidden={true}
            ref={fileInput}
            onInput={handleSubmit}
            accept={"image/png, image/gif, image/jpeg"}
         />
      </Box>
   );
}
