import { Box, Grow, InputLabel, MenuItem, Select, Theme } from "@mui/material";
import PlayerAndCommanderComponent from "../playerAndCommander/PlayerAndCommanderComponent";
import React, { ReactNode, useCallback, useMemo } from "react";
import { SxProps } from "@mui/system";
import { UserSummary } from "../../rest.client/useApplicationApi";
import { Maybe } from "../../TYPE";
import { empty } from "../../utils";
import { CardNameAndImage, CommanderPair } from "../../cardFeatures/CardTypes";
import CardsCircleDisplayComponent from "../../cardFeatures/cardsCircleDisplay/CardsCircleDisplayComponent";
import {
   ComponentMode,
   CONTAINER_CIRCLE_BORDER_WIDTH,
   CONTAINER_CIRCLE_SIZE,
   PLAYER_AND_COMMANDER_COMPONENT_GAP_RATIO,
   PLAYER_AND_COMMANDER_COMPONENT_SIZE,
} from "../../UI_CONST";
import FormControl from "@mui/material/FormControl";
import { AdditionalStatsType } from "./GameData";
import { Property } from "csstype";
import { useNavigate } from "react-router-dom";
import { toCommanderInsights } from "../../navPaths";
import { MutableGame } from "../MutableGame";

const containerCircleBasicSx: SxProps = {
   position: "relative",
   margin: "auto",
   width: `${CONTAINER_CIRCLE_SIZE}vmin`,
   height: `${CONTAINER_CIRCLE_SIZE}vmin`,
   borderRadius: "50%",
   borderWidth: `${CONTAINER_CIRCLE_BORDER_WIDTH}vmin`,
   borderStyle: "dotted dashed dashed dotted",
   borderColor: "rgb(43,16,84)",
   boxSizing: "border-box",
   boxShadow: "inset 0 0 3vmin 2vmin rgb(21,0,52)",
   display: "flex",
};

const blurSx: SxProps = {
   position: "absolute",
   left: `-${CONTAINER_CIRCLE_BORDER_WIDTH + CONTAINER_CIRCLE_BORDER_WIDTH * 0.5}vmin`,
   top: `-${CONTAINER_CIRCLE_BORDER_WIDTH + CONTAINER_CIRCLE_BORDER_WIDTH * 0.5}vmin`,
   width: `${CONTAINER_CIRCLE_SIZE + CONTAINER_CIRCLE_BORDER_WIDTH}vmin`,
   height: `${CONTAINER_CIRCLE_SIZE + CONTAINER_CIRCLE_BORDER_WIDTH}vmin`,
   borderRadius: "50%",
   backdropFilter: "blur(8px)",
};

const endingTurnFieldLabelSx: SxProps<Theme> = {
   fontSize: "1.2vmin",
   color: (theme) => theme.palette.secondary.main,
   "&.Mui-focused": {
      color: (theme) => theme.palette.secondary.main,
   },
};

const endingTurnFieldBasicSx: SxProps = {
   width: "7vmin",
   height: "3vmin",
   fontSize: "1.2vmin",
};

const cardWidthInVmin = PLAYER_AND_COMMANDER_COMPONENT_SIZE * 0.5; //vmin

export type PlayerPosition = "PLAYER_1" | "PLAYER_2" | "PLAYER_3" | "PLAYER_4";

const getEndingTurnOptions = (): Array<ReactNode> => {
   const result = [];
   for (let i = 0; i < 26; i++) {
      result.push(
         <MenuItem key={i} value={i}>
            {i === 0 ? "--" : i}
         </MenuItem>
      );
   }
   return result;
};

const winConditionTexts = [
   "Combo",
   "Combat damage",
   "Commander damage",
   "Infect",
   "Time limit",
   "Hard stax lock",
   "All players conceded",
];

const winConditionValueMap: Record<string, string> = {
   Combo: "COMBO",
   "Combat damage": "COMBAT_DAMAGE",
   "Commander damage": "COMMANDER_DAMAGE",
   Infect: "INFECT",
   "Time limit": "TIME_LIMIT",
   "Hard stax lock": "HARD_STAX_LOCK",
   "All players conceded": "CONCEDE",
};

const getWinConditionOptions = (): Array<ReactNode> => {
   return winConditionTexts.map((text, i) => (
      <MenuItem key={i} value={winConditionValueMap[text]}>
         {text}
      </MenuItem>
   ));
};

export const endingTurnOptions = getEndingTurnOptions();
export const winConditions = getWinConditionOptions();

export default function GameViewer({
   game,
   selectedPlayerSummaries,
   additionStats,
   containerMargin,
}: {
   game: MutableGame;
   selectedPlayerSummaries: UserSummary[];
   additionStats?: AdditionalStatsType;
   containerMargin?: Property.Margin;
}) {
   const nav = useNavigate();
   const endingTurns = game.existingFullGame?.turns;
   const showTurns = endingTurns != null;

   const highImpactCards: Array<CardNameAndImage> = useMemo(() => {
      return game.existingFullGame ? game.existingFullGame.highImpactCards : [];
   }, [game.existingFullGame]);

   const gameWonByCards: Array<CardNameAndImage> = useMemo(() => {
      return game.existingFullGame?.gameWonBy != null ? game.existingFullGame?.gameWonBy : [];
   }, [game.existingFullGame]);

   const endingTurnFieldSx: SxProps = useMemo(() => ({ ...endingTurnFieldBasicSx, pointerEvents: "auto" }), []);

   const containerCircleSx: SxProps = useMemo(
      () => (containerMargin ? { ...containerCircleBasicSx, margin: containerMargin } : containerCircleBasicSx),
      [containerMargin]
   );
   const openInsightsCommander = useCallback(
      (pair: Maybe<CommanderPair>) => {
         pair && nav(toCommanderInsights(pair.commander1.id, pair.commander2 != null ? pair.commander2.id : ""));
      },
      [nav]
   );

   return (
      <Grow in={true}>
         <Box id={"gameViewer"} sx={containerCircleSx}>
            <Box sx={blurSx} />
            <PlayerAndCommanderComponent
               index={1}
               selectedPlayerSummaries={selectedPlayerSummaries}
               playerUserSummary={game.player1Summary}
               existingPlayer={game.existingFullGame?.player1}
               playerCommanderPair={game.player1CommanderPair}
               setPlayerSummary={empty}
               setPlayerCommanderPair={empty}
               width={PLAYER_AND_COMMANDER_COMPONENT_SIZE}
               height={PLAYER_AND_COMMANDER_COMPONENT_SIZE}
               displacementRatio={PLAYER_AND_COMMANDER_COMPONENT_GAP_RATIO}
               setWinner={empty}
               wonGame={game.winningUser === "PLAYER_1"}
               mode={ComponentMode.VIEW}
               onClickCommanderPair={openInsightsCommander}
            />
            <PlayerAndCommanderComponent
               index={2}
               selectedPlayerSummaries={selectedPlayerSummaries}
               playerUserSummary={game.player2Summary}
               existingPlayer={game.existingFullGame?.player2}
               playerCommanderPair={game.player2CommanderPair}
               setPlayerSummary={empty}
               setPlayerCommanderPair={empty}
               width={PLAYER_AND_COMMANDER_COMPONENT_SIZE}
               height={PLAYER_AND_COMMANDER_COMPONENT_SIZE}
               displacementRatio={PLAYER_AND_COMMANDER_COMPONENT_GAP_RATIO}
               setWinner={empty}
               wonGame={game.winningUser === "PLAYER_2"}
               mode={ComponentMode.VIEW}
               onClickCommanderPair={openInsightsCommander}
            />
            <PlayerAndCommanderComponent
               index={3}
               selectedPlayerSummaries={selectedPlayerSummaries}
               playerUserSummary={game.player3Summary}
               existingPlayer={game.existingFullGame?.player3}
               playerCommanderPair={game.player3CommanderPair}
               setPlayerSummary={empty}
               setPlayerCommanderPair={empty}
               width={PLAYER_AND_COMMANDER_COMPONENT_SIZE}
               height={PLAYER_AND_COMMANDER_COMPONENT_SIZE}
               displacementRatio={PLAYER_AND_COMMANDER_COMPONENT_GAP_RATIO}
               setWinner={empty}
               wonGame={game.winningUser === "PLAYER_3"}
               mode={ComponentMode.VIEW}
               onClickCommanderPair={openInsightsCommander}
            />
            {game.player4Summary != null && (
               <PlayerAndCommanderComponent
                  index={4}
                  selectedPlayerSummaries={selectedPlayerSummaries}
                  playerUserSummary={game.player4Summary}
                  existingPlayer={game.existingFullGame?.player4}
                  playerCommanderPair={game.player4CommanderPair}
                  setPlayerSummary={empty}
                  setPlayerCommanderPair={empty}
                  width={PLAYER_AND_COMMANDER_COMPONENT_SIZE}
                  height={PLAYER_AND_COMMANDER_COMPONENT_SIZE}
                  displacementRatio={PLAYER_AND_COMMANDER_COMPONENT_GAP_RATIO}
                  setWinner={empty}
                  wonGame={game.winningUser === "PLAYER_4"}
                  mode={ComponentMode.VIEW}
                  onClickCommanderPair={openInsightsCommander}
               />
            )}
            <CardsCircleDisplayComponent
               mode={ComponentMode.VIEW}
               startingDegree={70}
               rangeDegree={40}
               cardsInfo={highImpactCards}
               cardWidth={cardWidthInVmin}
               cardWidthUnit={"vmin"}
               diameter={"50vmin"}
               centerPositionLeft={"50%"}
               centerPositionTop={"50%"}
               setCards={empty}
               placeHolderText={"Game defining cards"}
            />
            <CardsCircleDisplayComponent
               mode={ComponentMode.VIEW}
               startingDegree={250}
               rangeDegree={40}
               cardsInfo={gameWonByCards}
               cardWidth={cardWidthInVmin}
               cardWidthUnit={"vmin"}
               diameter={"50vmin"}
               centerPositionLeft={"50%"}
               centerPositionTop={"50%"}
               setCards={empty}
               placeHolderText={"Cards in win con"}
            />
            {showTurns && (
               <FormControl variant={"outlined"} sx={{ margin: "auto" }}>
                  <InputLabel sx={endingTurnFieldLabelSx}>Ending turn</InputLabel>
                  <Select
                     sx={endingTurnFieldSx}
                     IconComponent={() => null}
                     readOnly={true}
                     defaultValue={0}
                     value={endingTurns}
                     label="Ending turn"
                  >
                     {endingTurnOptions}
                  </Select>
               </FormControl>
            )}
         </Box>
      </Grow>
   );
}
