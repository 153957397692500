import React from "react";
import { Alert, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { MatchResultWinnerOnlyResponse } from "../../../rest.client/useStagesApi";
import MatchRow from "./MatchRow";
import MatchDialog from "./MatchDialog";
import { RemoteStage } from "../stageView/useRemoteStage";
import { Consumer, Procedure } from "../../../utils";

export default function MatchesTable({
   matches,
   remoteStage,
   onClickMatch,
   matchId,
   onClose,
   showNumber = true,
   showDelete = false,
   onClickDelete,
}: {
   matches: MatchResultWinnerOnlyResponse[];
   remoteStage: RemoteStage;
   onClickMatch: Consumer<MatchResultWinnerOnlyResponse>;
   matchId?: string;
   onClose: Procedure;
   showNumber?: boolean;
   showDelete?: boolean;
   onClickDelete?: Consumer<MatchResultWinnerOnlyResponse>;
}) {
   const selectedMatch = matches.find((m) => m.id === matchId);
   return (
      <Paper sx={{ width: "100%", position: "relative" }}>
         <TableContainer component={Paper}>
            <Table sx={{}} aria-label="simple table">
               <TableHead>
                  <TableRow>
                     {showNumber && <TableCell>No.</TableCell>}
                     <TableCell>Players</TableCell>
                     <TableCell>Status</TableCell>
                     {showDelete && <TableCell>Delete</TableCell>}
                  </TableRow>
               </TableHead>
               <TableBody>
                  {matches.map((match) => (
                     <MatchRow
                        key={match.id}
                        match={match}
                        onClick={onClickMatch}
                        showNumber={showNumber}
                        onClickDelete={onClickDelete}
                        showDelete={showDelete}
                     />
                  ))}
               </TableBody>
            </Table>
            {matches.length === 0 && <Alert severity={"info"}>No data found.</Alert>}
         </TableContainer>
         {selectedMatch && (
            <MatchDialog remoteStage={remoteStage} matchId={selectedMatch.id} open={true} onClose={onClose} />
         )}
      </Paper>
   );
}
