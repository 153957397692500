import React, { useCallback, useMemo } from "react";
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, useTheme } from "@mui/material";
import { MutableGame } from "../MutableGame";
import { PlayerPosition } from "../viewGamePage/GameViewer";

const WinnerSelect: React.FC<{ game: MutableGame; readOnly: boolean }> = ({ game, readOnly }) => {
   const theme = useTheme();
   const winnerSelectorItems = useMemo(() => {
      const result = [];
      for (const key in game.usersBySeat) {
         const value = game.usersBySeat[key as PlayerPosition];
         result.push(
            <MenuItem key={key} value={key}>
               {key + (value != null ? "(" + value.name + ")" : "")}
            </MenuItem>
         );
      }
      result.push(
         <MenuItem key={"draw"} value={"draw"}>
            {"Draw"}
         </MenuItem>
      );
      return result;
   }, [game.usersBySeat]);

   const winnerSelectorChangeHandler = useCallback(
      (event: SelectChangeEvent) => {
         game.setWinningUser(event.target.value === "draw" ? undefined : (event.target.value as PlayerPosition));
      },
      [game]
   );

   return (
      <FormControl sx={{ marginTop: theme.spacing(1), marginBottom: theme.spacing(1) }}>
         <InputLabel>Winner</InputLabel>
         <Select
            readOnly={readOnly}
            style={{ minWidth: "133px" }}
            size={"small"}
            id={"winnerSelector"}
            value={game.winningUser == null ? "draw" : game.winningUser}
            label="Winner"
            onChange={winnerSelectorChangeHandler}
         >
            {winnerSelectorItems}
         </Select>
      </FormControl>
   );
};

export default WinnerSelect;
