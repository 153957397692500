import React, { useCallback, useState } from "react";
import { Box, Button, Paper, useTheme } from "@mui/material";
import { SxProps } from "@mui/system";
import { useNavigate, useParams } from "react-router-dom";
import { RemoteTournament } from "../useRemoteTournament";
import InvitesListDialog from "../invites/InvitesListDialog";
import TournamentRegistrationDialog from "../registration/TournamentRegistrationDialog";
import PlayerTournamentCardDialog from "../registration/PlayerTournamentCardDialog";
import TournamentsCreationEditDialog from "../creationPage/TournamentsCreationEditDialog";
import StartDialog from "./StartDialog";
import EndDialog from "./EndDialog";
import { useFilePicker } from "use-file-picker";
import useAddMessageItem from "../../messagesComponent/useAddMessageItem";
import useCurrentUser from "../../auth/useCurrentUser";
import { LOGIN_PATH } from "../../index";

const buttonSx: SxProps = {
   width: "200px",
};

export default function TournamentControls({ remoteTournament }: { remoteTournament: RemoteTournament }) {
   const { tournamentId } = useParams();
   const nav = useNavigate();
   const theme = useTheme();
   const user = useCurrentUser();
   const tournament = remoteTournament.tournament;
   const messages = useAddMessageItem();

   const { openFilePicker } = useFilePicker({
      readAs: "ArrayBuffer",
      accept: "image/*",
      multiple: false,
      onFilesSuccessfullySelected: async ({ plainFiles, filesContent }) => {
         const formData = new FormData();
         formData.append("file", plainFiles[0]);
         await remoteTournament.uploadLogo(formData);
         messages("Logo updated!", "success");
         await remoteTournament.loadData();
      },
   });

   const [openTournamentRegistration, setOpenTournamentRegistration] = useState<boolean>(false);
   const [openStartDialog, setOpenStartDialog] = useState(false);
   const [openEndDialog, setOpenEndDialog] = useState(false);
   const [openInvitesDialog, setOpenInvitesDialog] = useState(false);
   const [openCardDialog, setOpenCardDialog] = useState(false);
   const [openEditDialog, setOpenEditDialog] = useState(false);

   const editTournamentsButtonHandler = useCallback(() => {
      tournamentId && setOpenEditDialog(true);
   }, [tournamentId]);

   const joinTournamentButtonHandler = useCallback(() => {
      tournamentId && setOpenTournamentRegistration(true);
   }, [tournamentId]);

   return (
      <Paper
         elevation={1}
         sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: theme.spacing(1),
            gap: theme.spacing(1),
         }}
      >
         {!user.loggedIn && (
            <Button sx={buttonSx} variant={"outlined"} onClick={() => nav(LOGIN_PATH)}>
               Create account to register
            </Button>
         )}
         {tournament?.canRegister && (
            <Button sx={buttonSx} variant={"outlined"} onClick={joinTournamentButtonHandler}>
               Register for tournament
            </Button>
         )}
         {tournament?.myRegistration && (
            <Button sx={buttonSx} variant={"contained"} onClick={() => setOpenCardDialog(true)}>
               View tournament card
            </Button>
         )}
         {tournament?.myRegistration && (
            <Button
               sx={buttonSx}
               disabled={remoteTournament.hasEnded}
               variant={"outlined"}
               onClick={joinTournamentButtonHandler}
            >
               Edit my registration
            </Button>
         )}
         {remoteTournament.canEdit && (
            <Button sx={buttonSx} variant={"outlined"} onClick={editTournamentsButtonHandler}>
               Edit tournament
            </Button>
         )}
         {remoteTournament.canEdit && (
            <Button sx={buttonSx} variant={"outlined"} onClick={() => setOpenInvitesDialog(true)}>
               Show invites
            </Button>
         )}
         {remoteTournament.canEdit && (
            <Button sx={buttonSx} variant={"outlined"} onClick={() => openFilePicker()}>
               Upload logo
            </Button>
         )}
         {remoteTournament.tournament != null && (
            <Button
               sx={buttonSx}
               variant={"outlined"}
               onClick={() => {
                  const url =
                     window.location.protocol +
                     "//" +
                     window.location.host +
                     "/ev/" +
                     remoteTournament.tournament?.base32Id;
                  navigator.clipboard.writeText(url).then(() => {
                     messages("Link copied!", "success");
                  });
               }}
            >
               Copy compact link
            </Button>
         )}
         {remoteTournament.canEdit && (
            <Button
               sx={buttonSx}
               disabled={!remoteTournament.canClose}
               variant={"outlined"}
               onClick={() => setOpenEndDialog(true)}
            >
               End tournament
            </Button>
         )}
         {remoteTournament.canEdit && remoteTournament.tournament?.status === "SCHEDULED" && (
            <Button sx={buttonSx} variant={"contained"} onClick={() => setOpenStartDialog(true)}>
               Start tournament
            </Button>
         )}
         <Box>
            <InvitesListDialog
               open={openInvitesDialog}
               close={() => setOpenInvitesDialog(false)}
               remoteTournament={remoteTournament}
            />
            <TournamentRegistrationDialog
               remoteTournament={remoteTournament}
               open={openTournamentRegistration}
               tournamentId={""}
               closeHandler={() => setOpenTournamentRegistration(false)}
            />
            {tournament?.myRegistration && (
               <PlayerTournamentCardDialog
                  open={openCardDialog}
                  close={() => setOpenCardDialog(false)}
                  remoteTournament={remoteTournament}
                  registration={tournament.myRegistration}
               />
            )}
            {openEditDialog && (
               <TournamentsCreationEditDialog
                  open={openEditDialog}
                  remoteTournament={remoteTournament}
                  onClose={() => setOpenEditDialog(false)}
               />
            )}
            <StartDialog
               open={openStartDialog}
               onCancel={() => setOpenStartDialog(false)}
               onOk={() => {
                  remoteTournament.start().finally(() => setOpenStartDialog(false));
               }}
            />
            <EndDialog
               open={openEndDialog}
               onCancel={() => setOpenEndDialog(false)}
               onOk={async () => {
                  await remoteTournament.end();
                  setOpenEndDialog(false);
               }}
            />
         </Box>
      </Paper>
   );
}
