import { Alert, Box, useTheme } from "@mui/material";
import React from "react";
import { GameSummary } from "../../../rest.client/useApplicationApi";
import useRemoteGame from "../../../gameFeatures/useRemoteGame";
import { RemoteMatch } from "../stageView/useRemoteMatch";
import RegistrationChip from "../../registration/RegistrationChip";

export default function GameStatusBar({
   game,
   remoteMatch,
}: React.PropsWithChildren<{
   game: GameSummary;
   remoteMatch: RemoteMatch;
}>) {
   const theme = useTheme();
   const mutableSummary = useRemoteGame(game);
   const winningRegistration = remoteMatch.match?.matchResults.find(
      (mR) => mR.registration.user.id === mutableSummary.winner?.user.id
   );

   return (
      <Box sx={{ marginBottom: theme.spacing(2), display: "flex", flexWrap: "wrap", gap: theme.spacing(1) }}>
         {winningRegistration && <RegistrationChip registration={winningRegistration?.registration} />}
         {!winningRegistration && (
            <Alert
               sx={{
                  height: "24px",
                  padding: "0px 12px",
                  lineHeight: 1,
                  "& .MuiAlert-message": {
                     overflow: "hidden",
                  },
                  "& .MuiAlert-icon": {
                     fontSize: 14,
                  },
               }}
               severity={"info"}
            >
               Draw
            </Alert>
         )}
         <Alert
            sx={{
               height: "24px",
               padding: "0px 12px",
               lineHeight: 1,
               "& .MuiAlert-message": {
                  overflow: "hidden",
               },
               "& .MuiAlert-icon": {
                  fontSize: 14,
               },
            }}
            severity={game.status === "PENDING" ? "warning" : game.status === "APPROVED" ? "success" : "error"}
         >
            {game.status === "PENDING" ? "Pending" : game.status === "APPROVED" ? "Approved" : "Disputed"}
         </Alert>
      </Box>
   );
}
