import React, { CSSProperties, memo, useEffect, useMemo, useRef, useState } from "react";
import { Box, useTheme } from "@mui/material";
import { NUM_OF_TILES_PER_COLUMN, NUM_OF_TILES_PER_ROW, TILES_TRANSITION_TIME } from "../UI_CONST";
import useGlobal from "../global/useGlobal";
import { Maybe, TPoint2 } from "../TYPE";

interface IInteractiveTileComponentProps {
   idPostfix: string;
   row: number;
   column: number;
}

const InteractiveTileComponent: React.FC<IInteractiveTileComponentProps> = ({ idPostfix, row, column }) => {
   const theme = useTheme();
   const tileBasicStyle: CSSProperties = useMemo(
      () => ({
         position: "absolute",
         backgroundColor: theme.palette.interactiveTilesColor,
         boxShadow: `0 0 1vmin 0.1vmin rgba(0,0,0,0.1)`,
         transformOrigin: "50% 50%",
         transform: "rotateX(0) rotateY(0)",
         boxSizing: "border-box",
         pointerEvents: "none",
         backfaceVisibility: "hidden",
         willChange: "transform",
         transition: `transform ${TILES_TRANSITION_TIME}s`,
         width: 0,
         height: 0,
         left: 0,
         top: 0,
         borderRadius: 0,
         display: "none",
         border: "1px solid rgba(0,0,0,0.2)",
      }),
      [theme.palette.interactiveTilesColor]
   );

   const tileRef = useRef<HTMLDivElement>(null);

   const [tilesPos] = useGlobal<Maybe<TPoint2>>("tilesPoints", null);

   const [tileStyle, setTileStyle] = useState<CSSProperties>(tileBasicStyle);
   const [flipped, setFlipped] = useState(false);

   const isLastTile = useMemo(
      () => (row + 1) * (column + 1) === NUM_OF_TILES_PER_ROW * NUM_OF_TILES_PER_COLUMN,
      [column, row]
   );
   const isFirstTile = useMemo(() => row === 0 && column === 0, [column, row]);

   const tileWidth = useMemo(() => `${100 / NUM_OF_TILES_PER_ROW}%`, []);
   const tileHeight = useMemo(() => `${100 / NUM_OF_TILES_PER_COLUMN}%`, []);
   const tileLeft = useMemo(() => `${(row * 100) / NUM_OF_TILES_PER_ROW}%`, [row]);
   const tileTop = useMemo(() => `${(column * 100) / NUM_OF_TILES_PER_COLUMN}%`, [column]);
   const tileBorderRadius = useMemo(() => `${(100 / NUM_OF_TILES_PER_COLUMN) * 0.1}%`, []);

   useEffect(() => {
      const target = tileRef.current;

      if (target) {
         const windowWidth = window.innerWidth;
         const windowHeight = window.innerHeight;

         let tileTransform: string;
         if (tilesPos) {
            const tileCenterX = target.offsetLeft + target.offsetWidth * 0.5;
            const tileCenterY = target.offsetTop + target.offsetHeight * 0.5;
            const xDiff = tilesPos.x - tileCenterX;
            const yDiff = tilesPos.y - tileCenterY;

            const xRotation = 180 * (xDiff / windowWidth);
            const yRotation = 180 * (yDiff / windowHeight);
            tileTransform = `rotateX(${xRotation}deg) rotateY(${yRotation}deg)`;
         } else {
            tileTransform = "rotateX(0) rotateY(0)";
         }

         const tileStyle = {
            ...tileBasicStyle,
            width: tileWidth,
            height: tileHeight,
            left: tileLeft,
            top: tileTop,
            transform: `${tileTransform}`,
            borderRadius: tileBorderRadius,
            display: "block",
         };
         setTileStyle(tileStyle);
      }
   }, [tilesPos, tileBorderRadius, tileHeight, tileLeft, tileTop, tileWidth, tileBasicStyle]);

   return <Box id={`tile${idPostfix}`} ref={tileRef} style={tileStyle} />;
};

export default memo(InteractiveTileComponent);
