import React, { useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Typography, useTheme } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { RemoteStage } from "./useRemoteStage";
import CreateOrEditStageDialog from "../createDialog/CreateOrEditStageDialog";
import { RemoteTournament } from "../../useRemoteTournament";
import OkCancelDialog from "../../../OkCancelDialog";
import { useNavigate } from "react-router-dom";
import { toTournamentDetails } from "../../../navPaths";

export default function StageOrganiserControls({
   remoteStage,
   remoteTournament,
}: {
   remoteStage: RemoteStage;
   remoteTournament: RemoteTournament;
}) {
   const nav = useNavigate();
   const stageFull = remoteStage.stage;
   const theme = useTheme();
   const [openEditDialog, setOpenEditDialog] = useState(false);
   const [openCloseDialog, setOpenCloseDialog] = useState(false);
   const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
   const [openActivateDialog, setOpenActivateDialog] = useState(false);

   return (
      <>
         {stageFull && (
            <Accordion defaultExpanded={true}>
               <AccordionSummary aria-controls="panel1d-content" expandIcon={<ExpandMoreIcon />}>
                  <Typography>Organiser controls</Typography>
               </AccordionSummary>
               <AccordionDetails>
                  <Box
                     sx={{
                        marginTop: theme.spacing(2),
                        display: "flex",
                        gap: theme.spacing(1),
                        flexWrap: "wrap",
                     }}
                  >
                     <Button
                        variant={"contained"}
                        disabled={!remoteStage.canActivate}
                        onClick={() => setOpenActivateDialog(true)}
                     >
                        Activate stage
                     </Button>
                     <Button disabled={!remoteStage.canEdit} onClick={() => setOpenEditDialog(true)}>
                        Edit stage
                     </Button>
                     <Button disabled={!remoteStage.canClose} onClick={() => setOpenCloseDialog(true)}>
                        Close stage
                     </Button>
                     <Button disabled={!remoteStage.canDelete} onClick={() => setOpenDeleteDialog(true)}>
                        Delete stage
                     </Button>
                  </Box>
               </AccordionDetails>
            </Accordion>
         )}
         <CreateOrEditStageDialog
            open={openEditDialog}
            close={() => setOpenEditDialog(false)}
            remoteTournament={remoteTournament}
            id={stageFull?.id}
         />
         <OkCancelDialog
            open={openCloseDialog}
            onCancel={() => setOpenCloseDialog(false)}
            onOk={async () => {
               await remoteStage.close();
               setOpenCloseDialog(false);
            }}
            title={"Close this stage?"}
            message={
               "Are your sure you want to close this stage? Closed stages cannot have any more games logged against them."
            }
         />
         <OkCancelDialog
            open={openActivateDialog}
            onCancel={() => setOpenActivateDialog(false)}
            onOk={async () => {
               await remoteStage.start();
               setOpenActivateDialog(false);
            }}
            title={"Activate this stage?"}
            message={
               "Are you ready to activate this stage, once started users or organisers will be able to submit games to the stage."
            }
         />
         <OkCancelDialog
            open={openDeleteDialog}
            onCancel={() => setOpenDeleteDialog(false)}
            onOk={async () => {
               await remoteStage.deleteStage();
               setOpenCloseDialog(false);
               remoteTournament.tournament && nav(toTournamentDetails(remoteTournament.tournament.id));
               remoteTournament.loadData();
            }}
            title={"Delete this stage?"}
            message={"Are your sure you want to delete " + stageFull?.name + "?"}
         />
      </>
   );
}
