import React, { useState } from "react";
import {
   Avatar,
   Box,
   Button,
   Card,
   CardActions,
   CardContent,
   CardHeader,
   Link,
   List,
   ListItem,
   ListItemText,
   Typography,
   useTheme,
} from "@mui/material";
import { moxfieldUrl } from "../../utils";
import DeckViewer from "../deckViewer/DeckViewer";
import { SnapshotSummary } from "../../rest.client/useDeckListApi";
import DecklistLock from "./DecklistLock";
import { RegistrationSummary } from "../../rest.client/useTournamentApi";
import { Maybe } from "../../TYPE";

export default function DecklistInfoCard({
   snapshot,
   registration,
}: {
   snapshot: SnapshotSummary;
   registration?: Maybe<RegistrationSummary>;
}) {
   const theme = useTheme();
   const [showDeckViewer, setShowDeckViewer] = useState<boolean>(false);

   return (
      <Card>
         <CardHeader
            avatar={snapshot.commander1 != null ? <Avatar src={snapshot.commander1.artCropImageUri}></Avatar> : null}
            title={
               <Link sx={{ cursor: "pointer" }} onClick={() => setShowDeckViewer(true)} underline="none">
                  {snapshot.name}
               </Link>
            }
         />
         <CardContent>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
               <List dense={true}>
                  <ListItem>
                     <ListItemText primary={snapshot?.name} secondary={"Name"} />
                  </ListItem>
                  {registration && (
                     <ListItem>
                        <ListItemText
                           primary={<DecklistLock registration={registration} />}
                           secondary={registration.decklistLocked ? "Deck locked" : "Deck unlocked"}
                        />
                     </ListItem>
                  )}
                  <ListItem>
                     <ListItemText
                        primary={new Date(snapshot.createDate).toLocaleString()}
                        secondary={"Snapshot taken on"}
                     />
                  </ListItem>
               </List>
               <List dense={true}>
                  <ListItem>
                     <ListItemText
                        primary={
                           <Link
                              href={moxfieldUrl(snapshot.deckListId)}
                              underline="none"
                              target="_blank"
                              rel="noopener"
                              sx={{ display: "flex", alignItems: "center" }}
                           >
                              <Avatar
                                 style={{ height: 24, width: 24 }}
                                 src={"/moxfield_icon.png"}
                                 alt={"open in moxfield icon"}
                              />
                              <Typography sx={{ marginLeft: theme.spacing(1) }}>Moxfield</Typography>
                           </Link>
                        }
                        secondary={"Source platform"}
                     />
                  </ListItem>
                  <ListItem>
                     <ListItemText primary={snapshot.hash.slice(0, 8) + "..."} secondary={"Snapshot hash"} />
                  </ListItem>
               </List>
            </Box>
            <DeckViewer
               open={showDeckViewer}
               initialSnapId={snapshot.id}
               closeHandler={() => setShowDeckViewer(false)}
            />
         </CardContent>
         <CardActions sx={{ gap: theme.spacing(1) }}>
            <Button variant={"contained"} size="medium" onClick={() => setShowDeckViewer(true)}>
               View snapshot
            </Button>
            <Link href={moxfieldUrl(snapshot.deckListId)} underline="none" target="_blank" rel="noopener">
               <Button
                  startIcon={
                     <Avatar
                        style={{ height: 24, width: 24 }}
                        src={"/moxfield_icon.png"}
                        alt={"open in moxfield icon"}
                     />
                  }
               >
                  View latest
               </Button>
            </Link>
         </CardActions>
      </Card>
   );
}
