import React from "react";
import { Alert, Box } from "@mui/material";
import { StandingsRegResponse } from "../../../../rest.client/useStagesApi";

export default function StandingStatusAlert({ standing }: { standing: StandingsRegResponse }) {
   return (
      <Box>
         {standing.standing.status === "ELIMINATED" && <Alert severity="error">Eliminated</Alert>}
         {standing.standing.status === "ACTIVE" && <Alert severity="success">Active</Alert>}
         {standing.standing.status === "DROPPED" && <Alert severity="warning">Dropped</Alert>}
      </Box>
   );
}
