import React, { useEffect } from "react";
import { useApplicationApi } from "../rest.client/useApplicationApi";
import useCurrentUser from "../auth/useCurrentUser";
import { useNavigate } from "react-router-dom";
import { Badge, Box, useTheme } from "@mui/material";

const MyGamesTabLabel: React.FC = () => {
   const {
      findGamesByUserId: { call: findPending, responseData: pendingGames },
   } = useApplicationApi();

   const user = useCurrentUser();
   const nav = useNavigate();
   const theme = useTheme();

   useEffect(() => {
      user?.user?.uid &&
         findPending({
            queryParams: {
               userId: user?.user?.uid,
               page: 0,
               pendingOnly: true,
               creatorOnly: false,
               size: 1,
               projection: "gameBasic",
               sort: "",
            },
         });
   }, [findPending, user?.user?.uid]);

   return user.loggedIn ? (
      <Box>
         My Games
         <Badge
            sx={{
               position: "absolute",
               pointerEvents: "none",
               "& .MuiBadge-badge": {
                  right: 2,
                  top: -3,
               },
            }}
            badgeContent={pendingGames?.page.totalElements}
            color={"primary"}
            component={"span"}
         />
      </Box>
   ) : null;
};

export default MyGamesTabLabel;
