import useCurrentUser from "../auth/useCurrentUser";
import axios from "axios";
import { useMemo } from "react";
import { API_BASE_URL } from "./useApplicationApi";

export function useAxiosInstance() {
   const userData = useCurrentUser();
   return useMemo(() => {
      return axios.create({
         baseURL: API_BASE_URL,
         timeout: 60000,
         headers: { Authorization: userData?.idToken || "" },
      });
   }, [userData?.idToken]);
}
