import { ListItemIcon, Menu, MenuItem, TableCell, Typography } from "@mui/material";
import React, { useCallback } from "react";
import { GameSummary, PlayerSummary, useApplicationApi } from "../../rest.client/useApplicationApi";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import useCurrentUser from "../../auth/useCurrentUser";
import useReload from "../../header/useReload";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelIcon from "@mui/icons-material/Cancel";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";

export default function GameTableOptionsCell({ game }: { game: GameSummary }) {
   const userData = useCurrentUser();
   const reload = useReload();
   const nav = useNavigate();
   const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
   const {
      approveGame: { call: approveGame },
      disputeGame: { call: disputeGame },
   } = useApplicationApi();

   const allPlayers: Array<PlayerSummary> = [game.player1, game.player2, game.player3, game.player4].filter(
      (p) => p != null
   ) as Array<PlayerSummary>;
   const myPlayer = allPlayers.find((p) => p.user.id === userData?.user?.uid);
   const canApprove = myPlayer != null && myPlayer.status !== "APPROVED";
   const canDispute = game.status !== "APPROVED" && myPlayer != null && myPlayer.status !== "DISPUTED";
   const showButton = canApprove || canDispute;
   const canEdit = game.creator.id === userData?.user?.uid;

   const handleOpenUserMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
      setAnchorElUser(event.currentTarget);
      event.stopPropagation();
   }, []);

   const handleCloseUserMenu = useCallback((event: React.MouseEvent, reason: string) => {
      setAnchorElUser(null);
      event.stopPropagation();
   }, []);
   return (
      <TableCell sx={{ cursor: "pointer" }}>
         <span onClick={handleOpenUserMenu}>{showButton && <MoreVertIcon />}</span>
         <Menu
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
               vertical: "bottom",
               horizontal: "right",
            }}
            transformOrigin={{
               vertical: "top",
               horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
         >
            {canApprove && (
               <MenuItem
                  onClick={(e) => {
                     approveGame({ pathParams: { id: game.id } });
                     e.stopPropagation();
                     reload.requestReload();
                  }}
               >
                  <ListItemIcon>
                     <CheckCircleOutlineIcon color={"success"} fontSize="small" />
                  </ListItemIcon>
                  <Typography>Approve game</Typography>
               </MenuItem>
            )}
            {canDispute && (
               <MenuItem
                  onClick={(e) => {
                     disputeGame({ pathParams: { id: game.id } });
                     e.stopPropagation();
                     reload.requestReload();
                  }}
               >
                  <ListItemIcon>
                     <CancelIcon color={"error"} fontSize="small" />
                  </ListItemIcon>
                  <Typography>Dispute game</Typography>
               </MenuItem>
            )}
            {canEdit && (
               <MenuItem
                  onClick={(e) => {
                     e.stopPropagation();
                     nav("/editGame/" + game.id);
                  }}
               >
                  <ListItemIcon>
                     <EditIcon color={"primary"} fontSize="small" />
                  </ListItemIcon>
                  <Typography>Edit game</Typography>
               </MenuItem>
            )}
         </Menu>
      </TableCell>
   );
}
