export const toPlayerInsights = (uid?: string) => (uid ? `/insights/player/${uid}` : `/insights/player/`);

export const toCommanderInsights = (id1?: string, id2?: string) =>
   `/insights/commander/` + (id1 != null ? id1 : "") + (id2 != null ? "/" + id2 : "");

export const toMetaInsights = () => {
   return "/insights/meta/";
};

export const toTopPlayersAndCards = () => {
   return "/insights/topPlayersAndCards/";
};

export const toLeaderBoard = () => {
   return "/insights/leaderboard/";
};

export const toPopularCommanders = () => {
   return "/insights/popularCommanders/";
};

export const toStageViewPage = (tournamentId: string, stageId: string) => {
   return `/tournaments/${tournamentId}/stages/${stageId}`;
};

export const toStageRoundViewPage = (tournamentId: string, stageId: string, roundId: string) => {
   return `/tournaments/${tournamentId}/stages/${stageId}/rounds/${roundId}`;
};

export const toStageRoundMatchViewPage = (tournamentId: string, stageId: string, roundId: string, matchId: string) => {
   return `/tournaments/${tournamentId}/stages/${stageId}/rounds/${roundId}/matches/${matchId}`;
};

export const toStageViewPageMatchOpen = (tournamentId: string, stageId: string, matchId: string) => {
   return toStageViewPage(tournamentId, stageId) + `/match/${matchId}`;
};

export const toTournamentDetails = (tournamentId: string) => {
   return `/tournaments/${tournamentId}/details/`;
};
