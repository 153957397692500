import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Alert, Box, IconButton, TextField, Typography } from "@mui/material";
import SyncIcon from "@mui/icons-material/Sync";
import { SnapshotSummary, useDeckListsApi } from "../../rest.client/useDeckListApi";
import { Maybe } from "../../TYPE";
import { moxfieldUrl } from "../../utils";
import CheckIcon from "@mui/icons-material/Check";
import Spinner from "../../common/Spinner";

interface IMoxfieldDecklistTextField {
   onSnapshotUpdate: (deckListAllData: SnapshotSummary) => void;
   snapshot: Maybe<SnapshotSummary>;
   decklistLocked: boolean;
   required?: boolean;
}

const MoxfieldDecklistTextField: FC<IMoxfieldDecklistTextField> = ({
   onSnapshotUpdate,
   snapshot,
   decklistLocked,
   required = true,
}) => {
   const {
      importDeckList: { call: importDeckList },
      refreshDeckList: { call: refreshSnapshotCall, responseCode },
      loading,
   } = useDeckListsApi();
   const [snapShotSuccess, setSnapShotSuccess] = useState<Maybe<boolean>>(null);
   const [snapShotFail, setSnapShotFail] = useState<Maybe<boolean>>(null);

   const [moxfieldDecklistUrl, setMoxfieldDecklistUrl] = useState<Maybe<string>>(
      snapshot != null ? moxfieldUrl(snapshot.deckListId) : null
   );

   const refreshButtonHandler = useCallback(() => {
      setSnapShotFail(undefined);
      setSnapShotSuccess(undefined);
      if (snapshot && moxfieldDecklistUrl?.includes(snapshot.deckListId)) {
         refreshSnapshotCall({ pathParams: { id: snapshot.deckListId } })
            .then((res) => {
               setSnapShotSuccess(true);
               onSnapshotUpdate(res);
            })
            .catch((err) => {
               setSnapShotFail(true);
            });
      } else {
         moxfieldDecklistUrl &&
            importDeckList({ body: { url: moxfieldDecklistUrl } })
               .then((res) => {
                  setSnapShotSuccess(true);
                  onSnapshotUpdate(res.latestSnapshot);
               })
               .catch((err) => {
                  setSnapShotFail(true);
               });
      }
   }, [importDeckList, moxfieldDecklistUrl, onSnapshotUpdate, refreshSnapshotCall, snapshot]);

   useEffect(() => {
      if (moxfieldDecklistUrl == null && snapshot) {
         setMoxfieldDecklistUrl(moxfieldUrl(snapshot.deckListId));
      }
   }, [moxfieldDecklistUrl, snapshot]);

   useEffect(() => {
      if (snapshot == null && moxfieldDecklistUrl) {
         refreshButtonHandler();
      }
   }, [importDeckList, moxfieldDecklistUrl, refreshButtonHandler, snapshot]);

   const endAdornment = useMemo(
      () => (
         <IconButton onClick={refreshButtonHandler} disabled={snapshot?.id == null || loading || decklistLocked}>
            <SyncIcon />
         </IconButton>
      ),
      [decklistLocked, loading, refreshButtonHandler, snapshot?.id]
   );

   return (
      <Box sx={{ width: "100%" }} component="form">
         <TextField
            fullWidth
            required={required}
            disabled={loading || decklistLocked}
            size={"small"}
            variant={"outlined"}
            label={"Moxfield deck link"}
            value={moxfieldDecklistUrl || ""}
            onChange={(e) => setMoxfieldDecklistUrl(e.target.value)}
            inputProps={{ maxLength: 2000 }}
            InputProps={{
               endAdornment: endAdornment,
            }}
         />
         <Box>
            <Spinner show={loading} />
         </Box>
         {snapShotSuccess && snapshot && responseCode === 201 && (
            <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
               <Typography>
                  {"Snapshot was created for deck '" +
                     snapshot?.name +
                     "' with hash " +
                     snapshot.hash.slice(0, 8) +
                     "..."}
               </Typography>
            </Alert>
         )}
         {snapShotSuccess && snapshot && responseCode === 200 && (
            <Alert severity="info">
               <Typography>
                  {"Found existing snapshot for this deck with hash " +
                     snapshot.hash.slice(0, 8) +
                     "..." +
                     " created at " +
                     new Date(snapshot.createDate).toLocaleString()}
               </Typography>
            </Alert>
         )}
         {snapShotFail && (
            <Alert severity="error">
               <Typography>
                  Snapshot update failed, please ensure the decklist visibility is set to either UNLISTED or PUBLIC on
                  Moxfield and try again.
               </Typography>
            </Alert>
         )}
      </Box>
   );
};

export default MoxfieldDecklistTextField;
