import React, { ReactNode } from "react";
import { Box, SxProps } from "@mui/system";
import { Paper, useTheme } from "@mui/material";
import Footer from "../Footer";

export default function ScrollableBorderedWrapper({ children, sx }: { children: ReactNode; sx?: SxProps }) {
   const theme = useTheme();
   return (
      <Box
         sx={{
            width: "100%",
            height: "100%",
            overflow: "auto",
            boxSizing: "border-box",
         }}
      >
         <Paper
            elevation={2}
            id={"paper_inScroll"}
            sx={{
               width: "90%",
               minHeight: "100%",
               marginLeft: "auto",
               marginRight: "auto",
               ...sx,
            }}
         >
            {children}
         </Paper>
         <Box sx={{ marginTop: theme.spacing(2) }}>
            <Footer />
         </Box>
      </Box>
   );
}
