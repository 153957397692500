import { SxProps } from "@mui/system";
import React, { ReactElement, useEffect, useMemo, useState } from "react";
import { Box, MenuItem, Select, Theme, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useApplicationApi } from "../../rest.client/useApplicationApi";
import { CARD_HEIGHT_WIDTH_RATIO } from "../../UI_CONST";
import TopCard, { SECONDARY_COMMANDER_OFFSET_IN_PERCENTAGE } from "./TopCard";
import ScrollableBorderedWrapper from "../../common/ScrollableBorderedWrapper";
import InsightsSelectorBanner from "../InsightsSelectorBanner";

const top3CardsHeight = 20;
const top3CardsWidth = top3CardsHeight / CARD_HEIGHT_WIDTH_RATIO;
const top3CardSizeUnit = "vh";
const top3CardsTransformYBase = SECONDARY_COMMANDER_OFFSET_IN_PERCENTAGE + 3;
const top3CardsTransformX = 80;

const otherTopCardsHeight = 16;
const otherTopCardsWidth = otherTopCardsHeight / CARD_HEIGHT_WIDTH_RATIO;
const otherTopCardSizeUnit = "vh";

const mobileTop3CardsHeight = 19;
const mobileTop3CardsWidth = mobileTop3CardsHeight / CARD_HEIGHT_WIDTH_RATIO;
const mobileTop3CardSizeUnit = "vh";

const mobile4to6TopCardsHeight = 16;
const mobile4to6TopCardsWidth = mobile4to6TopCardsHeight / CARD_HEIGHT_WIDTH_RATIO;
const mobile4to6TopCardSizeUnit = "vh";

const mobile7to10TopCardsHeight = 13;
const mobile7to10TopCardsWidth = mobile7to10TopCardsHeight / CARD_HEIGHT_WIDTH_RATIO;
const mobile7to10TopCardSizeUnit = "vh";

const homePageTopCardsRootSx: SxProps = {
   left: "-5%",
   width: "90%",
   position: "relative",
   display: "flex",
   flexDirection: "column",
   overflow: "hidden",
   justifyContent: "space-around",
   alignItems: "center",
   flexGrow: 1,
};

const mobileTop10CardsContainerSx: SxProps = {
   width: "100%",
   position: "relative",
   display: "flex",
   flexDirection: "column",
   rowGap: "9vh",
   overflow: "visible",
};
const mobile1to3CardsContainerSx: SxProps = {
   position: "relative",
   display: "flex",
   justifyContent: "space-evenly",
   width: "100%",
   height: `${mobileTop3CardsHeight}${mobileTop3CardSizeUnit}`,
};
const mobile4to6CardsContainerSx: SxProps = {
   position: "relative",
   display: "flex",
   justifyContent: "space-evenly",
   width: "100%",
   height: `${mobile4to6TopCardsHeight}${mobile4to6TopCardSizeUnit}`,
};
const mobile7to10CardsContainerSx: SxProps = {
   position: "relative",
   display: "flex",
   justifyContent: "space-evenly",
   width: "100%",
   height: `${mobile7to10TopCardsHeight}${mobile7to10TopCardSizeUnit}`,
};

const topCardsOptionsWrapperBasicSx: SxProps<Theme> = {
   textShadow: "0 0 1px rgba(0,0,0,0.4), 0 0 5px rgba(0,0,0,0.4)",
};

const topCardsOptionsTextBasicSx: SxProps = {
   margin: "auto 0",
   whiteSpace: "nowrap",
};

const topCardsOptionsSelectSx: SxProps = {
   margin: "auto 0.5vw",
};

const PopularCommandersPage: React.FC = () => {
   const theme = useTheme();
   const isMobileDevice = !useMediaQuery("(min-width:600px)");
   const [numDays, setNumDays] = useState(180);

   const {
      findTop10CardsByPopularityInPastDays: {
         call: findTop10CardsByPopularityInPastDays,
         responseData: top10CardsByPopularityResponse,
      },
   } = useApplicationApi();

   useEffect(() => {
      findTop10CardsByPopularityInPastDays({ pathParams: { numOfDays: numDays } }).then();
   }, [findTop10CardsByPopularityInPastDays, numDays]);

   const topCardsOptionsTextSx: SxProps = useMemo(
      () => ({ ...topCardsOptionsTextBasicSx, fontSize: isMobileDevice ? "12px" : "20px" }),
      [isMobileDevice]
   );

   const top10CardsByPopularity = useMemo(
      () =>
         top10CardsByPopularityResponse
            ? top10CardsByPopularityResponse
            : [
                 [null, null],
                 [null, null],
                 [null, null],
                 [null, null],
                 [null, null],
                 [null, null],
                 [null, null],
                 [null, null],
                 [null, null],
                 [null, null],
              ],
      [top10CardsByPopularityResponse]
   );

   const topCardsOptionsWrapperSx: SxProps<Theme> = useMemo(
      () => ({
         ...topCardsOptionsWrapperBasicSx,
         // position: isMobileDevice ? "relative" : "absolute",
         left: isMobileDevice ? "auto" : "50%",
         top: isMobileDevice ? "auto" : "53%",
         transform: isMobileDevice ? "none" : "translateX(-50%)",
      }),
      [isMobileDevice]
   );

   const cardsFromNum1To10 = useMemo(() => {
      const firstCardTransform = `translateX(-50%) translateY(${top3CardsTransformYBase}%)`;
      const secondCardTransform = `translateX(${top3CardsTransformX}%) translateY(${top3CardsTransformYBase + 30}%)`;
      const thirdCardTransform = `translateX(-1${top3CardsTransformX}%) translateY(${top3CardsTransformYBase + 60}%)`;

      const result = [
         <TopCard
            position={"absolute"}
            key={0}
            width={top3CardsWidth}
            height={top3CardsHeight}
            sizeUnit={top3CardSizeUnit}
            rankString={"1st"}
            rankNumber={1}
            top={"0"}
            left={"50%"}
            transform={firstCardTransform}
            cardsInfo={top10CardsByPopularity[0]}
         />,
         <TopCard
            position={"absolute"}
            key={1}
            width={top3CardsWidth}
            height={top3CardsHeight}
            sizeUnit={top3CardSizeUnit}
            rankString={"2nd"}
            rankNumber={2}
            top={"0"}
            left={"50%"}
            transform={secondCardTransform}
            cardsInfo={top10CardsByPopularity[1]}
         />,
         <TopCard
            position={"absolute"}
            key={2}
            width={top3CardsWidth}
            height={top3CardsHeight}
            sizeUnit={top3CardSizeUnit}
            rankString={"3rd"}
            rankNumber={3}
            top={"0"}
            left={"50%"}
            transform={thirdCardTransform}
            cardsInfo={top10CardsByPopularity[2]}
         />,
      ];

      const interval = 100 / 8;
      for (let i = 1; i < 8; i++) {
         const transform =
            i === 1 || i === 7
               ? `translateX(-50%) translateY(-45%)`
               : i === 2 || i === 6
               ? `translateX(-50%) translateY(-20%)`
               : i === 3 || i === 5
               ? `translateX(-50%) translateY(-10%)`
               : `translateX(-50%)`;
         result.push(
            <TopCard
               position={"absolute"}
               key={i + 2}
               width={otherTopCardsWidth}
               height={otherTopCardsHeight}
               sizeUnit={otherTopCardSizeUnit}
               rankString={`${i + 3}`}
               rankNumber={i + 3}
               bottom={"8vh"}
               left={`${i * interval}%`}
               transform={transform}
               cardsInfo={top10CardsByPopularity[i + 2]}
            />
         );
      }
      return result;
   }, [top10CardsByPopularity]);

   const cardsFromNum1To10ForMobile = useMemo(() => {
      const cards1to3: Array<ReactElement> = [];
      const cards3to6: Array<ReactElement> = [];
      const cards7to10: Array<ReactElement> = [];

      top10CardsByPopularity.forEach((cardsInfo, index) => {
         if (index < 3) {
            cards1to3.push(
               <TopCard
                  position={"relative"}
                  key={index}
                  width={mobileTop3CardsWidth}
                  height={mobileTop3CardsHeight}
                  sizeUnit={mobileTop3CardSizeUnit}
                  rankString={`${index + 1}`}
                  rankNumber={index + 1}
                  cardsInfo={cardsInfo}
               />
            );
         } else if (index < 6) {
            cards3to6.push(
               <TopCard
                  position={"relative"}
                  key={index}
                  width={mobile4to6TopCardsWidth}
                  height={mobile4to6TopCardsHeight}
                  sizeUnit={mobile4to6TopCardSizeUnit}
                  rankString={`${index + 1}`}
                  rankNumber={index + 1}
                  cardsInfo={cardsInfo}
               />
            );
         } else {
            cards7to10.push(
               <TopCard
                  position={"relative"}
                  key={index}
                  width={mobile7to10TopCardsWidth}
                  height={mobile7to10TopCardsHeight}
                  sizeUnit={mobile7to10TopCardSizeUnit}
                  rankString={`${index + 1}`}
                  rankNumber={index + 1}
                  cardsInfo={cardsInfo}
               />
            );
         }
      });

      return (
         <Box id={"mobileTop10CardsContainer"} sx={mobileTop10CardsContainerSx}>
            <Box id={"mobile1to3CardsContainer"} sx={mobile1to3CardsContainerSx}>
               {cards1to3}
            </Box>
            <Box id={"mobile4to6CardsContainer"} sx={mobile4to6CardsContainerSx}>
               {cards3to6}
            </Box>
            <Box id={"mobile7to10CardsContainer"} sx={mobile7to10CardsContainerSx}>
               {cards7to10}
            </Box>
         </Box>
      );
   }, [top10CardsByPopularity]);

   return (
      <ScrollableBorderedWrapper sx={{ height: "100vh" }}>
         <InsightsSelectorBanner />
         <Box
            sx={{
               display: "flex",
               flexWrap: "nowrap",
               justifyContent: "center",
            }}
         >
            <Typography color={theme.palette.text.primary} sx={topCardsOptionsTextSx}>
               Top 10 most popular commanders in past
            </Typography>
            <Select
               sx={topCardsOptionsSelectSx}
               variant={"outlined"}
               size={"small"}
               value={numDays}
               onChange={(event) => setNumDays(Number(event.target.value))}
            >
               <MenuItem value={90}>90</MenuItem>
               <MenuItem value={180}>180</MenuItem>
            </Select>
            <Typography color={theme.palette.text.primary} sx={topCardsOptionsTextSx}>
               days
            </Typography>
         </Box>
         <Box
            sx={{
               position: "absolute",
               width: "100%",
               height: "80%",
               display: "flex",
               flexDirection: "column",
               alignItems: "center",
            }}
         >
            <Box id={"homePageTopCardsRoot"} sx={homePageTopCardsRootSx}>
               {isMobileDevice ? cardsFromNum1To10ForMobile : cardsFromNum1To10}
            </Box>
         </Box>
      </ScrollableBorderedWrapper>
   );
};

export default PopularCommandersPage;
