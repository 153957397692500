import React, { useMemo } from "react";
import { Box, Typography } from "@mui/material";
import { Pie, PieChart, Tooltip } from "recharts";
import { CardUsageData } from "../cardFeatures/CardTypes";
import WinLossPie from "./WinLossPie";

const RADIAN = Math.PI / 180;

export default function PieChartInsightsView({
   winCons,
   highImpact,
   wins,
   losses,
}: {
   winCons: Array<CardUsageData>;
   highImpact: Array<CardUsageData>;
   wins?: number;
   losses?: number;
}) {
   const processedWincons = useMemo(() => {
      return winCons
         .map((e) => {
            return {
               name: e.card.name,
               ...e,
            };
         })
         .slice(0, 5);
   }, [winCons]);

   const processedHighImpact = useMemo(() => {
      return highImpact
         .map((e) => {
            return {
               name: e.card.name,
               ...e,
            };
         })
         .slice(0, 5);
   }, [highImpact]);

   const renderCustomizedWinconsLabel = ({
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      percent,
      index,
   }: {
      cx: number;
      cy: number;
      midAngle: number;
      innerRadius: number;
      outerRadius: number;
      percent: number;
      index: number;
   }) => {
      const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
      const x = cx + radius * Math.cos(-midAngle * RADIAN);
      const y = cy + radius * Math.sin(-midAngle * RADIAN);
      const target = processedWincons[index];

      return <image href={target.card.artCropImageUri} width={40} x={x - 40 / 2} y={y} />;
   };

   const renderCustomizedImpactLabel = ({
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      percent,
      index,
   }: {
      cx: number;
      cy: number;
      midAngle: number;
      innerRadius: number;
      outerRadius: number;
      percent: number;
      index: number;
   }) => {
      const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
      const x = cx + radius * Math.cos(-midAngle * RADIAN);
      const y = cy + radius * Math.sin(-midAngle * RADIAN);
      const target = processedHighImpact[index];

      return <image href={target.card.artCropImageUri} width={40} x={x - 40 / 2} y={y} />;
   };

   return (
      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
         {wins != null && losses != null && (
            <Box>
               <WinLossPie wins={wins} losses={losses} />
            </Box>
         )}
         {processedWincons.length > 0 && (
            <Box>
               <Typography variant={"h6"} textAlign={"center"}>
                  Wincons
               </Typography>
               <PieChart width={250} height={225}>
                  <Pie
                     dataKey="appearances"
                     data={processedWincons}
                     cx="50%"
                     cy="50%"
                     labelLine={false}
                     outerRadius={80}
                     fill={"gray"}
                     label={renderCustomizedWinconsLabel}
                  />
                  <Tooltip />
               </PieChart>
            </Box>
         )}
         {processedHighImpact.length > 0 && (
            <Box>
               <Typography variant={"h6"} textAlign={"center"}>
                  Impactful cards
               </Typography>
               <PieChart width={250} height={225}>
                  <Pie
                     dataKey="appearances"
                     data={processedHighImpact}
                     cx="50%"
                     cy="50%"
                     labelLine={false}
                     outerRadius={80}
                     fill={"gray"}
                     label={renderCustomizedImpactLabel}
                  />
                  <Tooltip />
               </PieChart>
            </Box>
         )}
      </Box>
   );
}
