import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useTheme } from "@mui/material";
import { Procedure } from "../utils";

export default function DrawGameConfirmDialog({
   open,
   onOk,
   onCancel,
}: {
   open: boolean;
   onOk: Procedure;
   onCancel: Procedure;
}) {
   const theme = useTheme();
   return (
      <Dialog
         open={open}
         aria-labelledby="alert-dialog-title"
         aria-describedby="alert-dialog-description"
         onKeyUp={(event) => {
            if (event.key === "Escape") {
               onCancel();
            }
         }}
      >
         <DialogTitle id="alert-dialog-title">{"Mark game as Draw?"}</DialogTitle>
         <DialogContent>
            <DialogContentText id="alert-dialog-description">
               You have not slected a winning player for this game, meaning it will be counted as a draw is this
               correct?
            </DialogContentText>
         </DialogContent>
         <DialogActions>
            <Button onClick={onOk} color={"secondary"}>
               Ok
            </Button>
            <Button onClick={onCancel} autoFocus>
               Cancel
            </Button>
         </DialogActions>
      </Dialog>
   );
}
