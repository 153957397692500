import { useCallback, useMemo } from "react";
import { MatchFullResponse, MatchOverrideSummary, SaveOverrideRequest } from "../../../rest.client/useStagesApi";
import { RemoteStage } from "./useRemoteStage";
import { useMatchesApi } from "../../../rest.client/useMatchesApi";
import { Maybe } from "../../../TYPE";
import { Supplier } from "../../../utils";

export interface RemoteMatch {
   match: Maybe<MatchFullResponse>;
   loadData: Supplier<Promise<MatchFullResponse>>;
   saveOverride: (req: SaveOverrideRequest) => Promise<MatchOverrideSummary>;
   deleteOverride: (regId: string) => Promise<MatchOverrideSummary>;
   loading: boolean;
   anyGamesPending: boolean;
   remoteStage: RemoteStage;
}

export default function useRemoteMatch(remoteStage: RemoteStage, matchId: string): RemoteMatch {
   const {
      loading,
      getMatchFull: { call: getMatchFull, responseData: match },
      deleteOverride: { call: deleteMatchResultOverride },
      saveMatchResultOverride: { call: saveMatchResultOverride },
   } = useMatchesApi();

   const anyGamesPending = Boolean(match && match.games.some((g) => g.status === "PENDING"));

   const loadData = useCallback(async () => {
      return await getMatchFull({ pathParams: { id: matchId } });
   }, [getMatchFull, matchId]);

   const saveOverride = useCallback(
      async (overrideRequest: SaveOverrideRequest) => {
         let res = await saveMatchResultOverride({ pathParams: { id: matchId }, body: overrideRequest });
         await loadData();
         return res;
      },
      [loadData, matchId, saveMatchResultOverride]
   );

   const deleteOverride = useCallback(
      async (regId: string) => {
         let res = await deleteMatchResultOverride({ pathParams: { id: matchId, regId: regId } });
         await loadData();
         return res;
      },
      [deleteMatchResultOverride, loadData, matchId]
   );

   return useMemo(
      () => ({
         match,
         saveOverride,
         remoteStage,
         deleteOverride,
         loadData,
         loading,
         anyGamesPending,
      }),
      [anyGamesPending, deleteOverride, loadData, loading, match, remoteStage, saveOverride]
   );
}
