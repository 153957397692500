import { useApplicationApi } from "../../rest.client/useApplicationApi";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useReload from "../../header/useReload";
import { Badge, Box, Button, ButtonGroup, useMediaQuery, useTheme } from "@mui/material";
import GameViewer from "./GameViewer";
import { empty } from "../../utils";
import useGlobal from "../../global/useGlobal";
import { Maybe, TPoint2 } from "../../TYPE";
import useCurrentUser from "../../auth/useCurrentUser";
import GameCommentsComponent from "../gameComments/GameCommentsComponent";
import GameData from "./GameData";
import InstructionComponent from "../../instruction/InstructionComponent";
import StatusIcon from "../StatusIcon";
import { ANONYMOUS_USER_ID } from "../PlayerPickerComponent";
import MobileGameContentComponent from "../mobileViewGamePage/MobileCreateOrUpdateGameContentComponent";
import MobilePlayersStepComponent from "../mobileViewGamePage/MobilePlayersStepComponent";
import MobileOptionalGameDataStepComponent from "../mobileViewGamePage/MobileOptionalGameDataStepComponent";
import MobileOptionalGameDescriptionStepComponent from "../mobileViewGamePage/MobileOptionalGameDescriptionStepComponent";
import { SxProps } from "@mui/system";
import DeleteGameConfirmDialog from "../DeleteGameConfirmDialog";
import { MY_GAMES_PATH } from "../../index";
import useMutableGame from "../useMutableGame";

const containerSx: SxProps = {
   position: "relative",
   display: "flex",
   flexDirection: "column",
   flexGrow: 1,
   overflow: "visible",
   height: "100%",
   width: "100%",
};

export default function ViewGamePage() {
   const isMobileDevice = !useMediaQuery("(min-width:600px)");
   const [, setTilesPos] = useGlobal<Maybe<TPoint2>>("tilesPoints", null);
   const userData = useCurrentUser();
   const theme = useTheme();
   const {
      approveGame: { call: approveGame },
      disputeGame: { call: disputeGame },
      deleteGame: { call: deleteGameRequest },
   } = useApplicationApi();
   const { id } = useParams();
   const { requestReload, reload } = useReload();
   const nav = useNavigate();
   const [openComment, setOpenComment] = useState(false);
   const [openAdditionalStats, setOpenAdditionalStats] = useState(false);
   const [showInstruction, setShowInstruction] = useState(true);
   const [showDeleteDialog, setShowDeleteDialog] = useState(false);
   const mutableGame = useMutableGame(id);
   const game = mutableGame.existingFullGame;
   const hasGameData =
      game != null &&
      (game.turns != null ||
         game.highImpactCards.length > 0 ||
         game.gameWonBy.length > 0 ||
         game.winType != null ||
         game.description != null);

   const allPlayers = game != null ? [game.player1, game.player2, game.player3] : [];
   game?.player4 && allPlayers.push(game.player4);
   const ownsGame = game?.creator.id === userData?.user?.uid;
   const canEdit =
      (game?.status !== "APPROVED" || allPlayers.every((p) => p.user.id === ANONYMOUS_USER_ID)) &&
      game?.creator.id === userData?.user?.uid;
   const myPlayer = allPlayers.find((p) => p.user.id === userData?.user?.uid);
   game?.player4 && allPlayers.push(game.player4);
   const winningPlayer = game?.player1.winner
      ? "PLAYER_1"
      : game?.player2.winner
      ? "PLAYER_2"
      : game?.player3.winner
      ? "PLAYER_3"
      : game?.player4?.winner
      ? "PLAYER_4"
      : null;
   const needsApprovalFromMe = myPlayer != null && myPlayer.status !== "APPROVED";
   const canDispute = game?.status !== "APPROVED" && myPlayer != null && myPlayer?.status !== "DISPUTED";

   useEffect(() => {
      setTilesPos({ x: 0.25 * window.innerWidth, y: -0.6 * window.innerHeight });
   }, [setTilesPos]);

   useEffect(
      () => {
         mutableGame.loadGame(true);
      },
      //dont add game
      [reload]
   );

   const approveGameCb = useCallback(() => {
      if (game) {
         approveGame({ pathParams: { id: game.id } }).finally(() => {
            requestReload();
         });
      } else {
         throw new Error("No game to approve");
      }
   }, [approveGame, game, requestReload]);

   const disputeGameCb = useCallback(() => {
      if (game) {
         disputeGame({ pathParams: { id: game.id } }).finally(() => {
            requestReload();
         });
      } else {
         throw new Error("No game to approve");
      }
   }, [disputeGame, game, requestReload]);

   const deleteGame = useCallback(() => {
      game != null && deleteGameRequest({ pathParams: { id: game.id } }).then(() => nav(MY_GAMES_PATH));
   }, [deleteGameRequest, game, nav]);

   const contentForNonMobileDevice = useMemo(
      () =>
         game ? (
            <Box sx={containerSx}>
               <GameViewer game={mutableGame} selectedPlayerSummaries={[]} />
               <DeleteGameConfirmDialog
                  open={showDeleteDialog}
                  onOk={deleteGame}
                  onCancel={() => setShowDeleteDialog(false)}
               />
               <Box sx={{ display: "flex", justifyContent: "center", padding: theme.spacing(1) }}>
                  <ButtonGroup sx={{}} color={"secondary"}>
                     {ownsGame && (
                        <Button variant={"contained"} color={"warning"} onClick={() => setShowDeleteDialog(true)}>
                           Delete
                        </Button>
                     )}
                     {canEdit && (
                        <Button variant={"contained"} onClick={() => nav("/editGame/" + game?.id)}>
                           Edit
                        </Button>
                     )}
                     {needsApprovalFromMe && (
                        <Button variant={"contained"} onClick={approveGameCb}>
                           Approve
                        </Button>
                     )}
                     {canDispute && (
                        <Button variant={"contained"} onClick={disputeGameCb}>
                           Dispute
                        </Button>
                     )}
                     <Badge badgeContent={game.commentCount} color={"warning"}>
                        <Button
                           variant={"contained"}
                           onClick={() => {
                              setOpenComment(true);
                           }}
                        >
                           Comments
                        </Button>
                     </Badge>
                     {hasGameData && (
                        <Button
                           variant={"contained"}
                           onClick={() => {
                              setOpenAdditionalStats(true);
                           }}
                        >
                           View game data
                        </Button>
                     )}
                  </ButtonGroup>
               </Box>
               <GameData
                  open={openAdditionalStats}
                  setAdditionalStats={empty}
                  setDescription={empty}
                  description={game.description}
                  additionalStats={game}
                  readonly={true}
                  onClose={() => setOpenAdditionalStats(false)}
               />
               <GameCommentsComponent
                  gameId={game.id}
                  showGameComment={openComment}
                  closeCommentHandler={() => {
                     setOpenComment(false);
                  }}
               />
               {game.status !== "APPROVED" && (
                  <InstructionComponent
                     indexByNum={false}
                     texts={[
                        <>
                           <StatusIcon status={game.status} />{" "}
                           {game.status === "PENDING"
                              ? "Game is now pending approval from players!"
                              : "Game is disputed by players involved, please check comments and update game data."}
                        </>,
                        game.status === "PENDING"
                           ? " Game will auto approve in an estimated " + game.daysUntilAutoApprove + " days."
                           : null,
                     ]}
                     closeHandler={() => setShowInstruction(false)}
                     open={showInstruction}
                  />
               )}
            </Box>
         ) : (
            <></>
         ),
      [
         approveGameCb,
         canDispute,
         canEdit,
         deleteGame,
         disputeGameCb,
         game,
         hasGameData,
         mutableGame,
         nav,
         needsApprovalFromMe,
         openAdditionalStats,
         openComment,
         ownsGame,
         showDeleteDialog,
         showInstruction,
         theme,
      ]
   );

   const contentForMobileDevice = useMemo(
      () =>
         game ? (
            <Box sx={containerSx}>
               <MobileGameContentComponent
                  stepContents={[
                     <MobilePlayersStepComponent game={mutableGame} />,
                     <MobileOptionalGameDataStepComponent existingGame={game} winningPlayer={winningPlayer} />,
                     <MobileOptionalGameDescriptionStepComponent gameDescription={game.description} />,
                  ]}
               />
            </Box>
         ) : (
            <></>
         ),
      [game, mutableGame, winningPlayer]
   );

   return isMobileDevice ? contentForMobileDevice : contentForNonMobileDevice;
}
