import React from "react";
import { Alert, Box, MenuItem, TextField } from "@mui/material";
import { getUserFriendlyNameForTieBreakers, TieBreakerSystem } from "../../../rest.client/useStagesApi";
import { Consumer } from "../../../utils";

export default function TieBreakerSystemDropdown({
   setError,
   onChange,
   tiebreakerSystem,
}: {
   tiebreakerSystem: TieBreakerSystem;
   setError: (key: string, isInError: boolean) => void;
   onChange: Consumer<TieBreakerSystem>;
}) {
   return (
      <Box>
         {tiebreakerSystem === "MTR_3_1" && (
            <Alert severity={"warning"}>
               MTR 3.1 should only be used if you intend to play multiple games per match. If you are running best of 1
               then please use MSTR or MTRA instead.
            </Alert>
         )}
         <TextField
            fullWidth
            required
            select
            sx={{ marginTop: "10px" }}
            variant={"outlined"}
            label={"Tiebreaker system"}
            value={tiebreakerSystem}
            onChange={(e) => {
               const selection = e.target.value as TieBreakerSystem;
               onChange(selection);
            }}
            inputProps={{ maxLength: 100 }}
         >
            <MenuItem key={""} value={"MAMTR_3_1"}>
               {getUserFriendlyNameForTieBreakers("MAMTR_3_1")}
            </MenuItem>
            <MenuItem key={""} value={"MTRA_3_1"}>
               {getUserFriendlyNameForTieBreakers("MTRA_3_1")}
            </MenuItem>
            <MenuItem key={""} value={"BRAINSTORM_WBE"}>
               {getUserFriendlyNameForTieBreakers("BRAINSTORM_WBE")}
            </MenuItem>
            <MenuItem key={""} value={"MTR_3_1"}>
               {getUserFriendlyNameForTieBreakers("MTR_3_1")}
            </MenuItem>
         </TextField>
      </Box>
   );
}
