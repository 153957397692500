import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useApplicationApi } from "../../rest.client/useApplicationApi";
import { Box, CircularProgress, useTheme } from "@mui/material";
import useGlobal from "../../global/useGlobal";
import { Maybe, TPoint2 } from "../../TYPE";
import CardPlaceHolderComponent from "../../gameFeatures/playerAndCommander/CardPlaceHolderComponent";
import { ComponentMode } from "../../UI_CONST";
import InsightsWinsByTurn from "../InsightsWinsByTurn";
import InsightsWinsBySeat from "../InsightsWinsBySeat";
import InsightsVersusView from "../InsightsVersusView";
import PieChartInsightsView from "../PieChartInsightsView";
import { CardExtendedInfo } from "../../cardFeatures/CardTypes";
import { toCommanderInsights } from "../../navPaths";
import { randomPos } from "../../utils";
import ScrollableBorderedWrapper from "../../common/ScrollableBorderedWrapper";
import InsightsSelectorBanner from "../InsightsSelectorBanner";

export default function CommanderInsightsPage() {
   const [, setTilesPos] = useGlobal<Maybe<TPoint2>>("tilesPoints", null);
   let nav = useNavigate();
   let params = useParams<{ id1?: string; id2?: string }>();
   const [commander1Card, setCommander1Card] = useState<Maybe<CardExtendedInfo>>(null);
   const [commander2Card, setCommander2Card] = useState<Maybe<CardExtendedInfo>>(null);
   const showCard2 = commander1Card?.partner || commander1Card?.requiresBackground || commander1Card?.friendsForever;

   const {
      findCommanderPairInsights: { call: findInsights, responseData: insights, loading },
      findCardExtendedInfoById: { call: findExtendedInfo },
   } = useApplicationApi();
   const theme = useTheme();

   useEffect(() => {
      if (params.id1 != null) {
         findExtendedInfo({ pathParams: { id: params.id1 } }).then((res) => setCommander1Card(res as CardExtendedInfo));
         findInsights({ pathParams: { id1: params.id1, id2: params.id2 || "" } });
      }
   }, [findExtendedInfo, findInsights, params.id1, params.id2]);

   useEffect(() => {
      if (params.id2 != null) {
         findExtendedInfo({ pathParams: { id: params.id2 } }).then((res) => setCommander2Card(res as CardExtendedInfo));
      }
   }, [findExtendedInfo, params.id2]);

   useEffect(() => {
      if (params.id2 == null) {
         setCommander2Card(null);
      }
   }, [params.id2]);

   useEffect(() => {
      setTilesPos(randomPos());
   }, [setTilesPos]);

   return (
      <ScrollableBorderedWrapper sx={{}}>
         <InsightsSelectorBanner />
         <Box sx={{ display: "flex", flexWrap: "wrap" }}>
            <Box
               sx={{
                  width: "100%",
                  margin: theme.spacing(2),
                  display: "flex",
                  alignItems: "center",
                  gap: theme.spacing(2),
                  justifyContent: "center",
               }}
            >
               <CardPlaceHolderComponent
                  card={commander1Card}
                  setCard={(card) => nav(toCommanderInsights(card.id))}
                  disallowedCards={[]}
                  width={175}
                  position={"relative"}
                  left={undefined}
                  right={undefined}
                  top={undefined}
                  bottom={undefined}
                  hoverText={"Commander"}
                  filterCommanders={true}
                  filterPartners={false}
                  filterBackgrounds={false}
                  filterFriendsForever={false}
                  mode={ComponentMode.EDIT}
                  key={"commander1"}
                  cardWidthUnit={"px"}
                  sx={{ margin: "none" }}
               />
               {showCard2 && (
                  <CardPlaceHolderComponent
                     card={commander2Card}
                     setCard={(card) => nav(toCommanderInsights(commander1Card?.id, card.id))}
                     disallowedCards={[]}
                     width={175}
                     position={"relative"}
                     left={undefined}
                     right={undefined}
                     top={undefined}
                     bottom={undefined}
                     hoverText={"Commander"}
                     filterCommanders={!commander1Card.requiresBackground}
                     filterPartners={!commander1Card.requiresBackground && !commander1Card?.friendsForever}
                     filterBackgrounds={commander1Card.requiresBackground}
                     filterFriendsForever={commander1Card?.friendsForever}
                     mode={ComponentMode.EDIT}
                     key={"commander1"}
                     cardWidthUnit={"px"}
                     sx={{ margin: "none" }}
                  />
               )}
            </Box>
            {loading && (
               <Box sx={{}}>
                  <CircularProgress />
               </Box>
            )}
            {!loading && insights != null && (
               <PieChartInsightsView
                  winCons={insights.winConsUsed}
                  highImpact={insights.highImpactCardsInWins}
                  wins={insights.winCount}
                  losses={insights.lossCount}
               />
            )}
         </Box>
         {!loading && insights != null && (
            <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "space-evenly" }}>
               <InsightsWinsByTurn data={insights.gamesByTurn} />
               <InsightsWinsBySeat data={insights.gamesBySeat} />
               <InsightsVersusView data={insights.vsOtherCommandersData} />
            </Box>
         )}
      </ScrollableBorderedWrapper>
   );
}
