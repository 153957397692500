import React, { ReactNode } from "react";
import {
   Button,
   Dialog,
   DialogActions,
   DialogContent,
   DialogContentText,
   DialogTitle,
   Typography,
} from "@mui/material";
import { Procedure } from "./utils";

export default function OkCancelDialog({
   open,
   onOk,
   onCancel,
   title,
   message,
}: {
   open: boolean;
   onCancel: Procedure;
   onOk: Procedure;
   title: string;
   message: ReactNode;
}) {
   return (
      <Dialog
         open={open}
         aria-labelledby="alert-dialog-title"
         aria-describedby="alert-dialog-description"
         onKeyUp={(event) => {
            if (event.key === "Escape") {
               onCancel();
            }
         }}
      >
         <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
         <DialogContent>
            <DialogContentText id="alert-dialog-description">
               <Typography>{message}</Typography>
            </DialogContentText>
         </DialogContent>
         <DialogActions>
            <Button onClick={onOk} color={"secondary"}>
               Ok
            </Button>
            <Button onClick={onCancel} autoFocus>
               Cancel
            </Button>
         </DialogActions>
      </Dialog>
   );
}
