import React, { useEffect, useMemo, useState } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import dayjs from "dayjs";
import { RoundFullResponse } from "../../rest.client/useStagesApi";

export default function RoundTimerCountdown({
   round,
   showMinsOnly = false,
   size = 70,
   fontSize = 17.5,
   minsOnlyFontSize = 35,
}: {
   round: RoundFullResponse;
   size?: number | string;
   fontSize?: number | string;
   minsOnlyFontSize?: number | string;
   showMinsOnly?: boolean;
}) {
   const [fullSecondsLeft, setFullSecondsLeft] = useState<number>(0);
   const secsLeft = fullSecondsLeft % 60 > 0 ? fullSecondsLeft % 60 : 0;
   const minsLeft = Math.floor(fullSecondsLeft / 60) > 0 ? Math.floor(fullSecondsLeft / 60) : 0;

   const stringToShow = useMemo(
      () => (showMinsOnly ? `${minsLeft}` : `${minsLeft} : ${secsLeft}`),
      [minsLeft, secsLeft, showMinsOnly]
   );

   useEffect(() => {
      const update = () =>
         setFullSecondsLeft(
            round.roundEndDate != null ? dayjs(new Date(round.roundEndDate)).diff(new Date(), "seconds") : 0
         );
      update();
      const interval = setInterval(() => {
         update();
      }, 1000);

      return () => {
         clearInterval(interval);
      };
   }, [round.roundEndDate]);
   return (
      <Box display="flex" justifyContent="center" alignItems="center">
         <CircularProgress
            color={fullSecondsLeft < 5 * 60 ? "error" : fullSecondsLeft < 15 * 60 ? "warning" : "success"}
            variant="determinate"
            size={size}
            value={secsLeft === 0 ? 100 : (secsLeft / 60) * 100}
         ></CircularProgress>
         <Typography position="absolute" fontWeight={"bold"} fontSize={showMinsOnly ? minsOnlyFontSize : fontSize}>
            {stringToShow}
         </Typography>
      </Box>
   );
}
