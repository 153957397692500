import React, { ReactNode } from "react";
import { Backdrop, Box, useTheme } from "@mui/material";
import Spinner from "./Spinner";

export default function BackDropSpinner({ show, message }: { show: boolean; message?: ReactNode }) {
   const theme = useTheme();
   return (
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={show}>
         <Box sx={{ display: "flex", flexDirection: "column", gap: theme.spacing(1) }}>
            <Spinner show={true} />
            <Box>{message}</Box>
         </Box>
      </Backdrop>
   );
}
