import React, { useEffect } from "react";
import { Checkbox, FormControl, FormControlLabel, FormGroup, InputLabel, MenuItem, Select } from "@mui/material";
import useCurrentUser from "../../auth/useCurrentUser";
import useReload from "../../header/useReload";
import { ThemeSelection, useApplicationApi } from "../../rest.client/useApplicationApi";

export function AdditionalSettings() {
   const user = useCurrentUser();
   const { reload, requestReload } = useReload();
   const {
      findUserProfileById: { call: findUserById, responseData: userProfileData },
      changeSettings: { call: changeSettings },
   } = useApplicationApi();

   useEffect(() => {
      user?.user?.uid && findUserById({ pathParams: { id: user?.user?.uid } });
   }, [findUserById, reload, user?.user?.uid]);

   return (
      <FormGroup sx={{ width: "100%" }}>
         <FormControl fullWidth>
            <InputLabel id="theme-label">Theme</InputLabel>
            {userProfileData && (
               <Select
                  labelId="theme-label"
                  id="demo-simple-select"
                  value={userProfileData?.themeSelection}
                  label="Theme"
                  onChange={(event) =>
                     userProfileData &&
                     changeSettings({
                        body: {
                           sendAutoApproveEmails: userProfileData?.sendAutoApproveEmails,
                           themeSelection: event.target.value as ThemeSelection,
                        },
                     }).then(() => requestReload())
                  }
               >
                  <MenuItem value={"LIGHT"}>Light</MenuItem>
                  <MenuItem value={"DARK"}>Dark</MenuItem>
               </Select>
            )}
         </FormControl>
         <FormControlLabel
            control={
               <Checkbox
                  checked={userProfileData != null && userProfileData.sendAutoApproveEmails}
                  onChange={() =>
                     userProfileData &&
                     changeSettings({
                        body: {
                           sendAutoApproveEmails: !userProfileData?.sendAutoApproveEmails,
                           themeSelection: userProfileData.themeSelection,
                        },
                     }).then(() => requestReload())
                  }
               />
            }
            label="Send auto-approve email notifications"
         />
      </FormGroup>
   );
}
