import React, { CSSProperties, memo, ReactNode, useMemo } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { SxProps } from "@mui/system";
import { Property } from "csstype";
import { GameStatus } from "../../rest.client/useApplicationApi";
import { GiChessQueen } from "react-icons/gi";
import StatusIcon from "../StatusIcon";

const containerBasicSx: SxProps = {
   position: "absolute",
   display: "flex",
   flexDirection: "column",
   width: "100%",
   height: "100%",
   justifyContent: "center",
   // backgroundColor: "blue",
};

const itemSx: SxProps = {
   marginTop: "0.3vmin",
   marginBottom: "0.3vmin",
   // border: "1px solid red",
   display: "flex",
   justifyContent: "center",
};

const winnerIconCss: CSSProperties = {
   borderRadius: "50%",
   padding: "0.1vmin",
   margin: "auto 0.2vmin auto 0.2vmin",
   backgroundColor: "rgba(175,0,165,0.8)",
   boxShadow: "0 0 0.5vmin 0.2vmin rgb(175,0,165)",
};

const statusIconCss: CSSProperties = {
   borderRadius: "50%",
   padding: "0.1vmin",
   margin: "auto 0.2vmin auto 0.2vmin",
   backgroundColor: "black",
   boxShadow: "0 0 0.5vmin 0.2vmin black",
};

const iconTextSx: SxProps = {
   margin: "auto 0.5vmin auto 0.5vmin",
};

interface IPlayerStatusComponentProps {
   show: boolean;
   isWinner: boolean;
   status?: GameStatus;
   left: Property.Left;
   top: Property.Top;
   bottom: Property.Bottom;
   right: Property.Right;
}

const PlayerStatusComponent: React.FC<IPlayerStatusComponentProps> = ({
   show,
   status,
   isWinner,
   left,
   right,
   top,
   bottom,
}) => {
   const theme = useTheme();
   const containerSx: SxProps = useMemo(() => {
      return {
         ...containerBasicSx,
         left: left,
         right: right,
         top: top,
         bottom: bottom,
         alignItems: left !== "auto" ? "end" : "start",
      };
   }, [bottom, left, right, top]);

   const winnerIconText: ReactNode = useMemo(() => {
      if (isWinner) {
         const pair = [
            <GiChessQueen style={winnerIconCss} color={"rgb(255,166,0)"} />,
            <Typography color={theme.palette.secondary.main} sx={iconTextSx}>
               {"Winner"}
            </Typography>,
         ];
         left !== "auto" && pair.reverse();
         return <Box sx={itemSx}>{pair}</Box>;
      }
      return <></>;
   }, [isWinner, left, theme.palette.secondary.main]);

   const statusIconText: ReactNode = useMemo(() => {
      if (status) {
         const pair = [
            <StatusIcon status={status} iconStyle={statusIconCss} />,
            <Typography color={theme.palette.secondary.main} sx={iconTextSx}>
               {status === "PENDING"
                  ? "Approval pending"
                  : status === "APPROVED"
                  ? "Approved"
                  : status === "DISPUTED"
                  ? "Dispute"
                  : ""}
            </Typography>,
         ];
         left !== "auto" && pair.reverse();
         return <Box sx={itemSx}>{pair}</Box>;
      }
      return <></>;
   }, [left, status, theme.palette.secondary.main]);

   return show ? (
      <Box sx={containerSx}>
         {winnerIconText}
         {statusIconText}
      </Box>
   ) : (
      <></>
   );
};

export default memo(PlayerStatusComponent);
