import React, { useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Typography, useMediaQuery } from "@mui/material";
import StandingsTopPlayers from "../standings/StandingsTopPlayers";
import { StageFullResponse } from "../../../../rest.client/useStagesApi";
import LeagueStandingsTable from "../standings/LeagueStandingsTable";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MatchesTable from "../../matches/MatchesTable";
import { RemoteStage } from "../useRemoteStage";
import { useMatchesApi } from "../../../../rest.client/useMatchesApi";
import useReload from "../../../../header/useReload";
import { Maybe } from "../../../../TYPE";
import TournamentGameEditDialog from "../../matches/editDialog/TournamentGameEditDialog";
import { toStageViewPage, toStageViewPageMatchOpen } from "../../../../navPaths";
import { useNavigate, useParams } from "react-router-dom";
import MatchDialog from "../../matches/MatchDialog";

export default function UnstructuredLeagueView({
   stageFull,
   remoteStage,
}: {
   stageFull: StageFullResponse;
   remoteStage: RemoteStage;
}) {
   const { tournamentId, stageId, matchId } = useParams();
   const nav = useNavigate();
   const isMobile = !useMediaQuery("(min-width:600px)");
   const { requestReload } = useReload();
   const {
      createMatch: { call: createMatchCall },
   } = useMatchesApi();
   const [selectedMatchId, setSelectedMatchId] = useState<Maybe<string>>(null);

   const [expandedPanel, setExpandedPanel] = useState<"standings" | "matches" | "none">("none");
   const onlyRound = stageFull.rounds[0];

   return (
      <>
         <Accordion
            expanded={expandedPanel === "standings"}
            onChange={() => (expandedPanel === "standings" ? setExpandedPanel("none") : setExpandedPanel("standings"))}
         >
            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ExpandMoreIcon />}>
               <Typography>Standings</Typography>
            </AccordionSummary>
            <AccordionDetails>
               {!isMobile && stageFull && <StandingsTopPlayers numOfTopPlayers={4} stage={stageFull} />}
               <Box id={"leagueViewRoot"}>
                  <LeagueStandingsTable
                     stage={stageFull}
                     standings={stageFull.standings}
                     remoteTournament={remoteStage.remoteTournament}
                  />
               </Box>
            </AccordionDetails>
         </Accordion>
         <Accordion
            expanded={expandedPanel === "matches"}
            onChange={() => (expandedPanel === "matches" ? setExpandedPanel("none") : setExpandedPanel("matches"))}
         >
            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ExpandMoreIcon />}>
               <Typography>Matches(pods)</Typography>
            </AccordionSummary>
            <AccordionDetails>
               <Box id={"leagueViewRoot"}>
                  {remoteStage.canSubmitGame && (
                     <Button
                        onClick={async () => {
                           const match = await createMatchCall({ body: { roundId: onlyRound.id } });
                           requestReload();
                           setSelectedMatchId(match.id);
                        }}
                        variant={"contained"}
                     >
                        Submit new match
                     </Button>
                  )}
                  <MatchesTable
                     matches={onlyRound.matches}
                     remoteStage={remoteStage}
                     onClickMatch={(m) => {
                        tournamentId && stageId && nav(toStageViewPageMatchOpen(tournamentId, stageId, m.id));
                     }}
                     onClose={() => tournamentId && stageId && nav(toStageViewPage(tournamentId, stageId))}
                  />
               </Box>
            </AccordionDetails>
         </Accordion>
         {selectedMatchId != null && (
            <TournamentGameEditDialog
               open={true}
               onCancel={() => setSelectedMatchId(null)}
               onOk={() => setSelectedMatchId(null)}
               matchId={selectedMatchId}
               remoteStage={remoteStage}
            />
         )}
         {tournamentId && stageId && matchId && (
            <MatchDialog
               open={true}
               onClose={() => nav(toStageViewPage(tournamentId, stageId))}
               matchId={matchId}
               remoteStage={remoteStage}
            />
         )}
      </>
   );
}
