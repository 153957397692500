import React, { useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Alert, Typography, useTheme } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Consumer } from "../../../utils";
import DynamicNumberField from "./DynamicNumberField";

const textFieldStyle = { marginTop: "10px" };

export interface AdditionalPoints {
   secondPlacePoints: number;
   thirdPlacePoints: number;
   fourthPlacePoints: number;
   matchWinPercentageFloor: number;
}

export default function AdditionalPointsOptions({
   additionalPoints,
   onChangePoints,
   setError,
}: {
   additionalPoints: AdditionalPoints;
   onChangePoints: Consumer<AdditionalPoints>;
   setError: (key: string, isInError: boolean) => void;
}) {
   const [expanded, setExpanded] = useState(false);
   const theme = useTheme();
   return (
      <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
         <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ExpandMoreIcon />}>
            <Typography>Additional points settings</Typography>
         </AccordionSummary>
         <AccordionDetails>
            <DynamicNumberField
               label={"Points for second"}
               setError={setError}
               errorMapKey={"secondPoints"}
               onChange={(points) => {
                  onChangePoints({
                     ...additionalPoints,
                     secondPlacePoints: points,
                  });
               }}
               isError={(num) => false}
               value={additionalPoints.secondPlacePoints}
            />
            <DynamicNumberField
               label={"Points for third"}
               setError={setError}
               errorMapKey={"thirdPoints"}
               onChange={(points) => {
                  onChangePoints({
                     ...additionalPoints,
                     thirdPlacePoints: points,
                  });
               }}
               isError={(num) => false}
               value={additionalPoints.thirdPlacePoints}
            />
            <DynamicNumberField
               label={"Points for fourth"}
               setError={setError}
               errorMapKey={"fourthPoints"}
               onChange={(points) => {
                  onChangePoints({
                     ...additionalPoints,
                     fourthPlacePoints: Number(points),
                  });
               }}
               isError={(num) => false}
               value={additionalPoints.fourthPlacePoints}
            />
            {additionalPoints.matchWinPercentageFloor > 0.2 && (
               <Alert severity={"warning"} sx={{ marginTop: theme.spacing(1) }}>
                  Increased ties can occur when win percent floor is increased, it is recommended to keep this value at
                  a maximum of 1 / MatchPoints.
               </Alert>
            )}
            <DynamicNumberField
               label={"Win percent floor"}
               errorMapKey={"winPercentFloor"}
               setError={setError}
               isError={(num) => num < 0 || num > 100}
               onChange={(winPercentFloor) =>
                  onChangePoints({
                     ...additionalPoints,
                     matchWinPercentageFloor: winPercentFloor / 100,
                  })
               }
               value={additionalPoints.matchWinPercentageFloor * 100}
            />
         </AccordionDetails>
      </Accordion>
   );
}
