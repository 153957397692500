import React, { useMemo } from "react";
import { Box, Grow, Theme, Typography } from "@mui/material";
import { SxProps } from "@mui/system";
import { Property } from "csstype";
import { CardNameAndImage } from "../../cardFeatures/CardTypes";
import { Maybe } from "../../TYPE";
import { useNavigate } from "react-router-dom";
import { toCommanderInsights } from "../../navPaths";

export const SECONDARY_COMMANDER_OFFSET_IN_PERCENTAGE = 15;

const cardBasicSx: SxProps = {
   overflow: "visible",
};

const cardTransitionWrapperSx: SxProps = {
   position: "absolute",
   overflow: "visible",
   width: "100%",
   height: "100%",
   left: 0,
   top: 0,
};

const rankNumTextBasicSx: SxProps<Theme> = {
   position: "absolute",
   top: "100%",
   left: 0,
   width: "100%",
   textAlign: "center",
   color: (theme) => theme.palette.text.secondary,
   fontSize: "5vh",
   textShadow: "0 0 1vmin black",
};

const card1ImgSx: SxProps = {
   width: "100%",
   height: "100%",
   borderRadius: "4%",
   position: "absolute",
   left: 0,
   top: 0,
   pointerEvents: "auto",
   boxShadow: `0 0 5px 1px rgba(0,0,0,1)`,
};

const card2ImgSx: SxProps = {
   ...card1ImgSx,
   left: `${SECONDARY_COMMANDER_OFFSET_IN_PERCENTAGE}%`,
   top: `-${SECONDARY_COMMANDER_OFFSET_IN_PERCENTAGE}%`,
};

interface IHomePageTopCard {
   position: Property.Position;
   rankString: string;
   rankNumber: number;
   width: number;
   height: number;
   sizeUnit: string;
   transform?: Property.Transform;
   left?: Property.Left;
   top?: Property.Top;
   bottom?: Property.Bottom;
   right?: Property.Right;
   cardsInfo: Maybe<Array<Maybe<CardNameAndImage>>>;
}

const TopCard: React.FC<IHomePageTopCard> = ({
   position,
   rankString,
   rankNumber,
   left,
   right,
   top,
   bottom,
   transform,
   width,
   height,
   sizeUnit,
   cardsInfo,
}) => {
   const nav = useNavigate();
   const cardSx: SxProps = useMemo(
      () => ({
         ...cardBasicSx,
         position: position,
         width: `${width}${sizeUnit}`,
         height: `${height}${sizeUnit}`,
         left: left ? left : "auto",
         right: right ? right : "auto",
         top: top ? top : "auto",
         bottom: bottom ? bottom : "auto",
         transform: transform ? transform : "none",
         cursor: "pointer",
      }),
      [bottom, height, left, position, right, sizeUnit, top, transform, width]
   );

   const rankNumTextSx: SxProps<Theme> = useMemo(() => {
      return {
         ...rankNumTextBasicSx,
         color:
            rankNumber === 1
               ? "rgb(255,183,0)"
               : rankNumber === 2
               ? "white"
               : rankNumber === 3
               ? "rgb(185,75,0)"
               : "rgba(185,185,185,0.6)",
      };
   }, [rankNumber]);

   const cardImages = useMemo(() => {
      if (cardsInfo == null) {
         return null;
      } else {
         const card0 = cardsInfo[0];
         const card1 = cardsInfo[1];
         return (
            <div onClick={() => nav(toCommanderInsights(card0?.id, card1?.id))}>
               {card1 ? <Box component="img" sx={card2ImgSx} src={card1.largeImageUri} /> : <></>}
               {card0 ? <Box component="img" sx={card1ImgSx} src={card0.largeImageUri} /> : <></>}
            </div>
         );
      }
   }, [cardsInfo, nav]);

   return (
      <Box id={`homePageTopCard_${rankNumber}`} sx={cardSx}>
         <Grow timeout={{ enter: (10 - rankNumber) * 200, exit: 100 }} in={cardsInfo != null && cardsInfo[0] !== null}>
            <Box sx={cardTransitionWrapperSx}>
               {cardImages}
               <Typography sx={rankNumTextSx}>{rankString}</Typography>
            </Box>
         </Grow>
      </Box>
   );
};

export default TopCard;
