import React, { useMemo } from "react";
import { Box } from "@mui/material";
import { SxProps } from "@mui/system";
import { XLARGE_PADDING_IN_PX } from "../../../tournaments2dComponents/TOURNAMENTS_2D_CONST";
import { StageFullResponse, StandingsRegResponse } from "../../../../rest.client/useStagesApi";
import TopPlayerCard from "./TopPlayerCard";

const titleSectionHeightInPx = 120;
const topHorizontalClipValue = 40;
const bottomHorizontalClipValue = 100 - topHorizontalClipValue;
const playerBoxClipPath = `polygon(${topHorizontalClipValue}% 0%, 100% 0%, ${bottomHorizontalClipValue}% 100%, 0% 100%)`;

const leagueViewTopPlayersRootSx: SxProps = {
   position: "relative",
   boxSizing: "border-box",
   width: "100%",
   height: "300px",
   marginBottom: "20px",
   "@media (max-width:600px)": {
      height: `${titleSectionHeightInPx + XLARGE_PADDING_IN_PX}px`,
   },
};

const playerBoxesContainerSx: SxProps = {
   position: "absolute",
   boxSizing: "border-box",
   justifyContent: "center",
   display: "flex",
   width: "100%",
   gap: "10px",
   height: "300px",
};

const playerBoxBasicSx: SxProps = {
   display: "flex",
   boxSizing: "border-box",
   position: "relative",
   height: "300px",
   width: "25%",
   maxWidth: "450px",
   minWidth: "280px",
   backgroundColor: "rgba(255, 255, 255, 0.5)",
   clipPath: playerBoxClipPath,
};

interface ILeagueViewTopPlayers {
   numOfTopPlayers: number;
   stage: StageFullResponse;
}

const StandingsTopPlayers: React.FC<ILeagueViewTopPlayers> = ({ stage }) => {
   const standings = stage.standings;
   const numOfTopPlayers = 3;
   const playerBoxes = useMemo(() => {
      return Array.from({ length: numOfTopPlayers }, (_, i) => {
         const standing: StandingsRegResponse | undefined = standings[i];

         if (!standing) {
            return null;
         }

         return (
            <TopPlayerCard index={i} key={standing.registration.id} standing={standing} rootSx={playerBoxBasicSx} />
         );
      });
   }, [standings]);

   return (
      <Box id={"leagueViewTopPlayersRoot"} sx={leagueViewTopPlayersRootSx}>
         <Box sx={playerBoxesContainerSx}>{playerBoxes}</Box>
      </Box>
   );
};

export default StandingsTopPlayers;
