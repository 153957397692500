import React from "react";
import { Chip, useTheme } from "@mui/material";
import { TagSummary } from "../../../rest.client/useTagsApi";
import { Consumer } from "../../../utils";
import { ChipProps } from "@mui/material/Chip/Chip";

export default function TagChip({
   tag,
   onClick,
   onDelete,
   size,
   chipProps,
}: {
   tag: TagSummary;
   onClick?: Consumer<TagSummary>;
   onDelete?: Consumer<TagSummary>;
   size?: "small" | "medium";
   chipProps?: ChipProps;
}) {
   const theme = useTheme();
   return (
      <Chip
         sx={{ backgroundColor: tag.color }}
         size={size || "small"}
         label={tag.name}
         onClick={() => onClick && onClick(tag)}
         onDelete={onDelete ? () => onDelete(tag) : undefined}
         {...chipProps}
      />
   );
}
