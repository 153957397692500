import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useApplicationApi, UserStatsExtended } from "../../rest.client/useApplicationApi";
import { Avatar, Box, CircularProgress, List, ListItem, ListItemText, Typography, useTheme } from "@mui/material";
import useReload from "../../header/useReload";
import RankRender from "../../gameFeatures/RankRender";
import { numberFormat } from "../../gameFeatures/viewGamePage/PlayerCard";

export default function PlayerDetailsRow({ id }: { id: string }) {
   const {
      findUserById: { call: findUser, responseData, loading },
   } = useApplicationApi();
   const userData = responseData as UserStatsExtended | null;
   const theme = useTheme();
   const { reload } = useReload();
   const nav = useNavigate();

   useEffect(() => {
      id && findUser({ pathParams: { id: id }, queryParams: { projection: "extendedStats" } });
   }, [findUser, id, reload]);

   return (
      <>
         <Box
            style={{
               display: "flex",
               flexDirection: "row",
               justifyContent: "space-between",
               padding: theme.spacing(1),
            }}
         >
            {loading && (
               <Box sx={{}}>
                  <CircularProgress />
               </Box>
            )}
            {userData && (
               <div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                     <Avatar src={userData.photoUrl} />
                     <Typography>
                        <span style={{ color: theme.palette.text.primary, fontWeight: "bold" }}>
                           {userData?.name}
                           <span
                              style={{
                                 color: "gray",
                                 fontStyle: "italic",
                                 marginRight: theme.spacing(1),
                              }}
                           >
                              /{userData?.shortTag}
                           </span>
                        </span>
                     </Typography>
                  </div>
                  <List dense={true}>
                     <ListItem>
                        <ListItemText primary={<RankRender rank={userData.rank} />} secondary={"Rank"} />
                     </ListItem>
                     <ListItem>
                        <ListItemText primary={numberFormat.format(userData.elo)} secondary={"elo"} />
                     </ListItem>
                     <ListItem>
                        <ListItemText
                           primary={numberFormat.format(userData?.winPercent || 0) + "%"}
                           secondary={"W/R%"}
                        />
                     </ListItem>
                  </List>
               </div>
            )}
            {userData && (
               <div>
                  <Typography variant={"h5"}>Most played commander</Typography>
                  <div style={{ margin: theme.spacing(1), width: "30%", display: "flex" }}>
                     {userData.commandersPlayed.slice(0, 1).map((cP) => {
                        return (
                           <div
                              key={"" + cP.commander1.id + cP.commander2?.id}
                              style={{
                                 display: "flex",
                                 flexDirection: "column",
                                 marginRight: theme.spacing(1),
                              }}
                           >
                              <div style={{ display: "flex" }}>
                                 <img src={cP.commander1.smallImageUri} width={150} alt={cP.commander1.name} />
                                 {cP.commander2 && (
                                    <img src={cP.commander2.smallImageUri} width={150} alt={cP.commander2.name} />
                                 )}
                              </div>
                              <ListItemText
                                 primary={
                                    cP.commander1.name + (cP.commander2 != null ? " / " + cP.commander2?.name : "")
                                 }
                                 secondary={"Played " + cP.playedCount + " games"}
                              />
                           </div>
                        );
                     })}
                  </div>
               </div>
            )}
         </Box>
      </>
   );
}
