import React from "react";
import {
   Alert,
   Paper,
   Table,
   TableBody,
   TableCell,
   TableContainer,
   TableHead,
   TableRow,
   Typography,
} from "@mui/material";
import { StageFullResponse, StandingsRegResponse } from "../../../../rest.client/useStagesApi";
import RegistrationChip from "../../../registration/RegistrationChip";
import { RemoteTournament } from "../../../useRemoteTournament";
import StandingStatusAlert from "./StandingStatusAlert";
import BrainstormWbeHeaders from "./BrainstormWbeHeaders";
import BrainstormWbeCells from "./BrainstormWbeCells";
import Mtra31Headers from "./Mtra31Headers";
import Mtra31Cells from "./Mtra31Cells";
import MstrCells from "./MstrCells";
import MstrHeaders from "./MstrHeaders";
import MtrCells from "./MtrCells";
import Mtr31Headers from "./Mtr31Headers";

export default function LeagueStandingsTable({
   standings,
   stage,
   remoteTournament,
   title,
   showPos = true,
}: {
   stage: StageFullResponse;
   standings: StandingsRegResponse[];
   remoteTournament?: RemoteTournament;
   title?: string;
   showPos?: boolean;
}) {
   const tieBreaker =
      stage.unstructuredLeagueStage?.tiebreakerSystem || stage.swissLeagueStage?.tiebreakerSystem || "MSTR_3_1";
   return (
      <Paper sx={{ width: "100%", position: "relative" }}>
         <Typography variant={"h5"}>{title || "All standings:"}</Typography>
         <TableContainer component={Paper}>
            <Table sx={{}} aria-label="simple table">
               <TableHead>
                  <TableRow>
                     {showPos && <TableCell>Pos</TableCell>}
                     <TableCell>Name</TableCell>
                     <TableCell>Points</TableCell>
                     {tieBreaker === "BRAINSTORM_WBE" && <BrainstormWbeHeaders />}
                     {tieBreaker === "MTRA_3_1" && <Mtra31Headers />}
                     {tieBreaker === "MAMTR_3_1" && <MstrHeaders />}
                     {tieBreaker === "MTR_3_1" && <Mtr31Headers />}
                     {tieBreaker !== "BRAINSTORM_WBE" && <TableCell>Wins</TableCell>}
                     <TableCell>Losses</TableCell>
                     <TableCell>Draws</TableCell>
                     <TableCell>Byes</TableCell>
                     <TableCell>Status</TableCell>
                  </TableRow>
               </TableHead>
               <TableBody>
                  {standings.map((standing, i) => {
                     return (
                        <TableRow key={standing.registration.id} sx={{ cursor: "pointer" }}>
                           {showPos && <TableCell>{i + 1}</TableCell>}
                           <TableCell>
                              <RegistrationChip
                                 registration={standing.registration}
                                 onClickOpenRegistrationCard={remoteTournament != null}
                                 remoteTournament={remoteTournament}
                              />
                           </TableCell>
                           <TableCell>{standing.standing.pointsTotal}</TableCell>
                           {tieBreaker === "BRAINSTORM_WBE" && <BrainstormWbeCells standing={standing} />}
                           {tieBreaker === "MTRA_3_1" && <Mtra31Cells standing={standing} />}
                           {tieBreaker === "MAMTR_3_1" && <MstrCells standing={standing} />}
                           {tieBreaker === "MTR_3_1" && <MtrCells standing={standing} />}
                           {tieBreaker !== "BRAINSTORM_WBE" && <TableCell>{standing.standing.wins}</TableCell>}
                           <TableCell>{standing.standing.losses}</TableCell>
                           <TableCell>{standing.standing.draws}</TableCell>
                           <TableCell>{standing.standing.byes}</TableCell>
                           <TableCell>
                              <StandingStatusAlert standing={standing} />
                           </TableCell>
                        </TableRow>
                     );
                  })}
               </TableBody>
            </Table>
            {standings.length === 0 && <Alert severity={"info"}>No standings data found.</Alert>}
         </TableContainer>
      </Paper>
   );
}
