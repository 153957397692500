import React from "react";
import { TableCell } from "@mui/material";
import { StandingsRegResponse } from "../../../../rest.client/useStagesApi";

export default function MstrCells({ standing }: { standing: StandingsRegResponse }) {
   return (
      <>
         <TableCell>{(standing.standing.playerMatchWinPercentage * 100).toFixed(4)}</TableCell>
         <TableCell>{standing.standing.opponentsAverageMatchPoints.toFixed(4)}</TableCell>
         <TableCell>{(standing.standing.opponentMatchWinPercentage * 100).toFixed(4)}</TableCell>
      </>
   );
}
