import { Alert, Box, Button, useTheme } from "@mui/material";
import React, { useState } from "react";
import { GameSummary, useApplicationApi } from "../../../rest.client/useApplicationApi";
import useRemoteGame from "../../../gameFeatures/useRemoteGame";
import { RemoteMatch } from "../stageView/useRemoteMatch";
import useReload from "../../../header/useReload";
import DeleteGameConfirmDialog from "../../../gameFeatures/DeleteGameConfirmDialog";
import ResultSubmitDialog from "./ResultSubmitDialog";
import OkCancelDialog from "../../../OkCancelDialog";
import { Consumer } from "../../../utils";

export default function GameControlsRow({
   game,
   remoteMatch,
   requestEditGame,
}: React.PropsWithChildren<{
   game: GameSummary;
   remoteMatch: RemoteMatch;
   requestEditGame?: Consumer<GameSummary>;
}>) {
   const {
      forceApproveGame: { call: forceApprove },
   } = useApplicationApi();
   const { requestReload } = useReload();
   const theme = useTheme();
   const [openDelete, setOpenDelete] = useState(false);
   const [openSubmit, setOpenSubmit] = useState(false);
   const [showToApproveWarning, setShowToApproveWarning] = useState(false);
   const remoteSummary = useRemoteGame(game);
   const myPlayer = remoteSummary.myPlayer;
   const gameApproved = game.status === "APPROVED";
   const canEditGame = !gameApproved && remoteMatch.remoteStage.canEdit && !remoteMatch.remoteStage.isClosed;
   const canDelete = remoteMatch.remoteStage.canEdit && !remoteMatch.remoteStage.isClosed;

   return (
      <Box sx={{ marginBottom: theme.spacing(2), display: "flex", flexWrap: "wrap", gap: theme.spacing(1) }}>
         {(canEditGame || remoteSummary.canSubmitResult) && (
            <Button variant={remoteSummary.winner == null ? "contained" : "text"} onClick={() => setOpenSubmit(true)}>
               Submit result
            </Button>
         )}
         {myPlayer && myPlayer?.status !== "APPROVED" && !gameApproved && (
            <Button
               color={"success"}
               onClick={async (e) => {
                  await remoteSummary.approveGame();
                  requestReload();
               }}
            >
               Approve result
            </Button>
         )}
         {myPlayer && myPlayer?.status !== "DISPUTED" && !gameApproved && (
            <Button
               color={"error"}
               onClick={async (e) => {
                  await remoteSummary.disputeGame();
                  requestReload();
               }}
            >
               Dispute result
            </Button>
         )}
         {canEditGame && !gameApproved && (
            <Button
               color={"success"}
               onClick={async (e) => {
                  if (remoteSummary.winner == null) {
                     setShowToApproveWarning(true);
                  } else {
                     await forceApprove({ pathParams: { id: game.id } });
                     requestReload();
                  }
               }}
            >
               Organiser approve
            </Button>
         )}
         {canEditGame && <Button onClick={() => requestEditGame && requestEditGame(game)}>Edit</Button>}
         {canDelete && (
            <Button color={"error"} onClick={() => setOpenDelete(true)}>
               Delete
            </Button>
         )}
         <DeleteGameConfirmDialog
            open={openDelete}
            onOk={async () => {
               await remoteSummary.deleteGame();
               requestReload();
            }}
            onCancel={() => setOpenDelete(false)}
         />
         <ResultSubmitDialog open={openSubmit} onClose={() => setOpenSubmit(false)} remoteGame={remoteSummary} />
         <OkCancelDialog
            open={showToApproveWarning}
            onCancel={() => setShowToApproveWarning(false)}
            onOk={async () => {
               await forceApprove({ pathParams: { id: game.id } });
               requestReload();
               setShowToApproveWarning(false);
            }}
            title={"Approve draw?"}
            message={
               <Alert severity={"warning"}>This game is marked as a draw are you sure you want to approve?</Alert>
            }
         />
      </Box>
   );
}
