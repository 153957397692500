import React, { useCallback, useEffect, useState } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery, useTheme } from "@mui/material";
import { Procedure } from "../../../utils";
import { RemoteStage } from "../stageView/useRemoteStage";
import useRemoteMatch from "../stageView/useRemoteMatch";
import useReload from "../../../header/useReload";
import MatchResultsAccordian from "./MatchResultsAccordian";
import GamesListAccordion from "./GamesListAccordion";
import TournamentGameEditDialog from "./editDialog/TournamentGameEditDialog";
import Spinner from "../../../common/Spinner";

export default function MatchDialog({
   open,
   matchId,
   onClose,
   remoteStage,
}: {
   matchId: string;
   open: boolean;
   onClose: Procedure;
   remoteStage: RemoteStage;
}) {
   const theme = useTheme();
   const { reload, requestReload } = useReload();
   const [openAddDialog, setOpenAddDialog] = useState(false);
   const remoteMatch = useRemoteMatch(remoteStage, matchId);
   const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));
   const [gameToEditId, setGameToEditId] = useState<string | undefined>(undefined);
   const match = remoteMatch.match;

   const closeDialog = useCallback(() => {
      setOpenAddDialog(false);
      setGameToEditId(undefined);
   }, []);

   useEffect(() => {
      if (open && (match == null || matchId !== match.id || reload)) {
         remoteMatch.loadData();
      }
   }, [match, matchId, open, reload, remoteMatch]);

   return (
      <Dialog
         open={open}
         aria-labelledby="alert-dialog-title"
         aria-describedby="alert-dialog-description"
         maxWidth={"lg"}
         fullWidth={true}
         fullScreen={smallScreen}
         sx={{ minWidth: "200px" }}
         onKeyUp={(event) => {
            if (event.key === "Escape") {
               onClose();
            }
         }}
      >
         <DialogTitle id="alert-dialog-title">Match {match?.matchNumber || " - "}</DialogTitle>
         {remoteStage.remoteTournament.tournament != null && match && (
            <DialogContent>
               <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                  <Box>
                     <Button
                        disabled={!remoteStage.canSubmitGame}
                        sx={{ marginBottom: theme.spacing(1) }}
                        variant={"contained"}
                        onClick={() => setOpenAddDialog(true)}
                     >
                        Add game
                     </Button>
                  </Box>
                  <Box>
                     <MatchResultsAccordian remoteMatch={remoteMatch} remoteStage={remoteStage} />
                  </Box>
                  <Box>
                     <GamesListAccordion remoteMatch={remoteMatch} remoteStage={remoteStage} startExpanded={true} />
                  </Box>
               </Box>
               {(openAddDialog || gameToEditId != null) && (
                  <TournamentGameEditDialog
                     open={openAddDialog || gameToEditId != null}
                     matchId={match.id}
                     gameId={gameToEditId}
                     onCancel={closeDialog}
                     onOk={closeDialog}
                     remoteStage={remoteStage}
                  />
               )}
            </DialogContent>
         )}
         <Spinner show={remoteStage.remoteTournament.tournament == null || match == null} />
         <DialogActions>
            <Button
               onClick={() => {
                  onClose();
               }}
               autoFocus
            >
               Close
            </Button>
         </DialogActions>
      </Dialog>
   );
}
