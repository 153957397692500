import React, { CSSProperties, useEffect } from "react";
import {
   Avatar,
   Box,
   Card,
   CardContent,
   CardHeader,
   CircularProgress,
   IconButton,
   List,
   ListItem,
   ListItemText,
   Typography,
   useTheme,
} from "@mui/material";
import CardPlaceHolderComponent from "../../gameFeatures/playerAndCommander/CardPlaceHolderComponent";
import { empty } from "../../utils";
import { ComponentMode } from "../../UI_CONST";
import { RemoteTournament } from "../useRemoteTournament";
import { RegistrationSummary } from "../../rest.client/useTournamentApi";
import { SxProps } from "@mui/system";
import { useApplicationApi, UserStatsExtended } from "../../rest.client/useApplicationApi";
import useReload from "../../header/useReload";
import { numberFormat } from "../../gameFeatures/viewGamePage/PlayerCard";
import { StageFullResponse } from "../../rest.client/useStagesApi";
import DecklistLock from "./DecklistLock";

const cardPlaceholderHorizontalWrapperCss: CSSProperties = {
   display: "flex",
   gap: 20,
   justifyContent: "center",
};

const placeholderAndTextContainerSx: SxProps = {
   display: "flex",
   flexDirection: "column",
   alignItems: "center",
};
export default function PlayerTournamentDetailsCard({
   remoteTournament,
   registration,
   allStages,
}: {
   remoteTournament: RemoteTournament;
   registration: RegistrationSummary;
   allStages: StageFullResponse[];
}) {
   const uid = registration.user.id;
   const {
      findUserById: { call: findUser, responseData, loading },
   } = useApplicationApi();
   const userData = responseData as UserStatsExtended | null;
   const theme = useTheme();
   const { reload } = useReload();
   const allMatches = allStages.flatMap((s) => s.rounds.flatMap((r) => r.matches));
   const matchesForUser = allMatches.filter((m) => m.matchResults.some((mR) => mR.registration.id === registration.id));
   const matchesWon = matchesForUser.filter((m) => m.winningPlayer?.id === uid);
   const matchesLost = matchesForUser.filter((m) => m.winningPlayer != null && m.winningPlayer?.id !== uid);
   const matchesDrawn = matchesForUser.filter((m) => m.winningPlayer == null);
   const winPc = matchesForUser.length > 0 ? (matchesWon.length / matchesForUser.length) * 100 : 0;

   useEffect(() => {
      registration.user.id &&
         findUser({
            pathParams: { id: registration.user.id },
            queryParams: { projection: "extendedStats" },
         });
   }, [findUser, registration.user.id, reload]);

   return (
      <>
         {userData && (
            <Card>
               <CardHeader
                  avatar={<Avatar src={userData.photoUrl}></Avatar>}
                  action={<IconButton aria-label="settings">{/*<MoreVertIcon />*/}</IconButton>}
                  title={userData?.name}
                  subheader={"/" + userData?.shortTag}
               />
               <CardContent>
                  <div style={cardPlaceholderHorizontalWrapperCss}>
                     <Box sx={placeholderAndTextContainerSx}>
                        <CardPlaceHolderComponent
                           card={registration.commander1}
                           setCard={empty}
                           disallowedCards={[]}
                           width={80}
                           position={"initial"}
                           left={0}
                           right={0}
                           top={0}
                           bottom={0}
                           hoverText={"1st commander"}
                           filterCommanders={true}
                           filterPartners={false}
                           filterBackgrounds={false}
                           filterFriendsForever={false}
                           mode={ComponentMode.VIEW}
                           key={"commander1"}
                           cardWidthUnit={"px"}
                           onClick={empty}
                        />
                        <Typography variant={"body2"}>1st Commander</Typography>
                     </Box>
                     {registration.commander2 && (
                        <Box sx={placeholderAndTextContainerSx}>
                           <CardPlaceHolderComponent
                              card={registration.commander2}
                              setCard={empty}
                              disallowedCards={[]}
                              width={80}
                              position={"initial"}
                              left={0}
                              right={0}
                              top={0}
                              bottom={0}
                              hoverText={"2nd commander"}
                              filterCommanders={true}
                              filterPartners={false}
                              filterBackgrounds={false}
                              filterFriendsForever={false}
                              mode={ComponentMode.VIEW}
                              key={"commander1"}
                              cardWidthUnit={"px"}
                              onClick={empty}
                           />
                           <Typography variant={"body2"}>2nd Commander</Typography>
                        </Box>
                     )}
                  </div>
                  <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                     <List dense={true}>
                        <ListItem>
                           <ListItemText primary={matchesWon.length} secondary={"Pods/Matches won"} />
                        </ListItem>
                        <ListItem>
                           <ListItemText primary={matchesLost.length} secondary={"Pods/Matches lost"} />
                        </ListItem>
                        <ListItem>
                           <ListItemText primary={matchesDrawn.length} secondary={"Pods/Matches draw"} />
                        </ListItem>
                     </List>
                     <List dense={true}>
                        <ListItem>
                           <ListItemText primary={numberFormat.format(winPc) + "%"} secondary={"W/R%"} />
                        </ListItem>
                        <ListItem>
                           <ListItemText
                              primary={<DecklistLock registration={registration} />}
                              secondary={registration.decklistLocked ? "Deck locked" : "Deck unlocked"}
                           />
                        </ListItem>
                     </List>
                  </Box>
               </CardContent>
            </Card>
         )}

         <Box
            style={{
               display: "flex",
               flexDirection: "row",
               justifyContent: "space-between",
               padding: theme.spacing(1),
            }}
         >
            {loading && (
               <Box sx={{}}>
                  <CircularProgress />
               </Box>
            )}
         </Box>
      </>
   );
}
