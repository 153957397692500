import { TournamentFull, useTournamentApi } from "../rest.client/useTournamentApi";
import { useCallback, useEffect, useMemo } from "react";
import useCurrentUser from "../auth/useCurrentUser";
import { Maybe } from "../TYPE";
import { Procedure, Supplier } from "../utils";
import { TagSummary } from "../rest.client/useTagsApi";

export interface RemoteTournament {
   canEdit: boolean;
   hasEnded: boolean;
   tournament: Maybe<TournamentFull>;
   start: Supplier<Promise<unknown>>;
   end: Supplier<Promise<unknown>>;
   uploadLogo: (image: FormData) => Promise<unknown>;
   loadData: Procedure;
   loadTags: Procedure;
   tags?: TagSummary[];
   userIsRegistered: boolean;
   canClose: boolean;
   isOrganiser: boolean;
   loading: boolean;
   canEditNotEnded: boolean;
   id?: string;
}

export default function useRemoteTournament(id?: string): RemoteTournament {
   const user = useCurrentUser();

   const {
      getTournamentFullById: { call: getTournamentFullById, responseData: tournament },
      startTournament: { call: startTournament },
      endTournament: { call: endTournament },
      uploadLogo: { call: uploadLogoCall },
      getTagsInTournament: { call: getTags, responseData: tags },
      loading,
   } = useTournamentApi();

   const isOrganiser =
      tournament != null &&
      (tournament.creator.id === user.user?.uid ||
         tournament.tournamentOrganisers.some((tO) => tO.id === user.user?.uid));
   const canEdit = isOrganiser;
   const ended = tournament?.status === "ENDED";
   const canEditNotEnded = canEdit && !ended;
   const userIsRegistered = Boolean(tournament?.userRegistrationAccepted);
   const canClose =
      tournament != null &&
      tournament.stages.every((s) => s.status === "CLOSED") &&
      tournament.status === "STARTED" &&
      tournament.prizeTiers.length > 0;

   const loadData = useCallback(async () => {
      if (id) {
         await getTournamentFullById({ pathParams: { id: id } });
      }
   }, [getTournamentFullById, id]);

   const loadTags = useCallback(async () => {
      if (id) {
         await getTags({ pathParams: { id: id } });
      }
   }, [getTags, id]);

   useEffect(() => {
      loadData();
   }, [loadData]);

   const start = useCallback(() => {
      if (id != null) {
         return startTournament({ pathParams: { id: id } }).finally(() => loadData());
      }
      return Promise.reject("No id");
   }, [id, loadData, startTournament]);

   const end = useCallback(async () => {
      if (id != null) {
         try {
            return await endTournament({ pathParams: { id: id } });
         } finally {
            await loadData();
         }
      }
      return Promise.reject("No id");
   }, [endTournament, id, loadData]);

   const uploadLogo = useCallback(
      async (image: FormData) => {
         if (tournament) {
            return await uploadLogoCall({ pathParams: { id: tournament?.id }, body: image });
         }
      },
      [tournament, uploadLogoCall]
   );

   return useMemo(
      () => ({
         userIsRegistered,
         tournament,
         canEditNotEnded,
         loadTags,
         tags: tags?._embedded.tags,
         hasEnded: ended,
         uploadLogo,
         loading,
         end,
         canClose,
         isOrganiser,
         canEdit,
         start,
         loadData,
         id,
      }),
      [
         userIsRegistered,
         canEditNotEnded,
         tournament,
         loadTags,
         tags?._embedded.tags,
         ended,
         uploadLogo,
         loading,
         end,
         canClose,
         isOrganiser,
         canEdit,
         start,
         loadData,
         id,
      ]
   );
}
