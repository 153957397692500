export enum ComponentMode {
   VIEW = "VIEW",
   EDIT = "EDIT",
}

export const GLOBAL_VAR_NAME = {
   SELECTED_NAV_INDEX: "selectedNavIndex",
   TILES_POINTS: "tilesPoints",
   SELECTED_FLEXIBLE_CONTAINER_INDEX: "selectedFlexibleContainer",
   MESSAGES_ITEMS: "messageItems",
   TOURNAMENTS_DIALOG_STATE: "TOURNAMENTS_DIALOG_STATE",
   MOXFIELD_DECKLIST_URL: "moxfieldDecklistUrl",
};

export enum TournamentCreationPagePanelIndex {
   TOURNAMENT_INFO = 0,
   TOURNAMENT_SETTINGS = 1,
   TOURNAMENT_CONFIRMATION = 2,
}

export const NUM_OF_TOURNAMENT_CREATION_PAGE_PANELS = Object.values(TournamentCreationPagePanelIndex).length * 0.5;

export const HEADER_HEIGHT_IN_PX = 60;
export const HEADER_SIDE_PADDING_IN_PX = 10;
export const HEADER_LOGO_MARGIN_RIGHT_IN_PX = 10;

export const BODY_HEIGHT_IN_CALC = `calc(100vh - ${HEADER_HEIGHT_IN_PX}px)`;

export const HOME_PAGE_SWITCH_VIEW_BUTTON_MARGIN_TOP_IN_VH = 1;
export const HOME_PAGE_SWITCH_VIEW_BUTTON_MARGIN_BOTTOM_IN_VH = 1;
export const HOME_PAGE_TABLE_RADIO_BUTTON_MY_RANK_VALUE = "My Rank";
export const HOME_PAGE_TABLE_RADIO_BUTTON_TOP_VALUE = "Top";

export const CONTAINER_CIRCLE_SIZE = 35; // vmin
export const CONTAINER_CIRCLE_BORDER_WIDTH = 1.5; // vmin
export const PLAYER_AND_COMMANDER_COMPONENT_SIZE = CONTAINER_CIRCLE_SIZE * 0.7; // vmin
export const PLAYER_AND_COMMANDER_COMPONENT_GAP_RATIO = 0.45; // vmin

export const CARD_HEIGHT_WIDTH_RATIO = 88 / 63;

export const TILES_TRANSITION_TIME = 1.5; // seconds
export const NUM_OF_TILES_PER_ROW = 20;
export const NUM_OF_TILES_PER_COLUMN = 20;

export const EMPTY_STRING = "";

export const PLAYER1_TEXT_COLOR = "rgba(0,95,204,0.9)";
export const PLAYER2_TEXT_COLOR = "rgba(208,69,0,0.9)";
export const PLAYER3_TEXT_COLOR = "rgba(44,185,0,0.9)";
export const PLAYER4_TEXT_COLOR = "rgba(131,6,6,0.9)";
export const DECK_VIEWER_CARD_COLUMN_WIDTH_IN_VW = 14;
export const DECK_VIEWER_CARD_HEIGHT_IN_VW = DECK_VIEWER_CARD_COLUMN_WIDTH_IN_VW * CARD_HEIGHT_WIDTH_RATIO;
export const DECK_VIEWER_CARD_OFFSET_RATIO = 0.11;
