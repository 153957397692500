import React from "react";
import { Alert, Box } from "@mui/material";
import { RoundFullResponse } from "../../../rest.client/useStagesApi";

export default function RoundStatusBanner({ round }: { round: RoundFullResponse }) {
   return (
      <Box>
         {round.status === "ACTIVE" && <Alert severity="success">This round is active.</Alert>}
         {round.status === "SCHEDULED" && <Alert severity="info">This round is scheduled.</Alert>}
         {round.status === "CLOSED" && (
            <Alert severity="info">This round is closed, certain attributes may not be edited in this state.</Alert>
         )}
      </Box>
   );
}
