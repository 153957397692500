import React from "react";
import { TableCell } from "@mui/material";
import { StandingsRegResponse } from "../../../../rest.client/useStagesApi";
import { numberFormat } from "../../../../gameFeatures/viewGamePage/PlayerCard";

export default function BrainstormWbeCells({ standing }: { standing: StandingsRegResponse }) {
   return (
      <>
         <TableCell>{standing.standing.wins}</TableCell>
         <TableCell>{standing.standing.buchholzScore}</TableCell>
         <TableCell>{numberFormat.format(standing.standing.elo)}</TableCell>
      </>
   );
}
