import React, { useMemo } from "react";
import { Tooltip, useTheme } from "@mui/material";
import { GiChessQueen } from "react-icons/gi";
import { empty, Procedure } from "../utils";

export default function WinnerIcon({
   won,
   onClick,
   readonly,
}: {
   won: boolean;
   onClick: Procedure;
   readonly?: boolean;
}) {
   const theme = useTheme();
   const winnerIconStyle = useMemo(
      () => ({
         cursor: readonly ? "arrow" : "pointer",
         color: won ? "orange" : theme.palette.text.primary,
      }),
      [readonly, theme.palette.text.primary, won]
   );
   return (
      <Tooltip title={won ? "Winner!" : !readonly ? "Set as winner" : "Loser"}>
         <span style={{}}>
            <GiChessQueen style={winnerIconStyle} onClick={readonly ? empty : onClick} />
         </span>
      </Tooltip>
   );
}
