import React, { useMemo } from "react";
import { Box, Grow, IconButton } from "@mui/material";
import { SxProps } from "@mui/system";
import { Property } from "csstype";
import { Procedure } from "../../utils";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { CARD_HEIGHT_WIDTH_RATIO } from "../../UI_CONST";

const containerBasicSx: SxProps = {
   position: "absolute",
   left: 0,
   bottom: 0,
   display: "flex",
   justifyContent: "space-evenly",
};

const imgBasicSx: SxProps = {
   width: "100%",
   height: "100%",
   borderRadius: "4%",
   position: "absolute",
   left: 0,
   bottom: 0,
   pointerEvents: "auto",
};

const buttonSx: SxProps = {
   margin: "2.2vh auto auto auto",
   pointerEvents: "auto",
   backdropFilter: "blur(2px)",
   backgroundColor: "rgba(0,0,0,0.5)",
   boxShadow: `0 0 0.5vmin 0.01vmin rgb(0,200,200)`,
   ":hover": {
      backgroundColor: "rgba(0,0,0,0.8)",
      boxShadow: `0 0 1vmin 0.1vmin rgb(0,255,255)`,
   },
};

interface ICardWithButtonsComponentProps {
   showButtons: boolean;
   cardWidth: number;
   cardWidthUnit: string;
   transform: Property.Transform;
   imgSrc: string;
   onMouseEnter?: Procedure;
   onMouseLeave?: Procedure;
   onClick?: Procedure;
   removeCard: Procedure;
}

const CardWithButtonsComponent: React.FC<ICardWithButtonsComponentProps> = ({
   showButtons,
   cardWidth,
   cardWidthUnit,
   transform,
   imgSrc,
   onMouseEnter,
   onMouseLeave,
   onClick,
   removeCard,
}) => {
   const containerSx: SxProps = useMemo(
      () => ({
         ...containerBasicSx,
         width: `${cardWidth}${cardWidthUnit}`,
         height: `${cardWidth * CARD_HEIGHT_WIDTH_RATIO}${cardWidthUnit}`,
         transform: transform,
      }),
      [cardWidth, cardWidthUnit, transform]
   );

   const imgSx: SxProps = useMemo(
      () => ({
         ...imgBasicSx,
         boxShadow: showButtons ? `0 0 1vmin 0.3vmin rgb(0,126,123)` : `0 0 1vmin 0.3vmin black`,
      }),
      [showButtons]
   );

   return (
      <Box sx={containerSx} onMouseLeave={onMouseLeave} onMouseEnter={onMouseEnter} onClick={onClick}>
         <Box component="img" sx={imgSx} src={imgSrc} />
         <Grow in={showButtons}>
            <IconButton size={"medium"} color={"secondary"} disabled={!showButtons} sx={buttonSx} onClick={removeCard}>
               <DeleteForeverIcon />
            </IconButton>
         </Grow>
      </Box>
   );
};

export default CardWithButtonsComponent;
