import useGlobal from "../global/useGlobal";
import { TMessageItem, TMessageItemLvl } from "../TYPE";
import { GLOBAL_VAR_NAME } from "../UI_CONST";
import { useCallback } from "react";

const useAddMessageItem = () => {
   const [messageItems, setMessageItems] = useGlobal<Array<TMessageItem>>(GLOBAL_VAR_NAME.MESSAGES_ITEMS, []);

   return useCallback(
      (messageOrStatus: string | number, level?: TMessageItemLvl, autoHideDuration?: number | null) => {
         const msg = typeof messageOrStatus === "number" ? statusCodeToMessage(messageOrStatus) : messageOrStatus;
         const newMessageItem: TMessageItem = {
            message: msg,
            key: new Date().getTime().toString(),
            level: level || "info",
            autoHideDuration: autoHideDuration !== undefined ? autoHideDuration : 3000,
         };
         setMessageItems([...messageItems, newMessageItem]);
      },
      [messageItems, setMessageItems]
   );
};

const statusCodeToMessage = (statusCode: number) => {
   switch (statusCode) {
      case 400:
         return "Bad request";
      case 401:
         return "Unauthorized";
      case 403:
         return "Forbidden";
      case 404:
         return "Not found";
      case 409:
         return "Tournament name already exists, please pick another name and try again";
      case 500:
         return "Internal server error";
      default:
         return "Unknown error";
   }
};

export default useAddMessageItem;
