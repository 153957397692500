import { Box, Link, Paper, Typography, useTheme } from "@mui/material";
import React from "react";
import { FaDiscord } from "react-icons/fa";

const Footer: React.FC = () => {
   const theme = useTheme();

   return (
      <Paper id={"footerRoot"} sx={{ width: "100%" }}>
         <Box
            sx={{
               paddingLeft: theme.spacing(4),
               paddingRight: theme.spacing(4),
               paddingTop: theme.spacing(2),
               paddingBottom: theme.spacing(2),
            }}
         >
            <Box>
               <Link target={"_blank"} rel="noopener" href={"https://discord.gg/DAQ6SJaa6M"}>
                  {" "}
                  <FaDiscord />
               </Link>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", gap: theme.spacing(1) }}>
               <Typography variant={"body2"}>
                  Parts of Brainstorm TMS are unofficial Fan Content permitted under the Wizards of the Coast Fan
                  Content Policy. The literal and graphical information presented on this site about Magic: The
                  Gathering, including card images, mana symbols, and Oracle text, is copyright Wizards of the Coast,
                  LLC, a subsidiary of Hasbro, Inc. Brainstorm TMS is not produced by or endorsed by Wizards of the
                  Coast.
               </Typography>
               <Typography variant={"body2"}>
                  <span>Card data provided by </span>
                  <Link href="https://mtgjson.com/" target="_blank" rel="nofollow noopener noreferrer">
                     MTGJson.
                  </Link>
                  <span> Card images provided by </span>
                  <Link href="https://www.scryfall.com" target="_blank" rel="nofollow noopener noreferrer">
                     Scryfall.
                  </Link>
               </Typography>
               <Typography variant={"body2"}></Typography>
               <Typography variant={"body2"}>
                  For more information see the <Link href={"/tos/"}>Terms of service</Link>
               </Typography>
            </Box>
         </Box>
      </Paper>
   );
};

export default Footer;
