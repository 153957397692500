import React, { useEffect } from "react";
import { Box } from "@mui/material";
import { SxProps } from "@mui/system";
import FlexibleContainer from "./FlexibleContainer";
import ConnectorLine from "./ConnectorLine";
import LogGamePagePlayersAndCommandersFullPanel from "./LogGamePagePlayersAndCommandersFullPanel";
import LogGamePagePlayersAndCommandersSummaryPanel from "./LogGamePagePlayersAndCommandersSummaryPanel";
import LogGamePageOptionalGameDataFullPanel from "./LogGamePageOptionalGameDataFullPanel";
import LogGamePageOptionalGameDataSummaryPanel from "./LogGamePageOptionalGameDataSummaryPanel";
import useMutableGame from "../useMutableGame";
import { useParams } from "react-router-dom";
import useGlobal from "../../global/useGlobal";
import { GLOBAL_VAR_NAME } from "../../UI_CONST";
import { Maybe, TPoint2 } from "../../TYPE";
import { randomPos } from "../../utils";

const logGamePageRootSx: SxProps = {
   overflow: "auto",
   width: "100%",
   height: "100%",
   display: "flex",
   flexDirection: "column",
   alignItems: "center",
   boxSizing: "border-box",
   paddingTop: "2vh",
   paddingBottom: "2vh",
};

const LogGamePage: React.FC = () => {
   const { id } = useParams();
   const mutableGame = useMutableGame(id);

   const [, setSelectedFlexibleContainerIndex] = useGlobal<number | null>(
      GLOBAL_VAR_NAME.SELECTED_FLEXIBLE_CONTAINER_INDEX,
      null
   );
   const [, setTilesPos] = useGlobal<Maybe<TPoint2>>("tilesPoints", null);

   useEffect(() => {
      setTilesPos(randomPos());
   }, [setTilesPos]);

   useEffect(() => {
      setSelectedFlexibleContainerIndex(null);
   }, [id, setSelectedFlexibleContainerIndex]);

   return (
      <Box id={"logGamePageRoot"} sx={logGamePageRootSx}>
         <FlexibleContainer
            containerIndex={0}
            fullContent={<LogGamePagePlayersAndCommandersFullPanel game={mutableGame} />}
            summaryContent={<LogGamePagePlayersAndCommandersSummaryPanel game={mutableGame} />}
         />
         <ConnectorLine />
         <FlexibleContainer
            containerIndex={1}
            fullContent={<LogGamePageOptionalGameDataFullPanel readOnly={false} game={mutableGame} />}
            summaryContent={<LogGamePageOptionalGameDataSummaryPanel game={mutableGame} />}
         />
      </Box>
   );
};

export default LogGamePage;
