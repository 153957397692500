import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Tab, Tabs, Typography, useTheme } from "@mui/material";
import { toStageRoundViewPage } from "../../../../navPaths";
import { RemoteStage } from "../useRemoteStage";
import { useStageApi } from "../../../../rest.client/useStagesApi";
import useReload from "../../../../header/useReload";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RoundTimerCountdown from "../../../rounds/RoundTimerCountdown";

export default function RoundTabs({ roundId, remoteStage }: { roundId?: string; remoteStage: RemoteStage }) {
   const nav = useNavigate();
   const theme = useTheme();
   const stage = remoteStage.stage;
   const tournament = remoteStage.remoteTournament.tournament;
   const reload = useReload();
   const {
      addRound: { call: addRound },
   } = useStageApi();

   return tournament != null && stage != null ? (
      <Tabs value={roundId} variant="scrollable" scrollButtons="auto" allowScrollButtonsMobile>
         {stage.rounds.map((r, i) => (
            <Tab
               key={i}
               value={r.id}
               label={
                  <Box sx={{ display: "flex", alignItems: "center", gap: theme.spacing(1) }}>
                     <Typography>{"Round " + r.roundNumber + (r.status === "ACTIVE" ? " (Active)" : "")}</Typography>
                     {r.roundEndDate && r.status === "ACTIVE" && (
                        <RoundTimerCountdown round={r} size={30} showMinsOnly={true} minsOnlyFontSize={13} />
                     )}
                  </Box>
               }
               onClick={() => nav(toStageRoundViewPage(tournament.id, stage?.id, r.id))}
            ></Tab>
         ))}
         {remoteStage.canEdit && remoteStage.stage?.canModifyRounds && remoteStage.stage.status !== "CLOSED" && (
            <Tab
               color={"secondary"}
               label={
                  <Box sx={{ display: "flex", gap: theme.spacing(0.5), color: theme.palette.secondary.main }}>
                     <AddCircleOutlineIcon />
                     <Typography>Add round</Typography>
                  </Box>
               }
               onClick={() => addRound({ pathParams: { id: stage?.id } }).then(() => reload.requestReload())}
            ></Tab>
         )}
      </Tabs>
   ) : (
      <></>
   );
}
