import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
   FormControl,
   FormHelperText,
   Grow,
   IconButton,
   InputAdornment,
   InputLabel,
   OutlinedInput,
} from "@mui/material";
import { SxProps } from "@mui/system";
import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";
import useCurrentUser from "../../auth/useCurrentUser";
import { useApplicationApi } from "../../rest.client/useApplicationApi";
import useReload from "../../header/useReload";
import useAddMessageItem from "../../messagesComponent/useAddMessageItem";

const MAX_CHANGE_COUNT = 3;
const profilePageUserNameFieldFormControlSx: SxProps = {
   width: "100%",
};

export default function ProfilePageUserNameField() {
   const user = useCurrentUser();
   const addMessageItem = useAddMessageItem();
   const { reload } = useReload();

   const {
      findUserProfileById: { call: findUserById, responseData: userProfileData },
      changeUsername: { call: changeUsername },
   } = useApplicationApi();

   const [isEditing, setIsEditing] = useState(false);
   const [usernameInEdit, setUsernameInEdit] = useState<string>(userProfileData ? userProfileData.name : "");

   const canChangeName = (userProfileData?.nameChangeCount || 0) < MAX_CHANGE_COUNT;

   useEffect(() => {
      user?.user?.uid && findUserById({ pathParams: { id: user?.user?.uid } });
   }, [findUserById, reload, user?.user?.uid]);

   const changeCb = useCallback(() => {
      if (usernameInEdit && usernameInEdit !== "") {
         changeUsername({ body: { name: usernameInEdit } }).finally(() => {
            setIsEditing(false);
            user?.user?.uid && findUserById({ pathParams: { id: user?.user?.uid } });
         });
      } else {
         addMessageItem("Username cannot be empty", "error");
      }
   }, [addMessageItem, changeUsername, findUserById, user?.user?.uid, usernameInEdit]);

   const editUserName = useCallback(() => {
      setIsEditing(true);
      setUsernameInEdit(userProfileData ? userProfileData.name : "");
   }, [userProfileData]);

   const cancelEditUserName = useCallback(() => {
      setIsEditing(false);
      setUsernameInEdit(userProfileData ? userProfileData.name : "");
   }, [userProfileData]);

   const endAdornment = useMemo(() => {
      return canChangeName ? (
         <InputAdornment position="end">
            {isEditing ? (
               <>
                  <Grow in={true}>
                     <IconButton onClick={changeCb}>
                        <DoneIcon />
                     </IconButton>
                  </Grow>
                  <Grow in={true}>
                     <IconButton onClick={cancelEditUserName} edge="end">
                        <ClearIcon />
                     </IconButton>
                  </Grow>
               </>
            ) : (
               <Grow in={true}>
                  <IconButton onClick={editUserName} edge="end">
                     <EditIcon />
                  </IconButton>
               </Grow>
            )}
         </InputAdornment>
      ) : (
         <></>
      );
   }, [canChangeName, cancelEditUserName, changeCb, editUserName, isEditing]);

   const userNameToDisplay = useMemo(
      () => (isEditing ? usernameInEdit : userProfileData ? `${userProfileData.name}/${userProfileData.shortTag}` : ""),
      [isEditing, usernameInEdit, userProfileData]
   );

   const onKeyDown = useCallback(
      (e: React.KeyboardEvent<HTMLInputElement>) => {
         e.key === "Enter" && changeCb();
      },
      [changeCb]
   );

   const onInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
      setUsernameInEdit(e.target.value);
   }, []);

   const helperText = useMemo(() => {
      return canChangeName
         ? `You can change your name ${MAX_CHANGE_COUNT - (userProfileData?.nameChangeCount || 0)} more times`
         : "You can't change your name anymore";
   }, [canChangeName, userProfileData?.nameChangeCount]);

   return (
      <FormControl
         id={"profilePageUserNameFieldFormControl"}
         sx={profilePageUserNameFieldFormControlSx}
         variant="outlined"
      >
         <InputLabel>User name</InputLabel>
         <OutlinedInput
            disabled={!isEditing}
            size={"medium"}
            endAdornment={endAdornment}
            label="User name"
            value={userNameToDisplay}
            onChange={onInputChange}
            onKeyDown={onKeyDown}
         />
         <FormHelperText>{helperText}</FormHelperText>
      </FormControl>
   );
}
