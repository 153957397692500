import React, {CSSProperties, MouseEventHandler, ReactElement, SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState,} from "react";
import {Autocomplete, Box, Grow, IconButton, TextField} from "@mui/material";
import {SxProps} from "@mui/system";
import {Maybe} from "../TYPE";
import {useApplicationApi} from "../rest.client/useApplicationApi";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {CardExtendedInfo} from "./CardTypes";
import BackdropContainerComponent from "../backdropContainer/BackdropContainerComponent";

const maxNumOfCardsToShow = 8;
const cardsContainerWidth = 100; //vmin
const cardsContainerHeight = 60; //vmin
const cardWidth = 18; //vmin
const cardsShowingAngleRange = 95; //deg
const cardsInitialZRotation = cardsShowingAngleRange * 0.5; //deg

const cardPickerContainerBasicSx: SxProps = {
    width: "100vw",
    height: "100vh",
    position: "relative",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
};

const commanderCardSearchFieldSx: SxProps = {
    margin: "auto",
    marginBottom: "0",
    width: "50vmin",
};

const cardBasicCss: CSSProperties = {
    width: `${cardWidth}vmin`,
    position: "absolute",
    top: 0,
    left: 0,
    borderRadius: "4%",
    overflow: "hidden",
    boxShadow: `0 0 0.8vmin 0.1vmin rgba(0,0,0,0.8)`,
};

const cardWithPointerSx: SxProps = {
    ...cardBasicCss,
    cursor: "pointer",
    transition: "top 0.3s",
    ":hover": {
        top: `-${cardWidth * 0.2}vmin`,
    },
};

const cardsContainerSx: SxProps = {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    width: `${cardsContainerWidth}vmin`,
    height: `${cardsContainerHeight}vmin`,
    margin: "auto",
    // backgroundColor: "rgba(255,255,255,0.1)",
};

const cardTrackBasicSx: SxProps = {
    position: "absolute",
    top: 0,
    left: `${(cardsContainerWidth - cardWidth) * 0.5}vmin`,
    width: `${cardWidth}vmin`,
    height: `${cardsContainerHeight}vmin`,
    // backgroundColor: "rgba(255,255,255,0.1)",
    transition: "transform 0.5s",
    transformOrigin: "50% 100%",
    transform: `rotateZ(${cardsInitialZRotation}deg)`,
    display: "flex",
};

const leftIconButtonContainerSx: SxProps = {
    ...cardTrackBasicSx,
    transform: `rotateZ(${cardsInitialZRotation - cardsShowingAngleRange}deg)`,
};

const rightIconButtonContainerSx: SxProps = {
    ...cardTrackBasicSx,
    transform: `rotateZ(${cardsInitialZRotation}deg) scale(1,1)`,
};

const leftIconButtonSx: SxProps = {
    margin: "auto",
    marginLeft: `-${cardWidth * 0.5}vmin`,
    marginTop: `${cardWidth * 0.45}vmin`,
};

const rightIconButtonSx: SxProps = {
    margin: "auto",
    marginRight: `-${cardWidth * 0.5}vmin`,
    marginTop: `${cardWidth * 0.45}vmin`,
};

const arrowIconSx: SxProps = {fontSize: "6vmin"};

interface ICardPickerComponentProps {
    showCardPicker: boolean;
    filterCommanders: boolean;
    filterPartners: boolean;
    filterBackgrounds: boolean;
    filterFriendsForever: boolean;
    selectCardHandler: (card: CardExtendedInfo) => void;
    closeCardPickerHandler: () => void;
    disallowedCards: Array<CardExtendedInfo>;
}

const CardPickerComponent: React.FC<ICardPickerComponentProps> = ({
    showCardPicker,
    filterCommanders,
    filterPartners,
    filterBackgrounds,
    filterFriendsForever,
    closeCardPickerHandler,
    selectCardHandler,
    disallowedCards,
}) => {
    const {
        findCardByName: {call: findCard, clear: clearSearch, responseData, requestsSent},
    } = useApplicationApi();
    const textField = useRef<HTMLInputElement>(null);

    const [searchActiveTimeout, setSearchActiveTimeout] = useState<Maybe<number>>(null);
    const [triggerActiveTimeout, setTriggerActiveTimeout] = useState<Maybe<number>>(null);
    const [processingInput, setProcessingInput] = useState(false);
    const [firstShowingCardIndex, setFirstShowingCardIndex] = useState(0);
    const [cardsTransitionTrigger, setCardsTransitionTrigger] = useState(false);
    const [cardsInfoToShow, setCardsInfoToShow] = useState<Array<CardExtendedInfo>>([]);
    const [autoCompleteValue, setAutoCompleteValue] = useState<Maybe<CardExtendedInfo>>(null);
    const [hoverCardIndex, setHoverCardIndex] = useState<Maybe<number>>(null);

    const searchResultOfAllMainCommanders: Array<CardExtendedInfo> = useMemo(
        () => responseData?._embedded.cards || [],
        [responseData?._embedded.cards]
    );
    // const partners = (partnersResponse?._embedded.cards || []).filter((pC) => pC.id !== commander?.commander1.id);

    const isShowingCardIndexSmallEnough = useMemo(
        () =>
            cardsInfoToShow.length > 0 &&
            firstShowingCardIndex + maxNumOfCardsToShow < searchResultOfAllMainCommanders.length,
        [cardsInfoToShow.length, firstShowingCardIndex, searchResultOfAllMainCommanders.length]
    );
    const isShowingCardIndexLargeEnough = useMemo(
        () => cardsInfoToShow.length > 0 && firstShowingCardIndex > 0,
        [cardsInfoToShow.length, firstShowingCardIndex]
    );

    const clearData = useCallback(() => {
        setAutoCompleteValue(null);
        setCardsInfoToShow([]);
        clearSearch();
    }, [clearSearch]);

    const selectCard = useCallback(
        (card: CardExtendedInfo) => {
            selectCardHandler(card);
            clearData();
            closeCardPickerHandler();
        },
        [clearData, closeCardPickerHandler, selectCardHandler]
    );

    useEffect(() => {
        const lastShowingCardIndex =
            searchResultOfAllMainCommanders.length > maxNumOfCardsToShow
                ? firstShowingCardIndex + (maxNumOfCardsToShow - 1)
                : firstShowingCardIndex + (searchResultOfAllMainCommanders.length - 1);
        setCardsInfoToShow(searchResultOfAllMainCommanders.slice(firstShowingCardIndex, lastShowingCardIndex + 1));
    }, [firstShowingCardIndex, searchResultOfAllMainCommanders]);

    useEffect(() => {
        if (showCardPicker && textField.current != null) {
            textField.current.focus();
        }
    });

    const onValueChangeHandler = useCallback(
        (evt: SyntheticEvent, value: CardExtendedInfo | string | null) => {
            setAutoCompleteValue(typeof value === "string" ? null : value);
            if (value !== null && typeof value !== "string") {
                selectCard(value);
            }
        },
        [selectCard]
    );

    const searchPrimaryCommanderHandler = useCallback(
        (evt: SyntheticEvent, value: string) => {
            if (value.length < 2) {
                clearData();
            }
            if (searchActiveTimeout != null) {
                clearTimeout(searchActiveTimeout);
                setSearchActiveTimeout(null);
            }
            if (triggerActiveTimeout != null) {
                clearTimeout(triggerActiveTimeout);
                setTriggerActiveTimeout(null);
            }
            if (value.length >= 2) {
                setFirstShowingCardIndex(0);
                setCardsTransitionTrigger(false);
                setProcessingInput(true);
                const timeOutId = window.setTimeout(() => {
                    findCard({
                        queryParams: {
                            name: value,
                            nameStripped: value.replaceAll(/[^A-Za-z0-9]/gi, ""),
                            filterCommanders: filterCommanders,
                            filterPartners: filterPartners,
                            filterBackgrounds: filterBackgrounds,
                            filterFriendsForever,
                            size: 25,
                            page: 0,
                            sort: "name,desc",
                        },
                    }).finally(() => {
                        setProcessingInput(false);
                        setCardsTransitionTrigger(false);
                        const cardsTransitionTriggerTimeOutId = window.setTimeout(() => setCardsTransitionTrigger(true), 100);
                        setTriggerActiveTimeout(cardsTransitionTriggerTimeOutId);
                    });
                }, 500);
                setSearchActiveTimeout(timeOutId);
            }
        },
        [
            searchActiveTimeout,
            triggerActiveTimeout,
            clearData,
            findCard,
            filterCommanders,
            filterPartners,
            filterBackgrounds,
            filterFriendsForever,
        ]
    );

    const increaseFirstShowingCardIndex = useCallback(() => {
        if (isShowingCardIndexSmallEnough) {
            setFirstShowingCardIndex((prevState) => prevState + 1);
        }
    }, [isShowingCardIndexSmallEnough]);

    const decreaseFirstShowingCardIndex = useCallback(() => {
        if (isShowingCardIndexLargeEnough) {
            setFirstShowingCardIndex((prevState) => prevState - 1);
        }
    }, [isShowingCardIndexLargeEnough]);

    const eventPropagationStopper: MouseEventHandler<HTMLDivElement> = useCallback((e) => e.stopPropagation(), []);

    const cardComponentsToShow = useMemo(() => {
        const result: Array<ReactElement> = [];
        const rotationDiff = cardsShowingAngleRange / cardsInfoToShow.length;
        const onlyOneCard = cardsInfoToShow.length === 1;

        cardsInfoToShow.forEach((cardInfo, index) => {
            if (!disallowedCards.some((disallowedCard) => disallowedCard.id === cardInfo.id)) {
                let cardRotation = 0;
                if (!onlyOneCard) {
                    cardRotation = cardsInitialZRotation - (index + 1) * rotationDiff;
                    if (rotationDiff < 23 && hoverCardIndex === index + 1) {
                        cardRotation = cardsInitialZRotation - (index + 0.3) * rotationDiff;
                    }
                }
                const cardTrackSx: SxProps = {
                    ...cardTrackBasicSx,
                    transform: `rotateZ(${cardsTransitionTrigger ? cardRotation : cardsInitialZRotation}deg)`,
                };

                result.push(
                    <Box key={cardInfo.id} sx={cardTrackSx}>
                        <Box
                            onClick={() => selectCard(cardInfo)}
                            component="img"
                            src={cardInfo.largeImageUri}
                            sx={cardWithPointerSx}
                            onMouseEnter={() => setHoverCardIndex(index)}
                            onMouseLeave={() => setHoverCardIndex(null)}
                        />
                    </Box>
                );
            }
        });
        return result.reverse();
    }, [cardsInfoToShow, cardsTransitionTrigger, disallowedCards, hoverCardIndex, selectCard]);

    const cardBackComponents = useMemo(() => {
        const result: Array<ReactElement> = [];
        const rotationDiff = cardsShowingAngleRange / maxNumOfCardsToShow;

        for (let i = 0; i <= maxNumOfCardsToShow; i++) {
            const cardTrackSx: SxProps = {
                ...cardTrackBasicSx,
                transform: `rotateZ(${
                    cardsInfoToShow.length === 0 ? cardsInitialZRotation - i * rotationDiff : cardsInitialZRotation
                }deg)`,
            };
            result.push(
                <Box key={i} sx={cardTrackSx}>
                    <img style={cardBasicCss} src={"/magic_card_back.png"} alt={"mtg card back"}/>
                </Box>
            );
        }

        return result.reverse();
    }, [cardsInfoToShow]);

    return (
        <BackdropContainerComponent open={showCardPicker} closeHandler={closeCardPickerHandler}>
            <Box sx={cardPickerContainerBasicSx} onClick={eventPropagationStopper}>
                <Box sx={cardsContainerSx}>
                    <Box sx={leftIconButtonContainerSx}>
                        <Grow in={isShowingCardIndexLargeEnough}>
                            <IconButton sx={leftIconButtonSx} onClick={decreaseFirstShowingCardIndex} color="primary">
                                <ArrowBackIosNewIcon sx={arrowIconSx}/>
                            </IconButton>
                        </Grow>
                    </Box>
                    {cardBackComponents}
                    {cardComponentsToShow}
                    <Box sx={rightIconButtonContainerSx}>
                        <Grow in={isShowingCardIndexSmallEnough}>
                            <IconButton sx={rightIconButtonSx} onClick={increaseFirstShowingCardIndex} color="primary">
                                <ArrowForwardIosIcon sx={arrowIconSx}/>
                            </IconButton>
                        </Grow>
                    </Box>
                    <Autocomplete
                        size={"small"}
                        value={autoCompleteValue}
                        onChange={onValueChangeHandler}
                        onInputChange={searchPrimaryCommanderHandler}
                        options={searchResultOfAllMainCommanders}
                        getOptionLabel={(option) => {
                            if (typeof option === "string") {
                                return option;
                            }
                            return option.name;
                        }}
                        filterOptions={(opts) => opts}
                        loading={processingInput}
                        noOptionsText={requestsSent === 0 ? "Keep typing..." : "No results"}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        sx={commanderCardSearchFieldSx}
                        clearOnBlur={false}
                        freeSolo={true}
                        renderInput={(params) => (
                            <TextField
                                inputRef={textField}
                                autoFocus={true}
                                placeholder={"Search card here with at least 2 letters"}
                                {...params}
                            />
                        )}
                    />
                </Box>
            </Box>
        </BackdropContainerComponent>
    );
};

export default CardPickerComponent;
