import React from "react";
import { Box } from "@mui/material";
import { SxProps } from "@mui/system";
import ProfilePageUserNameField from "./ProfilePageUserNameField";
import ProfilePageUserAvatar from "./ProfilePageUserAvatar";
import ProfilePageEmailField from "./ProfilePageEmailField";
import { AdditionalSettings } from "./AdditionalSettings";
import ScrollableBorderedWrapper from "../../common/ScrollableBorderedWrapper";

const profilePageContentContainerSx: SxProps = {
   position: "relative",
   display: "flex",
   flexDirection: "column",
   alignItems: "center",
   rowGap: "30px",
   padding: "15px",
   boxSizing: "border-box",
   width: "60%",
   minWidth: "250px",
   borderRadius: "5px",
   marginLeft: "auto",
   marginRight: "auto",
};

export default function ProfilePage() {
   return (
      <ScrollableBorderedWrapper>
         <Box sx={profilePageContentContainerSx}>
            <ProfilePageUserAvatar />
            <ProfilePageUserNameField />
            <ProfilePageEmailField />
            <AdditionalSettings />
         </Box>
      </ScrollableBorderedWrapper>
   );
}
