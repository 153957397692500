import React from "react";
import { Alert, Box } from "@mui/material";
import { StageSummaryResponse } from "../../../rest.client/useStagesApi";

export default function StageStatusBanner({ stage }: { stage: StageSummaryResponse }) {
   return (
      <Box>
         {stage.status === "ACTIVE" && <Alert severity="success">This stage is active.</Alert>}
         {stage.status === "SCHEDULED" && (
            <Alert severity="info">This stage is scheduled. No game data will be present.</Alert>
         )}
         {stage.status === "CLOSED" && (
            <Alert severity="info">This stage is closed, certain attributes may not be edited in this state.</Alert>
         )}
      </Box>
   );
}
