import React from "react";
import { Alert, Box, Button, CardMedia, Link, Paper, Typography, useMediaQuery, useTheme } from "@mui/material";
import useCurrentUser from "../../auth/useCurrentUser";
import { Procedure } from "../../utils";

export default function WelcomeBanner({ onCreateClicked }: { onCreateClicked: Procedure }) {
   const theme = useTheme();
   const user = useCurrentUser();
   const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));
   return (
      <Box>
         <Alert severity={"warning"}>
            Brainstorm TMS is still in Beta and help features are still on the list... if you need help please head over
            to our
            <Link target={"_blank"} rel="noopener" href={"https://discord.gg/DAQ6SJaa6M"}>
               <span> Discord.</span>
            </Link>
         </Alert>
         <Paper sx={{ position: "relative" }}>
            <CardMedia component="img" height="250" image={"sta-13-brainstorm.jpg"} alt={"brainstorm card image"} />
            <Box
               sx={{
                  position: "absolute",
                  bottom: 0,
                  backgroundColor: theme.palette.fadedBackground,
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
               }}
            >
               <Typography
                  color={theme.palette.primary.main}
                  fontSize={smallScreen ? "55px" : "100px"}
                  fontFamily={"Neon"}
                  fontWeight={400}
                  fontStyle={"italic"}
                  sx={{ marginBottom: theme.spacing(2) }}
               >
                  Brainstorm
               </Typography>
               <Typography
                  fontSize={"16px"}
                  fontWeight={300}
                  letterSpacing={2}
                  lineHeight={1.4}
                  marginBottom={1.5}
                  textAlign={"center"}
               >
                  Brainstorm TMS, the free tournament management system for cEDH with{" "}
                  <span style={{ fontWeight: "bold" }}>unlimited</span> customization.
               </Typography>
               {!user.loggedIn && (
                  <Link href={"/login"}>
                     <Typography fontSize={"16px"} fontWeight={600} letterSpacing={2} lineHeight={2.8}>
                        Build your own, Sign up.
                     </Typography>
                  </Link>
               )}
               {user.loggedIn && (
                  <Button variant={"text"} onClick={onCreateClicked}>
                     Build your own!
                  </Button>
               )}
            </Box>
         </Paper>
      </Box>
   );
}
