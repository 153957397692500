import React from "react";
import { CircularProgress } from "@mui/material";
import { Box } from "@mui/system";

export default function Spinner({ show }: { show: boolean }) {
   return (
      <>
         {show && (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
               <CircularProgress />
            </Box>
         )}
      </>
   );
}
