import React, { useEffect, useState } from "react";
import { Button, IconButton, Snackbar } from "@mui/material";
import { useApplicationApi } from "../rest.client/useApplicationApi";
import useCurrentUser from "../auth/useCurrentUser";
import CloseIcon from "@mui/icons-material/Close";

export default function VerifiedEmailChecker() {
   const user = useCurrentUser();
   const {
      findUserProfileById: { call: findUserById, responseData: userAppData },
      sendVerifyEmail: { call: sendVerifyEmail },
   } = useApplicationApi();
   const [open, setOpen] = useState(true);
   const canSendEmail =
      userAppData?.lastVerifyEmailDate == null || Date.parse(userAppData.lastVerifyEmailDate) + 60 * 1000 < Date.now();

   useEffect(() => {
      user?.user?.uid && findUserById({ pathParams: { id: user?.user?.uid } });
   }, [findUserById, user?.user?.uid]);

   const action = (
      <>
         <Button
            color="secondary"
            size="small"
            onClick={() => {
               sendVerifyEmail().finally(() => setOpen(false));
            }}
         >
            Send email
         </Button>
         <IconButton size="small" aria-label="close" color="inherit" onClick={() => setOpen(false)}>
            <CloseIcon fontSize="small" />
         </IconButton>
      </>
   );

   return (
      <Snackbar
         open={open && canSendEmail && user.user != null && !user.user.emailVerified}
         autoHideDuration={6000}
         onClose={() => setOpen(false)}
         message="You email address is not verified!"
         action={action}
      />
   );
}
