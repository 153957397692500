import { Box, Drawer, Link, List, ListItem, ListItemButton, ListItemText, useTheme } from "@mui/material";
import React from "react";
import { Procedure } from "../utils";
import { useNavigate } from "react-router-dom";
import { toMetaInsights } from "../navPaths";
import { LOG_GAME_PATH, MY_GAMES_PATH } from "../index";
import useCurrentUser from "../auth/useCurrentUser";
import { FaDiscord } from "react-icons/fa";

export default function NavigationDrawer({ open, onClose }: { open: boolean; onClose: Procedure }) {
   const theme = useTheme();
   const user = useCurrentUser();
   const nav = useNavigate();
   return (
      <Drawer open={open} onClose={onClose}>
         <Box sx={{ width: 250 }} onClick={onClose}>
            <List>
               <ListItem disablePadding>
                  <ListItemButton onClick={() => nav("/")}>
                     <ListItemText primary={"Brainstorm TMS"} />
                  </ListItemButton>
               </ListItem>
               <ListItem disablePadding>
                  <ListItemButton onClick={() => nav(toMetaInsights())}>
                     <ListItemText primary={"Insights"} />
                  </ListItemButton>
               </ListItem>
               {user.loggedIn && (
                  <ListItem disablePadding>
                     <ListItemButton onClick={() => nav(MY_GAMES_PATH)}>
                        <ListItemText primary={"My games"} />
                     </ListItemButton>
                  </ListItem>
               )}
               {user.loggedIn && (
                  <ListItem disablePadding>
                     <ListItemButton onClick={() => nav(LOG_GAME_PATH)}>
                        <ListItemText primary={"Log personal game"} />
                     </ListItemButton>
                  </ListItem>
               )}
               {user.loggedIn && (
                  <ListItem disablePadding>
                     <ListItemButton onClick={() => nav(LOG_GAME_PATH)}>
                        <ListItemText
                           primary={
                              <Link target={"_blank"} rel="noopener" href={"https://discord.gg/DAQ6SJaa6M"}>
                                 <Box sx={{ display: "flex", alignItems: "center", gap: theme.spacing(1) }}>
                                    <FaDiscord />
                                    Discord
                                 </Box>
                              </Link>
                           }
                        />
                     </ListItemButton>
                  </ListItem>
               )}
            </List>
         </Box>
      </Drawer>
   );
}
