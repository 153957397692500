import React from "react";
import { Tooltip, useTheme } from "@mui/material";
import { Procedure } from "../utils";
import QueryStatsIcon from "@mui/icons-material/QueryStats";

export default function ShowStatsButton({ onClick }: { onClick: Procedure }) {
   const theme = useTheme();

   return (
      <Tooltip title={"Show insights for player"}>
         <QueryStatsIcon
            onClick={onClick}
            style={{
               cursor: "pointer",
               color: theme.palette.text.primary,
               marginRight: theme.spacing(1),
               fontSize: "18px",
            }}
         />
      </Tooltip>
   );
}
