import React, { useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import StandingsTopPlayers from "../standings/StandingsTopPlayers";
import { StageFullResponse } from "../../../../rest.client/useStagesApi";
import LeagueStandingsTable from "../standings/LeagueStandingsTable";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { RemoteStage } from "../useRemoteStage";
import { useParams } from "react-router-dom";
import RoundTabs from "./RoundTabs";
import RoundMatchesExpander from "./RoundMatchesExpander";
import OrganiserRoundControls from "./OrganiserRoundControls";
import RoundDetails from "../../../rounds/RoundDetails";
import ByesExpander from "./ByesExpander";

export default function SwissStageView({
   stageFull,
   remoteStage,
}: {
   stageFull: StageFullResponse;
   remoteStage: RemoteStage;
}) {
   const { roundId, matchId } = useParams();
   const theme = useTheme();
   const midScreen = useMediaQuery(theme.breakpoints.down("md"));
   const activeRound = stageFull.rounds.find((r) => r.status === "ACTIVE");
   const selectedRound = stageFull.rounds.find((r) => r.id === roundId);
   const lastRound = stageFull.rounds.length > 0 ? stageFull.rounds[stageFull.rounds.length - 1] : null;
   const displayRound = selectedRound || activeRound || lastRound;
   const [standingsExpanded, setStandingsExpanded] = useState(false);

   return (
      <>
         <Accordion expanded={standingsExpanded} onChange={() => setStandingsExpanded(!standingsExpanded)}>
            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ExpandMoreIcon />}>
               <Typography>Standings</Typography>
            </AccordionSummary>
            <AccordionDetails>
               {!midScreen && stageFull && stageFull.status !== "SCHEDULED" && (
                  <StandingsTopPlayers numOfTopPlayers={4} stage={stageFull} />
               )}
               <Box>
                  <LeagueStandingsTable
                     stage={stageFull}
                     standings={stageFull.standings}
                     remoteTournament={remoteStage.remoteTournament}
                  />
               </Box>
            </AccordionDetails>
         </Accordion>
         <RoundTabs remoteStage={remoteStage} roundId={displayRound?.id} />
         {displayRound && <RoundDetails round={displayRound} />}
         {displayRound && remoteStage.canEdit && (
            <OrganiserRoundControls remoteStage={remoteStage} selectedRound={displayRound} />
         )}
         {displayRound && <ByesExpander remoteStage={remoteStage} round={displayRound} />}
         {displayRound != null && (
            <RoundMatchesExpander remoteStage={remoteStage} round={displayRound} selectedMatchId={matchId} />
         )}
      </>
   );
}
