import React, { ReactNode, useCallback, useState } from "react";
import { Box, Button, InputLabel, MenuItem, Select, TextField, useTheme } from "@mui/material";
import { SxProps } from "@mui/system";
import FormControl from "@mui/material/FormControl";
import useGlobal from "../../global/useGlobal";
import { GLOBAL_VAR_NAME } from "../../UI_CONST";
import { WinType } from "../../TYPE";
import LogGamePageOtherCardPlaceholder from "./LogGamePageOtherCardPlaceholder";
import { CardNameAndImage } from "../../cardFeatures/CardTypes";
import { MutableGame } from "../MutableGame";
import DrawGameConfirmDialog from "../DrawGameConfirmDialog";
import useAddMessageItem from "../../messagesComponent/useAddMessageItem";

const getEndingTurnOptions = (): Array<ReactNode> => {
   const result = [];
   for (let i = 1; i < 26; i++) {
      result.push(
         <MenuItem key={i} value={i}>
            {i === 0 ? "--" : i}
         </MenuItem>
      );
   }
   return result;
};

export const endingTurnOptions = getEndingTurnOptions();

const selectorsContainerSx: SxProps = {
   position: "relative",
   display: "flex",
   width: "100%",
   justifyContent: "space-around",
};

const endingTurnSx: SxProps = {
   width: "120px",
};

const winConditionSx: SxProps = {
   width: "180px",
};

const gameDescriptionSx: SxProps = { width: "80%" };

const logGamePageOptionalFullPanelButtonsContainerSx: SxProps = {
   position: "relative",
   width: "100%",
   display: "flex",
   justifyContent: "space-around",
};

const logGamePageOptionalGameDataFullPanelRootSx: SxProps = {
   position: "relative",
   width: "98vw",
   height: "100%",
   boxSizing: "border-box",
   display: "flex",
   justifyContent: "space-around",
   flexWrap: "wrap",
   alignContent: "space-around",
   overflow: "visible",
};

const LogGamePageOptionalGameDataFullPanel: React.FC<{
   game: MutableGame;
   showButtons?: boolean;
   readOnly: boolean;
}> = ({ game, readOnly, showButtons = true }) => {
   const theme = useTheme();
   const addMessageItem = useAddMessageItem();
   const [openDrawConfirm, setOpenDrawConfirm] = useState(false);

   const [, setSelectedFlexibleContainerIndex] = useGlobal<number | null>(
      GLOBAL_VAR_NAME.SELECTED_FLEXIBLE_CONTAINER_INDEX,
      null
   );

   const addToHighImpactCards = useCallback(
      (card: CardNameAndImage) => {
         const cardExists = game.highImpactCards.some((c) => c.name === card.name);
         if (!cardExists) {
            game.setHighImpactCards([...game.highImpactCards, card]);
         }
      },
      [game]
   );

   const removeFromHighImpactCards = useCallback(() => {
      if (game.highImpactCards.length > 0) {
         const result = [...game.highImpactCards];
         result.pop();
         game.setHighImpactCards(result);
      }
   }, [game]);

   const addToGameWonByCards = useCallback(
      (card: CardNameAndImage) => {
         const cardExists = game.gameWonBy.some((c) => c.name === card.name);
         if (!cardExists) {
            game.setGameWonBy([...game.gameWonBy, card]);
         }
      },
      [game]
   );

   const removeFromGameWonByCards = useCallback(() => {
      if (game.gameWonBy.length > 0) {
         const result = [...game.gameWonBy];
         result.pop();
         game.setGameWonBy(result);
      }
   }, [game]);

   const closeButtonHandler = useCallback(() => {
      setSelectedFlexibleContainerIndex(null);
   }, [setSelectedFlexibleContainerIndex]);

   const okDrawConfirm = useCallback(() => {
      game.saveGame();
   }, [game]);

   return (
      <Box id={"logGamePageOptionalGameDataFullPanel"} sx={logGamePageOptionalGameDataFullPanelRootSx}>
         <DrawGameConfirmDialog
            open={openDrawConfirm}
            onOk={okDrawConfirm}
            onCancel={() => setOpenDrawConfirm(false)}
         />
         <Box sx={selectorsContainerSx}>
            <FormControl size={"small"} variant={"outlined"} sx={endingTurnSx}>
               <InputLabel>Ending turn</InputLabel>
               <Select
                  readOnly={readOnly}
                  size={"small"}
                  IconComponent={() => null}
                  value={game.endingTurn}
                  label="Ending turn"
                  onChange={(event) => game.setEndingTurn(Number(event.target.value))}
               >
                  {endingTurnOptions}
               </Select>
            </FormControl>
            <FormControl size={"small"} sx={winConditionSx}>
               <InputLabel>Win condition used</InputLabel>
               <Select
                  readOnly={readOnly}
                  value={game.winType}
                  label="Win condition used"
                  onChange={(event) => game.setWinType(event.target.value as WinType)}
               >
                  <MenuItem key={"COMBO"} value={"COMBO"}>
                     Combo
                  </MenuItem>
                  <MenuItem key={"COMBAT_DAMAGE"} value={"COMBAT_DAMAGE"}>
                     Combat damage
                  </MenuItem>
                  <MenuItem key={"COMMANDER_DAMAGE"} value={"COMMANDER_DAMAGE"}>
                     Commander damage
                  </MenuItem>
                  <MenuItem key={"INFECT"} value={"INFECT"}>
                     Infect
                  </MenuItem>
                  <MenuItem key={"TIME_LIMIT"} value={"TIME_LIMIT"}>
                     Game time limit
                  </MenuItem>
                  <MenuItem key={"HARD_STAX_LOCK"} value={"HARD_STAX_LOCK"}>
                     Hard stax lock
                  </MenuItem>
                  <MenuItem key={"CONCEDE"} value={"CONCEDE"}>
                     All players conceded
                  </MenuItem>
               </Select>
            </FormControl>
         </Box>
         <LogGamePageOtherCardPlaceholder
            readOnly={readOnly}
            addCard={addToHighImpactCards}
            removeCard={removeFromHighImpactCards}
            cardsToDisplay={game.highImpactCards}
            title={"Game defining cards"}
         />
         <LogGamePageOtherCardPlaceholder
            readOnly={readOnly}
            addCard={addToGameWonByCards}
            removeCard={removeFromGameWonByCards}
            cardsToDisplay={game.gameWonBy}
            title={"Win con cards"}
         />
         <TextField
            label={"Game description"}
            disabled={readOnly}
            placeholder={"Type here..."}
            size={"small"}
            sx={gameDescriptionSx}
            multiline
            rows={6}
            value={game.description}
            onChange={(v) => v.target.value.length <= 2000 && game.setDescription(v.target.value)}
         />
         {showButtons && (
            <Box sx={logGamePageOptionalFullPanelButtonsContainerSx}>
               <Button variant={"outlined"} size={"medium"} onClick={closeButtonHandler}>
                  Close
               </Button>
               <Button
                  variant={"contained"}
                  size={"medium"}
                  onClick={() => {
                     if (!game.valid) {
                        addMessageItem(
                           "Game data not valid, please check the commander assignment and that all players have been assigned to users.",
                           "error",
                           6000
                        );
                     } else {
                        if (game.isDraw) {
                           setOpenDrawConfirm(true);
                        } else {
                           game.saveGame();
                        }
                     }
                  }}
               >
                  {game.existingFullGame != null ? "Save game" : "Create game"}
               </Button>
            </Box>
         )}
      </Box>
   );
};

export default LogGamePageOptionalGameDataFullPanel;
