import React, {MouseEventHandler, ReactNode, useCallback} from "react";
import {Box, Dialog, IconButton, Theme} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {SxProps} from "@mui/system";

const dialogSx: SxProps = {
    "& .MuiDialog-paper": {
        backgroundColor: "rgba(255,255,255,0.3)",
        backdropFilter: "blur(10px)",
    }
}

const containerBasicSx: SxProps = {
    width: "100vw",
    height: "100vh",
    position: "relative",
    overflow: "hidden",
};

const scrollContainerSx: SxProps = {
    width: "100vw",
    maxHeight: "100vh",
    position: "absolute",
    left: 0,
    top: 0,
    overflow: "auto",
};

const closeButtonSx: SxProps<Theme> = {
    position: "fixed",
    top: "2vmin",
    right: "2vmin",
    backgroundColor: (theme) => theme.palette.primary.contrastText,
    color: (theme) => theme.palette.primary.dark,
    transition: "background-color 0.3s, color 0.3s",
    ":hover": {
        backgroundColor: (theme) => theme.palette.primary.dark,
        color: (theme) => theme.palette.primary.contrastText,
    },
};

interface IBackdropContainerComponentProps {
    open: boolean;
    closeHandler: () => void;
    children?: ReactNode;
}

const BackdropContainerComponent: React.FC<IBackdropContainerComponentProps> = ({
    open,
    closeHandler,
    children,
}) => {
    const eventPropagationStopper: MouseEventHandler<HTMLDivElement> = useCallback((e) => e.stopPropagation(), []);

    return (
        <Dialog sx={dialogSx} open={open} fullScreen>
            <Box sx={containerBasicSx} onClick={eventPropagationStopper}>
                <Box sx={scrollContainerSx}>{children}</Box>
                <IconButton sx={closeButtonSx} onClick={closeHandler}>
                    <CloseIcon fontSize={"medium"}/>
                </IconButton>
            </Box>
        </Dialog>
    );
};

export default BackdropContainerComponent;
