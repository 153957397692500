import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Theme } from "@mui/material";
import { SxProps } from "@mui/system";
import { TPlayerIndex } from "../../TYPE";
import { CardExtendedInfo } from "../../cardFeatures/CardTypes";
import CardPickerComponent from "../../cardFeatures/CardPickerComponent";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { MutableGame } from "../MutableGame";

const heightOfLogGamePagePlayerPicker = "40px";
const logGamePageCardPlaceholderRatio = 0.7;

const logGamePageCommanderPlaceholderRootSx: SxProps = {
   boxSizing: "border-box",
   position: "absolute",
   left: 0,
   top: heightOfLogGamePagePlayerPicker,
   width: "100%",
   height: `calc(100% - ${heightOfLogGamePagePlayerPicker})`,
};

const logGamePageCardPlaceholderBasicSx: SxProps<Theme> = {
   position: "absolute",
   display: "flex",
   width: `${100 * logGamePageCardPlaceholderRatio}%`,
   height: `calc((100% + ${heightOfLogGamePagePlayerPicker}) * ${logGamePageCardPlaceholderRatio})`,
   cursor: "pointer",
   borderRadius: "4%",
   border: (theme) => "0.5vmin dashed " + theme.palette.borderColor,
   boxSizing: "border-box",
   transition: "left 1s, bottom 1s, transform 1s, border-color 0.3s",
   ":hover": {
      borderColor: (theme) => theme.palette.mainHoverColor,
      ".addIcon": {
         opacity: 0.6,
         transform: "scale(1.2,1.2)",
      },
      ".cardImg": {
         transform: "scale(1.05,1.05)",
      },
   },
};

const addIconSx: SxProps<Theme> = {
   margin: "auto",
   marginTop: "6%",
   opacity: 0.15,
   transform: "scale(1,1)",
   transition: "opacity 0.5s, transform 0.3s",
   color: (theme) => theme.palette.text.primary,
   pointerEvents: "none",
};

const imgSx: SxProps = {
   position: "absolute",
   left: 0,
   top: 0,
   width: "100%",
   borderRadius: "4%",
   transition: "transform 0.5s",
   boxShadow: "0 0 0.5vmin 0.1vmin rgba(0,0,0,1)",
};

interface ILogGamePageCommanderPlaceholder {
   playerIndex: TPlayerIndex;
   game: MutableGame;
}

const LogGamePageCommanderPlaceholder: React.FC<ILogGamePageCommanderPlaceholder> = ({ playerIndex, game }) => {
   const [commanderPair, setCommanderPair] =
      playerIndex === 1
         ? [game.player1CommanderPair, game.setPlayer1CommanderPair]
         : playerIndex === 2
         ? [game.player2CommanderPair, game.setPlayer2CommanderPair]
         : playerIndex === 3
         ? [game.player3CommanderPair, game.setPlayer3CommanderPair]
         : [game.player4CommanderPair, game.setPlayer4CommanderPair];

   const [showCardPicker, setShowCardPicker] = useState<"primary" | "secondary" | null>(null);
   const isPickingPrimaryCard = showCardPicker === "primary";
   const isPickingSecondCard = showCardPicker === "secondary";
   const primaryCardRequiresBackground = Boolean(commanderPair?.commander1?.requiresBackground);
   const shouldFilterCommanders = isPickingPrimaryCard || (isPickingSecondCard && !primaryCardRequiresBackground);
   const shouldFilterPartners =
      !isPickingPrimaryCard && !primaryCardRequiresBackground && !commanderPair?.commander1.friendsForever;
   const shouldFilterBackgrounds = !isPickingPrimaryCard && primaryCardRequiresBackground;
   const shouldFilterFriendsForever = !isPickingPrimaryCard && commanderPair?.commander1.friendsForever;

   useEffect(() => {}, [commanderPair?.commander1?.requiresBackground, showCardPicker]);

   const showSecondaryCardPlaceholder: boolean = useMemo(() => {
      if (commanderPair?.commander1) {
         return (
            commanderPair?.commander1.partner ||
            commanderPair?.commander1.requiresBackground ||
            commanderPair?.commander1.friendsForever
         );
      }
      return false;
   }, [commanderPair?.commander1]);

   const logGamePagePrimaryCardPlaceholderSx: SxProps<Theme> = useMemo(() => {
      return {
         ...logGamePageCardPlaceholderBasicSx,
         border: commanderPair?.commander1 ? "none" : logGamePageCardPlaceholderBasicSx.border,
         zIndex: 2,
         left: showSecondaryCardPlaceholder ? 0 : "50%",
         bottom: showSecondaryCardPlaceholder ? 0 : "50%",
         transform: showSecondaryCardPlaceholder ? "translate(0%, 0%)" : "translate(-50%, 50%)",
      };
   }, [commanderPair?.commander1, showSecondaryCardPlaceholder]);

   const logGamePageSecondaryCardPlaceholderSx: SxProps<Theme> = useMemo(() => {
      return {
         ...logGamePageCardPlaceholderBasicSx,
         border: commanderPair?.commander2 ? "none" : logGamePageCardPlaceholderBasicSx.border,
         zIndex: 1,
         right: 0,
         top: "1vh",
      };
   }, [commanderPair?.commander2]);

   const primaryCardPlaceholderClickHandler = useCallback(() => {
      setShowCardPicker("primary");
   }, []);

   const secondaryCardPlaceholderClickHandler = useCallback(() => {
      setShowCardPicker("secondary");
   }, []);

   const closeCardPickerHandler = useCallback(() => {
      setShowCardPicker(null);
   }, []);

   const selectCardHandler = useCallback(
      (card: CardExtendedInfo) => {
         if (showCardPicker !== null) {
            if (showCardPicker === "primary") {
               setCommanderPair({ commander1: card, commander2: null });
            } else if (showCardPicker === "secondary" && commanderPair) {
               setCommanderPair({ commander1: commanderPair.commander1, commander2: card });
            }
         }
      },
      [commanderPair, setCommanderPair, showCardPicker]
   );

   return (
      <Box id={`logGamePageCommanderPlaceholderRoot_${playerIndex}`} sx={logGamePageCommanderPlaceholderRootSx}>
         <Box
            id={`logGamePagePlayer${playerIndex}PrimaryCardPlaceholder`}
            sx={logGamePagePrimaryCardPlaceholderSx}
            onClick={primaryCardPlaceholderClickHandler}
         >
            {commanderPair?.commander1 ? (
               <Box sx={imgSx} className={"cardImg"} component="img" src={commanderPair?.commander1.largeImageUri} />
            ) : (
               <AddCircleOutlineIcon className={"addIcon"} sx={addIconSx} />
            )}
         </Box>
         {showSecondaryCardPlaceholder && (
            <Box
               id={`logGamePagePlayer${playerIndex}SecondaryCardPlaceholder`}
               sx={logGamePageSecondaryCardPlaceholderSx}
               onClick={secondaryCardPlaceholderClickHandler}
            >
               {commanderPair?.commander2 ? (
                  <Box sx={imgSx} className={"cardImg"} component="img" src={commanderPair?.commander2.largeImageUri} />
               ) : (
                  <AddCircleOutlineIcon className={"addIcon"} sx={addIconSx} />
               )}
            </Box>
         )}
         <CardPickerComponent
            showCardPicker={showCardPicker !== null}
            filterCommanders={shouldFilterCommanders}
            filterPartners={shouldFilterPartners}
            filterBackgrounds={shouldFilterBackgrounds}
            closeCardPickerHandler={closeCardPickerHandler}
            filterFriendsForever={Boolean(shouldFilterFriendsForever)}
            selectCardHandler={selectCardHandler}
            disallowedCards={[]}
         />
      </Box>
   );
};

export default LogGamePageCommanderPlaceholder;
