import FormControl from "@mui/material/FormControl";
import React, { useState } from "react";
import { Consumer, Procedure } from "../../utils";
import {
   Box,
   Button,
   Dialog,
   DialogActions,
   DialogContent,
   DialogTitle,
   InputLabel,
   MenuItem,
   Select,
   TextField,
   Typography,
   useTheme,
} from "@mui/material";
import { CardNameAndImage } from "../../cardFeatures/CardTypes";
import CardImageList from "./CardImageList";
import CardPickerComponent from "../../cardFeatures/CardPickerComponent";
import { WinType } from "../../TYPE";

export interface AdditionalStatsType {
   turns?: number;
   winType?: WinType;
   highImpactCards: Array<CardNameAndImage>;
   gameWonBy: Array<CardNameAndImage>;
}

export default function GameData({
   additionalStats,
   setAdditionalStats,
   readonly = false,
   description,
   setDescription,
   open,
   onClose,
}: {
   setAdditionalStats: Consumer<AdditionalStatsType>;
   additionalStats: AdditionalStatsType;
   description: string;
   setDescription: Consumer<string>;
   readonly?: boolean;
   open: boolean;
   onClose: Procedure;
}) {
   const theme = useTheme();
   const [showSelectHic, setShowHic] = useState(false);
   const [showGameWinSelector, setShowGameWinSelector] = useState(false);
   return (
      <Dialog
         open={open}
         aria-labelledby="alert-dialog-title"
         aria-describedby="alert-dialog-description"
         onKeyUp={(event) => {
            if (event.key === "Escape") {
               onClose();
            }
         }}
      >
         <DialogTitle id="alert-dialog-title">{"Game data"}</DialogTitle>
         <DialogContent>
            <FormControl margin={"normal"} sx={{ minWidth: 200, paddingLeft: theme.spacing(1) }}>
               <InputLabel id="demo-simple-select-label">Ending turn</InputLabel>
               <Select
                  readOnly={readonly}
                  labelId="demo-simple-select-label"
                  value={additionalStats.turns}
                  label="Ending turn"
                  onChange={(event) =>
                     setAdditionalStats({
                        ...additionalStats,
                        turns: parseInt(event.target.value + ""),
                     })
                  }
               >
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={6}>6</MenuItem>
                  <MenuItem value={7}>7</MenuItem>
                  <MenuItem value={8}>8</MenuItem>
                  <MenuItem value={9}>9</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={11}>11</MenuItem>
                  <MenuItem value={12}>12</MenuItem>
                  <MenuItem value={13}>13</MenuItem>
                  <MenuItem value={14}>14</MenuItem>
                  <MenuItem value={15}>15</MenuItem>
                  <MenuItem value={16}>16</MenuItem>
                  <MenuItem value={17}>17</MenuItem>
                  <MenuItem value={18}>18</MenuItem>
                  <MenuItem value={19}>19</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={21}>21</MenuItem>
                  <MenuItem value={22}>22</MenuItem>
                  <MenuItem value={23}>23</MenuItem>
                  <MenuItem value={24}>24</MenuItem>
                  <MenuItem value={25}>25</MenuItem>
               </Select>
            </FormControl>
            <FormControl size={"medium"} margin={"normal"} sx={{ minWidth: 200, paddingLeft: theme.spacing(1) }}>
               <InputLabel id="win_con_label">Win condition used</InputLabel>
               <Select
                  readOnly={readonly}
                  labelId="win_con_label"
                  value={additionalStats.winType}
                  label="Win condition used"
                  onChange={(event) =>
                     setAdditionalStats({
                        ...additionalStats,
                        winType: event.target.value as WinType,
                     })
                  }
               >
                  <MenuItem value={"COMBO"}>Combo</MenuItem>
                  <MenuItem value={"COMBAT_DAMAGE"}>Combat damage</MenuItem>
                  <MenuItem value={"COMMANDER_DAMAGE"}>Commander damage</MenuItem>
                  <MenuItem value={"INFECT"}>Infect</MenuItem>
                  <MenuItem value={"TIME_LIMIT"}>Game time limit</MenuItem>
                  <MenuItem value={"HARD_STAX_LOCK"}>Hard stax lock</MenuItem>
                  <MenuItem value={"CONCEDE"}>All players conceded</MenuItem>
               </Select>
            </FormControl>
            {!readonly || description != null ? (
               <>
                  <Typography variant={"h5"}>Description</Typography>
                  <TextField
                     color={"secondary"}
                     id={"description"}
                     contentEditable={!readonly}
                     sx={{ width: "80%", margin: theme.spacing(1) }}
                     multiline
                     rows={5}
                     value={description}
                     onChange={(v) => v.target.value.length <= 2000 && setDescription(v.target.value)}
                  />
               </>
            ) : null}
            <Box>
               <Typography variant={"h5"}>Game defining cards</Typography>
               {!readonly && (
                  <Button color={"secondary"} variant={"contained"} onClick={() => setShowHic(true)}>
                     Add game defining card
                  </Button>
               )}
               <CardPickerComponent
                  showCardPicker={showSelectHic}
                  closeCardPickerHandler={() => setShowHic(false)}
                  disallowedCards={[]}
                  selectCardHandler={(c) => {
                     // no duplicates
                     if (!additionalStats.highImpactCards.find((eC) => eC.id === c.id)) {
                        setAdditionalStats({
                           ...additionalStats,
                           highImpactCards: [...additionalStats.highImpactCards, c],
                        });
                     }
                  }}
                  filterPartners={false}
                  filterBackgrounds={false}
                  filterCommanders={false}
                  filterFriendsForever={false}
               />
               <CardImageList
                  editable={!readonly}
                  cards={additionalStats.highImpactCards}
                  onRemoveClick={(toRemove) =>
                     setAdditionalStats({
                        ...additionalStats,
                        highImpactCards: additionalStats.highImpactCards.filter((c) => toRemove.id !== c.id),
                     })
                  }
               />
            </Box>
            <Box>
               <Typography variant={"h5"}>Cards used in win condition</Typography>
               {!readonly && (
                  <Button color={"secondary"} variant={"contained"} onClick={() => setShowGameWinSelector(true)}>
                     Add win con card
                  </Button>
               )}
               <CardPickerComponent
                  showCardPicker={showGameWinSelector}
                  closeCardPickerHandler={() => setShowGameWinSelector(false)}
                  disallowedCards={[]}
                  selectCardHandler={(c) => {
                     // no duplicates
                     if (!additionalStats.gameWonBy.find((eC) => eC.id === c.id)) {
                        setAdditionalStats({
                           ...additionalStats,
                           gameWonBy: [...additionalStats.gameWonBy, c],
                        });
                     }
                  }}
                  filterPartners={false}
                  filterBackgrounds={false}
                  filterCommanders={false}
                  filterFriendsForever={false}
               />
               <CardImageList
                  editable={!readonly}
                  cards={additionalStats.gameWonBy}
                  onRemoveClick={(toRemove) =>
                     setAdditionalStats({
                        ...additionalStats,
                        gameWonBy: additionalStats.gameWonBy.filter((c) => toRemove.id !== c.id),
                     })
                  }
               />
            </Box>
         </DialogContent>
         <DialogActions>
            <Button onClick={onClose} autoFocus>
               Cancel
            </Button>
         </DialogActions>
      </Dialog>
   );
}
