import React from "react";
import { Paper, Popover, useTheme } from "@mui/material";
import { RegistrationSummaryWithTags } from "../../rest.client/useTournamentApi";
import { RemoteTournament } from "../useRemoteTournament";
import useReload from "../../header/useReload";
import TagChip from "../viewPage/tags/TagChip";
import { Procedure } from "../../utils";
import useRemoteRegistration from "./useRemoteRegistration";
import useAddMessageItem from "../../messagesComponent/useAddMessageItem";

export default function AddTagsPopover({
   remoteTournament,
   registrationWithTags,
   anchor,
   close,
}: {
   remoteTournament: RemoteTournament;
   registrationWithTags: RegistrationSummaryWithTags;
   anchor: HTMLButtonElement | null;
   close: Procedure;
}) {
   const theme = useTheme();
   const showMessage = useAddMessageItem();
   const { requestReload } = useReload("registration");
   const tags = registrationWithTags.tags;
   const remoteReg = useRemoteRegistration(remoteTournament, registrationWithTags);
   const targetTags = (remoteTournament.tags || []).filter((t) => !tags.some((existingTag) => existingTag.id === t.id));

   const open = Boolean(anchor);
   const id = open ? "simple-popover" : undefined;

   return (
      <Popover
         id={id}
         open={open}
         anchorEl={anchor}
         onClose={close}
         anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
         }}
      >
         <Paper
            elevation={6}
            sx={{
               padding: theme.spacing(2),
               display: "flex",
               flexWrap: "wrap",
               maxWidth: "900px",
               gap: theme.spacing(1),
            }}
         >
            {targetTags.map((tag) => (
               <TagChip
                  size={"medium"}
                  tag={tag}
                  key={tag.id}
                  onClick={async () => {
                     await remoteReg.addTag(tag);
                     showMessage(tag.name + " added!", "success");
                     requestReload();
                  }}
               />
            ))}
         </Paper>
      </Popover>
   );
}
