import React, { SyntheticEvent, useCallback, useMemo, useState } from "react";
import { Autocomplete, Box, InputAdornment, TextField } from "@mui/material";
import { SxProps } from "@mui/system";
import { useApplicationApi, UserSummary } from "../rest.client/useApplicationApi";
import { Maybe, TPlayerIndex } from "../TYPE";
import { ComponentMode } from "../UI_CONST";
import WinnerIcon from "./WinnerIcon";
import { Procedure } from "../utils";
import { useNavigate } from "react-router-dom";
import { toPlayerInsights } from "../navPaths";
import ShowStatsButton from "./ShowStatsButton";

const playerSearchFieldBasicSx: SxProps = {
   margin: "auto",
   marginBottom: "0",
   width: "100%",
   "& input": {
      caretColor: "red",
      WebkitTextFillColor: "red",
   },
};

const playersContainerSx: SxProps = {
   width: "100%",
   position: "relative",
   display: "flex",
   flexDirection: "column",
   margin: "auto",
   paddingTop: "5px",
};

interface IPlayerPickerComponentProps {
   playerIndex: TPlayerIndex;
   playerColor: string;
   playerSummary: Maybe<UserSummary>;
   selectPlayerHandler: (summary: UserSummary) => void;
   otherPlayers: Array<UserSummary>;
   mode: ComponentMode;
   setWinner: Procedure;
   wonGame: boolean;
   hideWinnerSelector?: boolean;
   hideAnonymousSelector?: boolean;
   labelText?: string;
   showStatsButton?: boolean;
}

export const ANONYMOUS_USER_ID = "E1Ht8efkzyO1GcwbedHmwGvnHXB3";

const anonymousUser: UserSummary = {
   id: ANONYMOUS_USER_ID,
   name: "Anonymous",
   shortTag: "anon",
};

const PlayerPickerComponent: React.FC<IPlayerPickerComponentProps> = ({
   playerIndex,
   playerColor,
   selectPlayerHandler,
   playerSummary,
   otherPlayers,
   mode,
   setWinner,
   wonGame,
   hideWinnerSelector,
   hideAnonymousSelector,
   labelText,
   showStatsButton,
}) => {
   const {
      findUserByNameStart: { call: findUsers, responseData },
   } = useApplicationApi();

   const [searchActiveTimeout, setSearchActiveTimeout] = useState<Maybe<number>>(null);
   const [processingInput, setProcessingInput] = useState(false);
   const nav = useNavigate();

   const playerSearchFieldSx: SxProps = useMemo(
      () => ({
         ...playerSearchFieldBasicSx,
         "& input": {
            caretColor: playerColor,
            WebkitTextFillColor: playerColor,
         },
      }),
      [playerColor]
   );

   const textFieldSx: SxProps = useMemo(
      () => ({
         // pointerEvents: mode === ComponentMode.EDIT ? "auto" : "none",
         color: playerColor,
         "& label": {
            color: playerColor,
            "&.Mui-focused": {
               color: playerColor,
            },
         },
         "& MuiOutlinedInput-root": {
            backgroundColor: "red",
         },
      }),
      [playerColor]
   );

   const userSearchHandler = useCallback(
      (ev: SyntheticEvent, value: string) => {
         if (searchActiveTimeout != null) {
            clearTimeout(searchActiveTimeout);
            setSearchActiveTimeout(null);
         }
         if (value.length >= 2) {
            setProcessingInput(true);
            const timeOutId = window.setTimeout(() => {
               findUsers({ queryParams: { name: value, tag: value } }).finally(() => {
                  setProcessingInput(false);
               });
            }, 500);
            setSearchActiveTimeout(timeOutId);
         }
      },
      [searchActiveTimeout, findUsers]
   );

   const usersToShow: Array<UserSummary> = useMemo(
      () =>
         responseData != null
            ? responseData._embedded.users.filter((u) => otherPlayers.find((oP) => oP && oP.id === u.id) == null)
            : [],
      [otherPlayers, responseData]
   );

   const winnerSettingIcon = useMemo(
      () =>
         playerSummary ? (
            <InputAdornment position="end">
               {showStatsButton && (
                  <ShowStatsButton
                     onClick={() => {
                        playerSummary && nav(toPlayerInsights(playerSummary.id));
                     }}
                  />
               )}
               <WinnerIcon won={wonGame} onClick={setWinner} readonly={mode === "VIEW"} />
            </InputAdornment>
         ) : (
            <></>
         ),
      [mode, nav, playerSummary, setWinner, showStatsButton, wonGame]
   );

   const onChange = useCallback(
      (event: SyntheticEvent, val: Maybe<UserSummary>) => {
         val && selectPlayerHandler(val);
      },
      [selectPlayerHandler]
   );

   const emptyResult = (
      <div style={{ cursor: "pointer" }} onClick={() => selectPlayerHandler(anonymousUser)}>
         Set anonymous
      </div>
   );

   return (
      <Box sx={playersContainerSx}>
         <Autocomplete
            forcePopupIcon={false}
            size={"small"}
            onInputChange={userSearchHandler}
            readOnly={mode === ComponentMode.VIEW}
            onChange={onChange}
            options={usersToShow}
            value={playerSummary}
            getOptionLabel={(option) => option.name + "/" + option.shortTag}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            loading={processingInput}
            noOptionsText={hideAnonymousSelector ? "Start typing user name" : emptyResult}
            sx={playerSearchFieldSx}
            clearOnBlur={true}
            renderInput={(params) => (
               <TextField
                  sx={textFieldSx}
                  label={labelText || `Player ${playerIndex}`}
                  color={"secondary"}
                  {...params}
                  InputProps={{
                     ...params.InputProps,
                     endAdornment: hideWinnerSelector ? null : winnerSettingIcon,
                  }}
               />
            )}
         />
      </Box>
   );
};

export default PlayerPickerComponent;
