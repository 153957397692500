import React from "react";
import { DEFAULT_SWISS_SETTINGS, RemoteStage } from "../stageView/useRemoteStage";
import AdditionalPointsOptions from "./AdditionalPointsOptions";
import DynamicNumberField from "./DynamicNumberField";
import TieBreakerSystemDropdown from "./TieBreakerSystemDropdown";
import { ByeAllocationStrategy, getUserFriendlyNameForByeAllocator } from "../../../rest.client/useStagesApi";
import { MenuItem, TextField, useTheme } from "@mui/material";

export default function SwissOptions({
   remoteStage,
   setError,
}: {
   remoteStage: RemoteStage;
   setError: (key: string, isInError: boolean) => void;
}) {
   const theme = useTheme();
   const { saveRequest, setSaveRequest } = remoteStage;
   const swissSettings = saveRequest.swissSettings;

   return (
      <>
         <TextField
            sx={{ marginTop: theme.spacing(1) }}
            fullWidth
            required
            select
            variant={"outlined"}
            label={"Bye allocation"}
            value={saveRequest.swissSettings?.byeAllocationStrategy}
            onChange={(e) => {
               const selection = e.target.value as ByeAllocationStrategy;
               setSaveRequest({
                  ...saveRequest,
                  swissSettings:
                     swissSettings != null
                        ? {
                             ...swissSettings,
                             byeAllocationStrategy: selection,
                          }
                        : null,
               });
            }}
            inputProps={{ maxLength: 100 }}
         >
            <MenuItem key={""} value={"MAMTR"}>
               {getUserFriendlyNameForByeAllocator("MAMTR")}
            </MenuItem>
            <MenuItem key={""} value={"MSTR_REGULAR_REL"}>
               {getUserFriendlyNameForByeAllocator("MSTR_REGULAR_REL")}
            </MenuItem>
            <MenuItem key={""} value={"MSTR_COMPETITIVE_REL"}>
               {getUserFriendlyNameForByeAllocator("MSTR_COMPETITIVE_REL")}
            </MenuItem>
            <MenuItem key={""} value={"REDUCED_BYES"}>
               {getUserFriendlyNameForByeAllocator("REDUCED_BYES")}
            </MenuItem>
         </TextField>
         <DynamicNumberField
            setError={setError}
            errorMapKey={"seedCountKey"}
            label={"Max player count"}
            isError={(num) => num < 4}
            onChange={(seedCount) => {
               if (seedCount >= 4) {
                  setSaveRequest({
                     ...saveRequest,
                     swissSettings:
                        saveRequest.swissSettings != null
                           ? {
                                ...saveRequest.swissSettings,
                                seedCountLimit: seedCount,
                             }
                           : null,
                  });
               }
            }}
            value={saveRequest.swissSettings?.seedCountLimit}
         />
         <TieBreakerSystemDropdown
            tiebreakerSystem={saveRequest.swissSettings?.tiebreakerSystem || DEFAULT_SWISS_SETTINGS.tiebreakerSystem}
            onChange={(e) => {
               setSaveRequest({
                  ...saveRequest,
                  swissSettings:
                     saveRequest.swissSettings != null
                        ? {
                             ...saveRequest.swissSettings,
                             tiebreakerSystem: e,
                          }
                        : null,
               });
            }}
            setError={setError}
         />
         <DynamicNumberField
            label={"Points for win"}
            errorMapKey={"winPoints"}
            setError={setError}
            onChange={(points) => {
               if (points >= 0) {
                  setSaveRequest({
                     ...saveRequest,
                     swissSettings:
                        saveRequest.swissSettings != null
                           ? {
                                ...saveRequest.swissSettings,
                                winPoints: points,
                             }
                           : null,
                  });
               }
            }}
            value={saveRequest.swissSettings?.winPoints || 0}
         />
         <DynamicNumberField
            label={"Points for loss"}
            errorMapKey={"lossPoints"}
            setError={setError}
            isError={(num) => num > 0}
            onChange={(points) => {
               if (points <= 0) {
                  setSaveRequest({
                     ...saveRequest,
                     swissSettings:
                        saveRequest.swissSettings != null
                           ? {
                                ...saveRequest.swissSettings,
                                lossPoints: points,
                             }
                           : null,
                  });
               }
            }}
            value={saveRequest.swissSettings?.lossPoints}
         />
         <DynamicNumberField
            label={"Points for draw"}
            errorMapKey={"drawPoints"}
            setError={setError}
            onChange={(points) => {
               if (points >= 0) {
                  setSaveRequest({
                     ...saveRequest,
                     swissSettings:
                        saveRequest.swissSettings != null
                           ? {
                                ...saveRequest.swissSettings,
                                drawPoints: points,
                             }
                           : null,
                  });
               }
            }}
            value={saveRequest.swissSettings?.drawPoints}
         />
         <DynamicNumberField
            label={"Points for bye"}
            errorMapKey={"byePoints"}
            setError={setError}
            onChange={(points) => {
               if (points >= 0) {
                  setSaveRequest({
                     ...saveRequest,
                     swissSettings:
                        saveRequest.swissSettings != null
                           ? {
                                ...saveRequest.swissSettings,
                                byePoints: points,
                             }
                           : null,
                  });
               }
            }}
            value={saveRequest.swissSettings?.byePoints}
         />
         <AdditionalPointsOptions
            setError={setError}
            additionalPoints={{
               secondPlacePoints: swissSettings?.secondPlacePoints || 0,
               thirdPlacePoints: swissSettings?.thirdPlacePoints || 0,
               fourthPlacePoints: swissSettings?.fourthPlacePoints || 0,
               matchWinPercentageFloor: swissSettings?.matchWinPercentageFloor || 0.2,
            }}
            onChangePoints={(additionalSettings) =>
               setSaveRequest({
                  ...saveRequest,
                  swissSettings:
                     saveRequest.swissSettings != null
                        ? {
                             ...saveRequest.swissSettings,
                             ...additionalSettings,
                          }
                        : null,
               })
            }
         />
      </>
   );
}
