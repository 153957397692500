import React, { useMemo } from "react";
import { Box } from "@mui/material";
import { SxProps } from "@mui/system";
import useGlobal from "../../global/useGlobal";
import { GLOBAL_VAR_NAME } from "../../UI_CONST";

const connectorLineRootBasicSx: SxProps = {
   boxSizing: "border-box",
   width: "1px",
   boxShadow: `0 0 2px 0.1px rgba(0,0,0,0.8)`,
   backgroundColor: "rgba(0,0,0,0.5)",
   transition: "flex-grow 0.5s",
   minHeight: "1vh",
};

const ConnectorLine: React.FC = () => {
   const [selectedFlexibleContainerIndex] = useGlobal<number | null>(
      GLOBAL_VAR_NAME.SELECTED_FLEXIBLE_CONTAINER_INDEX,
      null
   );

   const noContainerSelected = useMemo(() => selectedFlexibleContainerIndex === null, [selectedFlexibleContainerIndex]);

   const connectorLineRootSx: SxProps = useMemo(() => {
      return {
         ...connectorLineRootBasicSx,
         flexGrow: noContainerSelected ? 1 : 0,
      };
   }, [noContainerSelected]);

   return <Box id={"connectorLineRoot"} sx={connectorLineRootSx} />;
};

export default ConnectorLine;
