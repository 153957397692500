import { useCallback, useMemo, useState } from "react";
import { Supplier } from "../../../utils";
import { MatchFullResponse, RoundFullResponse } from "../../../rest.client/useStagesApi";
import { EditRoundRequest, useRoundApi } from "../../../rest.client/useRoundApi";
import useReload from "../../../header/useReload";
import { RegistrationSummary } from "../../../rest.client/useTournamentApi";
import { RemoteStage } from "./useRemoteStage";
import dayjs from "dayjs";
import { useMatchesApi } from "../../../rest.client/useMatchesApi";

export interface RemoteRound {
   round: RoundFullResponse;
   activate: Supplier<Promise<any>>;
   close: Supplier<Promise<any>>;
   deleteRound: Supplier<Promise<any>>;
   deleteMatch: (matchId: string) => Promise<any>;
   getByes: Supplier<Promise<RegistrationSummary[]>>;
   addBye: (regId: string) => Promise<RoundFullResponse>;
   deleteBye: (regId: string) => Promise<void>;
   byes: RegistrationSummary[];
   loadByes: Supplier<Promise<void>>;
   canAddByes: boolean;
   loading: boolean;
   canDelete: boolean;
   isActive: boolean;
   canPair: boolean;
   canDeleteMatch: boolean;
   canCloseRound: boolean;
   edit: (data: EditRoundRequest) => Promise<void>;
   secondsUntilTimerEnd: number;
   autoPair: Supplier<Promise<RoundFullResponse>>;
   createMatch: Supplier<Promise<MatchFullResponse>>;
}

export default function useRemoteRound(remoteStage: RemoteStage, round: RoundFullResponse): RemoteRound {
   const {
      activateRound: { call: activateRound },
      closeRound: { call: closeRound },
      deleteRound: { call: deleteRoundCall },
      getByes: { call: getByesCall },
      addBye: { call: addByeCall },
      deleteBye: { call: deleteByeCall },
      autoPair: { call: autoPairCall },
      editRound: { call: editRound },
      loading,
   } = useRoundApi();
   const {
      createMatch: { call: createMatchCall },
      deleteMatch: { call: deleteMatchCall },
   } = useMatchesApi();
   const { requestReload } = useReload();
   const canAddByes = remoteStage.canEdit && round.status === "ACTIVE";
   const maxRoundNum = remoteStage.stage != null ? Math.max(...remoteStage.stage.rounds.map((r) => r.roundNumber)) : 0;
   const canDelete = round.roundNumber === maxRoundNum && round.status !== "CLOSED";
   const isActive = round.status === "ACTIVE";
   const canPair = isActive;
   const canCloseRound = round.status === "ACTIVE" && round.matches.every((m) => m.matchStatus === "APPROVED");
   const [byes, setByes] = useState<RegistrationSummary[]>([]);
   const secondsUntilTimerEnd =
      round.roundEndDate != null ? dayjs(new Date(round.roundEndDate)).diff(new Date(), "seconds") : 0;
   const canDeleteMatch = remoteStage.canEdit && round.status !== "CLOSED";

   const getByes = useCallback(async () => {
      const byes = await getByesCall({ pathParams: { id: round.id } });
      return byes._embedded.registrations;
   }, [getByesCall, round.id]);

   const loadByes = useCallback(
      () =>
         getByes().then((byes) => {
            setByes(byes);
         }),
      [getByes]
   );

   const activate = useCallback(async () => {
      await activateRound({ pathParams: { id: round.id } });
      requestReload();
   }, [activateRound, requestReload, round.id]);

   const createMatch = useCallback(async () => {
      const res = await createMatchCall({ body: { roundId: round.id } });
      requestReload();
      return res;
   }, [createMatchCall, requestReload, round.id]);

   const deleteMatch = useCallback(
      async (matchId: string) => {
         await deleteMatchCall({ pathParams: { id: matchId } });
         requestReload();
      },
      [deleteMatchCall, requestReload]
   );

   const close = useCallback(async () => {
      await closeRound({ pathParams: { id: round.id } });
      requestReload();
   }, [closeRound, requestReload, round.id]);

   const edit = useCallback(
      async (inlineRequestData: EditRoundRequest) => {
         await editRound({ pathParams: { id: round.id }, body: inlineRequestData });
         requestReload();
      },
      [editRound, requestReload, round.id]
   );

   const deleteRound = useCallback(async () => {
      await deleteRoundCall({ pathParams: { id: round.id } });
      requestReload();
   }, [deleteRoundCall, requestReload, round.id]);

   const addBye = useCallback(
      async (regId: string) => {
         const res = await addByeCall({ pathParams: { id: round.id }, body: { registrationId: regId } });
         await loadByes();
         return res;
      },
      [addByeCall, loadByes, round.id]
   );

   const deleteBye = useCallback(
      async (regId: string) => {
         await deleteByeCall({ pathParams: { registrationId: regId, id: round.id } });
         await loadByes();
      },
      [deleteByeCall, loadByes, round.id]
   );

   const autoPair = useCallback(async () => {
      const res = await autoPairCall({ pathParams: { id: round.id } });
      requestReload();
      return res;
   }, [autoPairCall, requestReload, round.id]);

   return useMemo(
      () => ({
         addBye,
         getByes,
         isActive,
         deleteMatch,
         loading,
         canAddByes,
         secondsUntilTimerEnd,
         deleteRound,
         loadByes,
         edit,
         canCloseRound,
         activate,
         deleteBye,
         byes,
         close,
         createMatch,
         canDeleteMatch,
         round,
         canPair,
         canDelete,
         autoPair,
      }),
      [
         addBye,
         getByes,
         isActive,
         deleteMatch,
         loading,
         canAddByes,
         secondsUntilTimerEnd,
         deleteRound,
         loadByes,
         edit,
         canCloseRound,
         activate,
         deleteBye,
         byes,
         close,
         createMatch,
         canDeleteMatch,
         round,
         canPair,
         canDelete,
         autoPair,
      ]
   );
}
