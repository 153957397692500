import React, { CSSProperties, useEffect, useMemo, useState } from "react";
import {
   Avatar,
   Box,
   FormControl,
   FormControlLabel,
   Paper,
   Radio,
   RadioGroup,
   Table,
   TableBody,
   TableCell,
   TableContainer,
   TableHead,
   TableRow,
   Typography,
   useMediaQuery,
} from "@mui/material";
import useCurrentUser from "../../auth/useCurrentUser";
import { useApplicationApi } from "../../rest.client/useApplicationApi";
import { useNavigate } from "react-router-dom";
import { numberFormat } from "../../gameFeatures/viewGamePage/PlayerCard";
import RankRender from "../../gameFeatures/RankRender";
import { HOME_PAGE_TABLE_RADIO_BUTTON_MY_RANK_VALUE, HOME_PAGE_TABLE_RADIO_BUTTON_TOP_VALUE } from "../../UI_CONST";
import { toPlayerInsights } from "../../navPaths";
import ScrollableBorderedWrapper from "../../common/ScrollableBorderedWrapper";
import InsightsSelectorBanner from "../InsightsSelectorBanner";

const userShortTagSx: CSSProperties = {
   fontStyle: "italic",
   fontSize: 12,
   display: "inline-block",
};

const LeaderBoardPage: React.FC = () => {
   const userData = useCurrentUser();
   const nav = useNavigate();
   const isMobileDevice = !useMediaQuery("(min-width:600px)");

   const {
      findAllRankedUsersStats: { call: findTopUsers, responseData: allUsers },
      findUserStatsById: { call: findUserStatsById, responseData: myUserStats },
      findAllUsersEloLessThan: { call: findAllUsersEloLessThan, responseData: usersLess },
      findAllUsersEloGreaterThan: { call: findAllUsersEloGreaterThan, responseData: usersGreater },
   } = useApplicationApi();

   const [showMyPosition, setShowMyPosition] = useState(false);

   const myPosUsers = useMemo(
      () =>
         usersGreater != null && usersLess != null && myUserStats != null
            ? [...usersGreater?._embedded.users, myUserStats, ...usersLess?._embedded.users]
            : [],
      [usersGreater, usersLess, myUserStats]
   );

   const usersToShow = useMemo(
      () => (showMyPosition ? myPosUsers : allUsers?._embedded.users),
      [allUsers?._embedded.users, myPosUsers, showMyPosition]
   );

   const myIndex = useMemo(() => myUserStats && myPosUsers.indexOf(myUserStats, 0), [myPosUsers, myUserStats]);

   useEffect(() => {
      findTopUsers({ queryParams: { sort: "elo,desc", page: 0, size: 25 } }).then();
   }, [findTopUsers]);

   useEffect(() => {
      userData?.user &&
         !showMyPosition &&
         findUserStatsById({
            pathParams: { id: userData.user.uid },
            queryParams: { projection: "statsAndRankPosition" },
         });
   }, [findUserStatsById, showMyPosition, userData]);

   useEffect(() => {
      if (showMyPosition && myUserStats) {
         findAllUsersEloLessThan({
            queryParams: {
               elo: myUserStats.elo,
               page: 0,
               size: 5,
               projection: "stats",
               sort: "elo,desc",
            },
         }).then();
         findAllUsersEloGreaterThan({
            queryParams: {
               elo: myUserStats.elo,
               page: 0,
               size: 5,
               projection: "stats",
               sort: "elo,desc",
            },
         }).then();
      }
   }, [findAllUsersEloGreaterThan, findAllUsersEloLessThan, myUserStats, showMyPosition]);

   const handleRadioButtonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = (event.target as HTMLInputElement).value;
      setShowMyPosition(value === HOME_PAGE_TABLE_RADIO_BUTTON_MY_RANK_VALUE);
   };
   return (
      <ScrollableBorderedWrapper>
         <InsightsSelectorBanner />
         <TableContainer component={Paper} sx={{}}>
            <Table size={isMobileDevice ? "small" : "medium"} stickyHeader={true}>
               <TableHead>
                  <TableRow>
                     <TableCell id={"tableTitleCell"} align="center" colSpan={5}>
                        <Box id={"tableTitleContentWrapper"}>
                           <Typography>{`Top Players By Elo`}</Typography>
                           {userData.loggedIn && (
                              <FormControl>
                                 <RadioGroup
                                    row
                                    onChange={handleRadioButtonChange}
                                    value={
                                       showMyPosition
                                          ? HOME_PAGE_TABLE_RADIO_BUTTON_MY_RANK_VALUE
                                          : HOME_PAGE_TABLE_RADIO_BUTTON_TOP_VALUE
                                    }
                                 >
                                    <FormControlLabel
                                       value={HOME_PAGE_TABLE_RADIO_BUTTON_MY_RANK_VALUE}
                                       control={<Radio size={"small"} />}
                                       label={HOME_PAGE_TABLE_RADIO_BUTTON_MY_RANK_VALUE}
                                    />
                                    <FormControlLabel
                                       value={HOME_PAGE_TABLE_RADIO_BUTTON_TOP_VALUE}
                                       control={<Radio size={"small"} />}
                                       label={HOME_PAGE_TABLE_RADIO_BUTTON_TOP_VALUE}
                                    />
                                 </RadioGroup>
                              </FormControl>
                           )}
                        </Box>
                     </TableCell>
                  </TableRow>
                  <TableRow>
                     <TableCell>#</TableCell>
                     <TableCell>Username</TableCell>
                     <TableCell>Elo</TableCell>
                     {!isMobileDevice && <TableCell>Winrate</TableCell>}
                     <TableCell>Rank</TableCell>
                  </TableRow>
               </TableHead>
               <TableBody>
                  {usersToShow &&
                     usersToShow.map((user, index) => {
                        //because we need to base the positions on where the current user is
                        const position =
                           showMyPosition && myIndex != null && myUserStats?.rankingsPosition != null
                              ? myUserStats.rankingsPosition + index - myIndex
                              : index + 1;
                        return (
                           <TableRow
                              sx={{
                                 cursor: "pointer",
                                 "&:nth-of-type(even)": {
                                    backgroundColor: "rgba(0,0,0,0.1)",
                                 },
                                 border: "none",
                              }}
                              key={user.id}
                              onClick={() => nav(toPlayerInsights(user.id))}
                           >
                              <TableCell
                                 sx={{
                                    fontWeight: "bold",
                                 }}
                              >
                                 {position}
                              </TableCell>
                              <TableCell sx={{ display: "flex", alignItems: "center" }}>
                                 <Avatar src={user.photoUrl} />
                                 <Typography sx={{}}>
                                    {`${user.name} `}
                                    <span style={userShortTagSx}>{`/ ${user.shortTag}`}</span>
                                 </Typography>
                              </TableCell>
                              <TableCell>{numberFormat.format(user.elo)}</TableCell>
                              {!isMobileDevice && <TableCell>{numberFormat.format(user.winPercent)}%</TableCell>}
                              <TableCell>
                                 <RankRender rank={user.rank} />
                              </TableCell>
                           </TableRow>
                        );
                     })}
               </TableBody>
            </Table>
         </TableContainer>
      </ScrollableBorderedWrapper>
   );
};

export default LeaderBoardPage;
