import { Box, Paper, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { GameSummary } from "../../../rest.client/useApplicationApi";
import CompactMultiPlayerCell from "../../../gameFeatures/myGamesPage/CompactMultiPlayerCell";
import { Consumer } from "../../../utils";
import { RemoteMatch } from "../stageView/useRemoteMatch";
import GameControlsRow from "./GameControlsRow";
import GameStatusBar from "./GameStatusBar";

export default function GameListItem({
   game,
   onClick,
   remoteMatch,
}: React.PropsWithChildren<{
   game: GameSummary;
   onClick?: Consumer<GameSummary>;
   remoteMatch: RemoteMatch;
}>) {
   const nav = useNavigate();
   const theme = useTheme();
   const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));
   const medUp = useMediaQuery(theme.breakpoints.up("md"));
   return (
      <Paper elevation={2} sx={{ display: "flex", flexDirection: "column", padding: theme.spacing(1) }}>
         <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
            <GameControlsRow game={game} remoteMatch={remoteMatch} requestEditGame={onClick} />
            <GameStatusBar game={game} remoteMatch={remoteMatch} />
         </Box>
         <Box onClick={onClick && (() => onClick(game))} sx={{ cursor: "pointer" }}>
            <CompactMultiPlayerCell game={game} sx={{ justifyContent: "space-evenly" }} />
         </Box>
      </Paper>
   );
}
