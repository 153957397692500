import React, { useState } from "react";
import { Box, Paper, useTheme } from "@mui/material";
import { RemoteTournament } from "../useRemoteTournament";
import { RegistrationSummary } from "../../rest.client/useTournamentApi";
import { MatchResultWinnerOnlyResponse, StageFullResponse } from "../../rest.client/useStagesApi";
import MatchesTable from "../viewPage/matches/MatchesTable";
import useRemoteStage from "../viewPage/stageView/useRemoteStage";
import { empty } from "../../utils";
import MatchDialog from "../viewPage/matches/MatchDialog";
import { Maybe } from "../../TYPE";
import StageStatusBanner from "../viewPage/stageView/StageStatusBanner";
import LeagueStandingsTable from "../viewPage/stageView/standings/LeagueStandingsTable";
import SingleEliminationStandingsTable from "../viewPage/stageView/singleElimination/SingleEliminationStandingsTable";

export default function RegistrationStageDetails({
   remoteTournament,
   registration,
   stage,
}: {
   remoteTournament: RemoteTournament;
   registration: RegistrationSummary;
   stage: StageFullResponse;
}) {
   const theme = useTheme();
   const allMatches = stage.rounds.flatMap((r) => r.matches);
   const remoteStage = useRemoteStage(remoteTournament, stage.id);
   const [selectedMatch, setSelectedMatch] = useState<Maybe<MatchResultWinnerOnlyResponse>>();
   const matchesForUser = allMatches.filter((m) => m.matchResults.some((mR) => mR.registration.id === registration.id));

   return (
      <Paper>
         <Box sx={{ marginBottom: theme.spacing(2) }}>
            <StageStatusBanner stage={stage} />
         </Box>
         <Box sx={{ marginBottom: theme.spacing(2) }}>
            {!stage.singleEliminationStage && (
               <LeagueStandingsTable
                  stage={stage}
                  title={"Player standings:"}
                  showPos={false}
                  standings={stage.standings.filter((s) => s.registration.id === registration.id)}
               />
            )}
            {stage.singleEliminationStage && (
               <SingleEliminationStandingsTable
                  title={"Player standings:"}
                  standings={stage.standings.filter((s) => s.registration.id === registration.id)}
               />
            )}
         </Box>
         <Box sx={{ marginBottom: theme.spacing(2) }}>
            <MatchesTable
               matches={matchesForUser}
               remoteStage={remoteStage}
               onClickMatch={(m) => setSelectedMatch(m)}
               onClose={empty}
               showNumber={true}
            />
         </Box>
         {selectedMatch && (
            <MatchDialog
               remoteStage={remoteStage}
               matchId={selectedMatch.id}
               open={true}
               onClose={() => setSelectedMatch(null)}
            />
         )}
      </Paper>
   );
}
