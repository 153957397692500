import React, { useCallback, useState } from "react";
import {
   Button,
   Dialog,
   DialogActions,
   DialogContent,
   DialogTitle,
   MenuItem,
   TextField,
   useTheme,
} from "@mui/material";
import { Procedure } from "../../../utils";
import useRemoteStage, {
   DEFAULT_LEAGUE_SETTINGS,
   DEFAULT_SINGLE_ELIMINATION_SETTINGS,
   DEFAULT_SWISS_SETTINGS,
} from "../stageView/useRemoteStage";
import { RemoteTournament } from "../../useRemoteTournament";
import SwissOptions from "./SwissOptions";
import LeagueOptions from "./LeagueOptions";
import SingleEliminationOptions from "./SingleEliminationOptions";
import {
   AutoPairStrategy,
   getUserFriendlyNameForAutoPair,
   getUserFriendlyNameForPodOrder,
   PodOrderStrategy,
} from "../../../rest.client/useStagesApi";

const textFieldStyle = { marginTop: "10px" };

export default function CreateOrEditStageDialog({
   open,
   close,
   remoteTournament,
   id,
}: {
   open: boolean;
   close: Procedure;
   remoteTournament: RemoteTournament;
   id?: string;
}) {
   const remoteStage = useRemoteStage(remoteTournament, id);
   const theme = useTheme();
   const { save, saveRequest, setSaveRequest } = remoteStage;
   const [fieldErrorMap, setFieldErrorMap] = useState<Record<string, boolean>>({});
   const showAutoSettings =
      remoteStage.saveRequest.singleEliminationSettings != null || remoteStage.saveRequest.swissSettings != null;

   const setError = useCallback((key: string, error: boolean) => {
      setFieldErrorMap((map) => {
         const newMap = { ...map };
         newMap[key] = error;
         return newMap;
      });
   }, []);

   return (
      <Dialog
         open={open}
         maxWidth={"md"}
         fullWidth={true}
         aria-labelledby="alert-dialog-title"
         aria-describedby="alert-dialog-description"
         onKeyUp={(event) => {
            if (event.key === "Escape") {
               close();
            }
         }}
      >
         <DialogTitle id="alert-dialog-title">{id == null ? "Add new stage" : "Edit stage"}</DialogTitle>
         <DialogContent sx={{ display: "flex", flexDirection: "column", gap: theme.spacing(1) }}>
            <TextField
               sx={textFieldStyle}
               fullWidth
               required
               variant={"outlined"}
               label={"Name"}
               onChange={(event) => setSaveRequest({ ...saveRequest, name: event.target.value })}
               value={saveRequest.name}
               inputProps={{ maxLength: 100 }}
            />
            <TextField
               sx={textFieldStyle}
               fullWidth
               required
               select
               disabled={id != null}
               variant={"outlined"}
               label={"Type"}
               value={
                  saveRequest.singleEliminationSettings != null
                     ? "SINGLE_ELIMINATION"
                     : saveRequest.unstructuredLeagueSettings != null
                     ? "LEAGUE"
                     : "SWISS"
               }
               onChange={(e) => {
                  const selection = e.target.value as string;
                  setFieldErrorMap({});
                  if (selection === "SWISS") {
                     setSaveRequest({
                        ...saveRequest,
                        swissSettings: DEFAULT_SWISS_SETTINGS,
                        unstructuredLeagueSettings: null,
                        singleEliminationSettings: null,
                     });
                  } else if (selection === "LEAGUE") {
                     setSaveRequest({
                        ...saveRequest,
                        swissSettings: null,
                        singleEliminationSettings: null,
                        unstructuredLeagueSettings: DEFAULT_LEAGUE_SETTINGS,
                     });
                  } else if (selection === "SINGLE_ELIMINATION") {
                     setSaveRequest({
                        ...saveRequest,
                        singleEliminationSettings: DEFAULT_SINGLE_ELIMINATION_SETTINGS,
                        unstructuredLeagueSettings: null,
                        swissSettings: null,
                     });
                  }
               }}
               inputProps={{ maxLength: 100 }}
            >
               <MenuItem key={""} value={"LEAGUE"}>
                  League
               </MenuItem>
               <MenuItem key={""} value={"SWISS"}>
                  Swiss
               </MenuItem>
               <MenuItem key={""} value={"SINGLE_ELIMINATION"}>
                  Single elimination (Knockout)
               </MenuItem>
            </TextField>
            {showAutoSettings && (
               <TextField
                  sx={textFieldStyle}
                  fullWidth
                  required
                  select
                  variant={"outlined"}
                  label={"Pod pairing strategy"}
                  value={saveRequest?.autoPairStrategy}
                  onChange={(e) => {
                     const selection = e.target.value as AutoPairStrategy;
                     setSaveRequest({
                        ...saveRequest,
                        autoPairStrategy: selection,
                     });
                  }}
                  inputProps={{ maxLength: 100 }}
               >
                  <MenuItem key={""} value={"RANDOM"}>
                     {getUserFriendlyNameForAutoPair("RANDOM")}
                  </MenuItem>
                  <MenuItem key={""} value={"ELO_BASED_NON_GROUPED"}>
                     {getUserFriendlyNameForAutoPair("ELO_BASED_NON_GROUPED")}
                  </MenuItem>
                  <MenuItem key={""} value={"ELO_BASED_GROUPED"}>
                     {getUserFriendlyNameForAutoPair("ELO_BASED_GROUPED")}
                  </MenuItem>
                  <MenuItem key={""} value={"STANDINGS_BASED_NON_GROUPED"}>
                     {getUserFriendlyNameForAutoPair("STANDINGS_BASED_NON_GROUPED")}
                  </MenuItem>
                  <MenuItem key={""} value={"STANDINGS_BASED_GROUPED"}>
                     {getUserFriendlyNameForAutoPair("STANDINGS_BASED_GROUPED")}
                  </MenuItem>
                  <MenuItem key={""} value={"STANDINGS_PREVIOUS_STAGE_NON_GROUPED"}>
                     {getUserFriendlyNameForAutoPair("STANDINGS_PREVIOUS_STAGE_NON_GROUPED")}
                  </MenuItem>
                  <MenuItem key={""} value={"STANDINGS_PREVIOUS_STAGE_GROUPED"}>
                     {getUserFriendlyNameForAutoPair("STANDINGS_PREVIOUS_STAGE_GROUPED")}
                  </MenuItem>
               </TextField>
            )}
            {showAutoSettings && (
               <TextField
                  sx={textFieldStyle}
                  fullWidth
                  required
                  select
                  variant={"outlined"}
                  label={"Player seat order strategy"}
                  value={saveRequest.podOrderingStrategy}
                  onChange={(e) => {
                     const selection = e.target.value as PodOrderStrategy;
                     setSaveRequest({
                        ...saveRequest,
                        podOrderingStrategy: selection,
                     });
                  }}
                  inputProps={{ maxLength: 100 }}
               >
                  <MenuItem key={""} value={"RANDOM"}>
                     {getUserFriendlyNameForPodOrder("RANDOM")}
                  </MenuItem>
                  <MenuItem key={""} value={"PREVIOUS_STAGE_STANDINGS"}>
                     {getUserFriendlyNameForPodOrder("PREVIOUS_STAGE_STANDINGS")}
                  </MenuItem>
                  <MenuItem key={""} value={"OVERALL_TOURNAMENT_STANDINGS"}>
                     {getUserFriendlyNameForPodOrder("OVERALL_TOURNAMENT_STANDINGS")}
                  </MenuItem>
               </TextField>
            )}
            {saveRequest.swissSettings && <SwissOptions remoteStage={remoteStage} setError={setError} />}
            {saveRequest.unstructuredLeagueSettings && <LeagueOptions remoteStage={remoteStage} setError={setError} />}
            {saveRequest.singleEliminationSettings && (
               <SingleEliminationOptions setError={setError} remoteStage={remoteStage} />
            )}
         </DialogContent>
         <DialogActions>
            <Button
               onClick={async () => {
                  await save();
                  close();
               }}
               disabled={Object.values(fieldErrorMap).some((v) => v)}
               color={"secondary"}
            >
               Save
            </Button>
            <Button onClick={close} autoFocus>
               Cancel
            </Button>
         </DialogActions>
      </Dialog>
   );
}
