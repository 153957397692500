import React from "react";
import {
   Alert,
   Button,
   Dialog,
   DialogActions,
   DialogContent,
   DialogContentText,
   DialogTitle,
   useTheme,
} from "@mui/material";
import { Procedure } from "../utils";

export default function DeleteGameConfirmDialog({
   open,
   onOk,
   onCancel,
}: {
   open: boolean;
   onOk: Procedure;
   onCancel: Procedure;
}) {
   const theme = useTheme();
   return (
      <Dialog
         open={open}
         aria-labelledby="alert-dialog-title"
         aria-describedby="alert-dialog-description"
         onKeyUp={(event) => {
            if (event.key === "Escape") {
               onCancel();
            }
         }}
      >
         <DialogTitle id="alert-dialog-title">{"Delete game?"}</DialogTitle>
         <DialogContent>
            <DialogContentText id="alert-dialog-description">
               <Alert severity={"warning"}>
                  Deleting a game will permanently remove the record of the game but will <b>NOT</b> roll back Elo
                  allocation previously applied. Are you sure you want to delete this game?
               </Alert>
            </DialogContentText>
         </DialogContent>
         <DialogActions>
            <Button onClick={onOk} color={"secondary"}>
               Ok
            </Button>
            <Button onClick={onCancel} autoFocus>
               Cancel
            </Button>
         </DialogActions>
      </Dialog>
   );
}
