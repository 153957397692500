import {useCallback, useEffect, useMemo} from "react";
import useGlobal from "../global/useGlobal";

/**
 * Handy thing to tell the app to reload any data from the server
 * */
export default function useReload(key?: string) {
   const [reload, setReloadData] = useGlobal<boolean>(key != null ? key : "reloadAppData", true);

   useEffect(() => {
      setReloadData(false);
   }, [setReloadData, reload]);

   const requestReload = useCallback(() => {
      setReloadData(true);
   }, [setReloadData]);

   return useMemo(() => ({ reload, requestReload }), [reload, requestReload]);
}
