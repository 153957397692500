import { Alert, Box, useTheme } from "@mui/material";
import React from "react";
import { GameSummary } from "../../../rest.client/useApplicationApi";
import GameListItem from "./GameListItem";
import { Consumer } from "../../../utils";
import { RemoteMatch } from "../stageView/useRemoteMatch";

export default function GamesList({
   games,
   onClickGame,
   remoteMatch,
}: React.PropsWithChildren<{
   games: Array<GameSummary>;
   onClickGame?: Consumer<GameSummary>;
   remoteMatch: RemoteMatch;
}>) {
   const theme = useTheme();
   return (
      <Box sx={{ display: "flex", flexDirection: "column", gap: theme.spacing(1) }}>
         {games
            .sort((g1, g2) => g2.createDate.localeCompare(g1.createDate))
            .map((g) => (
               <GameListItem remoteMatch={remoteMatch} key={g.id} game={g} onClick={onClickGame} />
            ))}
         {games.length === 0 && <Alert severity={"info"}>No games in match!</Alert>}
      </Box>
   );
}
