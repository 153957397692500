import React, { ReactNode, useEffect, useMemo, useState } from "react";
import { Consumer, isNumeric } from "../../../utils";
import TextField, { TextFieldProps } from "@mui/material/TextField/TextField";

const textFieldStyle = { marginTop: "10px" };
export default function DynamicNumberField({
   onChange,
   textFieldProps,
   value,
   isError = (num) => num < 0,
   label,
   setError,
   errorMapKey,
}: {
   value?: number;
   onChange: Consumer<number>;
   textFieldProps?: TextFieldProps;
   label: ReactNode;
   isError?: (num: number) => boolean;
   setError: (key: string, isInError: boolean) => void;
   errorMapKey: string;
}) {
   const [valueSave, setValueSave] = useState(value != null ? value + "" : "");

   const error = useMemo(() => !isNumeric(valueSave) || isError(Number(valueSave)), [isError, valueSave]);

   useEffect(() => {
      setError(errorMapKey, error);
   }, [error, errorMapKey, setError]);

   return (
      <TextField
         sx={textFieldStyle}
         fullWidth
         label={label}
         required
         variant={"outlined"}
         error={error}
         type={"number"}
         onChange={(event) => {
            const points = Number(event.target.value);
            onChange(points);
            setValueSave(event.target.value);
         }}
         value={valueSave}
         {...textFieldProps}
      />
   );
}
