import React, { useCallback, useState } from "react";
import { Box, Button, IconButton, TableCell, TableRow, TextField, Typography, useTheme } from "@mui/material";
import { RemoteTournament } from "../../useRemoteTournament";
import { PrizeTierFullResp, useTournamentApi } from "../../../rest.client/useTournamentApi";
import TierSingleDialog from "./TierSingleDialog";
import EditIcon from "@mui/icons-material/Edit";

export default function TierRow({
   remoteTournament,
   tier,
   showDelete,
}: {
   remoteTournament: RemoteTournament;
   tier: PrizeTierFullResp;
   showDelete: boolean;
}) {
   const {
      deletePrizeTier: { call: deleteTier },
      updatePrizeTier: { call: updateTier },
   } = useTournamentApi();
   const theme = useTheme();
   const [open, setOpen] = useState(false);
   const [edit, setEdit] = useState(false);
   const [name, setName] = useState(tier.name);

   const saveTier = useCallback(async () => {
      remoteTournament.id &&
         (await updateTier({
            pathParams: { id: remoteTournament.id, tierNum: tier.tier },
            body: { name },
         }));
      remoteTournament.loadData();
      setEdit(false);
   }, [name, remoteTournament, tier.tier, updateTier]);

   return (
      <>
         <TableRow sx={{ cursor: "pointer" }} key={tier.tier} onClick={() => setOpen(true)}>
            <TableCell>{tier.tier}</TableCell>
            <TableCell>
               <Box sx={{ display: "flex", alignItems: "center", gap: theme.spacing(1) }}>
                  {!edit && <Typography>{tier.name}</Typography>}
                  {!edit && remoteTournament.isOrganiser && (
                     <IconButton
                        color={"primary"}
                        onClick={(e) => {
                           e.stopPropagation();
                           setEdit(true);
                        }}
                     >
                        <EditIcon />
                     </IconButton>
                  )}
                  {edit && (
                     <>
                        <TextField
                           onClick={(e) => e.stopPropagation()}
                           value={name}
                           onChange={(e) => setName(e.target.value)}
                           onKeyUp={async (e) => {
                              if (e.key === "Enter") {
                                 await saveTier();
                              }
                           }}
                        ></TextField>
                        <Button
                           onClick={async (e) => {
                              e.stopPropagation();
                              await saveTier();
                           }}
                        >
                           Save
                        </Button>
                     </>
                  )}
               </Box>
            </TableCell>
            <TableCell>
               <Button onClick={() => setOpen(true)}>View</Button>
               {showDelete && (
                  <Button
                     onClick={async (e) => {
                        e.stopPropagation();
                        remoteTournament.tournament &&
                           (await deleteTier({
                              pathParams: {
                                 id: remoteTournament.tournament.id,
                                 tierNumber: tier.tier,
                              },
                           }));
                        remoteTournament.loadData();
                     }}
                  >
                     Delete
                  </Button>
               )}
            </TableCell>
         </TableRow>
         <TierSingleDialog
            open={open}
            onClose={() => setOpen(false)}
            remoteTournament={remoteTournament}
            prizeTier={tier}
         />
      </>
   );
}
