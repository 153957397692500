import { UserSummary } from "../rest.client/useApplicationApi";
import { ANONYMOUS_USER_ID } from "../gameFeatures/PlayerPickerComponent";
import { CommanderPair } from "../cardFeatures/CardTypes";
import { Maybe } from "../TYPE";

export const useSetPlayerAndDefaultCommander = (
   findDefaultCommanders: (requestData: { pathParams: { id: string } }) => Promise<any>,
   playerCommanderPair: Maybe<CommanderPair>,
   setPlayerCommanderPair: (commanderPair: CommanderPair) => void,
   setPlayerSummary: (summary: UserSummary) => void
) => {
   return (summary: UserSummary) => {
      if (playerCommanderPair == null && summary.id !== ANONYMOUS_USER_ID) {
         findDefaultCommanders({ pathParams: { id: summary.id } }).then((commanderDefaultData) => {
            if (commanderDefaultData && commanderDefaultData.defaultCommanderPair != null) {
               setPlayerCommanderPair(commanderDefaultData.defaultCommanderPair);
            }
         });
      }
      setPlayerSummary(summary);
   };
};
