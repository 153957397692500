import React from "react";
import {
   Alert,
   Paper,
   Table,
   TableBody,
   TableCell,
   TableContainer,
   TableHead,
   TableRow,
   Typography,
} from "@mui/material";
import { StandingsRegResponse } from "../../../../rest.client/useStagesApi";
import StandingStatusAlert from "../standings/StandingStatusAlert";

export default function SingleEliminationStandingsTable({
   standings,
   title,
}: {
   standings: StandingsRegResponse[];
   title?: string;
}) {
   return (
      <Paper sx={{ width: "100%", position: "relative" }}>
         <Typography variant={"h5"}>{title || "All standings:"}</Typography>
         <TableContainer component={Paper}>
            <Table sx={{}} aria-label="simple table">
               <TableHead>
                  <TableRow>
                     <TableCell>Name</TableCell>
                     <TableCell>Wins</TableCell>
                     <TableCell>Losses</TableCell>
                     <TableCell>Status</TableCell>
                  </TableRow>
               </TableHead>
               <TableBody>
                  {standings.map((standing) => {
                     return (
                        <TableRow key={standing.registration.id} sx={{ cursor: "pointer" }}>
                           <TableCell>{standing.registration.user.name}</TableCell>
                           <TableCell>{standing.standing.wins}</TableCell>
                           <TableCell>{standing.standing.losses}</TableCell>
                           <TableCell>
                              <StandingStatusAlert standing={standing} />
                           </TableCell>
                        </TableRow>
                     );
                  })}
               </TableBody>
            </Table>
            {standings.length === 0 && <Alert severity={"info"}>No standings data found.</Alert>}
         </TableContainer>
      </Paper>
   );
}
