import { useMemo } from "react";
import { useAxiosInstance } from "./useAxiosInstance";
import useRequest from "./useRequest";
import { MatchFullResponse, MatchOverrideSummary, SaveOverrideRequest } from "./useStagesApi";

export function useMatchesApi() {
   const instance = useAxiosInstance();

   const api = {
      createMatch: useRequest<MatchFullResponse, never, SaveMatchRequest, never>(
         useMemo(
            () => ({
               url: "/matches/",
               method: "post",
            }),
            []
         ),
         instance
      ),
      deleteMatch: useRequest<never, { id: string }, never, never>(
         useMemo(
            () => ({
               url: "/matches/{id}/delete",
               method: "delete",
            }),
            []
         ),
         instance
      ),
      getMatchFull: useRequest<MatchFullResponse, { id: string }, never, never>(
         useMemo(
            () => ({
               url: "/matches/{id}?projection=full",
               method: "get",
            }),
            []
         ),
         instance
      ),
      saveMatchResultOverride: useRequest<MatchOverrideSummary, { id: string }, SaveOverrideRequest, never>(
         useMemo(
            () => ({
               url: "/matches/{id}/matchResultOverrides",
               method: "post",
            }),
            []
         ),
         instance
      ),
      deleteOverride: useRequest<never, { id: string; regId: string }, never, never>(
         useMemo(
            () => ({
               url: "/matches/{id}/matchResultOverrides/{regId}",
               method: "delete",
            }),
            []
         ),
         instance
      ),
   };
   const loading = Object.values(api).some((r) => Boolean(r.loading));
   return { ...api, loading };
}

export interface SaveMatchRequest {
   roundId: string;
}
