import React, { useEffect, useState } from "react";
import {
   Box,
   Button,
   Dialog,
   DialogActions,
   DialogContent,
   DialogTitle,
   FormControl,
   InputLabel,
   MenuItem,
   Select,
   useTheme,
} from "@mui/material";
import { Procedure } from "../../../utils";
import useReload from "../../../header/useReload";
import { RemoteGame } from "../../../gameFeatures/useRemoteGame";
import { PlayerSummary } from "../../../rest.client/useApplicationApi";

export default function ResultSubmitDialog({
   open,
   onClose,
   remoteGame,
}: {
   open: boolean;
   onClose: Procedure;
   remoteGame: RemoteGame;
}) {
   const theme = useTheme();
   const { requestReload } = useReload();
   const gameResult = remoteGame.winner || "DRAW";
   const [currentResult, setCurrentResult] = useState<PlayerSummary | "DRAW">(gameResult);

   useEffect(() => {
      setCurrentResult(gameResult);
   }, [gameResult]);

   return (
      <Dialog
         open={open}
         aria-labelledby="alert-dialog-title"
         aria-describedby="alert-dialog-description"
         maxWidth={"sm"}
         fullWidth={true}
         sx={{ minWidth: "200px" }}
         onKeyUp={(event) => {
            if (event.key === "Escape") {
               onClose();
            }
         }}
      >
         <DialogTitle id="alert-dialog-title">Submit result</DialogTitle>
         <DialogContent>
            <Box sx={{ padding: theme.spacing(1) }}>
               <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Winner</InputLabel>
                  <Select
                     labelId="demo-simple-select-label"
                     fullWidth={true}
                     value={currentResult === "DRAW" ? currentResult : currentResult.id}
                     label="Winner"
                     onChange={(res) => {
                        const player = remoteGame.allPlayers.find((p) => p.id === res.target.value);
                        setCurrentResult(player || "DRAW");
                     }}
                  >
                     <MenuItem value={"DRAW"}>Draw</MenuItem>
                     {remoteGame.allPlayers.map((p, i) => (
                        <MenuItem value={p.id}>{`Player ${i + 1} (${p.user.name})`}</MenuItem>
                     ))}
                  </Select>
               </FormControl>
            </Box>
         </DialogContent>
         <DialogActions>
            <Button
               color={"secondary"}
               onClick={async () => {
                  await remoteGame.submitResult(currentResult === "DRAW" ? null : currentResult.id);
                  requestReload();
                  onClose();
               }}
            >
               Ok
            </Button>
            <Button
               onClick={() => {
                  onClose();
               }}
            >
               Cancel
            </Button>
         </DialogActions>
      </Dialog>
   );
}
