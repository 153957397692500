import React, { useEffect } from "react";
import { SliderPicker } from "react-color";
import {
   Box,
   Button,
   Dialog,
   DialogActions,
   DialogContent,
   DialogTitle,
   FormLabel,
   TextField,
   useMediaQuery,
   useTheme,
} from "@mui/material";
import { Procedure } from "../../../utils";
import useAddMessageItem from "../../../messagesComponent/useAddMessageItem";
import useRemoteTag from "./useRemoteTag";
import useReload from "../../../header/useReload";

export default function CreateOrEditTagDialog({
   open,
   close,
   tournamentId,
   tagId,
}: {
   open: boolean;
   close: Procedure;
   tournamentId: string;
   tagId?: string;
}) {
   const theme = useTheme();
   const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));
   const reload = useReload("tags");
   const addMessageItem = useAddMessageItem();
   const remoteTag = useRemoteTag(tournamentId, tagId);

   useEffect(() => {
      if (open && tagId && remoteTag.tag == null) {
         remoteTag.loadData();
      }
   }, [open, remoteTag, tagId]);

   return (
      <Dialog
         open={open}
         fullScreen={smallScreen}
         maxWidth={"sm"}
         fullWidth={true}
         aria-labelledby="alert-dialog-title"
         aria-describedby="alert-dialog-description"
         onKeyUp={(event) => {
            if (event.key === "Escape") {
               close();
            }
         }}
      >
         <DialogTitle id="alert-dialog-title">Create tag</DialogTitle>
         <DialogContent>
            <Box sx={{ display: "flex", flexDirection: "column", gap: theme.spacing(2), margin: theme.spacing(1) }}>
               <TextField
                  fullWidth
                  required
                  variant={"outlined"}
                  label={"Name"}
                  onChange={(event) =>
                     remoteTag.setSaveTagData({
                        ...remoteTag.tagSaveData,
                        name: event.target.value,
                     })
                  }
                  value={remoteTag.tagSaveData.name || ""}
                  inputProps={{ maxLength: 60 }}
               />
               <TextField
                  fullWidth
                  required
                  variant={"outlined"}
                  label={"Description"}
                  onChange={(event) =>
                     remoteTag.setSaveTagData({
                        ...remoteTag.tagSaveData,
                        description: event.target.value,
                     })
                  }
                  value={remoteTag.tagSaveData.description || ""}
                  inputProps={{ maxLength: 255 }}
               />
               <FormLabel>{"Tag color"}</FormLabel>
               <SliderPicker
                  color={remoteTag.tagSaveData.color}
                  onChange={(ev) => remoteTag.setSaveTagData({ ...remoteTag.tagSaveData, color: ev.hex })}
               />
            </Box>
         </DialogContent>
         <DialogActions>
            <Button
               color={"secondary"}
               disabled={remoteTag.tagSaveData.name === ""}
               onClick={async () => {
                  await remoteTag.save();
                  addMessageItem("Tag saved!", "success");
                  reload.requestReload();
                  close();
               }}
            >
               Save
            </Button>
            <Button
               onClick={() => {
                  close();
               }}
               autoFocus
            >
               Close
            </Button>
         </DialogActions>
      </Dialog>
   );
}
