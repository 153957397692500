import React from "react";
import { Paper, useTheme } from "@mui/material";
import Markdown from "react-markdown";
import { SxProps } from "@mui/system";

export default function MarkdownDescriptionDisplay({ description, sx }: { description: string; sx?: SxProps }) {
   const theme = useTheme();

   return (
      <Paper
         elevation={4}
         sx={{
            padding: theme.spacing(1),
            overflow: "auto",
            maxHeight: "600px",
            minWidth: 200,
            width: "100%",
            ...sx,
         }}
      >
         <Markdown>{description}</Markdown>
      </Paper>
   );
}
