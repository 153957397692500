import { useCallback, useEffect, useMemo, useState } from "react";
import { RemoteTournament } from "../useRemoteTournament";
import { RegistrationFull, RegistrationSummary, useTournamentApi } from "../../rest.client/useTournamentApi";
import { Maybe } from "../../TYPE";
import { CommanderPair } from "../../cardFeatures/CardTypes";
import { SnapshotSummary } from "../../rest.client/useDeckListApi";
import { Consumer, isPairValid, Supplier } from "../../utils";
import { TagSummary } from "../../rest.client/useTagsApi";

export interface RemoteRegistration {
   setAdditionalDetails: Consumer<string>;
   setCommanderPair: Consumer<CommanderPair>;
   setSnap: Consumer<SnapshotSummary>;
   additionalDetails: Maybe<string>;
   commanderPair: Maybe<CommanderPair>;
   loadData: Supplier<Promise<void>>;
   lockDecklist: Supplier<Promise<void>>;
   unlockDecklist: Supplier<Promise<void>>;
   addTag: (tag: TagSummary) => Promise<void>;
   removeTag: (tag: TagSummary) => Promise<void>;
   snap: Maybe<SnapshotSummary>;
   save: Supplier<Promise<any>>;
   valid: boolean;
   registrationFull: Maybe<RegistrationFull>;
}

export default function useRemoteRegistration(
   remoteTournament: RemoteTournament,
   targetRegistration?: RegistrationSummary
): RemoteRegistration {
   const {
      updateRegistration,
      registerForTournament,
      lockDecklist: { call: lockDecklistCall },
      addTag: { call: addTagCall },
      removeTag: { call: removeTagCall },
      unlockDecklist: { call: unlockDecklistCall },
      getRegistrationFullById: { call: getRegistrationFullById, responseData: registrationFull },
   } = useTournamentApi();
   const regToLoad = targetRegistration == null ? remoteTournament.tournament?.myRegistration : targetRegistration;
   const targetId = regToLoad?.id;
   const [additionalDetails, setAdditionalDetails] = useState<Maybe<string>>(null);
   const [commanderPair, setCommanderPair] = useState<Maybe<CommanderPair>>(null);
   const [snap, setSnap] = useState<Maybe<SnapshotSummary>>();

   const valid = Boolean(commanderPair && isPairValid(commanderPair));

   const loadData = useCallback(async () => {
      targetId && (await getRegistrationFullById({ pathParams: { id: targetId } }));
   }, [getRegistrationFullById, targetId]);

   const lockDecklist = useCallback(async () => {
      targetId && (await lockDecklistCall({ pathParams: { id: targetId } }));
   }, [lockDecklistCall, targetId]);

   const unlockDecklist = useCallback(async () => {
      targetId && (await unlockDecklistCall({ pathParams: { id: targetId } }));
   }, [targetId, unlockDecklistCall]);

   const addTag = useCallback(
      async (tag: TagSummary) => {
         targetId && (await addTagCall({ pathParams: { id: targetId, tagId: tag.id } }));
      },
      [addTagCall, targetId]
   );

   const removeTag = useCallback(
      async (tag: TagSummary) => {
         targetId && (await removeTagCall({ pathParams: { id: targetId, tagId: tag.id } }));
      },
      [removeTagCall, targetId]
   );

   useEffect(() => {
      if (registrationFull) {
         setAdditionalDetails(registrationFull.additionalDetails);
         setCommanderPair({ commander1: registrationFull.commander1, commander2: registrationFull.commander2 });
         setSnap(registrationFull.snapshot);
      }
   }, [registrationFull]);

   const save = useCallback(async () => {
      if (regToLoad && commanderPair) {
         await updateRegistration.call({
            pathParams: { id: regToLoad.id },
            body: {
               snapshotId: snap?.id,
               commander2Id: commanderPair?.commander2?.id,
               commander1Id: commanderPair.commander1.id,
               additionalDetails: additionalDetails,
            },
         });
      } else if (commanderPair && remoteTournament.tournament) {
         await registerForTournament.call({
            body: {
               snapshotId: snap?.id,
               tournamentId: remoteTournament.tournament.id,
               commander2Id: commanderPair?.commander2?.id,
               commander1Id: commanderPair.commander1.id,
               additionalDetails: additionalDetails,
            },
         });
      }
   }, [
      additionalDetails,
      commanderPair,
      regToLoad,
      registerForTournament,
      remoteTournament.tournament,
      snap?.id,
      updateRegistration,
   ]);

   return useMemo(
      () => ({
         snap,
         commanderPair,
         lockDecklist,
         removeTag,
         addTag,
         unlockDecklist,
         additionalDetails,
         loadData,
         setAdditionalDetails,
         setSnap,
         setCommanderPair,
         registrationFull,
         valid,
         save,
      }),
      [
         snap,
         addTag,
         removeTag,
         commanderPair,
         lockDecklist,
         unlockDecklist,
         additionalDetails,
         loadData,
         registrationFull,
         valid,
         save,
      ]
   );
}
