import { GameStatus, PlayerFull, useApplicationApi, UserSummary } from "../../rest.client/useApplicationApi";
import React, { CSSProperties, SyntheticEvent, useCallback, useEffect, useState } from "react";
import {
   Autocomplete,
   Button,
   CardActions,
   CardContent,
   List,
   ListItem,
   ListItemText,
   TextField,
   Theme,
   Typography,
   useTheme,
} from "@mui/material";
import { SxProps } from "@mui/system";
import { GiChessQueen } from "react-icons/gi";
import { MdCheckCircleOutline, MdError, MdWarning } from "react-icons/md";
import RankRender from "../RankRender";
import { Maybe } from "../../TYPE";
import useCurrentUser from "../../auth/useCurrentUser";

export const numberFormat = new Intl.NumberFormat(undefined, { maximumFractionDigits: 0 });

function PlayerCard({
   winningPlayer,
   setWinningUser,
   otherPlayers,
   label,
   user,
   player,
   setUser,
   rootStyle,
   approveGame,
   gameStatus,
   disputeGame,
   editable = true,
}: {
   otherPlayers: Array<UserSummary | null | undefined>;
   winningPlayer: UserSummary | null | undefined;
   setWinningUser: (user: UserSummary) => void;
   gameStatus?: GameStatus;
   label: string;
   player?: PlayerFull;
   setUser: (user: UserSummary | null) => void;
   user: Maybe<UserSummary>;
   approveGame?: () => void;
   disputeGame?: () => void;
   rootStyle?: CSSProperties;
   editable: boolean;
}) {
   const userData = useCurrentUser();
   const {
      findUserByNameStart: { call: findUsers, responseData, requestsSent },
      findUserStatsById: { call: findStats, responseData: stats },
   } = useApplicationApi();

   const [activeTimeout, setActiveTimeout] = useState<number | null>(null);
   const [processingInput, setProcessingInput] = useState(false);
   const [wantsToEdit, setWantsToEdit] = useState(false);
   const won: boolean = winningPlayer?.id === user?.id;
   const isLoggedInPlayer = userData?.user?.uid === user?.id;
   const isApprovedFromThisPlayer = player && player.status === "APPROVED";
   const pendingThisPlayer = player && player.status === "PENDING";
   const disputedThisPlayer = player && player.status === "DISPUTED";
   const needsApprovalFromMe = isLoggedInPlayer && !isApprovedFromThisPlayer;
   const canDispute = gameStatus !== "APPROVED" && isLoggedInPlayer && disputeGame && player?.status !== "DISPUTED";
   const theme = useTheme();

   const editing = wantsToEdit || user == null;

   useEffect(() => {
      if (user) {
         findStats({ pathParams: { id: user.id } });
      }
   }, [findStats, user]);

   const users =
      responseData != null
         ? responseData._embedded.users.filter((u) => otherPlayers.find((oP) => oP && oP.id === u.id) == null)
         : [];

   const autoSx: SxProps<Theme> = { padding: (theme) => theme.spacing(1) };

   const searchHandler = useCallback(
      (ev: SyntheticEvent, value: string) => {
         if (activeTimeout != null) {
            clearTimeout(activeTimeout);
            setActiveTimeout(null);
         }
         if (value.length >= 2) {
            setProcessingInput(true);
            const timeOutId = window.setTimeout(() => {
               findUsers({ queryParams: { name: value, tag: value } }).finally(() => {
                  setProcessingInput(false);
               });
            }, 500);
            setActiveTimeout(timeOutId);
         }
      },
      [activeTimeout, findUsers]
   );

   const selectPlayer = useCallback(
      (event: SyntheticEvent, val: UserSummary | null) => {
         setUser(val);
         setWantsToEdit(false);
      },
      [setUser]
   );

   const startEdit = useCallback(() => {
      setWantsToEdit(true);
      setUser(null);
   }, [setUser]);

   return (
      <div style={rootStyle}>
         {editing && editable && (
            <div>
               <Autocomplete
                  onInputChange={searchHandler}
                  disablePortal
                  options={users || []}
                  getOptionLabel={(option) => option.name + "/" + option.shortTag}
                  loading={processingInput}
                  onChange={selectPlayer}
                  noOptionsText={requestsSent === 0 ? "Keep typing..." : "No results"}
                  renderOption={(props, option) => (
                     <li {...props}>
                        {option.name}
                        <span style={{ color: "gray", fontStyle: "italic" }}>/{option.shortTag}</span>
                     </li>
                  )}
                  sx={autoSx}
                  renderInput={(params) => <TextField {...params} label={label} />}
               />
            </div>
         )}
         {(!editing || !editable) && (
            <>
               <CardContent>
                  <Typography sx={{ fontSize: 16 }} color="text.secondary" gutterBottom>
                     {label}
                     {!editable && (
                        <>
                           {isApprovedFromThisPlayer && (
                              <MdCheckCircleOutline
                                 style={{ color: theme.palette.success.main, marginLeft: theme.spacing(1) }}
                              />
                           )}
                           {pendingThisPlayer && (
                              <MdWarning style={{ color: theme.palette.warning.main, marginLeft: theme.spacing(1) }} />
                           )}
                           {disputedThisPlayer && (
                              <MdError style={{ color: theme.palette.error.main, marginLeft: theme.spacing(1) }} />
                           )}
                        </>
                     )}
                  </Typography>
                  <Typography>
                     <span style={{ color: won ? "orange" : "black", fontWeight: "bold" }}>
                        {user?.name}
                        <span style={{ color: won ? "orange" : "gray", fontStyle: "italic" }}>/{user?.shortTag}</span>
                     </span>
                     {won && <GiChessQueen style={{ color: "orange", marginLeft: theme.spacing(1) }} />}
                  </Typography>
                  <List dense={true}>
                     <ListItem>
                        <ListItemText primary={stats != null && <RankRender rank={stats?.rank} />} secondary={"Rank"} />
                     </ListItem>
                     <ListItem>
                        <ListItemText primary={numberFormat.format(stats?.elo || 0)} secondary={"elo"} />
                     </ListItem>
                     <ListItem>
                        <ListItemText primary={numberFormat.format(stats?.winPercent || 0) + "%"} secondary={"W/R%"} />
                     </ListItem>
                  </List>
               </CardContent>
               <CardActions>
                  {editable && (
                     <Button size="small" onClick={startEdit}>
                        Edit/Clear
                     </Button>
                  )}
                  {needsApprovalFromMe && approveGame && (
                     <Button size="small" onClick={() => approveGame()}>
                        Approve
                     </Button>
                  )}
                  {canDispute && (
                     <Button size="small" style={{ color: "red" }} onClick={() => disputeGame()}>
                        Dispute
                     </Button>
                  )}
                  {editable && user && (
                     <Button size="small" onClick={() => setWinningUser(user)}>
                        Make winner
                     </Button>
                  )}
               </CardActions>
            </>
         )}
      </div>
   );
}

export default PlayerCard;
