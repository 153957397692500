import React from "react";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { CartesianGrid, Legend, Line, LineChart, Tooltip, XAxis, YAxis } from "recharts";
import { MetaGamesByTurn } from "../../rest.client/useApplicationApi";
import { INSIGHTS_CHARTS_MOBILE_WIDTH } from "../InsightsWinsByTurn";

export default function MetaInsightsWinsByTurn({ data }: { data: Array<MetaGamesByTurn> }) {
   const theme = useTheme();
   const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));
   return (
      <Box>
         <Typography variant={"h6"} textAlign={"center"}>
            Games by turn
         </Typography>
         <LineChart
            width={smallScreen ? INSIGHTS_CHARTS_MOBILE_WIDTH : 500}
            height={300}
            syncId="turn"
            data={data}
            margin={{
               top: 5,
               right: 30,
               left: 20,
               bottom: 5,
            }}
         >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="turn" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="total" stroke="#8884d8" activeDot={{ r: 8 }} />
         </LineChart>
         <LineChart
            width={smallScreen ? INSIGHTS_CHARTS_MOBILE_WIDTH : 500}
            height={300}
            syncId="turn"
            data={data}
            margin={{
               top: 5,
               right: 30,
               left: 20,
               bottom: 5,
            }}
         >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="turn" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="gameEndRate" stroke={theme.palette.primary.main} />
         </LineChart>
      </Box>
   );
}
