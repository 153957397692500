import React, { useEffect } from "react";
import { ThemeProvider } from "@mui/material";
import { darkTheme, lightTheme } from "./theme";
import { useApplicationApi } from "./rest.client/useApplicationApi";
import useCurrentUser from "./auth/useCurrentUser";
import useReload from "./header/useReload";

export default function DynamicTheme(props: React.PropsWithChildren<{}>) {
   const user = useCurrentUser();
   const { reload } = useReload();
   const {
      findUserProfileById: { call: getProfile, responseData: profileData },
   } = useApplicationApi();

   useEffect(() => {
      if ((profileData == null || reload) && user.user != null) {
         getProfile({ pathParams: { id: user.user.uid } });
      }
   }, [getProfile, profileData, reload, user.user]);

   return (
      <ThemeProvider theme={profileData?.themeSelection === "LIGHT" ? lightTheme : darkTheme}>
         {props.children}
      </ThemeProvider>
   );
}
