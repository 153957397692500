import React, {useMemo} from "react";
import {CardNameAndImage} from "../../cardFeatures/CardTypes";
import {Box} from "@mui/material";
import {SxProps} from "@mui/system";
import {DECK_VIEWER_CARD_COLUMN_WIDTH_IN_VW, DECK_VIEWER_CARD_HEIGHT_IN_VW, DECK_VIEWER_CARD_OFFSET_RATIO} from "../../UI_CONST";
import {STRONG_BOX_SHADOW} from "../tournaments2dComponents/TOURNAMENTS_2D_CONST";
import {Maybe} from "../../TYPE";

const cardWrapperBasicSx: SxProps = {
    position: "absolute",
    width: `${DECK_VIEWER_CARD_COLUMN_WIDTH_IN_VW}vw`,
    height: `${DECK_VIEWER_CARD_HEIGHT_IN_VW}vw`,
    boxShadow: STRONG_BOX_SHADOW,
    borderRadius: "4%",
    transition: "transform 0.3s, box-shadow 0.3s",
    "&:hover": {
        boxShadow: "0 0 10px 3px white",
        transform: "translateY(10%) scale(1.2)",
        zIndex: 1,
    }
}

interface IDeckViewerCard {
    card: CardNameAndImage,
    index: number,
    highlightedCardIndex: Maybe<number>,
    setHighlightedCardIndex: (index: Maybe<number>) => void,
}

const DeckViewerCard: React.FC<IDeckViewerCard> = ({index, card, highlightedCardIndex, setHighlightedCardIndex}) => {

    const shouldTranslateY = useMemo(() => highlightedCardIndex !== null && highlightedCardIndex !== undefined && index > highlightedCardIndex, [highlightedCardIndex, index]);
    const cardWrapperSx: SxProps = useMemo(() => ({
        ...cardWrapperBasicSx,
        top: `${index * DECK_VIEWER_CARD_HEIGHT_IN_VW * DECK_VIEWER_CARD_OFFSET_RATIO}vw`,
        transform: shouldTranslateY ? "translateY(110%)" : "translateY(0%)",
    }), [index, shouldTranslateY]);

    return (
        <Box sx={cardWrapperSx} component={"img"} src={card.largeImageUri} onMouseEnter={() => setHighlightedCardIndex(index)} onMouseLeave={() => setHighlightedCardIndex(null)}>
        </Box>
    );
};

export default DeckViewerCard;
