import React, { useCallback, useEffect } from "react";
import { EmailAuthProvider, GoogleAuthProvider } from "firebase/auth";
import * as firebaseui from "firebaseui";
import { fireBaseAuth } from "./useCurrentUser";

const ui = new firebaseui.auth.AuthUI(fireBaseAuth);

export default function Login() {
   const startLogin = useCallback(() => {
      let uiConfig = {
         signInSuccessUrl: "/",
         signInOptions: [EmailAuthProvider.PROVIDER_ID, GoogleAuthProvider.PROVIDER_ID],
         signInFlow: "popup",
         // Terms of service url/callback.
         tosUrl: "/tos",
         // Privacy policy url/callback.
         privacyPolicyUrl: "/privacy",
      };
      // Initialize the FirebaseUI Widget using Firebase.

      // The start method will wait until the DOM is loaded.
      ui.start("#firebaseui-auth-container", uiConfig);
   }, []);

   useEffect(() => {
      startLogin();
   }, [startLogin]);

   return (
      <div style={{ marginTop: 20 }}>
         <div id={"firebaseui-auth-container"}></div>
      </div>
   );
}
