import React, { useCallback, useMemo, useState } from "react";
import { Box, Theme, Typography } from "@mui/material";
import { SxProps } from "@mui/system";
import { Property } from "csstype";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Maybe, TCardHoverTextPlacement } from "../../TYPE";
import { CardExtendedInfo } from "../../cardFeatures/CardTypes";
import CardPickerComponent from "../../cardFeatures/CardPickerComponent";
import { CARD_HEIGHT_WIDTH_RATIO, ComponentMode } from "../../UI_CONST";
import { Consumer } from "../../utils";

const cardBasicSx: SxProps = {
   margin: "auto",
   display: "flex",
   flexDirection: "column",
   backgroundColor: "rgba(0,0,0,0)",
   backdropFilter: "blur(6px)",
   transition: "background-color 0.5s",
   ":hover": {
      backgroundColor: "rgba(87,0,44,0.5)",
      ".addIcon": {
         opacity: 0.6,
         transform: "scale(1.2,1.2)",
      },
      ".hoverText": {
         opacity: 1,
      },
   },
   ":active": {
      backgroundColor: "rgba(253,0,127,0.5)",
   },
};

const hoverTextBasicSx: SxProps<Theme> = {
   color: (theme) => theme.palette.primary.main,
   textShadow: "1px 1px 2px black",
   whiteSpace: "nowrap",
   fontSize: "1.2vmin",
   position: "absolute",
   textAlign: "center",
   left: "50%",
   pointerEvents: "none",
   transition: "opacity 0.3s",
   opacity: 0,
};

const addIconBasicSx: SxProps<Theme> = {
   margin: "auto",
   marginTop: "30%",
   marginBottom: "5%",
   opacity: 0.5,
   transform: "scale(1,1)",
   transition: "opacity 0.5s, transform 0.3s",
   color: (theme) => theme.palette.primary.main,
   pointerEvents: "none",
};

const imgSx: SxProps = {
   width: "100%",
   borderRadius: "4%",
};

const defaultTextSx: SxProps<Theme> = {
   margin: "auto",
   marginTop: 0,
   color: (theme) => theme.palette.primary.main,
   textShadow: "1px 1px 2px black",
};

interface ICardPlaceHolderComponentProps {
   card: Maybe<CardExtendedInfo>;
   setCard: (card: CardExtendedInfo) => void;
   disallowedCards: Array<CardExtendedInfo>;
   hoverText?: string;
   hoverTextPlacement?: TCardHoverTextPlacement;
   defaultText?: string;
   width: number;
   position?: Property.Position;
   left?: number;
   top?: number;
   right?: number;
   bottom?: number;
   filterCommanders: boolean;
   filterPartners: boolean;
   filterBackgrounds: boolean;
   filterFriendsForever: boolean;
   mode: ComponentMode;
   transform?: Property.Transform;
   cardWidthUnit: string;
   onClick?: Consumer<Maybe<CardExtendedInfo>>;
   sx?: SxProps;
}

const CardPlaceHolderComponent: React.FC<ICardPlaceHolderComponentProps> = ({
   card,
   setCard,
   disallowedCards,
   hoverText,
   hoverTextPlacement = "top",
   defaultText,
   width,
   position,
   left,
   top,
   right,
   bottom,
   filterCommanders,
   filterPartners,
   filterFriendsForever,
   mode,
   filterBackgrounds,
   transform = "none",
   cardWidthUnit,
   sx,
   onClick,
}) => {
   const [showCardPicker, setShowCardPicker] = useState(false);

   const cardSx: SxProps = useMemo(() => {
      return {
         ...cardBasicSx,
         width: `${width}${cardWidthUnit}`,
         height: `${width * CARD_HEIGHT_WIDTH_RATIO}${cardWidthUnit}`,
         position: position ? position : "relative",
         left: left !== undefined ? `${left}${cardWidthUnit}` : "auto",
         top: top !== undefined ? `${top}${cardWidthUnit}` : "auto",
         right: right !== undefined ? `${right}${cardWidthUnit}` : "auto",
         bottom: bottom !== undefined ? `${bottom}${cardWidthUnit}` : "auto",
         cursor: "pointer",
         borderRadius: "4%",
         boxShadow: `inset 0 0 ${width * 0.4}vmin ${width * 0.1}vmin rgba(0,0,0,0.8), 0 0 ${width * 0.08}vmin ${
            width * 0.02
         }vmin rgba(0,255,255,0.1)`,
         transform: transform,
         ...sx,
      };
   }, [bottom, cardWidthUnit, left, position, right, sx, top, transform, width]);

   const addIconSx: SxProps<Theme> = useMemo(() => {
      return {
         ...addIconBasicSx,
         width: `${width * 0.25}${cardWidthUnit}`,
         height: `${width * 0.25}${cardWidthUnit}`,
      };
   }, [cardWidthUnit, width]);

   const hoverTextElement = useMemo(() => {
      const hoverTextSx = {
         ...hoverTextBasicSx,
         top: hoverTextPlacement === "top" ? 0 : "auto",
         bottom: hoverTextPlacement === "bottom" ? 0 : "auto",
         transform: `translateX(-50%) translateY(${hoverTextPlacement === "top" ? "-50%" : "50%"})`,
      };
      return hoverText ? (
         <Typography className={"hoverText"} sx={hoverTextSx}>
            {hoverText}
         </Typography>
      ) : (
         <></>
      );
   }, [hoverText, hoverTextPlacement]);

   const clickHandler = useCallback(() => {
      mode === ComponentMode.EDIT && setShowCardPicker(true);
      onClick && onClick(card);
   }, [card, mode, onClick]);

   const closeCardPickerHandler = useCallback(() => {
      setShowCardPicker(false);
   }, []);

   const selectCardHandler = useCallback(
      (card: CardExtendedInfo) => {
         setCard(card);
      },
      [setCard]
   );

   return (
      <Box sx={cardSx} onClick={clickHandler}>
         {hoverTextElement}
         {card ? (
            <Box sx={imgSx} component="img" src={card.largeImageUri} />
         ) : (
            <>
               <AddCircleOutlineIcon className={"addIcon"} sx={addIconSx} />
               {defaultText ? <Typography sx={defaultTextSx}>{defaultText}</Typography> : <></>}
            </>
         )}
         <CardPickerComponent
            showCardPicker={showCardPicker}
            filterCommanders={filterCommanders}
            filterPartners={filterPartners}
            filterBackgrounds={filterBackgrounds}
            filterFriendsForever={filterFriendsForever}
            closeCardPickerHandler={closeCardPickerHandler}
            selectCardHandler={selectCardHandler}
            disallowedCards={disallowedCards}
         />
      </Box>
   );
};

export default CardPlaceHolderComponent;
