import { useCallback, useEffect, useMemo, useState } from "react";
import { Maybe } from "../../../TYPE";
import { Consumer, Procedure, Supplier } from "../../../utils";
import {
   SaveStageRequest,
   SingleEliminationStageSettings,
   StageFullResponse,
   StageSummaryResponse,
   SwissStageSettings,
   UnstructuredLeagueSettings,
   useStageApi,
} from "../../../rest.client/useStagesApi";
import { RemoteTournament } from "../../useRemoteTournament";
import useReload from "../../../header/useReload";
import { RegistrationSummary } from "../../../rest.client/useTournamentApi";

export interface RemoteStage {
   canEdit: boolean;
   stage: Maybe<StageFullResponse>;
   start: Supplier<Promise<any>>;
   save: Supplier<Promise<StageSummaryResponse | void>>;
   saveRequest: SaveStageRequest;
   setSaveRequest: Consumer<SaveStageRequest>;
   canActivate: boolean;
   canClose: boolean;
   requiresSeed: boolean;
   canDelete: boolean;
   deleteStage: Supplier<Promise<unknown>>;
   close: Supplier<Promise<unknown>>;
   remoteTournament: RemoteTournament;
   canSubmitGame: boolean;
   autoSeed: Supplier<Promise<void>>;
   loadSeeds: Procedure;
   seeds: Maybe<RegistrationSummary[]>;
   deleteSeed: (id: string) => Promise<void>;
   seedPlayer: (id: string) => Promise<void>;
   loading: boolean;
   isClosed: boolean;
   requiresMatchAndQualifiesValidation: boolean;
}

export const DEFAULT_LEAGUE_SETTINGS: UnstructuredLeagueSettings = {
   allowPlayerSubmissions: false,
   drawPoints: 1,
   lossPoints: 0,
   winPoints: 5,
   secondPlacePoints: 0,
   thirdPlacePoints: 0,
   fourthPlacePoints: 0,
   tiebreakerSystem: "MAMTR_3_1",
   matchWinPercentageFloor: 0.2,
};
export const DEFAULT_SINGLE_ELIMINATION_SETTINGS: SingleEliminationStageSettings = {
   startingPlayerCount: 16,
};
export const DEFAULT_SWISS_SETTINGS: SwissStageSettings = {
   drawPoints: 1,
   lossPoints: 0,
   winPoints: 5,
   byePoints: 5,
   secondPlacePoints: 0,
   thirdPlacePoints: 0,
   fourthPlacePoints: 0,
   seedCountLimit: 32,
   tiebreakerSystem: "MAMTR_3_1",
   byeAllocationStrategy: "MSTR_REGULAR_REL",
   matchWinPercentageFloor: 0.2,
};

export default function useRemoteStage(remoteTournament: RemoteTournament, id?: string): RemoteStage {
   const {
      createStage: { call: createStage },
      updateStage: { call: updateStage },
      getStageFull: { call: getStageFull, responseData: stage },
      getSeededRegistrations: { call: getSeeds },
      seedPlayer: { call: seedPlayerCall },
      removeSeedPlayer: { call: deleteSeedCall },
      autoSeed: { call: autoSeedCall },
      deleteStage: { call: deleteStageCall },
      activateStage,
      closeStage,
      loading,
   } = useStageApi();
   const [saveRequest, setSaveRequest] = useState<SaveStageRequest>({
      tournamentId: remoteTournament.tournament?.id || "",
      name: "New Stage",
      podOrderingStrategy: "RANDOM",
      autoPairStrategy: "RANDOM",
      unstructuredLeagueSettings: DEFAULT_LEAGUE_SETTINGS,
      swissSettings: null,
   });
   const [seeds, setSeeds] = useState<Maybe<RegistrationSummary[]>>(null);

   const canActivate =
      remoteTournament.tournament?.status === "STARTED" &&
      stage?.status === "SCHEDULED" &&
      (stage.previousStage == null || stage.previousStage.status === "CLOSED");
   const allMatches = stage == null ? [] : stage.rounds.flatMap((r) => r.matches);
   const canClose = stage?.status === "ACTIVE" && allMatches.every((g) => g.matchStatus === "APPROVED");
   const canSubmitGame =
      stage?.status === "ACTIVE" &&
      (remoteTournament.canEdit ||
         (Boolean(stage.unstructuredLeagueStage?.allowPlayerSubmissions) && remoteTournament.userIsRegistered));
   const { requestReload, reload } = useReload();
   const canDelete = stage?.status === "SCHEDULED";
   const isClosed = stage?.status === "CLOSED";
   // all other types need seeding
   const requiresSeed = stage?.unstructuredLeagueStage == null;
   const requiresMatchAndQualifiesValidation = stage?.swissLeagueStage != null || stage?.singleEliminationStage != null;

   const loadSeeds = useCallback(async () => {
      if (id) {
         const seedsResp = await getSeeds({ pathParams: { id: id } });
         setSeeds(seedsResp._embedded.registrations);
      }
   }, [getSeeds, id]);

   const loadData = useCallback(
      (id?: string) => {
         if (id) {
            getStageFull({ pathParams: { id: id } }).then((res) => {
               if (res && remoteTournament.tournament) {
                  setSeeds(null);
                  setSaveRequest({
                     tournamentId: remoteTournament.tournament.id,
                     name: res.name,
                     autoPairStrategy: res.autoPairStrategy,
                     podOrderingStrategy: res.podOrderingStrategy,
                     unstructuredLeagueSettings:
                        res.unstructuredLeagueStage != null
                           ? {
                                allowPlayerSubmissions: res.unstructuredLeagueStage.allowPlayerSubmissions,
                                lossPoints: res.unstructuredLeagueStage.lossPoints,
                                drawPoints: res.unstructuredLeagueStage.drawPoints,
                                winPoints: res.unstructuredLeagueStage.winPoints,
                                secondPlacePoints: res.unstructuredLeagueStage.secondPlacePoints,
                                thirdPlacePoints: res.unstructuredLeagueStage.thirdPlacePoints,
                                fourthPlacePoints: res.unstructuredLeagueStage.fourthPlacePoints,
                                tiebreakerSystem: res.unstructuredLeagueStage.tiebreakerSystem,
                                matchWinPercentageFloor: res.unstructuredLeagueStage.matchWinPercentageFloor,
                             }
                           : null,
                     singleEliminationSettings:
                        res.singleEliminationStage != null
                           ? {
                                startingPlayerCount: res.singleEliminationStage.startingPlayerCount,
                             }
                           : null,
                     swissSettings:
                        res.swissLeagueStage != null
                           ? {
                                lossPoints: res.swissLeagueStage.lossPoints,
                                drawPoints: res.swissLeagueStage.drawPoints,
                                winPoints: res.swissLeagueStage.winPoints,
                                byePoints: res.swissLeagueStage.byePoints,
                                seedCountLimit: res.swissLeagueStage.seedCountLimit,
                                secondPlacePoints: res.swissLeagueStage.secondPlacePoints,
                                thirdPlacePoints: res.swissLeagueStage.thirdPlacePoints,
                                fourthPlacePoints: res.swissLeagueStage.fourthPlacePoints,
                                tiebreakerSystem: res.swissLeagueStage.tiebreakerSystem,
                                matchWinPercentageFloor: res.swissLeagueStage.matchWinPercentageFloor,
                                byeAllocationStrategy: res.byeAllocationStrategy,
                             }
                           : null,
                  });
               }
            });
         }
      },
      [getStageFull, remoteTournament.tournament]
   );

   useEffect(() => {
      if (reload || stage == null || stage.id !== id) {
         loadData(id);
      }
   }, [id, loadData, reload, stage]);

   const start = useCallback(
      () => (id ? activateStage.call({ pathParams: { id: id } }).then(() => loadData(id)) : Promise.reject("Failed")),
      [activateStage, id, loadData]
   );
   const close = useCallback(
      () => (id ? closeStage.call({ pathParams: { id: id } }).then(() => loadData(id)) : Promise.reject("Failed")),
      [closeStage, id, loadData]
   );
   const deleteStage = useCallback(
      () => (id ? deleteStageCall({ pathParams: { id: id } }) : Promise.reject("Failed")),
      [deleteStageCall, id]
   );

   const seedPlayer = useCallback(
      async (regId: string) => {
         if (id) {
            await seedPlayerCall({ pathParams: { id: id }, body: { registrationId: regId } });
            await loadSeeds();
         }
      },
      [id, loadSeeds, seedPlayerCall]
   );

   const deleteSeed = useCallback(
      async (regId: string) => {
         if (id) {
            await deleteSeedCall({ pathParams: { registrationId: regId, id: id } });
            await loadSeeds();
         }
      },
      [deleteSeedCall, id, loadSeeds]
   );

   const autoSeed = useCallback(async () => {
      if (id) {
         await autoSeedCall({ pathParams: { id: id } });
         await loadSeeds();
      }
   }, [autoSeedCall, id, loadSeeds]);
   const save = useCallback(() => {
      requestReload();
      if (id == null) {
         return createStage({ body: saveRequest }).then(() => remoteTournament.loadData());
      } else {
         return updateStage({ pathParams: { id: id }, body: saveRequest });
      }
   }, [createStage, id, remoteTournament, requestReload, saveRequest, updateStage]);

   return useMemo(
      () => ({
         canActivate,
         stage,
         isClosed,
         loadSeeds,
         requiresSeed,
         loading,
         seeds,
         canClose,
         seedPlayer,
         deleteStage,
         canDelete,
         canSubmitGame,
         deleteSeed,
         autoSeed,
         close,
         canEdit: remoteTournament.canEdit && remoteTournament.tournament?.status !== "ENDED",
         remoteTournament,
         start,
         save,
         saveRequest,
         setSaveRequest,
         requiresMatchAndQualifiesValidation,
      }),
      [
         canActivate,
         stage,
         requiresSeed,
         isClosed,
         loadSeeds,
         loading,
         seeds,
         canClose,
         seedPlayer,
         deleteStage,
         canDelete,
         canSubmitGame,
         deleteSeed,
         autoSeed,
         close,
         remoteTournament,
         start,
         save,
         saveRequest,
         requiresMatchAndQualifiesValidation,
      ]
   );
}
