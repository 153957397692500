import React, { ReactElement, useCallback, useMemo } from "react";
import { Box, Theme } from "@mui/material";
import { SxProps } from "@mui/system";
import useGlobal from "../../global/useGlobal";
import { GLOBAL_VAR_NAME } from "../../UI_CONST";

export const blurBackgroundStyle: SxProps = {
   backgroundColor: "rgba(255,255,255,0)",
   backdropFilter: "blur(6px)",
};

const flexibleContainerRootBasicSx: SxProps<Theme> = {
   ...blurBackgroundStyle,
   position: "relative",
   boxShadow: (theme) => "0 0 5px 1px " + theme.palette.boxShadowColor,
   padding: (theme) => theme.spacing(1),
   transition: "flex-grow 0.5s, width 0.5s, height 0.5s, border-radius 0.1s",
   borderRadius: "5px",
   boxSizing: "border-box",
   cursor: "pointer",
};

const flexibleContainerMinSx: SxProps = {
   position: "relative",
   width: "50px",
   height: "50px",
   borderRadius: "50%",
};

interface IFlexibleContainer {
   containerIndex: number;
   fullContent: ReactElement;
   summaryContent: ReactElement;
}

const FlexibleContainer: React.FC<IFlexibleContainer> = ({ containerIndex, fullContent, summaryContent }) => {
   const [selectedFlexibleContainerIndex, setSelectedFlexibleContainerIndex] = useGlobal<number | null>(
      GLOBAL_VAR_NAME.SELECTED_FLEXIBLE_CONTAINER_INDEX,
      null
   );
   const noContainerSelected = useMemo(() => selectedFlexibleContainerIndex === null, [selectedFlexibleContainerIndex]);
   const containerIsSelected = useMemo(
      () => containerIndex === selectedFlexibleContainerIndex,
      [containerIndex, selectedFlexibleContainerIndex]
   );

   const flexibleContainerRootSx: SxProps<Theme> = useMemo(() => {
      return {
         ...flexibleContainerRootBasicSx,
         cursor: containerIsSelected ? "default" : "pointer",
         flexGrow: containerIsSelected ? 2 : noContainerSelected ? 1 : 0,
         borderRadius: containerIsSelected || noContainerSelected ? "5px" : "50%",
      };
   }, [containerIsSelected, noContainerSelected]);

   const containerClickHandler = useCallback(() => {
      if (containerIndex !== selectedFlexibleContainerIndex) {
         setSelectedFlexibleContainerIndex(containerIndex);
      }
   }, [containerIndex, selectedFlexibleContainerIndex, setSelectedFlexibleContainerIndex]);

   return (
      <Box id={`flexibleContainer_${containerIndex}`} sx={flexibleContainerRootSx} onClick={containerClickHandler}>
         {noContainerSelected ? (
            summaryContent
         ) : containerIsSelected ? (
            fullContent
         ) : (
            <Box sx={flexibleContainerMinSx} />
         )}
      </Box>
   );
};

export default FlexibleContainer;
