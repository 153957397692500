import { Box, ListItemIcon, Menu, MenuItem, Typography } from "@mui/material";
import React, { useCallback } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import useReload from "../../header/useReload";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { RegistrationSummary, useTournamentApi } from "../../rest.client/useTournamentApi";
import { RemoteTournament } from "../useRemoteTournament";
import VisibilityIcon from "@mui/icons-material/Visibility";
import useRemoteRegistration from "./useRemoteRegistration";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { Consumer, Procedure } from "../../utils";
import EditIcon from "@mui/icons-material/Edit";
import useCurrentUser from "../../auth/useCurrentUser";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";

export default function RegistrationMenu({
   registration,
   remoteTournament,
   onClickViewReg,
   onClickEditReg,
   showViewOption = true,
}: {
   registration: RegistrationSummary;
   remoteTournament: RemoteTournament;
   onClickViewReg: Consumer<RegistrationSummary>;
   onClickEditReg: Procedure;
   showViewOption?: boolean;
}) {
   const reload = useReload("registration");
   const currentUser = useCurrentUser();
   const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
   const remoteReg = useRemoteRegistration(remoteTournament, registration);
   const {
      acceptRegistration: { call: accept },
      rejectRegistration: { call: reject },
      dropRegistration: { call: drop },
   } = useTournamentApi();

   const handleOpenUserMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
      setAnchorElUser(event.currentTarget);
      event.stopPropagation();
   }, []);

   const handleCloseUserMenu = useCallback((event: React.MouseEvent, reason: string) => {
      setAnchorElUser(null);
      event.stopPropagation();
   }, []);

   return (
      <Box sx={{ cursor: "pointer" }}>
         <span onClick={handleOpenUserMenu}>{<MoreVertIcon />}</span>
         <Menu
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
               vertical: "bottom",
               horizontal: "right",
            }}
            transformOrigin={{
               vertical: "top",
               horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
         >
            {remoteTournament.isOrganiser && registration.status !== "ACCEPTED" && (
               <MenuItem
                  onClick={async (e) => {
                     e.stopPropagation();
                     await accept({ pathParams: { id: registration.id } });
                     reload.requestReload();
                     setAnchorElUser(null);
                  }}
               >
                  <ListItemIcon>
                     <CheckCircleOutlineIcon color={"success"} fontSize="small" />
                  </ListItemIcon>
                  <Typography>Accept</Typography>
               </MenuItem>
            )}
            {remoteTournament.isOrganiser && registration.status !== "REJECTED" && (
               <MenuItem
                  onClick={async (e) => {
                     e.stopPropagation();
                     await reject({ pathParams: { id: registration.id } });
                     reload.requestReload();
                     setAnchorElUser(null);
                  }}
               >
                  <ListItemIcon>
                     <HighlightOffIcon color={"error"} fontSize="small" />
                  </ListItemIcon>
                  <Typography>Reject</Typography>
               </MenuItem>
            )}
            {remoteTournament.isOrganiser && registration.status === "ACCEPTED" && (
               <MenuItem
                  onClick={async (e) => {
                     e.stopPropagation();
                     await drop({ pathParams: { id: registration.id } });
                     reload.requestReload();
                     setAnchorElUser(null);
                  }}
               >
                  <ListItemIcon>
                     <PersonRemoveIcon color={"warning"} fontSize="small" />
                  </ListItemIcon>
                  <Typography>Drop</Typography>
               </MenuItem>
            )}
            {remoteTournament.isOrganiser && (
               <MenuItem
                  onClick={async (e) => {
                     e.stopPropagation();
                     if (!registration.decklistLocked) {
                        await remoteReg.lockDecklist();
                     } else {
                        await remoteReg.unlockDecklist();
                     }
                     reload.requestReload();
                     setAnchorElUser(null);
                  }}
               >
                  <ListItemIcon>
                     {registration.decklistLocked ? (
                        <LockOpenIcon color={"warning"} fontSize="small" />
                     ) : (
                        <LockIcon color={"success"} fontSize="small" />
                     )}
                  </ListItemIcon>
                  <Typography>{registration.decklistLocked ? "Unlock decklist" : "Lock decklist"}</Typography>
               </MenuItem>
            )}
            {currentUser.user?.uid === registration.user.id && (
               <MenuItem
                  onClick={(e) => {
                     e.stopPropagation();
                     onClickEditReg();
                     setAnchorElUser(null);
                  }}
               >
                  <ListItemIcon>
                     <EditIcon fontSize="small" />
                  </ListItemIcon>
                  <Typography>Edit registration</Typography>
               </MenuItem>
            )}
            {showViewOption && (
               <MenuItem
                  onClick={(e) => {
                     e.stopPropagation();
                     onClickViewReg(registration);
                     setAnchorElUser(null);
                  }}
               >
                  <ListItemIcon>
                     <VisibilityIcon color={"info"} fontSize="small" />
                  </ListItemIcon>
                  <Typography>View</Typography>
               </MenuItem>
            )}
         </Menu>
      </Box>
   );
}
