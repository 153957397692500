import React, { memo, ReactNode } from "react";
import { Box, TextField, Typography } from "@mui/material";
import { SxProps } from "@mui/system";
import {
   createGameButtonWrapperSx,
   optionalDataContainerSx,
   optionalDataContainerTitleSx,
} from "./MobileOptionalGameDataStepComponent";

const rootContainerSx: SxProps = {
   width: "100%",
   height: "100%",
   position: "absolute",
   left: 0,
   top: 0,
   display: "flex",
   flexDirection: "column",
};

const textFieldSx: SxProps = {
   width: "100%",
   backgroundColor: "rgba(0,0,0,0.5)",
   margin: "0 auto auto auto",
};

interface IMobileOptionalGameDescriptionStepComponentProps {
   createOrUpdateGameButtonElement?: ReactNode;
   gameDescription: string;
}

const MobileOptionalGameDescriptionStepComponent: React.FC<IMobileOptionalGameDescriptionStepComponentProps> = ({
   createOrUpdateGameButtonElement,
   gameDescription,
}) => {
   return (
      <Box sx={rootContainerSx}>
         {createOrUpdateGameButtonElement ? (
            <Box sx={createGameButtonWrapperSx}>{createOrUpdateGameButtonElement}</Box>
         ) : (
            <></>
         )}
         <Box sx={optionalDataContainerSx}>
            <Typography sx={optionalDataContainerTitleSx}>{"optional data"}</Typography>
            <TextField
               label={"Game description"}
               color={"secondary"}
               id={"description"}
               contentEditable={false}
               sx={textFieldSx}
               multiline
               rows={20}
               value={gameDescription}
            />
         </Box>
      </Box>
   );
};

export default memo(MobileOptionalGameDescriptionStepComponent);
