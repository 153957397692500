import React, { useCallback } from "react";
import { Box, Button } from "@mui/material";
import { SxProps } from "@mui/system";
import useGlobal from "../../global/useGlobal";
import { GLOBAL_VAR_NAME } from "../../UI_CONST";
import LogGamePagePlayersAndCommandersPicker from "./LogGamePagePlayersAndCommandersPicker";
import { MutableGame } from "../MutableGame";
import WinnerSelect from "./WinnerSelect";

const logGamePagePlayersAndCommandersFullPanelRootSx: SxProps = {
   position: "relative",
   width: "98vw",
   height: "100%",
   boxSizing: "border-box",
   display: "flex",
   justifyContent: "space-around",
   flexWrap: "wrap",
   alignContent: "space-around",
   overflow: "visible",
};

const logGamePagePlayersAndCommandersFullPanelButtonsContainerSx: SxProps = {
   position: "relative",
   width: "100%",
   display: "flex",
   justifyContent: "space-around",
};

const LogGamePagePlayersAndCommandersFullPanel: React.FC<{ game: MutableGame }> = ({ game }) => {
   const [selectedFlexibleContainerIndex, setSelectedFlexibleContainerIndex] = useGlobal<number | null>(
      GLOBAL_VAR_NAME.SELECTED_FLEXIBLE_CONTAINER_INDEX,
      null
   );

   const closeButtonHandler = useCallback(() => {
      setSelectedFlexibleContainerIndex(null);
   }, [setSelectedFlexibleContainerIndex]);

   const nextButtonHandler = useCallback(() => {
      selectedFlexibleContainerIndex !== null && setSelectedFlexibleContainerIndex(selectedFlexibleContainerIndex + 1);
   }, [selectedFlexibleContainerIndex, setSelectedFlexibleContainerIndex]);

   return (
      <Box id={"logGamePagePlayersAndCommandersFullPanelRoot"} sx={logGamePagePlayersAndCommandersFullPanelRootSx}>
         <LogGamePagePlayersAndCommandersPicker playerIndex={1} game={game} />
         <LogGamePagePlayersAndCommandersPicker playerIndex={2} game={game} />
         <LogGamePagePlayersAndCommandersPicker playerIndex={3} game={game} />
         <LogGamePagePlayersAndCommandersPicker playerIndex={4} game={game} />
         <Box sx={logGamePagePlayersAndCommandersFullPanelButtonsContainerSx}>
            <Button variant={"outlined"} size={"small"} onClick={closeButtonHandler}>
               Close
            </Button>
            <WinnerSelect readOnly={false} game={game} />
            <Button variant={"contained"} size={"small"} onClick={nextButtonHandler}>
               Next
            </Button>
         </Box>
      </Box>
   );
};

export default LogGamePagePlayersAndCommandersFullPanel;
