import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useApplicationApi } from "../../rest.client/useApplicationApi";
import { Box, CircularProgress, useTheme } from "@mui/material";
import useGlobal from "../../global/useGlobal";
import { Maybe, TPoint2 } from "../../TYPE";
import InsightsWinsByTurn from "../InsightsWinsByTurn";
import InsightsWinsBySeat from "../InsightsWinsBySeat";
import InsightsVersusView from "../InsightsVersusView";
import PieChartInsightsView from "../PieChartInsightsView";
import PlayerPickerComponent from "../../gameFeatures/PlayerPickerComponent";
import { empty, randomPos } from "../../utils";
import { ComponentMode } from "../../UI_CONST";
import useCurrentUser from "../../auth/useCurrentUser";
import InsightsPerformanceView from "./InsightsPerformanceView";
import PlayerGamesTable from "./PlayerGamesTable";
import PlayerDetailsRow from "./PlayerDetailsRow";
import ScrollableBorderedWrapper from "../../common/ScrollableBorderedWrapper";
import InsightsSelectorBanner from "../InsightsSelectorBanner";

export default function PlayerInsightsPage() {
   const currentUser = useCurrentUser();
   const [, setTilesPos] = useGlobal<Maybe<TPoint2>>("tilesPoints", null);
   let nav = useNavigate();
   let params = useParams<{ uid?: string }>();
   const targetUser = params.uid || currentUser.user?.uid;
   const theme = useTheme();

   const {
      findPlayerInsights: { call: findInsights, responseData: insights, loading },
      findUserById: { call: findUser, responseData: userSummaryData },
   } = useApplicationApi();

   useEffect(() => {
      if (targetUser) {
         findUser({ pathParams: { id: targetUser } });
      }
   }, [findInsights, findUser, targetUser]);

   useEffect(() => {
      if (targetUser) {
         findInsights({ pathParams: { uid: targetUser } });
      }
   }, [findInsights, targetUser]);

   useEffect(() => {
      setTilesPos(randomPos());
   }, [setTilesPos]);

   return (
      <ScrollableBorderedWrapper>
         <InsightsSelectorBanner />
         <Box sx={{ margin: theme.spacing(1) }}>
            <PlayerPickerComponent
               mode={ComponentMode.EDIT}
               playerColor={theme.palette.text.primary}
               hideWinnerSelector={true}
               hideAnonymousSelector={true}
               labelText={"Search player"}
               playerIndex={1}
               playerSummary={userSummaryData}
               selectPlayerHandler={(u) => nav("/insights/player/" + u.id)}
               otherPlayers={[]}
               setWinner={empty}
               wonGame={false}
            />
         </Box>
         {targetUser && <PlayerDetailsRow id={targetUser} />}
         <Box sx={{ display: "flex", flexWrap: "wrap" }}>
            {loading && (
               <Box sx={{}}>
                  <CircularProgress />
               </Box>
            )}
            {!loading && insights != null && (
               <PieChartInsightsView
                  winCons={insights.winConsUsed}
                  highImpact={insights.highImpactCardsInWins}
                  wins={insights.winCount}
                  losses={insights.lossCount}
               />
            )}
         </Box>
         {!loading && insights != null && (
            <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "space-evenly" }}>
               <InsightsWinsByTurn data={insights.gamesByTurn} />
               <InsightsWinsBySeat data={insights.gamesBySeat} />
               <InsightsPerformanceView data={insights.commanderPerformance} />
               <InsightsVersusView data={insights.vsOtherCommandersData} />
            </Box>
         )}
         {targetUser && <PlayerGamesTable id={targetUser} />}
      </ScrollableBorderedWrapper>
   );
}
