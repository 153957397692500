import React, { memo, ReactNode, useMemo, useState } from "react";
import { Box, IconButton, Theme } from "@mui/material";
import { SxProps } from "@mui/system";
import { Property } from "csstype";
import { CardNameAndImage } from "../CardTypes";
import { CARD_HEIGHT_WIDTH_RATIO, ComponentMode } from "../../UI_CONST";
import { Consumer, removeElementFromArrayByIndex } from "../../utils";
import CardPickerComponent from "../CardPickerComponent";
import { Maybe } from "../../TYPE";
import CardWithButtonsComponent from "./CardWithButtonsComponent";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

const containerBasicSx: SxProps = {
   width: 0,
   height: 0,
   position: "absolute",
   left: "50%",
   top: "50%",
};

const cardHolderBasicSx: SxProps = {
   // backgroundColor: "rgba(0,0,0,0.2)",
   position: "absolute",
   transformOrigin: "50% 0%",
   left: 0,
   pointerEvents: "none",
   transition: "transform 0.3s",
};

const actionsContainerBasicSx: SxProps = {
   position: "absolute",
   pointerEvents: "none",
};

const buttonSizeInVmin = 3;
const buttonBasicSx: SxProps = {
   pointerEvents: "auto",
   position: "absolute",
   width: `${buttonSizeInVmin}vmin`,
   height: `${buttonSizeInVmin}vmin`,
   fontSize: "3vmin",
   backgroundColor: "rgba(0,0,0,0.8)",
   boxShadow: `0 0 1vmin 0.1vmin black, inset 0 0 1vmin 0.5vmin black`,
   transition: "opacity 0.3s, background-color 0.3s, transform 0.3s",
   opacity: "0.4",
   transform: "scale(1,1)",
   ":hover": {
      transform: "scale(1.2,1.2)",
      opacity: "1",
      backgroundColor: "rgba(230,0,100,0.3)",
   },
};
const defaultPlaceholderOpacity = 0.4;
const placeholderBasicSx: SxProps = {
   pointerEvents: "none",
   boxSizing: "border-box",
   borderRadius: "4%",
   border: `0.3vmin dashed rgb(0,126,123)`,
   transformOrigin: "50% 50%",
   position: "absolute",
   left: 0,
   bottom: 0,
   opacity: defaultPlaceholderOpacity,
   display: "flex",
   transition: "opacity 0.3s",
   padding: "2vmin",
};

const placeHolderTextSx: SxProps<Theme> = {
   margin: "auto",
   fontSize: "2.2vmin",
   textAlign: "center",
   color: (theme) => theme.palette.secondary.main,
};

interface ICardsCircleDisplayComponentProps {
   mode: ComponentMode;
   startingDegree: number;
   rangeDegree: number;
   cardsInfo: Array<CardNameAndImage>;
   diameter: Property.Width;
   cardWidth: number;
   cardWidthUnit: string;
   centerPositionLeft: Property.Left;
   centerPositionTop: Property.Top;
   setCards: Consumer<Array<CardNameAndImage>>;
   placeHolderText: string;
}

const CardsCircleDisplayComponent: React.FC<ICardsCircleDisplayComponentProps> = ({
   mode,
   startingDegree,
   rangeDegree,
   cardsInfo,
   diameter,
   cardWidth,
   cardWidthUnit,
   centerPositionLeft,
   centerPositionTop,
   setCards,
   placeHolderText,
}) => {
   const [hoverCardIndex, setHoverCardIndex] = useState<Maybe<number>>(null);
   const [selectedCardIndex, setSelectedCardIndex] = useState<Maybe<number>>(null);
   const [showCardPicker, setShowCardPicker] = useState(false);
   const [highlightPlaceholder, setHighlightPlaceholder] = useState(false);

   const isThereCardSelected = useMemo(
      () => selectedCardIndex !== null && setSelectedCardIndex !== undefined,
      [selectedCardIndex]
   );
   const showAddButton = useMemo(
      () => mode === ComponentMode.EDIT && !isThereCardSelected,
      [isThereCardSelected, mode]
   );
   const isLeftHalfOfCircle = useMemo(() => startingDegree % 360 < 180, [startingDegree]);
   const containerSx: SxProps = useMemo(
      () => ({ ...containerBasicSx, left: centerPositionLeft, top: centerPositionTop }),
      [centerPositionLeft, centerPositionTop]
   );
   const cardHolderInitSx: SxProps = useMemo(
      () => ({
         ...cardHolderBasicSx,
         width: `${cardWidth}${cardWidthUnit}`,
         height: diameter,
         left: `calc(50% - ${cardWidth * 0.5}${cardWidthUnit})`,
      }),
      [cardWidth, cardWidthUnit, diameter]
   );

   const actionsContainerSx: SxProps = useMemo(() => {
      return {
         ...actionsContainerBasicSx,
         right: isLeftHalfOfCircle ? 0 : "auto",
         left: isLeftHalfOfCircle ? "auto" : 0,
         bottom: `calc(50% - ${cardWidth * 0.5}${cardWidthUnit})`,
         height: `${cardWidth}${cardWidthUnit}`,
         width: `calc(${diameter} - ${cardWidth * CARD_HEIGHT_WIDTH_RATIO}${cardWidthUnit})`,
      };
   }, [cardWidth, cardWidthUnit, diameter, isLeftHalfOfCircle]);

   const addCardButtonSx: SxProps = useMemo(() => {
      return {
         ...buttonBasicSx,
         left: isLeftHalfOfCircle ? 0 : "auto",
         right: isLeftHalfOfCircle ? "auto" : 0,
         top: `calc(50% - ${buttonSizeInVmin * 0.5}vmin)`,
      };
   }, [isLeftHalfOfCircle]);

   const cardsToShow = useMemo(() => {
      const rotationGap = rangeDegree / (cardsInfo.length + 1);
      const result: Array<ReactNode> = cardsInfo.map((cardInfo, i) => {
         let shouldRotateDown = false;
         if (hoverCardIndex !== null && hoverCardIndex !== undefined) {
            shouldRotateDown = isLeftHalfOfCircle ? hoverCardIndex > i : hoverCardIndex < i;
         }

         let degToRotateDown = 0;
         if (shouldRotateDown) {
            degToRotateDown = isLeftHalfOfCircle ? -10 : 10;
         }
         const holderRotationZ = startingDegree + (i + 1) * rotationGap + degToRotateDown;

         return (
            <Box key={i} sx={{ ...cardHolderInitSx, transform: `rotateZ(${holderRotationZ}deg)` }}>
               <CardWithButtonsComponent
                  showButtons={mode === ComponentMode.EDIT && hoverCardIndex === i}
                  cardWidth={cardWidth}
                  cardWidthUnit={cardWidthUnit}
                  transform={`rotateZ(${isLeftHalfOfCircle ? -90 : 90}deg)`}
                  imgSrc={cardInfo.largeImageUri}
                  onMouseEnter={() => setHoverCardIndex(i)}
                  onMouseLeave={() => setHoverCardIndex(null)}
                  removeCard={() => {
                     setHoverCardIndex(null);
                     setCards(removeElementFromArrayByIndex(cardsInfo, i));
                  }}
               />
            </Box>
         );
      });

      if (result.length === 0) {
         const placeHolderSx: SxProps = {
            ...placeholderBasicSx,
            width: `${cardWidth}${cardWidthUnit}`,
            height: `${cardWidth * CARD_HEIGHT_WIDTH_RATIO}${cardWidthUnit}`,
            transform: `rotateZ(${isLeftHalfOfCircle ? -90 : 90}deg)`,
            opacity: highlightPlaceholder ? 1 : defaultPlaceholderOpacity,
         };
         result.push(
            <Box
               key={placeHolderText}
               sx={{ ...cardHolderInitSx, transform: `rotateZ(${isLeftHalfOfCircle ? 90 : 270}deg)` }}
            >
               <Box sx={placeHolderSx}>
                  <Box sx={placeHolderTextSx}>{placeHolderText}</Box>
               </Box>
            </Box>
         );
      } else {
         isLeftHalfOfCircle && result.reverse();
      }
      return result;
   }, [
      cardHolderInitSx,
      cardWidth,
      cardWidthUnit,
      cardsInfo,
      highlightPlaceholder,
      hoverCardIndex,
      isLeftHalfOfCircle,
      mode,
      placeHolderText,
      rangeDegree,
      setCards,
      startingDegree,
   ]);

   return (
      <Box sx={containerSx}>
         {cardsToShow}
         <Box sx={actionsContainerSx}>
            {showAddButton ? (
               <IconButton
                  disabled={!showAddButton}
                  color={"secondary"}
                  sx={addCardButtonSx}
                  onMouseEnter={() => setHighlightPlaceholder(true)}
                  onMouseLeave={() => setHighlightPlaceholder(false)}
                  onClick={() => setShowCardPicker(true)}
               >
                  <AddCircleOutlineIcon fontSize="inherit" />
               </IconButton>
            ) : (
               <></>
            )}
         </Box>
         <CardPickerComponent
            showCardPicker={showCardPicker}
            closeCardPickerHandler={() => setShowCardPicker(false)}
            disallowedCards={[]}
            filterCommanders={false}
            filterBackgrounds={false}
            filterPartners={false}
            filterFriendsForever={false}
            selectCardHandler={(card) => setCards([...cardsInfo, card])}
         />
      </Box>
   );
};

export default memo(CardsCircleDisplayComponent);
