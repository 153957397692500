import { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { useCallback, useMemo, useState } from "react";
import useAddMessageItem from "../messagesComponent/useAddMessageItem";

export default function useRequest<ResponseType, PathParamType extends Object, BodyType, QueryParamsType>(
   {
      url,
      method,
      queryParamsDefault,
      configMutator,
   }: {
      url: string;
      method: string;
      queryParamsDefault?: QueryParamsType;
      configMutator?: (baseConfig: AxiosRequestConfig) => AxiosRequestConfig;
   },
   instance: AxiosInstance
) {
   const [responseData, setResponseData] = useState<AxiosResponse<ResponseType> | null>(null);
   const [error, setError] = useState<AxiosError | null>(null);
   const [loading, setLoading] = useState(false);
   const [requestsSent, setRequestsSent] = useState(0);
   const clear = useCallback(() => setResponseData(null), []);
   const addMessageItem = useAddMessageItem();
   let call = useCallback(
      (requestData?: {
         pathParams?: PathParamType;
         body?: BodyType;
         queryParams?: QueryParamsType;
         hideGlobalError?: boolean;
      }) => {
         setLoading(true);
         setRequestsSent((count) => count + 1);
         const pathKeys = requestData?.pathParams != null ? Object.keys(requestData.pathParams) : [];
         const pathParams = requestData?.pathParams || {};

         const modUrl =
            requestData?.pathParams != null
               ? pathKeys.reduce(
                    (acc, curr) =>
                       // @ts-ignore not sure what else to do with this one
                       acc.replaceAll(":" + curr, pathParams[curr]).replaceAll("{" + curr + "}", pathParams[curr]),
                    url
                 )
               : url;
         let baseConfig: AxiosRequestConfig = {
            url: modUrl,
            method,
            data: requestData?.body,
            params: { ...queryParamsDefault, ...requestData?.queryParams },
         };
         baseConfig = configMutator != null ? configMutator(baseConfig) : baseConfig;
         return instance
            .request(baseConfig)
            .then((response: AxiosResponse<ResponseType>) => {
               setResponseData(response);
               return response.data;
            })
            .catch((err) => {
               setError(err);
               if (!requestData?.hideGlobalError) {
                  const axiosError = err as AxiosError<{ message: string } | undefined>;
                  const msg =
                     axiosError.response?.data?.message ||
                     axiosError.message ||
                     axiosError.response?.status ||
                     "Unknown error";
                  addMessageItem(msg, "error", 3000);
               }
               throw err;
            })
            .finally(() => setLoading(false)) as Promise<ResponseType>;
      },
      // do not add addMessageItem here it will infinite loop
      [configMutator, instance, method, queryParamsDefault, url]
   );
   return useMemo(() => {
      return {
         call,
         clear,
         responseData: responseData?.data || null,
         responseCode: responseData?.status,
         error,
         loading,
         requestsSent,
         used: requestsSent > 0,
      };
   }, [call, clear, error, loading, requestsSent, responseData]);
}
