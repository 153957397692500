import React, { useEffect } from "react";
import { useApplicationApi, UserStatsExtended } from "../../rest.client/useApplicationApi";
import {
   Avatar,
   Box,
   Card,
   CardContent,
   CardHeader,
   CircularProgress,
   IconButton,
   List,
   ListItem,
   ListItemText,
   useTheme,
} from "@mui/material";
import useReload from "../../header/useReload";
import RankRender from "../../gameFeatures/RankRender";
import { numberFormat } from "../../gameFeatures/viewGamePage/PlayerCard";

export default function PlayerStatsCard({ id }: { id: string }) {
   const {
      findUserById: { call: findUser, responseData, loading },
   } = useApplicationApi();
   const userData = responseData as UserStatsExtended | null;
   const theme = useTheme();
   const { reload } = useReload();

   const topPlayedCommander = userData != null ? userData.commandersPlayed.slice(0, 1).pop() : null;
   const totalGames =
      userData?.commandersPlayed != null
         ? userData.commandersPlayed
              .map((cP) => cP.playedCount)
              .reduce((accumulator, currentValue) => accumulator + currentValue, 0)
         : 0;

   useEffect(() => {
      id && findUser({ pathParams: { id: id }, queryParams: { projection: "extendedStats" } });
   }, [findUser, id, reload]);

   return (
      <>
         {userData && (
            <Card>
               <CardHeader
                  avatar={<Avatar src={userData.photoUrl}></Avatar>}
                  action={<IconButton aria-label="settings">{/*<MoreVertIcon />*/}</IconButton>}
                  title={userData?.name}
                  subheader={"/" + userData?.shortTag}
               />
               <CardContent>
                  <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                     <List dense={true}>
                        <ListItem>
                           <ListItemText primary={<RankRender rank={userData.rank} />} secondary={"Rank"} />
                        </ListItem>
                        <ListItem>
                           <ListItemText primary={numberFormat.format(userData.elo)} secondary={"Elo"} />
                        </ListItem>
                        <ListItem>
                           <ListItemText
                              primary={numberFormat.format(userData?.winPercent || 0) + "%"}
                              secondary={"W/R%"}
                           />
                        </ListItem>
                     </List>
                     {topPlayedCommander && (
                        <List dense={true}>
                           <ListItem>
                              <ListItemText
                                 primary={
                                    topPlayedCommander.commander1.name +
                                    (topPlayedCommander.commander2 != null
                                       ? " / " + topPlayedCommander.commander2?.name
                                       : "")
                                 }
                                 secondary={"Most played"}
                              />
                           </ListItem>
                           <ListItem>
                              <ListItemText primary={totalGames} secondary={"Total games played"} />
                           </ListItem>
                        </List>
                     )}
                  </Box>
               </CardContent>
            </Card>
         )}

         <Box
            style={{
               display: "flex",
               flexDirection: "row",
               justifyContent: "space-between",
               padding: theme.spacing(1),
            }}
         >
            {loading && (
               <Box sx={{}}>
                  <CircularProgress />
               </Box>
            )}
         </Box>
      </>
   );
}
