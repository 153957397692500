import React, { useCallback, useMemo, useState } from "react";
import { Avatar, Box, Button, IconButton, Menu, MenuItem } from "@mui/material";
import useCurrentUser, { fireBaseAuth } from "../auth/useCurrentUser";
import { HOME_PATH, LOGIN_PATH, PROFILE_PATH } from "../index";
import { randomPos } from "../utils";
import { useNavigate } from "react-router-dom";
import useGlobal from "../global/useGlobal";
import { Maybe, TPoint2 } from "../TYPE";
import { GLOBAL_VAR_NAME } from "../UI_CONST";
import { API_BASE_URL } from "../rest.client/useApplicationApi";

const SQUIRRLE_MOD_UID = "TnebuXzI17R9AzZfEvtWVw9IPiC2";
const VOID_UID = "KxadWK5FSPb3QokSen20QN8m71W2";

const HeaderUserButton: React.FC = () => {
   const user = useCurrentUser();
   const nav = useNavigate();

   const [, setTilesPos] = useGlobal<Maybe<TPoint2>>(GLOBAL_VAR_NAME.TILES_POINTS, null);
   const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

   const userLoggedIn = useMemo(() => user.loggedIn, [user.loggedIn]);

   const signInHandler = useCallback(() => {
      nav(LOGIN_PATH);
      setTilesPos(randomPos());
   }, [nav, setTilesPos]);

   const signOutHandler = useCallback(() => {
      fireBaseAuth.signOut();
      nav(HOME_PATH);
      setAnchorElUser(null);
      setTilesPos(randomPos());
   }, [nav, setTilesPos]);

   const handleOpenUserMenu = useCallback(
      (event: React.MouseEvent<HTMLElement>) => {
         userLoggedIn && setAnchorElUser(event.currentTarget);
      },
      [userLoggedIn]
   );
   const handleCloseUserMenu = useCallback(() => {
      setAnchorElUser(null);
   }, []);

   const profileHandler = useCallback(() => {
      nav(PROFILE_PATH);
      setTilesPos(randomPos());
      handleCloseUserMenu();
   }, [handleCloseUserMenu, nav, setTilesPos]);

   return (
      <Box
         id={"headerUserButtonRoot"}
         sx={{
            height: "100%",
            display: "flex",
         }}
      >
         {userLoggedIn ? (
            <IconButton onClick={handleOpenUserMenu} id={"userIconButton"} sx={{}}>
               <Avatar alt="Remy Sharp" src={user?.user?.photoURL != null ? user?.user?.photoURL : ""} />
            </IconButton>
         ) : (
            <Button id={"headerSignInSignUpButton"} onClick={signInHandler}>
               {"Sign in / Sign up"}
            </Button>
         )}
         <Menu
            id={"userMenu"}
            anchorEl={anchorElUser}
            anchorOrigin={{
               vertical: "bottom",
               horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
               vertical: "top",
               horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
         >
            <MenuItem onClick={profileHandler}>Profile</MenuItem>
            <MenuItem onClick={signOutHandler}>Sign out</MenuItem>
            {(user.user?.uid === VOID_UID || user.user?.uid === SQUIRRLE_MOD_UID) && (
               <MenuItem>
                  <a href={API_BASE_URL + "export/games/csv"}>Export data</a>
               </MenuItem>
            )}
         </Menu>
      </Box>
   );
};

export default HeaderUserButton;
