import React from "react";
import { Alert, Box, useMediaQuery, useTheme } from "@mui/material";
import { RegistrationSummary } from "../../rest.client/useTournamentApi";

export default function RegistrationStatusBanner({ registration }: { registration: RegistrationSummary }) {
   const theme = useTheme();
   const isMobileDevice = !useMediaQuery("(min-width:600px)");

   return (
      <Box>
         {registration.status === "ACCEPTED" && <Alert severity="success">This player registration is accepted.</Alert>}
         {registration.status === "PENDING" && (
            <Alert severity="warning">This player registration is pending review.</Alert>
         )}
         {registration.status === "REJECTED" && (
            <Alert severity="error">
               This player registration is rejected. Please contact the tournament organisers for more information.
            </Alert>
         )}
         {registration.status === "DROPPED" && (
            <Alert severity="warning">
               This player registration is dropped. They cannot be auto paired or auto qualified in stages. They will
               still appear in standings and can be added to tournament results.
            </Alert>
         )}
      </Box>
   );
}
