import React, { useEffect, useMemo } from "react";
import { Button, FormControl, FormHelperText, InputLabel, OutlinedInput, Typography, useTheme } from "@mui/material";
import { SxProps } from "@mui/system";
import useCurrentUser from "../../auth/useCurrentUser";
import { useApplicationApi } from "../../rest.client/useApplicationApi";
import useReload from "../../header/useReload";

const profilePageUserEmailFieldFormControlSx: SxProps = {
   width: "100%",
};

export default function ProfilePageEmailField() {
   const user = useCurrentUser();
   const { reload } = useReload();
   const theme = useTheme();

   const {
      findUserProfileById: { call: findUserById, responseData: userProfileData },
      sendVerifyEmail: { call: sendVerifyEmail },
   } = useApplicationApi();

   useEffect(() => {
      user?.user?.uid && findUserById({ pathParams: { id: user?.user?.uid } });
   }, [findUserById, reload, user?.user?.uid]);

   const emailToDisplay = useMemo(() => {
      let email = "";
      user?.user?.email && (email = user?.user?.email);
      return email;
   }, [user?.user?.email]);

   const canSendEmail = useMemo(
      () =>
         userProfileData?.lastVerifyEmailDate == null ||
         Date.parse(userProfileData.lastVerifyEmailDate) + 60 * 1000 < Date.now(),
      [userProfileData?.lastVerifyEmailDate]
   );

   const helperText = useMemo(() => {
      if (user?.user?.emailVerified) {
         return "Your email is verified";
      } else {
         return "Your email is not verified";
      }
   }, [user?.user?.emailVerified]);

   const verification = useMemo(() => {
      if (!user?.user?.emailVerified) {
         if (canSendEmail) {
            return (
               <Button
                  size={"medium"}
                  onClick={() => {
                     sendVerifyEmail();
                  }}
               >
                  Send verification email
               </Button>
            );
         } else {
            return <Typography color={theme.palette.success.main}>Email in transit, please wait</Typography>;
         }
      }
      return <></>;
   }, [canSendEmail, sendVerifyEmail, theme.palette.success.main, user?.user?.emailVerified]);

   return (
      <>
         <FormControl
            id={"profilePageUserEmailFieldFormControl"}
            sx={profilePageUserEmailFieldFormControlSx}
            variant="outlined"
         >
            <InputLabel>Email</InputLabel>
            <OutlinedInput disabled={true} size={"medium"} label="Email" value={emailToDisplay} />
            <FormHelperText error={!user?.user?.emailVerified}>{helperText}</FormHelperText>
         </FormControl>
         {verification}
      </>
   );
}
