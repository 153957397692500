import React, { useState } from "react";
import {
   Avatar,
   Button,
   CircularProgress,
   Dialog,
   DialogActions,
   DialogContent,
   DialogTitle,
   List,
   ListItem,
   ListItemAvatar,
   ListItemText,
   Paper,
   useMediaQuery,
   useTheme,
} from "@mui/material";
import { empty, Procedure } from "../../utils";
import { RemoteTournament } from "../useRemoteTournament";
import { Maybe } from "../../TYPE";
import { ComponentMode } from "../../UI_CONST";
import PlayerPickerComponent from "../../gameFeatures/PlayerPickerComponent";
import { UserSummary } from "../../rest.client/useApplicationApi";
import { useTournamentApi } from "../../rest.client/useTournamentApi";
import { Box } from "@mui/system";
import useAddMessageItem from "../../messagesComponent/useAddMessageItem";

export default function InvitesListDialog({
   open,
   close,
   remoteTournament,
}: {
   open: boolean;
   close: Procedure;
   remoteTournament: RemoteTournament;
}) {
   const theme = useTheme();
   const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));
   const addMessageItem = useAddMessageItem();
   const [inviteTarget, setInviteTarget] = useState<Maybe<UserSummary>>();

   const invites = remoteTournament.tournament?.invitedUsers || [];

   const {
      inviteToTournament: { call: invite, loading: iLoad },
      unInviteToTournament: { call: unInvite, loading: uLoad },
   } = useTournamentApi();

   const anyLoad = uLoad || iLoad;

   return (
      <Dialog
         open={open}
         fullScreen={smallScreen}
         maxWidth={"sm"}
         fullWidth={true}
         aria-labelledby="alert-dialog-title"
         aria-describedby="alert-dialog-description"
         onKeyUp={(event) => {
            if (event.key === "Escape") {
               close();
            }
         }}
      >
         <DialogTitle id="alert-dialog-title">Invites</DialogTitle>
         <DialogContent sx={{ minWidth: 250 }}>
            {!anyLoad && (
               <Paper sx={{ padding: theme.spacing(1) }}>
                  <Box sx={{ display: "flex" }}>
                     <PlayerPickerComponent
                        mode={ComponentMode.EDIT}
                        playerColor={theme.palette.text.primary}
                        playerIndex={1}
                        hideWinnerSelector={true}
                        playerSummary={inviteTarget}
                        selectPlayerHandler={setInviteTarget}
                        hideAnonymousSelector={true}
                        otherPlayers={invites}
                        setWinner={empty}
                        wonGame={false}
                        showStatsButton={false}
                        labelText={"Invite user"}
                     />
                     <Button
                        onClick={async () => {
                           remoteTournament.tournament &&
                              inviteTarget &&
                              (await invite({
                                 pathParams: { id: remoteTournament.tournament.id },
                                 body: { userId: inviteTarget?.id },
                              }));
                           addMessageItem(`Invited ${inviteTarget?.name}`, "success", 3000);
                           setInviteTarget(undefined);
                           remoteTournament.loadData();
                        }}
                        disabled={!inviteTarget}
                     >
                        Send invite
                     </Button>
                  </Box>
                  <List sx={{ width: "100%", maxWidth: 360 }}>
                     {invites.map((i) => {
                        return (
                           <ListItem alignItems="flex-start">
                              <ListItemAvatar>
                                 <Avatar alt="Remy Sharp" src={"null"} />
                              </ListItemAvatar>
                              <ListItemText primary={i.name} />
                              <Button
                                 onClick={async () => {
                                    remoteTournament.tournament &&
                                       (await unInvite({
                                          pathParams: {
                                             id: remoteTournament.tournament.id,
                                             uid: i.id,
                                          },
                                       }));
                                    addMessageItem(`Invite deleted`, "success", 3000);
                                    remoteTournament.loadData();
                                 }}
                              >
                                 Delete
                              </Button>
                           </ListItem>
                        );
                     })}
                  </List>
               </Paper>
            )}
            {anyLoad && (
               <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <CircularProgress />
               </Box>
            )}
         </DialogContent>
         <DialogActions>
            <Button onClick={close} autoFocus>
               Close
            </Button>
         </DialogActions>
      </Dialog>
   );
}
