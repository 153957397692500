import React from "react";
import { GameSummary, PlayerSummary } from "../../rest.client/useApplicationApi";
import { Box, Theme, useMediaQuery, useTheme } from "@mui/material";
import PlayerGameEntryCell from "./PlayerGameEntryCell";
import { SxProps } from "@mui/system";

export default function CompactMultiPlayerCell({ game, sx }: { game: GameSummary; sx?: SxProps<Theme> }) {
   const theme = useTheme();
   const players = [game.player1, game.player2, game.player3, game.player4].filter((p) => p != null) as PlayerSummary[];
   const medUp = useMediaQuery(theme.breakpoints.up("md"));
   return (
      <Box sx={{ display: "flex", flexWrap: "wrap", ...sx }}>
         {players.map((p, i) => {
            return <PlayerGameEntryCell player={p} num={i} />;
         })}
      </Box>
   );
}
