import React, { CSSProperties, useCallback, useEffect, useMemo, useState } from "react";
import {
   Box,
   Button,
   Checkbox,
   FormControlLabel,
   FormGroup,
   Grow,
   MenuItem,
   Paper,
   Select,
   TextField,
   Typography,
   useTheme,
} from "@mui/material";
import { TournamentStatus, useTournamentApi } from "../../rest.client/useTournamentApi";
import CircularProgress from "@mui/material/CircularProgress";
import TournamentTile from "./TournamentTile";
import { useNavigate, useSearchParams } from "react-router-dom";
import useRemoteTournament from "../useRemoteTournament";
import TournamentsCreationEditDialog from "../creationPage/TournamentsCreationEditDialog";
import { Maybe } from "../../TYPE";
import useCurrentUser from "../../auth/useCurrentUser";
import Footer from "../../Footer";
import WelcomeBanner from "./WelcomeBanner";

const tournamentsViewPageRootCss: CSSProperties = {
   left: "0px",
   top: "0px",
   width: "100%",
   height: "100%",
   overflow: "auto",
   display: "flex",
   flexWrap: "wrap",
   boxSizing: "border-box",
   justifyContent: "space-evenly",
   alignItems: "flex-start",
   alignContent: "flex-start",
   rowGap: "5%",
};

interface ITournamentsViewPage {}

const TournamentsViewPage: React.FC<ITournamentsViewPage> = () => {
   const nav = useNavigate();
   const theme = useTheme();
   let [searchParams, setSearchParams] = useSearchParams();
   const {
      getAllTournaments: { call: getAllTournaments, responseData: getAllTournamentsResponseData },
   } = useTournamentApi();
   const user = useCurrentUser();
   const [textFilterTemp, setTextFilterTemp] = useState(searchParams.get("searchText"));
   const [searchActiveTimeout, setSearchActiveTimeout] = useState<Maybe<number>>(null);
   const remoteTournament = useRemoteTournament();
   const filterFutureEvents =
      searchParams.get("filterFutureEvents") == null || searchParams.get("filterFutureEvents") === "true";
   const filterOrganisedByMe = searchParams.get("filterOrganisedByMe") == "true";
   const filterByText = searchParams.get("filterByText");
   const filterByStatus = searchParams.get("filterByStatus");
   const filterJoinedByMe = searchParams.get("filterJoinedByMe") == "true";
   const fullFilterParams = useMemo(
      () => ({
         filterFutureEvents: filterFutureEvents + "",
         filterOrganisedByMe: filterOrganisedByMe + "",
         filterJoinedByMe: filterJoinedByMe + "",
         filterByText: filterByText == null ? "" : filterByText,
         filterByStatus: filterByStatus == null ? "" : filterByStatus,
      }),
      [filterByStatus, filterByText, filterFutureEvents, filterJoinedByMe, filterOrganisedByMe]
   );
   const statusFilterToSearch =
      filterByStatus != null
         ? filterByStatus === "ANY" || filterByStatus === ""
            ? null
            : (filterByStatus as TournamentStatus)
         : null;
   const statusFilterToSelect = statusFilterToSearch == null ? "ANY" : statusFilterToSearch;

   const [openCreateDialog, setOpenCreateDialog] = useState(false);

   const [searchingTournaments, setSearchTournaments] = useState<boolean>(false);

   const allTournaments = useMemo(() => {
      return getAllTournamentsResponseData ? getAllTournamentsResponseData._embedded.tournaments : [];
   }, [getAllTournamentsResponseData]);

   useEffect(() => {
      setSearchTournaments(true);
      getAllTournaments({
         queryParams: {
            page: 0,
            size: 25,
            projection: "summary",
            sort: "startDate,desc",
            afterDate: filterFutureEvents ? new Date().toISOString() : null,
            organiserUserId: filterOrganisedByMe ? user.user?.uid : null,
            joinedUserId: filterJoinedByMe ? user.user?.uid : null,
            status: statusFilterToSearch,
            searchText: filterByText,
         },
      }).finally(() => setSearchTournaments(false));
   }, [
      filterByStatus,
      filterByText,
      filterFutureEvents,
      filterJoinedByMe,
      filterOrganisedByMe,
      getAllTournaments,
      statusFilterToSearch,
      user.user?.uid,
   ]);

   const userSearchHandler: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = useCallback(
      (ev) => {
         if (searchActiveTimeout != null) {
            clearTimeout(searchActiveTimeout);
            setSearchActiveTimeout(null);
         }
         const timeOutId = window.setTimeout(() => {
            setSearchParams({ ...fullFilterParams, filterByText: ev.target.value });
         }, 500);
         setTextFilterTemp(ev.target.value);
         setSearchActiveTimeout(timeOutId);
      },
      [fullFilterParams, searchActiveTimeout, setSearchParams]
   );

   const handleCreateButtonClick = useCallback(() => setOpenCreateDialog(true), []);

   const tournamentTiles = useMemo(() => {
      if (searchingTournaments) return <CircularProgress size={80} />;
      if (allTournaments.length > 0) {
         return allTournaments.map((tournamentSummary) => (
            <TournamentTile key={tournamentSummary.id} tournamentSummary={tournamentSummary} />
         ));
      } else {
         return (
            <Paper
               elevation={4}
               sx={{
                  padding: theme.spacing(4),
                  display: "flex",
                  flexDirection: "column",
                  gap: theme.spacing(1),
               }}
            >
               <Typography variant={"h6"}>{"No tournaments match your filters!"}</Typography>
               {user.loggedIn && (
                  <Button variant={"contained"} onClick={handleCreateButtonClick}>
                     {"Create your tournament!"}
                  </Button>
               )}
            </Paper>
         );
      }
   }, [allTournaments, handleCreateButtonClick, searchingTournaments, theme, user.loggedIn]);

   return (
      <Grow in={true} timeout={1000}>
         <Box id={"tournamentsViewPageRoot"} style={tournamentsViewPageRootCss}>
            <Paper sx={{ width: "100%" }}>
               <WelcomeBanner onCreateClicked={handleCreateButtonClick} />
               <Paper
                  sx={{
                     display: "flex",
                     flexWrap: "wrap",
                     alignItems: "center",
                     marginLeft: theme.spacing(1),
                     marginRight: theme.spacing(1),
                     paddingBottom: theme.spacing(2),
                     paddingTop: theme.spacing(2),
                     gap: theme.spacing(1),
                  }}
               >
                  {user.loggedIn && (
                     <Button variant={"contained"} onClick={handleCreateButtonClick}>
                        {"Create your tournament!"}
                     </Button>
                  )}
                  <TextField
                     size={"small"}
                     variant={"outlined"}
                     label={"Search"}
                     value={textFilterTemp}
                     onChange={userSearchHandler}
                  />
                  <FormGroup row>
                     <FormControlLabel
                        control={
                           <Checkbox
                              checked={filterFutureEvents}
                              onChange={() => {
                                 setSearchParams({
                                    ...fullFilterParams,
                                    filterFutureEvents: String(!filterFutureEvents),
                                 });
                              }}
                           />
                        }
                        label="Future events only"
                     />
                  </FormGroup>
                  {user.user && (
                     <FormGroup row>
                        <FormControlLabel
                           control={
                              <Checkbox
                                 checked={filterOrganisedByMe}
                                 onChange={() => {
                                    setSearchParams({
                                       ...fullFilterParams,
                                       filterOrganisedByMe: String(!filterOrganisedByMe),
                                    });
                                 }}
                              />
                           }
                           label="Organised by me"
                        />
                     </FormGroup>
                  )}
                  {user.user && (
                     <FormGroup row>
                        <FormControlLabel
                           control={
                              <Checkbox
                                 checked={filterJoinedByMe}
                                 onChange={() => {
                                    setSearchParams({
                                       ...fullFilterParams,
                                       filterJoinedByMe: String(!filterJoinedByMe),
                                    });
                                 }}
                              />
                           }
                           label="Joined/Invited"
                        />
                     </FormGroup>
                  )}
                  <FormGroup>
                     <Select
                        sx={{ minWidth: "200px" }}
                        labelId="status-label"
                        id="demo-simple-select"
                        label={"Status"}
                        value={statusFilterToSelect}
                        onChange={(event) => {
                           event.target.value != null &&
                              setSearchParams({
                                 ...fullFilterParams,
                                 filterByStatus: event.target.value,
                              });
                        }}
                     >
                        <MenuItem value={"SCHEDULED"}>Scheduled</MenuItem>
                        <MenuItem value={"STARTED"}>Started</MenuItem>
                        <MenuItem value={"ENDED"}>Ended</MenuItem>
                        <MenuItem value={"ANY"}>Any</MenuItem>
                     </Select>
                  </FormGroup>
               </Paper>
            </Paper>
            <Box
               sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "flex-start",
                  justifyContent: "space-evenly",
                  gap: theme.spacing(4),
                  minHeight: "100%",
                  width: "100%",
               }}
            >
               {tournamentTiles}
            </Box>
            <Footer />

            <TournamentsCreationEditDialog
               open={openCreateDialog}
               remoteTournament={remoteTournament}
               onClose={() => setOpenCreateDialog(false)}
            />
         </Box>
      </Grow>
   );
};

export default TournamentsViewPage;
