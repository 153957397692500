import { useMemo } from "react";
import { useAxiosInstance } from "./useAxiosInstance";
import useRequest from "./useRequest";
import { RoundFullResponse } from "./useStagesApi";
import { EmbeddedResponse } from "./useApplicationApi";
import { RegistrationSummary } from "./useTournamentApi";

export function useRoundApi() {
   const instance = useAxiosInstance();

   const api = {
      deleteRound: useRequest<void, { id: string }, never, never>(
         useMemo(
            () => ({
               url: "/rounds/{id}/delete",
               method: "delete",
            }),
            []
         ),
         instance
      ),
      activateRound: useRequest<RoundFullResponse, { id: string }, never, never>(
         useMemo(
            () => ({
               url: "/rounds/{id}/activate",
               method: "post",
            }),
            []
         ),
         instance
      ),
      closeRound: useRequest<RoundFullResponse, { id: string }, never, never>(
         useMemo(
            () => ({
               url: "/rounds/{id}/close",
               method: "post",
            }),
            []
         ),
         instance
      ),
      editRound: useRequest<RoundFullResponse, { id: string }, EditRoundRequest, never>(
         useMemo(
            () => ({
               url: "/rounds/{id}/update",
               method: "post",
            }),
            []
         ),
         instance
      ),
      addBye: useRequest<RoundFullResponse, { id: string }, AddBuyRequest, never>(
         useMemo(
            () => ({
               url: "/rounds/{id}/byes",
               method: "post",
            }),
            []
         ),
         instance
      ),
      autoPair: useRequest<RoundFullResponse, { id: string }, AddBuyRequest, never>(
         useMemo(
            () => ({
               url: "/rounds/{id}/autoPair",
               method: "post",
            }),
            []
         ),
         instance
      ),
      getByes: useRequest<
         EmbeddedResponse<{ registrations: RegistrationSummary[] }>,
         {
            id: string;
         },
         never,
         never
      >(
         useMemo(
            () => ({
               url: "/rounds/{id}/roundByes?projection=summary",
               method: "get",
            }),
            []
         ),
         instance
      ),
      deleteBye: useRequest<void, { id: string; registrationId: string }, never, never>(
         useMemo(
            () => ({
               url: "/rounds/{id}/byes/{registrationId}",
               method: "delete",
            }),
            []
         ),
         instance
      ),
   };
   const loading = Object.values(api).some((r) => Boolean(r.loading));
   return { ...api, loading };
}

export interface AddBuyRequest {
   registrationId: string;
}

export interface EditRoundRequest {
   roundEndDate: Date | null;
}
