import { useCallback, useMemo } from "react";
import { MutableGame } from "../../../../gameFeatures/MutableGame";
import { RemoteTournament } from "../../../useRemoteTournament";
import { RegistrationSummary } from "../../../../rest.client/useTournamentApi";
import { useApplicationApi, UserSummary } from "../../../../rest.client/useApplicationApi";
import { Consumer } from "../../../../utils";
import { CommanderPair } from "../../../../cardFeatures/CardTypes";
import { Maybe } from "../../../../TYPE";

export interface TournamentGameAdapter {
   regPlayer1: Maybe<RegistrationSummary>;
   regPlayer2: Maybe<RegistrationSummary>;
   regPlayer3: Maybe<RegistrationSummary>;
   regPlayer4: Maybe<RegistrationSummary>;
   setPlayer1Reg: (reg: RegistrationSummary) => Promise<void>;
   setPlayer2Reg: (reg: RegistrationSummary) => Promise<void>;
   setPlayer3Reg: (reg: RegistrationSummary) => Promise<void>;
   setPlayer4Reg: (reg: RegistrationSummary) => Promise<void>;
   mutableGame: MutableGame;
   remoteTournament: RemoteTournament;
   usedRegistrations: RegistrationSummary[];
}

export default function useTournamentGameAdapter(
   mutableGame: MutableGame,
   remoteTournament: RemoteTournament
): TournamentGameAdapter {
   const {
      findCardExtendedInfoById: { call: getCardExtInf },
   } = useApplicationApi();

   const regPlayer1 = remoteTournament.tournament?.registrations.find(
      (r) => r.user.id === mutableGame.player1Summary?.id
   );
   const regPlayer2 = remoteTournament.tournament?.registrations.find(
      (r) => r.user.id === mutableGame.player2Summary?.id
   );
   const regPlayer3 = remoteTournament.tournament?.registrations.find(
      (r) => r.user.id === mutableGame.player3Summary?.id
   );
   const regPlayer4 = remoteTournament.tournament?.registrations.find(
      (r) => r.user.id === mutableGame.player4Summary?.id
   );

   const setPlayerReg = useCallback(
      async (reg: RegistrationSummary, setUser: Consumer<UserSummary>, setPair: Consumer<CommanderPair>) => {
         setUser(reg.user);
         const card1 = await getCardExtInf({ pathParams: { id: reg.commander1.id } });
         const card2 = reg.commander2 != null ? await getCardExtInf({ pathParams: { id: reg.commander2.id } }) : null;

         setPair({ commander1: card1, commander2: card2 });
      },
      [getCardExtInf]
   );
   const setPlayer1Reg = useCallback(
      (reg: RegistrationSummary) =>
         setPlayerReg(reg, mutableGame.setPlayer1Summary, mutableGame.setPlayer1CommanderPair),
      [mutableGame.setPlayer1CommanderPair, mutableGame.setPlayer1Summary, setPlayerReg]
   );
   const setPlayer2Reg = useCallback(
      (reg: RegistrationSummary) =>
         setPlayerReg(reg, mutableGame.setPlayer2Summary, mutableGame.setPlayer2CommanderPair),
      [mutableGame.setPlayer2CommanderPair, mutableGame.setPlayer2Summary, setPlayerReg]
   );
   const setPlayer3Reg = useCallback(
      (reg: RegistrationSummary) =>
         setPlayerReg(reg, mutableGame.setPlayer3Summary, mutableGame.setPlayer3CommanderPair),
      [mutableGame.setPlayer3CommanderPair, mutableGame.setPlayer3Summary, setPlayerReg]
   );
   const setPlayer4Reg = useCallback(
      (reg: RegistrationSummary) =>
         setPlayerReg(reg, mutableGame.setPlayer4Summary, mutableGame.setPlayer4CommanderPair),
      [mutableGame.setPlayer4CommanderPair, mutableGame.setPlayer4Summary, setPlayerReg]
   );
   const usedRegistrations: RegistrationSummary[] = useMemo(() => {
      const regs = [];
      regPlayer1 && regs.push(regPlayer1);
      regPlayer2 && regs.push(regPlayer2);
      regPlayer3 && regs.push(regPlayer3);
      regPlayer4 && regs.push(regPlayer4);
      return regs;
   }, [regPlayer1, regPlayer2, regPlayer3, regPlayer4]);

   return useMemo(
      () => ({
         regPlayer1,
         regPlayer2,
         regPlayer3,
         regPlayer4,
         setPlayer1Reg,
         setPlayer2Reg,
         setPlayer3Reg,
         setPlayer4Reg,
         usedRegistrations,
         mutableGame,
         remoteTournament,
      }),
      [
         regPlayer1,
         regPlayer2,
         regPlayer3,
         regPlayer4,
         setPlayer1Reg,
         setPlayer2Reg,
         setPlayer3Reg,
         setPlayer4Reg,
         usedRegistrations,
         mutableGame,
         remoteTournament,
      ]
   );
}
