import React from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { RegistrationSummary } from "../../rest.client/useTournamentApi";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";

export default function DecklistLock({ registration }: { registration: RegistrationSummary }) {
   const theme = useTheme();
   const isMobileDevice = !useMediaQuery("(min-width:600px)");

   return (
      <Box>{registration.decklistLocked ? <LockIcon color={"success"} /> : <LockOpenIcon color={"warning"} />}</Box>
   );
}
