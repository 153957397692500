import React from "react";
import { Consumer } from "../utils";
import { InputLabel, MenuItem, Select, useTheme } from "@mui/material";
import { PlayerPosition } from "./viewGamePage/GameViewer";
import { Maybe } from "../TYPE";
import { SxProps } from "@mui/system";
import FormControl from "@mui/material/FormControl";

export default function WinnerDropDown({
   winningUser,
   setWinningUser,
   editable = true,
   rootStyle,
}: {
   winningUser: Maybe<PlayerPosition>;
   setWinningUser: Consumer<Maybe<PlayerPosition>>;
   editable?: boolean;
   rootStyle?: SxProps;
}) {
   const theme = useTheme();
   return (
      <FormControl size={"medium"} sx={rootStyle}>
         <InputLabel id="winner-label">Winner</InputLabel>
         <Select
            sx={{ width: "100%", height: "100%" }}
            readOnly={!editable}
            value={winningUser == null ? "DRAW" : winningUser}
            labelId={"winner-label"}
            onChange={(event) => {
               if (event.target.value == null || event.target.value === "DRAW") {
                  setWinningUser(null);
               } else {
                  setWinningUser(event.target.value as PlayerPosition);
               }
            }}
         >
            <MenuItem value={"PLAYER_1"}>Player 1</MenuItem>
            <MenuItem value={"PLAYER_2"}>Player 2</MenuItem>
            <MenuItem value={"PLAYER_3"}>Player 3</MenuItem>
            <MenuItem value={"PLAYER_4"}>Player 4</MenuItem>
            <MenuItem value={"DRAW"}>Draw</MenuItem>
         </Select>
      </FormControl>
   );
}
