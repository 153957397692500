import React from "react";
import useCurrentUser from "../auth/useCurrentUser";
import { useLocation, useNavigate } from "react-router-dom";
import { LOG_GAME_PATH, MY_GAMES_PATH } from "../index";
import { toMetaInsights } from "../navPaths";
import { Box, Link, Tab, Tabs, useTheme } from "@mui/material";
import MyGamesTabLabel from "./MyGamesTabLabel";
import { FaDiscord } from "react-icons/fa";

const HeaderNavigator: React.FC = () => {
   const user = useCurrentUser();
   const nav = useNavigate();
   const theme = useTheme();
   const location = useLocation();

   const path = location.pathname;
   let selected = path.includes("/insights/") ? "INSIGHTS" : "";
   selected = path === "/" || path.includes("/tournaments/") ? "BRAINSTORM" : selected;
   selected = path.includes(MY_GAMES_PATH) ? "MY_GAMES" : selected;
   selected = path.includes(LOG_GAME_PATH) ? "LOG_GAME" : selected;

   return (
      <Box>
         <Tabs value={selected} variant="scrollable" scrollButtons="auto" allowScrollButtonsMobile>
            <Tab value={"BRAINSTORM"} onClick={() => nav("/")} label={"Brainstorm TMS"} />
            <Tab value={"INSIGHTS"} onClick={() => nav(toMetaInsights())} label={"Insights"} />
            {user.loggedIn && <Tab value={"MY_GAMES"} onClick={() => nav(MY_GAMES_PATH)} label={<MyGamesTabLabel />} />}
            {user.loggedIn && <Tab value={"LOG_GAME"} onClick={() => nav(LOG_GAME_PATH)} label={"Log personal game"} />}

            <Tab
               value={"DISCORD"}
               label={
                  <Link target={"_blank"} rel="noopener" href={"https://discord.gg/DAQ6SJaa6M"}>
                     <Box sx={{ display: "flex", alignItems: "center", gap: theme.spacing(1) }}>
                        <FaDiscord />
                        Discord
                     </Box>
                  </Link>
               }
            />
         </Tabs>
      </Box>
   );
};

export default HeaderNavigator;
