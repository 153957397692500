import React from "react";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { CartesianGrid, Legend, Line, LineChart, Tooltip, XAxis, YAxis } from "recharts";
import { GamesByTurn } from "../cardFeatures/CardTypes";

export const INSIGHTS_CHARTS_MOBILE_WIDTH = 400;
export default function InsightsWinsByTurn({ data }: { data: Array<GamesByTurn> }) {
   const theme = useTheme();
   const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));
   return (
      <Box>
         <Typography variant={"h6"} textAlign={"center"}>
            Turns
         </Typography>
         <LineChart
            width={smallScreen ? INSIGHTS_CHARTS_MOBILE_WIDTH : 500}
            height={300}
            syncId="turn"
            data={data}
            margin={{
               top: 5,
               right: 30,
               left: 20,
               bottom: 5,
            }}
         >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="turn" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="total" stroke="#8884d8" activeDot={{ r: 8 }} />
            <Line type="monotone" dataKey="wins" stroke={theme.palette.primary.main} />
            <Line type="monotone" dataKey="losses" stroke="#BB3524" />
         </LineChart>
         <LineChart
            width={smallScreen ? INSIGHTS_CHARTS_MOBILE_WIDTH : 500}
            height={300}
            syncId="turn"
            data={data}
            margin={{
               top: 5,
               right: 30,
               left: 20,
               bottom: 5,
            }}
         >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="turn" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="winRate" stroke={theme.palette.primary.main} />
         </LineChart>
      </Box>
   );
}
