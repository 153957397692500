import React from "react";
import { Tab, Tabs, useTheme } from "@mui/material";
import { toCommanderInsights, toLeaderBoard, toMetaInsights, toPlayerInsights, toPopularCommanders } from "../navPaths";
import { useNavigate } from "react-router-dom";

export default function InsightsSelectorBanner({}: {}) {
   const theme = useTheme();
   const nav = useNavigate();
   const url = window.location.toString();
   let selected = url.includes("insights/player") ? "PLAYER" : "";
   selected = url.includes("insights/meta") ? "META" : selected;
   selected = url.includes("insights/commander") ? "COMMANDER" : selected;
   selected = url.includes("insights/leaderboard") ? "LEADERBOARD" : selected;
   selected = url.includes("insights/popularCommanders") ? "POPULAR_COMMANDERS" : selected;

   return (
      <Tabs value={selected} variant="scrollable" scrollButtons="auto" allowScrollButtonsMobile>
         <Tab value={"META"} onClick={() => nav(toMetaInsights())} label={"Meta insights"} />
         <Tab value={"PLAYER"} onClick={() => nav(toPlayerInsights())} label={"Player insights"} />
         <Tab value={"COMMANDER"} onClick={() => nav(toCommanderInsights())} label={"Commander insights"} />
         <Tab value={"LEADERBOARD"} onClick={() => nav(toLeaderBoard())} label={"Elo leaderboard"} />
         <Tab value={"POPULAR_COMMANDERS"} onClick={() => nav(toPopularCommanders())} label={"Popular commanders"} />
      </Tabs>
   );
}
