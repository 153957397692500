import React, { useState } from "react";
import {
   Accordion,
   AccordionDetails,
   AccordionSummary,
   Alert,
   Box,
   Button,
   FormControl,
   InputLabel,
   MenuItem,
   Paper,
   Select,
   Table,
   TableBody,
   TableCell,
   TableContainer,
   TableHead,
   TableRow,
   Typography,
   useTheme,
} from "@mui/material";
import { RemoteStage } from "../stageView/useRemoteStage";
import { RemoteMatch } from "../stageView/useRemoteMatch";
import useReload from "../../../header/useReload";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RegistrationSelector from "./editDialog/RegistrationSelector";
import { RegistrationSummary } from "../../../rest.client/useTournamentApi";
import { Maybe } from "../../../TYPE";
import { Result, singletonResults } from "../../../rest.client/useStagesApi";

export default function MatchResultsAccordian({
   remoteStage,
   remoteMatch,
}: {
   remoteMatch: RemoteMatch;
   remoteStage: RemoteStage;
}) {
   const theme = useTheme();
   const { requestReload } = useReload();
   const match = remoteMatch.match;
   const results = match?.matchResults || [];
   const [expanded, setExpanded] = useState(false);
   const [reg, setReg] = useState<Maybe<RegistrationSummary>>();
   const overrideRegs = match?.resultOverrides.map((rO) => rO.registration) || [];
   const allRegsInMatch = match?.matchResults.map((mR) => mR.registration);
   const [result, setResult] = useState<Result>("DRAW");
   const canEdit = remoteStage.canEdit;
   const supportedOverrides = remoteStage.stage?.supportedResultOverrides || [];
   const usedOverrides = match?.resultOverrides.map((rO) => rO.result) || [];
   const allowedOverrides = supportedOverrides.filter(
      (o) => !singletonResults.includes(o) || !usedOverrides.includes(o)
   );

   return (
      <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
         <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ExpandMoreIcon />}>
            <Typography>Match results and overrides</Typography>
         </AccordionSummary>
         <AccordionDetails sx={{ display: "flex", flexDirection: "column", gap: theme.spacing(2) }}>
            {remoteMatch.anyGamesPending && (
               <Alert severity="warning" sx={{ cursor: "pointer" }}>
                  Some games are still pending review. Result override will not be available until all individual games
                  have been reviewed.
               </Alert>
            )}
            {!remoteMatch.anyGamesPending && (
               <Box>
                  {canEdit && (
                     <Box sx={{ display: "flex", gap: theme.spacing(2), flexWrap: "wrap" }}>
                        <RegistrationSelector
                           reg={reg}
                           setRegistration={setReg}
                           inUseRegs={overrideRegs}
                           registrations={allRegsInMatch}
                           readOnly={false}
                        />
                        <FormControl>
                           <InputLabel id="demo-simple-select-label">Override result as</InputLabel>
                           <Select
                              sx={{ minWidth: "200px" }}
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={result}
                              label="Override result as"
                              onChange={(event) => setResult(event.target.value as Result)}
                           >
                              {allowedOverrides.map((sOr) => {
                                 return <MenuItem value={sOr}>{sOr === "FIRST" ? "WIN" : sOr}</MenuItem>;
                              })}
                           </Select>
                        </FormControl>
                        <Button
                           variant={"contained"}
                           disabled={reg == null}
                           onClick={async () => {
                              if (reg) {
                                 await remoteMatch.saveOverride({
                                    overrideResultAs: result,
                                    registrationId: reg?.id,
                                 });
                                 setResult("DRAW");
                                 requestReload();
                              }
                           }}
                        >
                           Override
                        </Button>
                     </Box>
                  )}
                  <TableContainer component={Paper}>
                     <Table sx={{}} aria-label="simple table" size={"small"}>
                        <TableHead>
                           <TableRow>
                              <TableCell>Player</TableCell>
                              <TableCell>Result override</TableCell>
                              {canEdit && <TableCell></TableCell>}
                           </TableRow>
                        </TableHead>
                        <TableBody>
                           {match?.resultOverrides.map((override) => (
                              <TableRow>
                                 <TableCell>{override.registration.user.name}</TableCell>
                                 <TableCell>{override.result === "FIRST" ? "WIN" : override.result}</TableCell>
                                 {canEdit && (
                                    <TableCell>
                                       <Button
                                          onClick={() => {
                                             remoteMatch.deleteOverride(override.registration.id);
                                             requestReload();
                                          }}
                                       >
                                          Delete
                                       </Button>
                                    </TableCell>
                                 )}
                              </TableRow>
                           ))}
                        </TableBody>
                     </Table>
                     {match?.resultOverrides.length === 0 && (
                        <Alert severity={"info"}>No result overrides found for match.</Alert>
                     )}
                  </TableContainer>
               </Box>
            )}
            <TableContainer component={Paper}>
               <Table sx={{}} aria-label="simple table" size={"small"}>
                  <TableHead>
                     <TableRow>
                        <TableCell>Player</TableCell>
                        <TableCell>Result</TableCell>
                     </TableRow>
                  </TableHead>
                  <TableBody>
                     {results.map((r) => (
                        <TableRow>
                           <TableCell>{r.registration.user.name}</TableCell>
                           <TableCell>{r.result === "FIRST" ? "WIN" : r.result}</TableCell>
                        </TableRow>
                     ))}
                  </TableBody>
               </Table>
               {results.length === 0 && (
                  <Alert severity={"info"}>No match results found. Please add games to match.</Alert>
               )}
            </TableContainer>
         </AccordionDetails>
      </Accordion>
   );
}
