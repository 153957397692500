import React, { useCallback, useEffect, useMemo } from "react";
import { SxProps } from "@mui/system";
import { Alert, Box, IconButton, Snackbar } from "@mui/material";
import useGlobal from "../global/useGlobal";
import { GLOBAL_VAR_NAME } from "../UI_CONST";
import { Maybe, TMessageItem } from "../TYPE";
import CloseIcon from "@mui/icons-material/Close";

const closeButtonSx: SxProps = {
   padding: 0,
   marginLeft: "5px",
};

const Messages: React.FC = () => {
   const [messageItems] = useGlobal<Array<TMessageItem>>(GLOBAL_VAR_NAME.MESSAGES_ITEMS, []);
   const [currentMessageKey, setCurrentMessageKey] = React.useState<string>("");
   const [message, setMessage] = React.useState<string | null>(null);

   const showMessage = useMemo(() => message !== null, [message]);
   const currentMessageItem: Maybe<TMessageItem> = useMemo(() => messageItems[messageItems.length - 1], [messageItems]);

   const hasNewMessageItem = useMemo(() => {
      return messageItems.length > 0 && currentMessageItem?.key !== currentMessageKey;
   }, [currentMessageItem, currentMessageKey, messageItems.length]);

   useEffect(() => {
      if (hasNewMessageItem) {
         setCurrentMessageKey(currentMessageItem?.key);
         setMessage(currentMessageItem?.message);
      }
   }, [currentMessageItem, hasNewMessageItem, messageItems]);

   const handleClose = useCallback((event: React.SyntheticEvent | Event, reason?: string) => {
      if (reason !== "clickaway") {
         setMessage(null);
      }
   }, []);

   return (
      <Snackbar
         key={currentMessageKey}
         open={showMessage}
         autoHideDuration={currentMessageItem ? currentMessageItem.autoHideDuration : 3000}
         onClose={handleClose}
         anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
         <Alert severity={currentMessageItem?.level || "info"}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
               <Box>{message}</Box>
               <IconButton sx={closeButtonSx} size={"small"} color={"secondary"} onClick={handleClose}>
                  <CloseIcon />
               </IconButton>
            </Box>
         </Alert>
      </Snackbar>
   );
};

export default Messages;
