import React, {CSSProperties, useMemo, useState} from "react";
import {Box, Theme, Typography} from "@mui/material";
import {SxProps} from "@mui/system";
import {DECK_VIEWER_CARD_COLUMN_WIDTH_IN_VW, DECK_VIEWER_CARD_HEIGHT_IN_VW, DECK_VIEWER_CARD_OFFSET_RATIO} from "../../UI_CONST";
import {CardNameAndImage} from "../../cardFeatures/CardTypes";
import DeckViewerCard from "./DeckViewerCard";
import {Maybe} from "../../TYPE";
import {STRONG_BOX_SHADOW} from "../tournaments2dComponents/TOURNAMENTS_2D_CONST";

const columnSx: SxProps = {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    width: `${DECK_VIEWER_CARD_COLUMN_WIDTH_IN_VW}vw`,
    height: "auto",
    "&:hover": {
        "& .deckviewerColumnTitle": {
            opacity: 1,
            transform: "scale(1.1)",
        },
    }
}

const cardsContainerBasicCss: CSSProperties = {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    width: "100%",
}

const columnTitleSx: SxProps<Theme> = {
    position: "relative",
    pointerEvents: "none",
    textShadow: (theme) => `0 0 1vmin ${theme.palette.textShadow1}`,
    opacity: 0.7,
    transition: "transform 0.2s, opacity 0.3s",
}

const cardPlaceholderSx: SxProps = {
    position: "absolute",
    width: `${DECK_VIEWER_CARD_COLUMN_WIDTH_IN_VW}vw`,
    height: `${DECK_VIEWER_CARD_HEIGHT_IN_VW}vw`,
    boxShadow: STRONG_BOX_SHADOW,
    borderRadius: "4%",
    pointerEvents: "none",

}


interface IDeckViewerCardColumn {
    columnTitle?: string,
    cards: Array<CardNameAndImage>
}

const DeckViewerCardColumn: React.FC<IDeckViewerCardColumn> = ({cards, columnTitle}) => {
    const [highlightedCardIndex, setHighlightedCardIndex] = useState<Maybe<number>>(null);
    const cardsToShow = useMemo(() => cards.map((card, i) =>
            <DeckViewerCard key={i} index={i} card={card}
                            highlightedCardIndex={highlightedCardIndex}
                            setHighlightedCardIndex={setHighlightedCardIndex}
            />),
        [cards, highlightedCardIndex]);

    const cardsContainerCss: CSSProperties = useMemo(() => {
        return {
            ...cardsContainerBasicCss,
            height: `${cardsToShow.length * DECK_VIEWER_CARD_HEIGHT_IN_VW * DECK_VIEWER_CARD_OFFSET_RATIO + DECK_VIEWER_CARD_HEIGHT_IN_VW}vw`,
        }
    }, [cardsToShow.length]);

    return cardsToShow.length > 0 ?
        <Box sx={columnSx}>
            {columnTitle && <Typography className={"deckviewerColumnTitle"} sx={columnTitleSx} align={"center"} variant={"h6"}>{`${columnTitle}(${cards.length})`}</Typography>}
            <div style={cardsContainerCss}>
                {cardsToShow}
            </div>
        </Box> : <></>;
}

export default DeckViewerCardColumn;
