import React, { useEffect, useState } from "react";
import {
   Box,
   Button,
   Dialog,
   DialogActions,
   DialogContent,
   DialogContentText,
   DialogTitle,
   FormControl,
   FormLabel,
   TextField,
   Typography,
   useTheme,
} from "@mui/material";
import { Procedure } from "../../utils";
import { RemoteRound } from "../viewPage/stageView/useRemoteRound";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";

export default function RoundTimerEditDialog({
   open,
   onClose,
   remoteRound,
}: {
   open: boolean;
   onClose: Procedure;
   remoteRound: RemoteRound;
}) {
   const theme = useTheme();
   const [mins, setMins] = useState<number>(0);
   const [secs, setSecs] = useState<number>(0);

   const valid = secs >= 0 && mins >= 0;
   useEffect(() => {
      setSecs(remoteRound.secondsUntilTimerEnd % 60 > 0 ? remoteRound.secondsUntilTimerEnd % 60 : 0);
      setMins(
         Math.floor(remoteRound.secondsUntilTimerEnd / 60) > 0 ? Math.floor(remoteRound.secondsUntilTimerEnd / 60) : 0
      );
   }, [remoteRound.secondsUntilTimerEnd]);
   return (
      <Dialog
         open={open}
         aria-labelledby="alert-dialog-title"
         aria-describedby="alert-dialog-description"
         onKeyUp={(event) => {
            if (event.key === "Escape") {
               onClose();
            }
         }}
      >
         <DialogTitle id="alert-dialog-title">Set round timer</DialogTitle>
         <DialogContent>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
               <DialogContentText id="alert-dialog-description">
                  <Typography></Typography>
                  <FormControl fullWidth sx={{ display: "flex", gap: theme.spacing(2) }}>
                     <FormLabel>{"Set by time"}</FormLabel>
                     <TextField
                        required
                        size={"medium"}
                        type={"number"}
                        variant={"outlined"}
                        label={"Minutes"}
                        onChange={(event) => setMins(Number.parseInt(event.target.value))}
                        value={mins > 0 ? mins : 0}
                        inputProps={{ maxLength: 3 }}
                     />
                     <TextField
                        required
                        size={"medium"}
                        type={"number"}
                        variant={"outlined"}
                        label={"Seconds"}
                        onChange={(event) => setSecs(Number.parseInt(event.target.value))}
                        value={secs > 0 ? secs : 0}
                        inputProps={{ maxLength: 3 }}
                     />
                  </FormControl>

                  <Box></Box>
               </DialogContentText>
            </LocalizationProvider>
         </DialogContent>
         <DialogActions>
            <Button
               disabled={!valid}
               onClick={async () => {
                  await remoteRound.edit({
                     roundEndDate: dayjs(new Date()).add(mins, "minutes").add(secs, "seconds").toDate(),
                  });
                  onClose();
               }}
               color={"secondary"}
            >
               Start
            </Button>
            <Button
               onClick={async () => {
                  await remoteRound.edit({
                     roundEndDate: null,
                  });
               }}
            >
               Stop timer
            </Button>
            <Button onClick={onClose} autoFocus>
               Cancel
            </Button>
         </DialogActions>
      </Dialog>
   );
}
