export const DEFAULT_PADDING_IN_PX = 5;
export const LARGE_PADDING_IN_PX = 10;
export const XLARGE_PADDING_IN_PX = 20;

export const LIGHT_BOX_SHADOW = "0px 0px 3px 0.5px rgba(0,0,0,0.1)";
export const DEFAULT_BOX_SHADOW = "0px 0px 3px 0.5px rgba(0,0,0,0.3)";
export const STRONG_BOX_SHADOW = "0 0 5px 1px rgba(0,0,0,0.7)";
export const DEFAULT_BLUR = "blur(5px)";
export const DEFAULT_BORDER_RADIUS_IN_PX = 5;

export const TOURNAMENT_CREATION_PAGE_PANEL_WIDTH_IN_PX = 600;

export const PLAYER_BOX_HEIGHT_IN_PX = 50;
export const PLAYER_BOX_NAME_FIELD_WIDTH_IN_PX = 200;

export const GAME_NODE_SIZE_IN_PX = 51;

export const MATCH_BOX_ROW_GAP_IN_PX = 10;
export const MATCH_BOX_COLUMN_GAP_IN_PX = 200;
export const MATCH_BOX_WIDTH_IN_PX = PLAYER_BOX_HEIGHT_IN_PX * 4 + MATCH_BOX_ROW_GAP_IN_PX * 3;
export const MATCH_BOX_HEIGHT_IN_PX = 456;
