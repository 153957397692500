import React, { useMemo } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { Cell, Pie, PieChart, Tooltip } from "recharts";

const RADIAN = Math.PI / 180;

export default function WinLossPie({ wins, losses }: { wins: number; losses: number }) {
   const theme = useTheme();
   const winToLossData = useMemo(() => {
      return [
         { name: "Games won", value: wins },
         { name: "Losses", value: losses },
      ];
   }, [losses, wins]);

   const COLORS = [theme.palette.primary.main, "#BB3524"];

   const renderCustomizedPercentLabel = ({
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      percent,
      index,
   }: {
      cx: number;
      cy: number;
      midAngle: number;
      innerRadius: number;
      outerRadius: number;
      percent: number;
      index: number;
   }) => {
      const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
      const x = cx + radius * Math.cos(-midAngle * RADIAN);
      const y = cy + radius * Math.sin(-midAngle * RADIAN);

      return (
         <text x={x} y={y} fill="white" textAnchor={x > cx ? "start" : "end"} dominantBaseline="central">
            {`${(percent * 100).toFixed(0)}%`}
         </text>
      );
   };

   return (
      <Box sx={{ display: "flex" }}>
         {(wins > 0 || losses > 0) && (
            <Box>
               <Typography variant={"h6"} textAlign={"center"}>
                  W/L
               </Typography>
               <PieChart width={250} height={225}>
                  <Pie
                     dataKey="value"
                     data={winToLossData}
                     cx="50%"
                     cy="50%"
                     labelLine={false}
                     outerRadius={80}
                     fill={"gray"}
                     label={renderCustomizedPercentLabel}
                  >
                     {winToLossData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                     ))}
                  </Pie>
                  <Tooltip />
               </PieChart>
            </Box>
         )}
      </Box>
   );
}
