import { useEffect, useRef } from "react";
import { ANONYMOUS_USER_ID } from "./gameFeatures/PlayerPickerComponent";
import { CardNameAndImage, CommanderPair } from "./cardFeatures/CardTypes";
import { TGroupedByManaCards, TGroupedByTypeCards } from "./TYPE";

const base32Chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ234567".toLowerCase();

export const empty = () => {};

export const isDefined = (target: unknown): boolean => target !== null && target !== undefined;

export const randomNumFromInterval = (min: number, max: number) => {
   return Math.random() * (max - min + 1) + min;
};

export const randomPos = () => {
   return { x: randomNumFromInterval(0, window.innerWidth), y: randomNumFromInterval(0, window.innerHeight) };
};

export function base32UuidToDashFormat(uuid: string) {
   return bytesToUuid(decodeBase32(uuid));
}

export function decodeBase32(base32: string) {
   let bits = "";
   for (const char of base32) {
      const value = base32Chars.indexOf(char);
      if (value === -1) {
         throw new Error("Invalid Base32 character.");
      }
      bits += value.toString(2).padStart(5, "0");
   }

   const bytes = new Uint8Array(bits.length / 8);
   for (let i = 0; i < bits.length; i += 8) {
      bytes[i / 8] = parseInt(bits.substring(i, i + 8), 2);
   }

   return bytes;
}

function bytesToUuid(bytes: Uint8Array) {
   let hex = "";
   bytes.forEach((byte) => {
      hex += byte.toString(16).padStart(2, "0");
   });
   return `${hex.slice(0, 8)}-${hex.slice(8, 12)}-${hex.slice(12, 16)}-${hex.slice(16, 20)}-${hex.slice(20)}`;
}

export function isNumeric(str: string) {
   if (typeof str != "string") return false; // we only process strings!
   return (
      // @ts-ignore
      !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
      !isNaN(parseFloat(str))
   ); // ...and ensure strings of whitespace fail
}

export function shuffleArray<T>(array: T[]) {
   return array
      .map((value) => ({ value, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ value }) => value);
}

export const isNumEven = (num: number): boolean => {
   return num % 2 === 0;
};

export type Consumer<T> = (t: T) => void;

export type Procedure = () => void;

export type Supplier<T> = () => T;

export const identity = (t: any) => t;

export const removeElementFromArrayByIndex = (array: Array<any>, index: number): Array<any> =>
   array.filter((element, i) => i !== index);

export const checkIfDuplicateExists = (arr: Array<string | undefined>) => {
   const nonAnons = arr.filter((p) => p !== ANONYMOUS_USER_ID);
   return new Set(nonAnons).size !== nonAnons.length;
};

export const isPairValid = (pair: CommanderPair) => {
   return !pair.commander1.partner || (pair.commander1.partner && pair.commander2 != null && pair.commander2.partner);
};
const usePrevious = (value: any, initialValue: any) => {
   const ref = useRef(initialValue);
   useEffect(() => {
      ref.current = value;
   });
   return ref.current;
};

export const useEffectDebugger = (effectHook: any, dependencies: Array<any>, dependencyNames = []) => {
   const previousDeps = usePrevious(dependencies, []);

   const changedDeps = dependencies.reduce((accum, dependency, index) => {
      if (dependency !== previousDeps[index]) {
         const keyName = dependencyNames[index] || index;
         return {
            ...accum,
            [keyName]: {
               before: previousDeps[index],
               after: dependency,
            },
         };
      }

      return accum;
   }, {});

   if (Object.keys(changedDeps).length) {
      console.log("[use-effect-debugger] ", changedDeps);
   }

   useEffect(effectHook, dependencies);
};

const cardTypeCsvStringToStringArray = (cardTypeCsv: string): Array<string> => {
   return cardTypeCsv.split(",").map((cardType) => cardType.trim());
};

export const sortCardsByName = (cards: Array<CardNameAndImage>): Array<CardNameAndImage> => {
   return cards.sort((a, b) => a.name.localeCompare(b.name));
};

export function moxfieldUrl(id: string) {
   return "https://www.moxfield.com/decks/" + id;
}

export const groupCardsByType = (cards: Array<CardNameAndImage>): TGroupedByTypeCards => {
   const creatureCards: Array<CardNameAndImage> = [];
   const sorceryCards: Array<CardNameAndImage> = [];
   const instantCards: Array<CardNameAndImage> = [];
   const artifactCards: Array<CardNameAndImage> = [];
   const enchantmentCards: Array<CardNameAndImage> = [];
   const planeswalkerCards: Array<CardNameAndImage> = [];
   const landCards: Array<CardNameAndImage> = [];

   cards.forEach((card) => {
      const typeArray = cardTypeCsvStringToStringArray(card.typesCsv);

      if (typeArray.includes("Creature")) {
         creatureCards.push(card);
      } else if (typeArray.includes("Sorcery")) {
         sorceryCards.push(card);
      } else if (typeArray.includes("Instant")) {
         instantCards.push(card);
      } else if (typeArray.includes("Artifact")) {
         artifactCards.push(card);
      } else if (typeArray.includes("Enchantment")) {
         enchantmentCards.push(card);
      } else if (typeArray.includes("Planeswalker")) {
         planeswalkerCards.push(card);
      } else if (typeArray.includes("Land")) {
         landCards.push(card);
      } else {
         console.error(`"${card.name}" has an unknown type: "${card.typesCsv}"`);
      }
   });

   return {
      creatures: creatureCards,
      sorceries: sorceryCards,
      instants: instantCards,
      artifacts: artifactCards,
      enchantments: enchantmentCards,
      planeswalkers: planeswalkerCards,
      lands: landCards,
   };
};

export const groupCardsByMana = (cards: Array<CardNameAndImage>): TGroupedByManaCards => {
   const oneManaCards: Array<CardNameAndImage> = [];
   const twoManaCards: Array<CardNameAndImage> = [];
   const threeManaCards: Array<CardNameAndImage> = [];
   const fourManaCards: Array<CardNameAndImage> = [];
   const fiveManaAndAboveCards: Array<CardNameAndImage> = [];

   cards.forEach((card) => {
      if (card.manaValue === 1) {
         oneManaCards.push(card);
      } else if (card.manaValue === 2) {
         twoManaCards.push(card);
      } else if (card.manaValue === 3) {
         threeManaCards.push(card);
      } else if (card.manaValue === 4) {
         fourManaCards.push(card);
      } else {
         fiveManaAndAboveCards.push(card);
      }
   });

   return {
      one: sortCardsByName(oneManaCards),
      two: sortCardsByName(twoManaCards),
      three: sortCardsByName(threeManaCards),
      four: sortCardsByName(fourManaCards),
      fiveAndAbove: sortCardsByName(fiveManaAndAboveCards),
   };
};
