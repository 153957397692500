import { useMemo } from "react";
import { useAxiosInstance } from "./useAxiosInstance";
import useRequest from "./useRequest";
import { UserSummary } from "./useApplicationApi";
import { CardNameAndImage } from "../cardFeatures/CardTypes";

export function useDeckListsApi() {
   const instance = useAxiosInstance();

   const api = {
      importDeckList: useRequest<DeckListAllData, never, SaveDeckListRequest, never>(
         useMemo(
            () => ({
               url: "/deckLists/",
               method: "post",
            }),
            []
         ),
         instance
      ),
      refreshDeckList: useRequest<SnapshotAllCards, { id: string }, never, never>(
         useMemo(
            () => ({
               url: "/deckLists/{id}/update/",
               method: "post",
            }),
            []
         ),
         instance
      ),
      getDeckListAllData: useRequest<DeckListAllData, { id: string }, never, never>(
         useMemo(
            () => ({
               url: "/deckLists/{id}/",
               method: "get",
            }),
            []
         ),
         instance
      ),
      getSnapshotAllData: useRequest<SnapshotAllCards, { id: string }, never, never>(
         useMemo(
            () => ({
               url: "/deckListSnapshots/{id}?projection=allCards",
               method: "get",
            }),
            []
         ),
         instance
      ),
   };
   const loading = Object.values(api).some((r) => Boolean(r.loading));
   return { ...api, loading };
}

export interface SaveDeckListRequest {
   url: string;
}

export interface DeckListSummaryResponse {
   id: string;
   creator: UserSummary;
   rawUrl: string;
   name: string;
}

export interface DeckListAllData extends DeckListSummaryResponse {
   latestSnapshot: SnapshotAllCards;
   snapshots: SnapshotSummary[];
}

export interface SnapshotAllCards extends SnapshotSummary {
   mainboardCards: MainboardCard[];
}

export interface MainboardCard {
   card: CardNameAndImage;
   quantity: number;
}

export interface SnapshotSummary {
   id: string;
   deckListId: string;
   commander1?: CardNameAndImage;
   commander2?: CardNameAndImage;
   name: string;
   hash: string;
   createDate: string;
}
