import React, { FC, ReactNode, useMemo } from "react";
import { Box, Fade, Typography, useMediaQuery } from "@mui/material";
import { SxProps } from "@mui/system";
import { LARGE_PADDING_IN_PX, XLARGE_PADDING_IN_PX } from "../tournaments2dComponents/TOURNAMENTS_2D_CONST";
import useGlobal from "../../global/useGlobal";
import { TTournamentsPageState } from "../../TYPE";
import { GLOBAL_VAR_NAME, TournamentCreationPagePanelIndex } from "../../UI_CONST";
import { tournamentsPageDefaultState } from "./TournamentsCreationEditDialog";

const tournamentCreationPagePanelRootSx: SxProps = {
   padding: `${LARGE_PADDING_IN_PX}px`,
   display: "flex",
   flexDirection: "column",
   boxSizing: "border-box",
   alignItems: "center",
   width: "100%",
   rowGap: `${XLARGE_PADDING_IN_PX}px`,
   backgroundColor: "rgb(255,255,255, 0.05)",
};

interface ITournamentCreationPagePanel {
   panelTitle: string;
   panelIndex: TournamentCreationPagePanelIndex;
   children: ReactNode;
}

const Panel: FC<ITournamentCreationPagePanel> = ({ panelIndex, panelTitle, children }) => {
   const isMobile = !useMediaQuery("(min-width:600px)");
   const [tournamentsPageState] = useGlobal<TTournamentsPageState>(
      GLOBAL_VAR_NAME.TOURNAMENTS_DIALOG_STATE,
      tournamentsPageDefaultState
   );

   const showPanel = useMemo(() => {
      if (isMobile) {
         return true;
      } else {
         return panelIndex === tournamentsPageState.creationPageCurrentPanelIndex;
      }
   }, [isMobile, panelIndex, tournamentsPageState.creationPageCurrentPanelIndex]);

   return showPanel ? (
      <Fade in={true} timeout={500}>
         <Box className={"tournamentCreationPagePanel"} sx={tournamentCreationPagePanelRootSx}>
            <Typography variant={"body2"}>{panelTitle}</Typography>
            {children}
         </Box>
      </Fade>
   ) : (
      <> </>
   );
};

export default Panel;
