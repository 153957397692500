import React, { MouseEventHandler } from "react";
import { Avatar, Box, TableCell, Tooltip, useMediaQuery, useTheme } from "@mui/material";
import { CardNameAndImage } from "../../../../cardFeatures/CardTypes";
import { Maybe } from "../../../../TYPE";

export default function CommandersCell({
   commander1,
   commander2,
   onClick,
}: {
   commander1: CardNameAndImage;
   commander2: Maybe<CardNameAndImage>;
   onClick?: MouseEventHandler | undefined;
}) {
   const theme = useTheme();
   const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));
   return (
      <TableCell onClick={onClick}>
         <Tooltip title={commander1.name + (commander2 != null ? " // " + commander2.name : "")}>
            <Box sx={{ display: "flex", cursor: "pointer" }}>
               <Avatar src={commander1.artCropImageUri} />
               {commander2 && <Avatar src={commander2.artCropImageUri} />}
            </Box>
         </Tooltip>
      </TableCell>
   );
}
