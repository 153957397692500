import { DateValidationError } from "@mui/x-date-pickers";

export const dateValidationErrorToErrorMsg = (error: DateValidationError, isForStartDate: boolean) => {
   switch (error) {
      case "invalidDate":
         return "Invalid date";
      case "minDate":
         return isForStartDate ? "Start date must be in the future" : "End date must be after start date";
      case "disablePast":
         return isForStartDate ? "Start date must be in the future" : "End date must be in the future";
      case "maxDate":
         return "Date must be before 2100";
      case null:
         return null;
      default:
         return "Unknown error";
   }
};
