import React from "react";
import { Alert, IconButton, TableCell, TableRow } from "@mui/material";
import { MatchResultWinnerOnlyResponse } from "../../../rest.client/useStagesApi";
import StatusIcon from "../../../gameFeatures/StatusIcon";
import { Consumer } from "../../../utils";
import RegistrationChip from "../../registration/RegistrationChip";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

export default function MatchRow({
   match,
   onClick,
   showNumber = true,
   showDelete = false,
   onClickDelete,
}: {
   match: MatchResultWinnerOnlyResponse;
   onClick: Consumer<MatchResultWinnerOnlyResponse>;
   showNumber?: boolean;
   showDelete?: boolean;
   onClickDelete?: Consumer<MatchResultWinnerOnlyResponse>;
}) {
   return (
      <TableRow key={match.id} sx={{ cursor: "pointer" }} onClick={() => onClick(match)}>
         {showNumber && <TableCell>{match.matchNumber}</TableCell>}
         <TableCell>
            {match.matchResults.map((rO) => {
               return (
                  <RegistrationChip key={rO.registration.id} registration={rO.registration} matchResult={rO.result} />
               );
            })}
            {match.matchResults.length === 0 && <Alert severity={"info"}>No players assigned to this match.</Alert>}
         </TableCell>
         <TableCell>
            <StatusIcon status={match.matchStatus} />
         </TableCell>
         {showDelete && (
            <TableCell>
               <IconButton
                  color={"primary"}
                  onClick={(e) => {
                     e.stopPropagation();
                     onClickDelete && onClickDelete(match);
                  }}
               >
                  <DeleteForeverIcon />
               </IconButton>
            </TableCell>
         )}
      </TableRow>
   );
}
