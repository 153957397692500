import { Dispatch, useCallback, useEffect, useMemo, useState } from "react";
import { v4 } from "uuid";

type UpdaterStoreType = Record<string, Record<string, Dispatch<any>>>;
const superGlobal: { data: Record<string, any>; updater: UpdaterStoreType } = {
   data: {},
   updater: {},
};

function useGlobal<ValueType>(name: string, value: ValueType): [ValueType, (input: ValueType) => void] {
   const [state, setState] = useState<ValueType>(() => {
      if (superGlobal.data[name] === undefined && value !== undefined) {
         superGlobal.data[name] = value;
      }
      return superGlobal.data[name];
   });
   useEffect(() => {
      const id = v4();
      if (state !== superGlobal.data[name]) {
         setState(superGlobal.data[name]);
      }
      if (!superGlobal.updater[name]) {
         superGlobal.updater[name] = {};
      }
      superGlobal.updater[name][id] = setState;
      return () => {
         delete superGlobal.updater[name][id];
         if (Object.keys(superGlobal.updater[name]).length <= 0) {
            delete superGlobal.updater[name];
         }
      };
   }, []);

   const updater = useCallback(
      (input: ValueType) => {
         for (const i in superGlobal.updater[name]) {
            superGlobal.updater[name][i](input);
         }
         superGlobal.data[name] = input;
      },
      [name]
   );

   return useMemo(() => [state, updater], [state, updater]);
}

export default useGlobal;
