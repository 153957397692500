import { Paper, Typography, useTheme } from "@mui/material";

export default function PrivacyPolicy() {
   const theme = useTheme();
   return (
      <Paper sx={{ padding: theme.spacing(1) }}>
         <Typography variant={"h3"}>Privacy Policy</Typography>
         <Typography variant={"h4"}>Effective date: 23 October 2022</Typography>
         <Typography>
            This privacy policy is intended to provide you with information about the personal information we collect
            and how that information is used and shared.
         </Typography>
         <Typography>
            It is important that you read this privacy policy together with any other privacy policy we may provide on
            specific occasions so that you are fully aware of how and why we are using your data. This privacy policy
            supplements the other notices and is not intended to override them.
         </Typography>
         <Typography>
            We may update this privacy policy from time to time. If we make any material changes, we will notify you
            prior to the changes taking effect by posting a notice on our website and, where appropriate, sending you a
            notification.
         </Typography>

         <Typography variant={"h4"}>If you register and account on the Site:</Typography>
         <Typography>
            We will use your personal information to create an account and support any interaction with the application
            arising from usage of that account.
         </Typography>
         <Typography>
            In order to open an account, you will need to provide us with some required information, including a
            username and email address. You may also provide other optional information such as a display name and an
            account avatar. None of this information needs to contain your legal name. We will never share your account
            email with anyone. Your username, display name, and avatar will be public. They may be visible to other
            people who use EdhElo.
         </Typography>
         <Typography>
            EdhElo will only send you critical account-related emails. For example, you may receive a message when you
            sign up, change account settings, or log in to the site. EdhElo does not have a “newsletter” or other
            marketing email lists.
         </Typography>
      </Paper>
   );
}
