import React from "react";
import { FormControlLabel } from "@mui/material";
import { DEFAULT_LEAGUE_SETTINGS, RemoteStage } from "../stageView/useRemoteStage";
import Checkbox from "@mui/material/Checkbox";
import AdditionalPointsOptions from "./AdditionalPointsOptions";
import DynamicNumberField from "./DynamicNumberField";
import TieBreakerSystemDropdown from "./TieBreakerSystemDropdown";

export default function LeagueOptions({
   remoteStage,
   setError,
}: {
   remoteStage: RemoteStage;
   setError: (key: string, isInError: boolean) => void;
}) {
   const { saveRequest, setSaveRequest } = remoteStage;
   const unstructuredLeagueSettings = saveRequest.unstructuredLeagueSettings;

   return (
      <>
         <FormControlLabel
            control={
               <Checkbox
                  checked={saveRequest.unstructuredLeagueSettings?.allowPlayerSubmissions}
                  onChange={(ev, checked) =>
                     setSaveRequest({
                        ...saveRequest,
                        unstructuredLeagueSettings:
                           saveRequest.unstructuredLeagueSettings != null
                              ? {
                                   ...saveRequest.unstructuredLeagueSettings,
                                   allowPlayerSubmissions: checked,
                                }
                              : null,
                     })
                  }
               />
            }
            label="Allow player submissions"
         />
         <TieBreakerSystemDropdown
            tiebreakerSystem={
               saveRequest.unstructuredLeagueSettings?.tiebreakerSystem || DEFAULT_LEAGUE_SETTINGS.tiebreakerSystem
            }
            onChange={(e) => {
               setSaveRequest({
                  ...saveRequest,
                  unstructuredLeagueSettings:
                     saveRequest.unstructuredLeagueSettings != null
                        ? {
                             ...saveRequest.unstructuredLeagueSettings,
                             tiebreakerSystem: e,
                          }
                        : null,
               });
            }}
            setError={setError}
         />
         <DynamicNumberField
            label={"Points for win"}
            setError={setError}
            errorMapKey={"winPoints"}
            onChange={(points) => {
               if (points >= 0) {
                  setSaveRequest({
                     ...saveRequest,
                     unstructuredLeagueSettings:
                        saveRequest.unstructuredLeagueSettings != null
                           ? {
                                ...saveRequest.unstructuredLeagueSettings,
                                winPoints: points,
                             }
                           : null,
                  });
               }
            }}
            value={saveRequest.unstructuredLeagueSettings?.winPoints}
         />
         <DynamicNumberField
            label={"Points for loss"}
            setError={setError}
            errorMapKey={"lossPoints"}
            isError={(num) => num > 0}
            onChange={(points) => {
               if (points <= 0) {
                  setSaveRequest({
                     ...saveRequest,
                     unstructuredLeagueSettings:
                        saveRequest.unstructuredLeagueSettings != null
                           ? {
                                ...saveRequest.unstructuredLeagueSettings,
                                lossPoints: points,
                             }
                           : null,
                  });
               }
            }}
            value={saveRequest.unstructuredLeagueSettings?.lossPoints}
         />
         <DynamicNumberField
            label={"Points for draw"}
            setError={setError}
            errorMapKey={"drawPoints"}
            onChange={(points) => {
               if (points >= 0) {
                  setSaveRequest({
                     ...saveRequest,
                     unstructuredLeagueSettings:
                        saveRequest.unstructuredLeagueSettings != null
                           ? {
                                ...saveRequest.unstructuredLeagueSettings,
                                drawPoints: points,
                             }
                           : null,
                  });
               }
            }}
            value={saveRequest.unstructuredLeagueSettings?.drawPoints}
         />

         <AdditionalPointsOptions
            additionalPoints={{
               secondPlacePoints: unstructuredLeagueSettings?.secondPlacePoints || 0,
               thirdPlacePoints: unstructuredLeagueSettings?.thirdPlacePoints || 0,
               fourthPlacePoints: unstructuredLeagueSettings?.fourthPlacePoints || 0,
               matchWinPercentageFloor: unstructuredLeagueSettings?.matchWinPercentageFloor || 0.2,
            }}
            setError={setError}
            onChangePoints={(additionalSettings) =>
               setSaveRequest({
                  ...saveRequest,
                  unstructuredLeagueSettings:
                     saveRequest.unstructuredLeagueSettings != null
                        ? {
                             ...saveRequest.unstructuredLeagueSettings,
                             ...additionalSettings,
                          }
                        : null,
               })
            }
         />
      </>
   );
}
