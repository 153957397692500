import { useCallback, useEffect, useMemo, useState } from "react";
import { Maybe, WinType } from "../TYPE";
import { CreateNewGameRequest, useApplicationApi, UserSummary } from "../rest.client/useApplicationApi";
import { CardNameAndImage, CommanderPair } from "../cardFeatures/CardTypes";
import { PlayerPosition } from "./viewGamePage/GameViewer";
import { checkIfDuplicateExists, Consumer, isPairValid, shuffleArray } from "../utils";
import { ANONYMOUS_USER_ID } from "./PlayerPickerComponent";
import { useNavigate } from "react-router-dom";
import { MutableGame } from "./MutableGame";
import { SnapshotSummary } from "../rest.client/useDeckListApi";

interface PlayerData {
   user: Maybe<UserSummary>;
   snap: Maybe<SnapshotSummary>;
   pair: Maybe<CommanderPair>;
}

export default function useMutableGame(id?: string, matchId?: string, naviagateOnSave = true): MutableGame {
   const {
      createGame: { call: createGame },
      findGameById: { call: findGameById, responseData: existingFullGame },
      updateGame: { call: updateGame },
   } = useApplicationApi();

   const [endingTurn, setEndingTurn] = useState<number | undefined>(undefined);
   const [winType, setWinType] = useState<WinType | undefined>(undefined);
   const [highImpactCards, setHighImpactCards] = useState<Array<CardNameAndImage>>([]);
   const [gameWonBy, setGameWonBy] = useState<Array<CardNameAndImage>>([]);
   const [player1Summary, setPlayer1Summary] = useState<Maybe<UserSummary>>(null);
   const [player2Summary, setPlayer2Summary] = useState<Maybe<UserSummary>>(null);
   const [player3Summary, setPlayer3Summary] = useState<Maybe<UserSummary>>(null);
   const [player4Summary, setPlayer4Summary] = useState<Maybe<UserSummary>>(null);
   const [player1Snapshot, setPlayer1Snapshot] = useState<Maybe<SnapshotSummary>>(null);
   const [player2Snapshot, setPlayer2Snapshot] = useState<Maybe<SnapshotSummary>>(null);
   const [player3Snapshot, setPlayer3Snapshot] = useState<Maybe<SnapshotSummary>>(null);
   const [player4Snapshot, setPlayer4Snapshot] = useState<Maybe<SnapshotSummary>>(null);
   const [player1CommanderPair, setPlayer1CommanderPair] = useState<Maybe<CommanderPair>>(null);
   const [player2CommanderPair, setPlayer2CommanderPair] = useState<Maybe<CommanderPair>>(null);
   const [player3CommanderPair, setPlayer3CommanderPair] = useState<Maybe<CommanderPair>>(null);
   const [player4CommanderPair, setPlayer4CommanderPair] = useState<Maybe<CommanderPair>>(null);
   const [description, setDescription] = useState("");
   const [winningUser, setWinningUser] = useState<Maybe<PlayerPosition>>(null);
   const nav = useNavigate();

   const players = useMemo(
      () => [player1Summary, player2Summary, player3Summary, player4Summary],
      [player1Summary, player2Summary, player3Summary, player4Summary]
   );
   const playerSelectedTwice = checkIfDuplicateExists(players.map((p) => p?.id));
   const isAnonymous = players.some((p) => p?.id === ANONYMOUS_USER_ID);
   const isDraw = winningUser == null || (winningUser === "PLAYER_4" && player4Summary == null);

   const player4Valid = (player4Summary == null && player4CommanderPair == null) || player4Summary != null;

   const allPlayerDataList: PlayerData[] = useMemo(() => {
      const players = [
         { user: player1Summary, snap: player1Snapshot, pair: player1CommanderPair },
         {
            user: player2Summary,
            snap: player2Snapshot,
            pair: player2CommanderPair,
         },
         {
            user: player3Summary,
            snap: player3Snapshot,
            pair: player3CommanderPair,
         },
      ];
      if (player4Summary != null) {
         players.push({
            user: player4Summary,
            snap: player4Snapshot,
            pair: player4CommanderPair,
         });
      }
      return players;
   }, [
      player1CommanderPair,
      player1Snapshot,
      player1Summary,
      player2CommanderPair,
      player2Snapshot,
      player2Summary,
      player3CommanderPair,
      player3Snapshot,
      player3Summary,
      player4CommanderPair,
      player4Snapshot,
      player4Summary,
   ]);

   const valid =
      !playerSelectedTwice &&
      player1Summary &&
      player2Summary &&
      player3Summary &&
      player1CommanderPair &&
      isPairValid(player1CommanderPair) &&
      player2CommanderPair &&
      isPairValid(player2CommanderPair) &&
      player3CommanderPair &&
      isPairValid(player3CommanderPair) &&
      player4Valid &&
      (player4CommanderPair == null || isPairValid(player4CommanderPair));

   const loadGame = useCallback(
      (force: boolean) => {
         if ((force || existingFullGame == null) && id != null) {
            findGameById({ pathParams: { id: id } }).then((game) => {
               if (game != null) {
                  setPlayer1Summary(game.player1.user);
                  setPlayer2Summary(game.player2.user);
                  setPlayer3Summary(game.player3.user);
                  game.player4 && setPlayer4Summary(game.player4.user);

                  setPlayer1CommanderPair({
                     commander1: game.player1.commander1,
                     commander2: game.player1.commander2,
                  });
                  setPlayer2CommanderPair({
                     commander1: game.player2.commander1,
                     commander2: game.player2.commander2,
                  });
                  setPlayer3CommanderPair({
                     commander1: game.player3.commander1,
                     commander2: game.player3.commander2,
                  });
                  game.player4 &&
                     setPlayer4CommanderPair({
                        commander1: game.player4.commander1,
                        commander2: game.player4.commander2,
                     });
                  setPlayer1Snapshot(game.player1.snapshot);
                  setPlayer2Snapshot(game.player2.snapshot);
                  setPlayer3Snapshot(game.player3.snapshot);
                  setPlayer4Snapshot(game.player4?.snapshot);
                  setEndingTurn(game.turns);
                  setWinType(game.winType);
                  setGameWonBy(game.gameWonBy);
                  setHighImpactCards(game.highImpactCards);
                  setDescription(game.description);
                  setWinningUser(
                     game.player1.winner
                        ? "PLAYER_1"
                        : game.player2.winner
                        ? "PLAYER_2"
                        : game.player3.winner
                        ? "PLAYER_3"
                        : game?.player4?.winner
                        ? "PLAYER_4"
                        : null
                  );
               }
            });
         }
      },
      [existingFullGame, findGameById, id]
   );

   useEffect(() => {
      loadGame(false);
   }, [loadGame]);

   const gameRequestData: Maybe<CreateNewGameRequest> = useMemo(() => {
      return valid
         ? {
              player1: {
                 userId: player1Summary?.id,
                 wonGame: winningUser === "PLAYER_1",
                 commander1Id: player1CommanderPair?.commander1.id,
                 commander2Id: player1CommanderPair?.commander2?.id,
                 deckSnapshotId: player1Snapshot?.id,
              },
              player2: {
                 userId: player2Summary?.id,
                 wonGame: winningUser === "PLAYER_2",
                 commander1Id: player2CommanderPair.commander1.id,
                 commander2Id: player2CommanderPair?.commander2?.id,
                 deckSnapshotId: player2Snapshot?.id,
              },
              player3: {
                 userId: player3Summary?.id,
                 wonGame: winningUser === "PLAYER_3",
                 commander1Id: player3CommanderPair.commander1.id,
                 commander2Id: player3CommanderPair?.commander2?.id,
                 deckSnapshotId: player3Snapshot?.id,
              },
              player4:
                 player4Summary != null && player4CommanderPair
                    ? {
                         userId: player4Summary?.id,
                         wonGame: winningUser === "PLAYER_4",
                         commander1Id: player4CommanderPair.commander1.id,
                         commander2Id: player4CommanderPair?.commander2?.id,
                         deckSnapshotId: player4Snapshot?.id,
                      }
                    : undefined,
              description: description,
              highImpactCards: highImpactCards.map((c) => c.id),
              gameWonByCards: gameWonBy.map((c) => c.id),
              turns: endingTurn,
              winType: winType,
              matchId: existingFullGame?.matchId || matchId,
           }
         : null;
   }, [
      description,
      endingTurn,
      existingFullGame?.matchId,
      gameWonBy,
      highImpactCards,
      matchId,
      player1CommanderPair?.commander1.id,
      player1CommanderPair?.commander2?.id,
      player1Snapshot?.id,
      player1Summary?.id,
      player2CommanderPair?.commander1.id,
      player2CommanderPair?.commander2?.id,
      player2Snapshot?.id,
      player2Summary?.id,
      player3CommanderPair?.commander1.id,
      player3CommanderPair?.commander2?.id,
      player3Snapshot?.id,
      player3Summary?.id,
      player4CommanderPair,
      player4Snapshot?.id,
      player4Summary,
      valid,
      winType,
      winningUser,
   ]);

   const setPlayerData = useCallback(
      (
         playerData: PlayerData,
         setUser: Consumer<Maybe<UserSummary>>,
         setPair: Consumer<Maybe<CommanderPair>>,
         setSnap: Consumer<Maybe<SnapshotSummary>>
      ) => {
         setPair(playerData.pair);
         setSnap(playerData.snap);
         setUser(playerData.user);
      },
      []
   );

   const randomizePlayers = useCallback(() => {
      const randomized = shuffleArray(allPlayerDataList);
      setPlayerData(randomized[0], setPlayer1Summary, setPlayer1CommanderPair, setPlayer1Snapshot);
      setPlayerData(randomized[1], setPlayer2Summary, setPlayer2CommanderPair, setPlayer2Snapshot);
      setPlayerData(randomized[2], setPlayer3Summary, setPlayer3CommanderPair, setPlayer3Snapshot);
      randomized.length > 3 &&
         setPlayerData(randomized[3], setPlayer4Summary, setPlayer4CommanderPair, setPlayer4Snapshot);
   }, [allPlayerDataList, setPlayerData]);

   const saveGame = useCallback(() => {
      if (gameRequestData != null) {
         if (id != null) {
            return updateGame({
               body: gameRequestData,
               pathParams: { id: id },
            }).then((res) => res != null && naviagateOnSave && nav("/viewGame/" + res.id));
         } else {
            return createGame({
               body: gameRequestData,
            }).then((res) => res != null && naviagateOnSave && nav("/viewGame/" + res.id));
         }
      }
   }, [createGame, gameRequestData, id, nav, naviagateOnSave, updateGame]);

   const usersBySeat: Record<PlayerPosition, Maybe<UserSummary>> = useMemo(
      () => ({
         PLAYER_1: player1Summary,
         PLAYER_2: player2Summary,
         PLAYER_3: player3Summary,
         PLAYER_4: player4Summary,
      }),
      [player1Summary, player2Summary, player3Summary, player4Summary]
   );

   return useMemo(
      () => ({
         player1Summary,
         setPlayer1Summary,
         player2Summary,
         setPlayer2Summary,
         player3Summary,
         setPlayer3Summary,
         player4Summary,
         setPlayer4Summary,
         player1CommanderPair,
         setPlayer1CommanderPair,
         player2CommanderPair,
         setPlayer2CommanderPair,
         player3CommanderPair,
         setPlayer3CommanderPair,
         player4CommanderPair,
         setPlayer4CommanderPair,
         setPlayer1Snapshot,
         setPlayer2Snapshot,
         setPlayer3Snapshot,
         setPlayer4Snapshot,
         player1Snapshot,
         player2Snapshot,
         player3Snapshot,
         player4Snapshot,
         description,
         setDescription,
         existingFullGame,
         winningUser,
         randomizePlayers,
         setWinningUser,
         players,
         isAnonymous,
         isDraw,
         saveGame,
         endingTurn,
         setEndingTurn,
         winType,
         setWinType,
         highImpactCards,
         setHighImpactCards,
         gameWonBy,
         setGameWonBy,
         loadGame,
         usersBySeat,
         valid: Boolean(valid),
      }),
      [
         player1Summary,
         player2Summary,
         player3Summary,
         player4Summary,
         player1CommanderPair,
         player2CommanderPair,
         player3CommanderPair,
         player4CommanderPair,
         player1Snapshot,
         player2Snapshot,
         player3Snapshot,
         player4Snapshot,
         description,
         existingFullGame,
         winningUser,
         players,
         isAnonymous,
         isDraw,
         saveGame,
         endingTurn,
         winType,
         highImpactCards,
         gameWonBy,
         loadGame,
         usersBySeat,
         valid,
      ]
   );
}
