import React, { memo, ReactElement, ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { Box, IconButton, MobileStepper, Theme } from "@mui/material";
import { SxProps } from "@mui/system";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

const rootContainerSx: SxProps<Theme> = {
   position: "absolute",
   left: 0,
   top: 0,
   width: "100%",
   height: "100%",
   display: "flex",
};

const contentContainerSx: SxProps = {
   left: 0,
   top: 0,
   position: "absolute",
   width: "100%",
   height: "100%",
};

const stepperSx: SxProps = {
   backgroundColor: "rgba(0,0,0,0.2)",
   backdropFilter: "blur(5px)",
};

interface IMobileCreateOrUpdateGameContentComponentProps {
   stepContents: Array<ReactElement | ReactNode>;
}

const MobileCreateOrUpdateGameContentComponent: React.FC<IMobileCreateOrUpdateGameContentComponentProps> = ({
   stepContents,
}) => {
   const [activeStep, setActiveStep] = useState(0);

   const numOfSteps = useMemo(() => stepContents.length, [stepContents.length]);
   const backButtonDisabled = useMemo(() => activeStep <= 0, [activeStep]);
   const nextButtonDisabled = useMemo(() => activeStep >= numOfSteps - 1, [activeStep, numOfSteps]);

   useEffect(() => {}, []);

   const nextButtonHandler = useCallback(() => {
      setActiveStep((prev) => (prev + 1 <= numOfSteps - 1 ? prev + 1 : prev));
   }, [numOfSteps]);

   const previousButtonHandler = useCallback(() => {
      setActiveStep((prev) => (prev - 1 >= 0 ? prev - 1 : 0));
   }, []);

   const content = useMemo(() => stepContents[activeStep], [activeStep, stepContents]);

   return (
      <Box sx={rootContainerSx} id={"mobileCreateOrUpdateGameContentComponentRoot"}>
         <Box sx={contentContainerSx} id={"mobileCreateOrUpdateGameContentComponentContent"}>
            {content}
         </Box>
         <MobileStepper
            sx={stepperSx}
            variant={"dots"}
            steps={numOfSteps}
            activeStep={activeStep}
            backButton={
               <IconButton onClick={previousButtonHandler} disabled={backButtonDisabled}>
                  <ArrowBackIosNewIcon color={backButtonDisabled ? "disabled" : "primary"} />
               </IconButton>
            }
            nextButton={
               <IconButton onClick={nextButtonHandler} disabled={nextButtonDisabled}>
                  <ArrowForwardIosIcon color={nextButtonDisabled ? "disabled" : "primary"} />
               </IconButton>
            }
         />
      </Box>
   );
};

export default memo(MobileCreateOrUpdateGameContentComponent);
