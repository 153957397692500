import React, { useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Alert, Box, Button, Typography, useTheme } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { RoundFullResponse } from "../../../../rest.client/useStagesApi";
import { RemoteStage } from "../useRemoteStage";
import OkCancelDialog from "../../../../OkCancelDialog";
import useRemoteRound from "../useRemoteRound";
import { useNavigate } from "react-router-dom";
import { toStageViewPage } from "../../../../navPaths";
import RoundTimerEditDialog from "../../../rounds/RoundTimerEditDialog";
import MatchControls from "../MatchControls";

export default function OrganiserRoundControls({
   remoteStage,
   selectedRound,
}: {
   remoteStage: RemoteStage;
   selectedRound: RoundFullResponse;
}) {
   const remoteRound = useRemoteRound(remoteStage, selectedRound);
   const theme = useTheme();
   const nav = useNavigate();

   const [openCloseDialog, setOpenCloseDialog] = useState(false);
   const [openActivateDialog, setOpenActivateDialog] = useState(false);
   const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
   const [openEditRoundTimer, setOpenEditRoundTimer] = useState(false);

   const canActivateRound = selectedRound.status === "SCHEDULED";

   return (
      <>
         <Accordion defaultExpanded={true}>
            <AccordionSummary aria-controls="panel1d-content" expandIcon={<ExpandMoreIcon />}>
               <Typography>{"Round " + remoteRound.round.roundNumber + " organiser controls"}</Typography>
            </AccordionSummary>
            <AccordionDetails>
               <Box sx={{ marginTop: theme.spacing(2), display: "flex", gap: theme.spacing(1), flexWrap: "wrap" }}>
                  <Button
                     variant={"contained"}
                     disabled={!canActivateRound}
                     onClick={() => setOpenActivateDialog(true)}
                  >
                     Activate round
                  </Button>
                  <Button disabled={!remoteRound.isActive} onClick={() => setOpenEditRoundTimer(true)}>
                     Round timer
                  </Button>
                  <Button disabled={!remoteRound.canCloseRound} onClick={() => setOpenCloseDialog(true)}>
                     Close round
                  </Button>
                  {remoteStage.stage?.canModifyRounds && (
                     <Button disabled={!remoteRound.canDelete} onClick={() => setOpenDeleteDialog(true)}>
                        Delete round
                     </Button>
                  )}
                  <MatchControls remoteStage={remoteStage} selectedRound={selectedRound} />
               </Box>
            </AccordionDetails>
         </Accordion>
         <RoundTimerEditDialog
            open={openEditRoundTimer}
            onClose={() => setOpenEditRoundTimer(false)}
            remoteRound={remoteRound}
         />
         <OkCancelDialog
            open={openCloseDialog}
            onCancel={() => setOpenCloseDialog(false)}
            onOk={async () => {
               await remoteRound.close();
               setOpenCloseDialog(false);
            }}
            title={"Close this Round?"}
            message={
               "Are your sure you want to close this round? Once the round is closed you will not be able to add or remove matches or games."
            }
         />
         <OkCancelDialog
            open={openActivateDialog}
            onCancel={() => setOpenActivateDialog(false)}
            onOk={async () => {
               await remoteRound.activate();
               setOpenActivateDialog(false);
            }}
            title={"Activate this round?"}
            message={"Are you ready to activate this round?"}
         />
         <OkCancelDialog
            open={openDeleteDialog}
            onCancel={() => setOpenDeleteDialog(false)}
            onOk={async () => {
               await remoteRound.deleteRound();
               setOpenDeleteDialog(false);
               remoteStage.remoteTournament.tournament &&
                  remoteStage.stage &&
                  nav(toStageViewPage(remoteStage.remoteTournament.tournament.id, remoteStage.stage.id));
            }}
            title={"Delete this round?"}
            message={
               <Box>
                  <Alert severity={"warning"}>
                     Deleting a round will permanently remove the record of the round and all games but will <b>NOT</b>{" "}
                     roll back Elo allocation previously applied from approved games.
                  </Alert>
                  <Typography>Are you sure you want to delete this round.</Typography>
               </Box>
            }
         />
      </>
   );
}
